import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import close from '../../Images/close.png';
import img1 from '../../Images/Group_openticket.png'
import img2 from '../../Images/gift.png'
import img3 from '../../Images/Vectorpopupticket.svg'
import img4 from '../../Images/Vectorpopupticket-1.svg'
import img5 from '../../Images/Grouppopupticket.svg'
import img6 from '../../Images/Grouptickret-2.svg'
import './Popupticket.scss'

const PopupTicketData = [
    {
        id: 1,
        image: `${img1}`,
        title: "Payment",
        desc: "is assigned a value but never used        showEditModal",
    },
    {
        id: 2,
        image: `${img3}`,
        title: "Trading",
        desc: "is assigned a value but never used        showEditModal",
    },

    {
        id: 3,
        image: `${img4}`,
        title: "Technical",
        desc: "is assigned a value but never used        showEditModal",
    },
    {
        id: 4,
        image: `${img5}`,
        title: "VPS",
        desc: "is assigned a value but never used        showEditModal",
    },
    {
        id: 5,
        image: `${img6}`,
        title: "Partnerships",
        desc: "is assigned a value but never used        showEditModal",
    },
    {
        id: 6,
        image: `${img2}`,
        title: "Bonus",
        desc: "is assigned a value but never used        showEditModal",
    }
];

const Popupticket = ({ name, setShow, show }) => {
    const [showEditModal, setShowEditModal] = useState(false);

    const handleEditModalShow = () => {
        setShowEditModal(true);
    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    return (
        <div>
            <Modal.Header className='cusheader mb-0'>
                <div className='left'>
                    <button onClick={() => setShow(false)}> <img src={close} alt="close" /> </button>
                </div>
            </Modal.Header>
            <div className='accountsmain p-0' >
                <Modal.Body className='ticketdiv'>
                    <div className='card'>
                        <div className='card-body'>
                            <h3>Open a ticket</h3>
                            <p> is assigned a value but never used no-unused-vars Line
                            </p>

                            {
                                PopupTicketData && PopupTicketData.map((events, index) => {
                                    return (
                                        <div className='pop-up-ticket-card mt-2' key={index} style={{cursor:'pointer'}}>
                                           <div className='titlediv'>
                                                <img src={events.image} alt='images'></img>
                                                <h2>{events.title}</h2>
                                           </div>
                                            <p>{events.desc}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </div>
        </div>
    );
}

export default Popupticket;
