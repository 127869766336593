import React from 'react'
import NewHeader from '../Header/NewHeader'
import topwhite from '../../Images/top_white.png'
import bottowhite from '../../Images/arrow-down-white.png'

const Moretradingsignals = () => {
  return (
    <div className='accountsmain'>
        <NewHeader title="Trading SIgnals" addicon="none"/>
        <div className="tradingsignaldiv">
            <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-favoriate" type="button" role="tab" aria-controls="nav-real" aria-selected="true" > Favourites </button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-archived" aria-selected="false" > All </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active text-start" id="nav-favoriate" role="tabpanel" aria-labelledby="nav-home-tab" >               
                <div className='topmovermain'>
                    <h3>Favourites</h3>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen' />USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>                
              <div className="tab-pane fade text-start" id="nav-all" role="tabpanel" aria-labelledby="nav-profile-tab" >
                <div className='topmovermain'>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={bottowhite} className='gbred'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen' />USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="listdiv">
                        <div className="card">
                            <div className="card-body">
                                <div className="headercontant">
                                    <span><img src={topwhite} className='gbreen'/>USD/JPY</span>
                                    <p>2:38 PM</p>
                                </div>
                                <div className="bottomdiv">
                                    <b>Intraday:the downside prevails.</b>
                                    <p>Short positions below 1.3150 with target at 1.3090 & 1.3060 in extension.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='form-control mt-4 py-2 ' style={{background:"#a5c0be87",fontSize:"18px"}}>Show more</button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
  )
}

export default Moretradingsignals