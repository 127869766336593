import React from 'react'
import doubleuser from '../../Images/doubleusers.png'

const Referalprogram = () => {
  return (
    <div className='referaldiv'>
        <h4 className='profileheading'>Referal program</h4>
        <div className="card">
            <div className="card-body">
                <span><img src={doubleuser}/><b style={{fontSize:"12px"}}>Earn a stable income by introducing clients to {process.env.REACT_APP_COMPANY_NAME}.</b></span>
            </div>
        </div>
    </div>
  )
}

export default Referalprogram