import React, { useState } from 'react'
import NewHeader from '../Header/NewHeader'
import calander from '../../Images/calendar.png'
import Bottomdrawer from '../Bottomdrawer/Bottomdrawer';
import AllMovers from './AllMovers';
import { useSelector } from 'react-redux';


const Moremovers = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const { topMovers, loading, error } = useSelector((state) => state.trade);
    
    // const CDS =    

  return (
    <div className='accountsmain'>
            <NewHeader title="Top Movers" addicon="none"/>
            <div className="topmoversdiv">  
                <div className="btndiv">
                    {/* <button onClick={()=>toggleDrawer()}><img src={calander}/> Today</button> */}
                </div>
                <div className='topmovermain'>      
                    <AllMovers title="MCX" curencysymbol="&#8377;"/>              
                    <AllMovers title="NFO" curencysymbol="&#8377;"/>
                    <AllMovers title="FX" curencysymbol="&#x24;"/>
                    <AllMovers title="US" curencysymbol="&#x24;"/>
                    <AllMovers title="CRYPTO"  curencysymbol="&#x24;"/>                    
                    <AllMovers title="CDS" curencysymbol="&#x24;"/>
                </div>
            </div>
            

            <Bottomdrawer isDrawerOpen={isDrawerOpen} changedrawer="topmovers"/>
    </div>
  )
}

export default Moremovers