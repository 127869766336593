import React from 'react'
import logouticon from '../../Images/logout.svg'
import { useDispatch } from 'react-redux';
import { signOut } from '../../Redux/actions/authActions';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = ({userdata}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = ()=>{ 
        localStorage.removeItem('userData');
        Cookies.remove("userData");
        dispatch(signOut(navigate));
        
    }
  return (
    <div className='supportdiv' style={{marginTop:"20px"}}>
        <div className="card" onClick={()=>handleLogout()} style={{cursor:"pointer"}}>
            <div className="card-body">
                <div className='innerdiv mb-0'>
                    <div>
                        {/* <img src={logouticon} alt="" /> */}
                        <i class="fa fa-sign-out" aria-hidden="true" style={{color: "red", fontSize: "1.3em"}}></i>
                    </div>
                    <div>
                        <button><p className='mb-0'>Log Out <small> ({userdata?.payload?.email})</small></p></button>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Logout