import React from 'react'
import accountimage from '../../Images/account.svg'
import tradeimage from '../../Images/trade.svg'
import marketimage from '../../Images/market.svg'
import performanceimage from '../../Images/performance.svg'
import profileimage from '../../Images/profile.svg'
import './Footer.css'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='fixedfooter'>
      <div className='footerdiv'>
        <div className={({ isActive }) => (isActive ? 'active-div' : '')}>
          <NavLink 
            to="/accounts" 
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src={accountimage} alt="" width="20"/>
            <p>Accounts</p>
          </NavLink>
        </div>
        <div className={({ isActive }) => (isActive ? 'active-div' : '')}>
          <NavLink 
            to="/trade" 
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src={tradeimage} alt="" width="20"/>
            <p>Trade</p>
          </NavLink>
        </div>
        <div className={({ isActive }) => (isActive ? 'active-div' : '')}>
          <NavLink 
            to="/markets" 
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src={marketimage} alt="" width="20"/>
            <p>Markets</p>
          </NavLink>
        </div>
        <div className={({ isActive }) => (isActive ? 'active-div' : '')}>
          <NavLink 
            to="/performance" 
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src={performanceimage} alt="" width="20"/>
            <p>Performance</p>
          </NavLink>
        </div>
        <div className={({ isActive }) => (isActive ? 'active-div' : '')}>
          <NavLink 
            to="/profile" 
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src={profileimage} alt="" width="20"/>
            <p>Profile</p>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Footer
