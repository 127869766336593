import React from 'react'
import { getUserToken } from '../../helper/getToken'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUserPosition } from '../../Redux/actions/userAction';
import { fetchTrade } from '../../Redux/actions/tradeAction';
import { fetchOrder } from '../../Redux/actions/orderAction';

const CancelOrderdrawer = ({ opencancelorder,onClose,calculateProfitLoss,moverData,tradeVol,takeProfitPlus,stopLossPlus,currentData,setPopupMessage,setShowPopup,isUpdating,setIsUpdating}) => {

 const token=getUserToken();
 const navigate=useNavigate();
 const dispatch=useDispatch();
  const handleSubmitPartialClose=()=>{
  const getQuantityOfShares=()=>{
    let qty=0;
    if(tradeVol?.type=='lots'){
      if(tradeVol?.val)
      qty=Number(Number(currentData?.lotsize)*Number(tradeVol?.val));
      else 
      qty=Number(moverData?.qty)
      return qty;
    }else if(tradeVol?.type=='usd'){
      if(tradeVol?.val)
      qty=Number(tradeVol?.val)
      else
      qty=Number(moverData?.qty)
      return qty;
    }else{
      return moverData?.qty;
    }
  }
    // let data={
    //   "exchange": moverData?.exchange, 
    //   "symbol": moverData?.symbol, 
    //   "price": moverData?.cmp||moverData?.avg_price, 
    //   "order_type": "market", 
    //   "type": moverData?.type=='buy'?'sell':'buy', 
    //   "qty":getQuantityOfShares(), 
    //   "from_custom_device_id": "web", 
    //   "take_profit": takeProfitPlus?.val, 
    //   "stoploss": stopLossPlus?.val ,
    //   "profit_loss_in": takeProfitPlus?.type,
    // }

    let data = {
      token:moverData?.token,
      id:moverData?.id,
      exchange: moverData?.exchange,
      symbol: moverData?.symbol,
      price: moverData?.cmp || moverData?.avg_price,
      order_type: "market",
      type: moverData?.type === 'buy' ? 'sell' : 'buy',
      qty: getQuantityOfShares(),
      from_custom_device_id: "web",
      take_profit: takeProfitPlus?.val,
      stoploss: stopLossPlus?.val,
      profit_loss_in: takeProfitPlus?.type,
      // ...(takeProfitPlus?.val || stopLossPlus?.val || takeProfitPlus?.type > 0 ? { is_updated: "Yes" } : {}),
      ...(isUpdating ? { is_updated: "Yes" } : {})
    };
    let config={};
    if(moverData.exchange=='CRYPTO'){
      config.url=`${process.env.REACT_APP_API_FRONTEND}client/order/pushCrypto`;
      config.method='POST'
      config.headers={
        Authorization:`Bearer ${token}`
      }
      config.data=data
    }else if(moverData.exchange=='FOREX'){
      config.url=`${process.env.REACT_APP_API_FRONTEND}client/order/pushForex`;
      config.method='POST'
      config.headers={
        Authorization:`Bearer ${token}`
      }
      config.data=data
    }
    axios.request(config).then(response=>{
      setPopupMessage(response?.data?.message);     
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
      dispatch(fetchOrder(token))
      dispatch(fetchTrade(token))
      dispatch(fetchUserPosition(token));
      onClose();
    }).catch(err=>{
      setPopupMessage(err?.response?.data?.message||err?.message||"something went wrong")
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); 
      }, 2000);
      onClose();
    })
    
 }
 
  return (

    <div onClick={(e)=>e.stopPropagation()} className={`drawer ${opencancelorder ? 'open' : ''}`}>
        <div  className='drawer-content'>
            <div className='drawerconteninner'>
                <h4>Close Position ?</h4>
                <div className='cancelorderinner'>
                    <span><p>Quantity</p><p>{tradeVol?.type=='lots'?(tradeVol?.val?`${tradeVol?.val} lots`:moverData?.qty):`${tradeVol?.val||moverData?.qty}`}</p></span>
                    <span><p>Closing price</p><p>{moverData?.avg_price}</p></span>
                    <span><p>Estimated P&L</p><p>{`${calculateProfitLoss} USD`}</p></span>
                </div>
                <button onClick={handleSubmitPartialClose} className='btn btnconfirm'>Confirm</button><br/>
                <button onClick={onClose} className='btn btncancel'>Cancel</button>
            </div>
        </div>        
    </div>
  )
}

export default CancelOrderdrawer