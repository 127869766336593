import React, { useState } from 'react'
import NewHeader from '../Header/NewHeader'
import checked from '../../Images/checked.png';

const tradingtermainalData = [
    {
        id: 1,
        title: 'Tradeyaar',
    },
    {
        id: 2,
        title: 'Trading View',
    },
    {
        id: 3,
        title: 'Built-in Meta Trader 5',
    },
    {
        id: 4,
        title: 'Meta Trader 5 app',
    }
];

const Tradingterminal = () => {
    // Initialize the checkedItems state based on the length of the tradingtermainalData array
    const [checkedItems, setCheckedItems] = useState(
        new Array(tradingtermainalData.length).fill(false)
    );

    const toggleCheck = (index) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.map((item, i) => (i === index ? !item : item))
        );
    };

    return (
        <div className='accountsmain'>
            <NewHeader title="Tradingterminal" addicon="none" />

            <div className="tradingdiv">
                <div className="card">
                    <div className="card-body">
                        {tradingtermainalData.map((tradedata, index) => (
                            <div className='indiceslist' key={tradedata.id} onClick={() => toggleCheck(index)}>
                                <div className='leftlist'>
                                    <p>{tradedata.title}</p>
                                </div>
                                <div className='rightlist'>
                                    <span style={{ marginRight: '10px' }}>
                                        {/* Render checked image if isChecked is true */}
                                        {checkedItems[index] ? <img src={checked} alt="checked" /> : ''}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tradingterminal;
