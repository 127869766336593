import React, { useState } from 'react'
// import leftarrow from '../../Images/angle-left.png';
import leftarrow from '../../Images/chevronleft.svg';
import { useNavigate } from 'react-router-dom';
import './Newheader.css'
import Bottomdrawer from '../Bottomdrawer/Bottomdrawer';
import Addalert from '../Bottomdrawer/Addalert';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const NewHeader = ({title,addicon}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
  };

  const deletaData = () => {
    Swal.fire({
      // title: "Do you want to remove all alerts?",
      text: "Do you want to remove all alerts?",
      // icon: "warning",
      showCancelButton: true,      
      confirmButtonText: "Yes", 
      cancelButtonText: "No",     
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button' 
      }
    })
    
    
  };
    const navigate = useNavigate();
    const handleClick = () => {       
        navigate(-1);
    };

  return (
    <div className='newheader'>
        <div className='left'>
          <button onClick={handleClick}> <img src={leftarrow} alt="back"/> </button>
          <h4 className='mb-0'>{title.toUpperCase()}</h4>         
        </div> 
        <div className='right' style={{display:addicon}}>
            <div className='rightinner'>
                {/* <button onClick={deletaData}><i class="fa fa-trash" aria-hidden="true"></i></button> */}
                <button onClick={toggleDrawer}><i class="fa fa-plus" aria-hidden="true"></i></button>
            </div>
        </div> 
        <Addalert isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} changedrawer = "bottomdrawer"/>      
    </div>
  )
}

export default NewHeader