import './App.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import LoginSignup from './Pages/LoginSignup';
import SignRegister from './Pages/SignRegister';
import SignIn from './Pages/SignIn';
import Residence from './Pages/Residence';
import EnterEmail from './Pages/EnterEmail';
import ChoosePassword from './Pages/ChoosePassword';
import Dashboard from './Pages/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css'
import Tradepages from './Pages/Tradepages';
import Marketpage from './Pages/Marketpage';
// import Performance from './Pages/Performancepage';
import Performancepage from './Pages/Performancepage';
import Profile from './Pages/Profile';
import Topnewsdetails from './Components/Topnews/Topnewsdetails';
import Upcomingeventsdetails from './Components/Upcommingevents/Upcomingeventsdetails';
import Settings from './Components/Profile/Settings';
import Tradingterminal from './Components/Settings/Tradingterminal';
import Moremovers from './Components/Topmovers/Moremovers';
import Moretradingsignals from './Components/Trandingsignals/Moretradingsignals';
import Showmoreevents from './Components/Upcommingevents/Showmoreevents';
import Accountdeposite from './Components/TopCard/Accountdeposite';
import Accountwithdraw from './Components/TopCard/Accountwithdraw';
import Accountdetails from './Components/TopCard/Accountdetails';
import Negativebalanceprotection from './Components/Profile/Negativebalanceprotection';
import Swapefree from './Components/Profile/Swapefree';
import Privateserver from './Components/Profile/Privateserver';
import Pricealerts from './Pages/Pricealerts';
import Topmorenews from './Components/Topnews/Topmorenews';
import TradingDetails from './Pages/TradingDetails';
import Changepassword from './Pages/Changepassword';
import Customname from './Components/TopCard/Customname';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Notificationsetting from './Components/Settings/Notificationsetting';
import Changelanguage from './Components/Settings/Changelanguage';
import Emailverification from './Components/Settings/Emailverification';
import Changeemail from './Components/Popupmodal/Changeemail';
import Mobileverification from './Components/2-stepverification/Mobileverification';
import Otpscreen from './Pages/Otpscreen';
import Forgotpassword from './Pages/Forgotpassword';
import Buypage from './Components/Topmovers/Buypage';
import Sellpage from './Components/Topmovers/Sellpage';
import { Helmet } from 'react-helmet';
import MobileFrame from './Components/MobileFrame';
import ProtectedRoute from './helper/ProtectedRoute';
import TradingSocket from './TradingSocket';
import Splashscreen from './Splashscreen';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserToken } from './helper/getToken';
import { fetchUser, fetchUserPosition } from './Redux/actions/userAction';
import { Toast } from 'react-bootstrap';
import { fetchOrder } from './Redux/actions/orderAction';
import { fetchTopmoversList, fetchTrade } from './Redux/actions/tradeAction';
import Transactionhistory from './Pages/Transactionhistory';
import Stockdetailsmain from './Pages/Stockdetailsmain';
import { connectNotifySocket } from './Redux/actions/socketActions';
import LegalDocument from './Components/Profile/LegalDocument';
import AntiInspect from './AntiInspect';
import LedgerPage from './Pages/LedgerPage';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';



function App() {
  const dispatch=useDispatch();
  const token=getUserToken();
  const {user}=useSelector((state) => state.user)
  const { notifySocket } = useSelector((state) => state.sockets);



  //console.log("notifySocketnotifySocketnotifySocketnotifySocketnotifySocket===",notifySocket);

  
  
  useEffect(() => {
          const fetchData = async () => {
              if (token) {
                  try {
                      await Promise.all([
                          dispatch(fetchOrder(token)),
                          dispatch(fetchTrade(token)),
                          dispatch(fetchUserPosition(token)),                          
                      ]);
                  } catch (error) {
                      console.error("Failed to fetch data:", error);
                  }
              }
          };
      
          fetchData();
  }, [dispatch, token]);
  useEffect(()=>{
    const fetchTopMoversData = async () => {
          if (token) {
            try {
              await dispatch(fetchTopmoversList(token));
            } catch (error) {
              console.error('Failed to fetch user data:', error);
            }
          }
        };
    
        fetchTopMoversData();
        // dispatch(fetchTopmoversList(token));
  },[])

  useEffect(() => {
    if (!user || !user.payload) {
      dispatch(fetchUser( token));
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    dispatch(connectNotifySocket()); // Dispatch the connectNotifySocket action
  }, [dispatch]);

 

  // useEffect(() => {
  //   if (notifySocket && user) {
  //     notifySocket.emit("authenticate", token);  
  //     console.log("tokentokentokentokentokentokentokentoken=======>",token);
  //     const handleNotify = (data) => {
  //       console.log("datadatadatadatadatadatadatadatauclient_idclient_idclient_idclient_id",data);
  //       if (data?.client_id === user?.payload?.client_account?.id && data?.visible === "yes") {
  //         if (data.type === "success") {;
  //           Swal.fire({
  //               width: "20rem",
  //               title: `${data.message}`,
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showConfirmButton: false,                      
  //               customClass: {
  //                   title: 'swaltext'
  //               } 
  //           });
  //         } else {
  //           Swal.fire({
  //             width: "20rem",
  //             title: `${data.message}`,
  //             timer: 2000,
  //             timerProgressBar: true,
  //             showConfirmButton: false,                      
  //             customClass: {
  //                 title: 'swaltext'
  //             } 
  //         }); 
  //         }
  //       }
  //     };
  
  //     // Attach the listener
  //     //notifySocket.on("notify", handleNotify);
  //     notifySocket.on("notify", handleNotify);
  //     return () => {
  //       notifySocket.off("notify", handleNotify);
  //     };
  //   }
  // }, [notifySocket, token]);

  

  useEffect(() => {
    if (notifySocket) {
      notifySocket.emit("authenticate", token);  
      const handleNotify = (data) => {
        if (data.visible === "yes") {
          if (data.type === "success") {;
            Swal.fire({
                width: "20rem",
                title: `${data.message}`,
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,                      
                customClass: {
                    title: 'swaltext'
                } 
            });
            dispatch(fetchOrder(token));
            dispatch(fetchTrade(token));
            dispatch(fetchUserPosition(token));
            // Fetch user after 30 seconds           
          } else {
            Swal.fire({
              width: "20rem",
              title: `${data.message}`,
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,                      
              customClass: {
                  title: 'swaltext'
              } 
          });
          }
        }
      };
  
      // Attach the listener
      notifySocket.on("notify", handleNotify);
      return () => {
        notifySocket.off("notify", handleNotify);
      };
    }
  }, [notifySocket, token]);



  const navigate = useNavigate();

  // useEffect(()=>{
  //   const tokenDatalocal = localStorage.getItem("userData"); 
  //     const tokenDatalocalCookie = Cookies.get("userData");
  //     let params=window.location.href;
  //     params=params.split("/");
  //     let param=params[params.length-1];
      
  //     if(tokenDatalocal !== ""&&tokenDatalocal!== null&&tokenDatalocal!== undefined&&tokenDatalocal!=="undefined"&&tokenDatalocal!=="null"){
  //       if(param!==""&&param!==undefined&&param!=="undefined"&&param!=="null"&&param!==null&&param!=="signin"&&param!=="signup"&&param!=="residence"&&param!=="otpscreen"&&param!=="enteremail"&&param!=="forgotpassword"&&param!=="choosepassword"){
  //         navigate(`/${param}`);
  //       }else{
  //         navigate("/accounts");
  //       }
  //     }else{
  //       navigate("/signin");
  //     }
  // },[])
  const tokenDatalocalCookie = Cookies.get("userData");
  // console.log("tokenDatalocalCookietokenDatalocalCookietokenDatalocalCookie",tokenDatalocalCookie);
  useEffect(()=>{
    // const tokenDatalocal = localStorage.getItem("userData"); 
      // const tokenDatalocalCookie = Cookies.get("userData");
      // console.log("tokenDatalocalCookietokenDatalocalCookietokenDatalocalCookie",tokenDatalocalCookie);
      let params=window.location.href;
      params=params.split("/");
      let param=params[params.length-1];
      
      if(tokenDatalocalCookie !== ""&&tokenDatalocalCookie!== null&&tokenDatalocalCookie!== undefined&&tokenDatalocalCookie!=="undefined"&&tokenDatalocalCookie!=="null"){
        if(param!==""&&param!==undefined&&param!=="undefined"&&param!=="null"&&param!==null&&param!=="signin"&&param!=="signup"&&param!=="residence"&&param!=="otpscreen"&&param!=="enteremail"&&param!=="forgotpassword"&&param!=="choosepassword"){
          navigate(`/${param}`);
        }else{
          navigate("/accounts");
        }
        // window.history.pushState(null, "", window.location.href);
        // window.addEventListener("popstate", () => {
        //   window.history.pushState(null, "", window.location.href);
        // });
        window.history.pushState(null, "", window.location.href);
      const handlePopState = () => {
        window.history.pushState(null, "", window.location.href);
      };

      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
      }else{
        navigate("/signin");
      }
  },[tokenDatalocalCookie])

  
  return (
    <div className='mobileview' >

      {/* <AntiInspect/> */}
      {/* <Helmet>
        <meta name="viewport" content="width=375, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet> */}
      {/* <MobileFrame> */}
        {/* Your main app content here */}
        {/* <h1>Mobile-only website</h1>
        <p>This site is restricted to mobile dimensions.</p> */}
        {/* <Router></Router> */}

        <Routes>
          <Route path="/" element={<SignRegister />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/residence" element={<Residence />} />
          <Route path="/otpscreen" element={<Otpscreen />} />
          <Route path="/enteremail" element={<EnterEmail />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />          
          <Route path="choosepassword" element={<ChoosePassword />} />
          <Route path="/accounts" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/trade" element={<ProtectedRoute><Tradepages /></ProtectedRoute>} />
          <Route path="/markets" element={<ProtectedRoute><Marketpage /></ProtectedRoute>} />
          <Route path="/performance" element={<ProtectedRoute><Performancepage /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/topnewsdetails" element={<ProtectedRoute><Topnewsdetails /></ProtectedRoute>} />
          <Route path="/upcomingeventsdetails" element={<ProtectedRoute><Upcomingeventsdetails /></ProtectedRoute>} />
          <Route path="/showmoreevents" element={<ProtectedRoute><Showmoreevents /></ProtectedRoute>} />
          <Route path="/negativebalance" element={<ProtectedRoute><Negativebalanceprotection /></ProtectedRoute>} />
          <Route path="/swapefree" element={<ProtectedRoute><Swapefree /></ProtectedRoute>} />
          <Route path="/privateserver" element={<ProtectedRoute><Privateserver /></ProtectedRoute>} />
          <Route path="/topmorenews" element={<ProtectedRoute><Topmorenews /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/pricealert" element={<ProtectedRoute><Pricealerts /></ProtectedRoute>} />
          <Route path="/tradingterminal" element={<ProtectedRoute><Tradingterminal /></ProtectedRoute>} />
          <Route path="/moretopmovers" element={<ProtectedRoute><Moremovers /></ProtectedRoute>} />
          <Route path="/moretradingsignals" element={<ProtectedRoute><Moretradingsignals /></ProtectedRoute>} />
          <Route path="/accountdeposite" element={<ProtectedRoute><Accountdeposite /></ProtectedRoute>} />
          <Route path="/accountwithdraw" element={<ProtectedRoute><Accountwithdraw /></ProtectedRoute>} />
          <Route path="/transactionhistory" element={<ProtectedRoute><Transactionhistory /></ProtectedRoute>} />
          <Route path="/accountdetails" element={<ProtectedRoute><Accountdetails /></ProtectedRoute>} />
          <Route path="/tradingdetails" element={<ProtectedRoute><TradingDetails /></ProtectedRoute>} />
          <Route path="/changepassword" element={<ProtectedRoute><Changepassword /></ProtectedRoute>} />
          <Route path="/customname" element={<ProtectedRoute><Customname /></ProtectedRoute>} />
          <Route path="/notificationsetting" element={<ProtectedRoute><Notificationsetting /></ProtectedRoute>} />
          <Route path="/languagesetting" element={<ProtectedRoute><Changelanguage /></ProtectedRoute>} />
          <Route path="/buy" element={<ProtectedRoute><Buypage /></ProtectedRoute>} />
          <Route path="/sell" element={<ProtectedRoute><Sellpage /></ProtectedRoute>} />
          <Route path='/stockdetails' element={<ProtectedRoute><Stockdetailsmain/></ProtectedRoute>}/>
          <Route path="/socketdata" element={<ProtectedRoute><TradingSocket /></ProtectedRoute>} />
          <Route path="/legaldocument" element={<ProtectedRoute><LegalDocument /></ProtectedRoute>} />
          <Route path="/userledger" element={<ProtectedRoute><LedgerPage /></ProtectedRoute>} />

          
        </Routes>
      
    </div>
  );
}

export default App;
