import React, { useEffect, useState } from 'react';
import NewHeader from '../Header/NewHeader';
import AccountSettings from './AccountSettings';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import { fetchUserPosition } from '../../Redux/actions/userAction';
import { fetchNewLeverage } from '../../helper/api_helper';
import { useNavigate } from 'react-router-dom';
import { formatNumber, getCurrencySymbol } from '../../helper/commonFunctions';

const Accountdetails = () => {
  const [livePrices, setLivePrices] = useState({});
  const { user, position, loading } = useSelector((state) => state.user);
  const [leverage, setLeverage] = useState(0);
  const { priceSocket } = useSelector((state) => state.sockets);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'funds');

  const dispatch = useDispatch();
  const token = getUserToken();
  const navigate = useNavigate();

  const clientdetails = position?.payload || {};
  const currentPositions = clientdetails?.positions?.[selectedCurrency] || [];

  // Fetch leverage data
  const fetchLeverageForCurrency = async () => {
    let exchange = selectedCurrency === "INR" ? { exchange: "INR" } : { exchange: "USD" };
    const response = await fetchNewLeverage(token, exchange);
    setLeverage(response?.data?.payload);
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchUserPosition(token));
      dispatch(connectPriceSocket());
      fetchLeverageForCurrency();
    }
  }, [dispatch, token, selectedCurrency]);

  // Function to handle tab switch
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab); // Store the active tab in localStorage
  };

  // Calculate total profit or loss
  const calculateProfitLoss = (item) => {
    const livePrice = livePrices[item.trading_symbol]?.ltp || item.cmp;
    const quantity = parseFloat(item.qty || 0);
    const avgPrice = parseFloat(item.avg_price || 0);
    const currentPrice = parseFloat(livePrice || 0);
    return ((currentPrice - avgPrice) * quantity).toFixed(2);
  };

  const calculateTotalProfitLoss = (positions) => {
    if (!Array.isArray(positions)) return "0.00";
    return positions.reduce(
      (total, item) => total + Number(calculateProfitLoss(item)),
      0
    ).toFixed(2);
  };

  const getBalance = (selectedCurrency) => {
    if (selectedCurrency === 'INR') {
      return formatNumber(clientdetails?.balance);
    } else if (selectedCurrency === "USD") {
      return formatNumber(clientdetails?.balance_usd);
    } else {
      return 0;
    }
  };

  const calculateEquity = (selectedCurrency) => {
    let profitLoss = calculateTotalProfitLoss(currentPositions);
    if (selectedCurrency === "INR") {
      return formatNumber(Number(clientdetails?.balance) + Number(profitLoss));
    } else if (selectedCurrency === 'USD') {
      return formatNumber(Number(clientdetails?.balance_usd) + Number(profitLoss));
    } else {
      return 0;
    }
  };

  return (
    <div className='accountsmain'>
      <NewHeader title={`${user?.payload?.firstname || "User"} (${user?.payload?.username || "Username"})`} urltext="accountdetails" addicon="none" />
      <div className='accountsmaintab'>
        <nav>
          <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
            <button 
              className={`nav-link ${activeTab === 'funds' ? 'active' : ''}`} 
              data-bs-toggle="tab" 
              data-bs-target="#nav-funds" 
              type="button" 
              role="tab" 
              onClick={() => handleTabChange('funds')}
            >
              Funds
            </button>
            <button 
              className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`} 
              data-bs-toggle="tab" 
              data-bs-target="#nav-settings" 
              type="button" 
              role="tab" 
              onClick={() => handleTabChange('settings')}
            >
              Settings
            </button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className={`tab-pane fade ${activeTab === 'funds' ? 'show active' : ''}`} id="nav-funds" role="tabpanel">
            <div className="acctabbtndiv">
              <button className={selectedCurrency === 'INR' ? 'active' : ''} onClick={() => setSelectedCurrency('INR')} > INR </button>
              <button className={selectedCurrency === 'USD' ? 'active' : ''} onClick={() => setSelectedCurrency('USD')} > USD </button>
            </div>
            <div className="card">
              <div className="card-body">
                <div className='balancediv'>
                  <p>Balance:</p>
                  <p>{getCurrencySymbol(selectedCurrency)} {getBalance(selectedCurrency)}</p>
                </div>
                <div className='balancediv'>
                  <p>Equity:</p>
                  {/* <p>{parseFloat(clientdetails?.balance || "0.00") + parseFloat(clientdetails?.equity || "0.00")}</p> */}
                  {getCurrencySymbol(selectedCurrency)} {calculateEquity(selectedCurrency)}
                </div>
                <div className='balancediv'>
                  <p>Total P/L:</p>
                  <p style={{ color: calculateTotalProfitLoss(currentPositions) >= 0 ? 'green' : 'red', fontWeight: '500', }}>{getCurrencySymbol(selectedCurrency)} {calculateTotalProfitLoss(currentPositions)}</p>
                </div>
                <div className='balancediv'>
                  <p>Margin:</p>
                  <p>{getCurrencySymbol(selectedCurrency)}{selectedCurrency=="INR"?formatNumber(clientdetails?.cnc_margin_req):formatNumber(clientdetails?.cnc_margin_req_usd)}</p>
                </div>
                <div className='balancediv'>
                  <p>Free Margin:</p>
                  <p>{getCurrencySymbol(selectedCurrency)} {clientdetails?.free_margin || "0.00"}</p>
                </div>
                <div className='balancediv'>
                  <p>Margin Level:</p>
                  {/* <p>{clientdetails?.margin_level || "-"}</p> */}
                  <p>{clientdetails?.margin_level || "-"}</p>                  
                </div>
                {/* <div className='balancediv'>
                  <p>Leverage:</p>
                  {loading ? <span className='spinner'></span> : <p>{`1 : ${leverage?.cds?.intraday}`}</p>}
                </div>
                <div className='balancediv'>
                  <p>Leverage:</p>
                  {loading ? <span className='spinner'></span> : <p>{`1 : ${leverage?.mcx?.intraday}`}</p>}
                </div>
                <div className='balancediv'>
                  <p>Leverage:</p>
                  {loading ? <span className='spinner'></span> : <p>{`1 : ${leverage?.nfo?.intraday}`}</p>}
                </div> */}
                {selectedCurrency === "INR" ? (
                  <>
                    <div className="balancediv">
                      <p>Leverage CDS:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.cds?.intraday||""}`}</p>
                      )}
                    </div>
                    <div className="balancediv">
                      <p>Leverage MCX:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.mcx?.intraday||""}`}</p>
                      )}
                    </div>
                    <div className="balancediv">
                      <p>Leverage NFO:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.nfo?.intraday||""}`}</p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="balancediv">
                      <p>Leverage FOREX:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.forex?.intraday}`}</p>
                      )}
                    </div>
                    <div className="balancediv">
                      <p>Leverage CRYPTO:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.crypto?.intraday}`}</p>
                      )}
                    </div>
                    <div className="balancediv">
                      <p>Leverage US:</p>
                      {loading ? (
                        <span className="spinner"></span>
                      ) : (
                        <p>{`1 : ${leverage?.us?.intraday}`}</p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card text-center mt-2">
              <div className="card-body">
                <button className="btn btn-success" onClick={() => navigate("/transactionhistory")}>See Transaction History</button>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 'settings' ? 'show active' : ''}`} id="nav-settings" role="tabpanel">
            <AccountSettings selectedCurrency={selectedCurrency} leverage={leverage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accountdetails;
