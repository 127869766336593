import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import NewHeader from '../Components/Header/NewHeader';
import Accountmiddlecard from '../Components/AccountMiddle/Accountmiddlecard';
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import gbpusd from '../Images/gbpusd.svg';
import CandlestickChart from '../Components/chart/CandlestickChart';
import TradingViewChart from '../Components/chart/TradingViewChart';
import Stockoverviews from '../Components/chart/Stockoverviews';
import Stockspecifications from '../Components/chart/Stockspecifications';

const Stockdetailsmain = ({}) => {
    const location = useLocation();
    
    const { idtoken,exchange,moverData, } = location.state || {};
    const [livePrice, setLivePrice] = useState(null);

    const handleLivePriceUpdate = (price) => {
        setLivePrice(price);
    };
   
  return (
    <div className='accountsmain' style={{padding:"0"}}>
        <div style={{padding:"15px 10px 10px"}}>
            <NewHeader  title={idtoken} addicon="none" />
        </div>
        <div className='p-2'>
            <Accountmiddlecard propdata="none" propdatadetails="none" newprop="block"/>
        </div>
        <div className='stockdetailsgraphmain'>
            <div className='stockdetailsgraph '>
                <div className='detailsheader m-2'>
                    <div className='d-flex gap-1'>
                        <img src={gbpusd} alt="" width="30px" />
                        <p>{idtoken} <span style={{color:`${livePrice >=0 ? "green" : "red"}`}}>{livePrice} </span>{livePrice >0 ? (
                                <i className="fa fa-arrow-up" style={{ color: "green" }}></i>
                            ) : livePrice < 0 ? (
                                <i className="fa fa-arrow-down" style={{ color: "red" }}></i>
                            ) : null}</p>
                    </div>
                    <div className='detailsheadericon'>
                        <i className="fa fa-star custom-fa" aria-hidden="true"></i>
                        <i className="fa fa-briefcase custom-fa" aria-hidden="true"></i>
                        <i className="fa fa-clock custom-fa" aria-hidden="true"></i>
                    </div>
                </div>
                <div className=''>
                    <ul className="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Chart</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Specifications</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" style={{marginTop:"0"}}>
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            {/* <CandlestickChart/> */}
                            <TradingViewChart tradesymbol={idtoken} moverData={moverData} onPriceUpdate={handleLivePriceUpdate}/>                        
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <Stockoverviews exchange={exchange} stocksymbol={idtoken}/>
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <Stockspecifications exchange={exchange} stocksymbol={idtoken} stocktoken={moverData.token}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Stockdetailsmain