import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faList } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { fetchIndexfundList } from '../../Redux/actions/tradeAction';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import { fetchUser } from '../../Redux/actions/userAction';
import '../TopCard/Topcard.css'; 

const AccountMiddleCard = ({ propdata, newprop, headingtext }) => {
 
  const token = getUserToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user,loading, position } = useSelector((state) => state.user);
  const { priceSocket } = useSelector((state) => state.sockets);
  const [livePrices, setLivePrices] = useState({});
  const [userAccount, setUserAccount] = useState(null);
  const [dynamicBalIN, setDynamicBalIN] = useState(0);
  const [dynamicBalUS, setDynamicBalUS] = useState(0);
 
  useEffect(() => {
    if (user?.payload?.client_account) {
      setUserAccount(user.payload.client_account);
    }
  }, [user]);

  // Fetch fund list and connect to WebSocket
  useEffect(() => {
    dispatch(fetchIndexfundList(token));
    dispatch(connectPriceSocket());
  }, [dispatch, token]);

 
  useEffect(() => {
    if (!priceSocket || typeof position?.payload?.positions !== 'object') return;
  
    const subscribeToSocket = (item) => {
      const { token, trading_symbol, exchange, symbol } = item;
      const event =
        exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
          ? `forexRoom${symbol?.toLowerCase()}`
          : `room${token}`;
      const emitData =
        exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
          ? symbol?.toLowerCase()
          : token;
  
      // console.warn("Subscribing to:", event);
  
      priceSocket.emit('tokenData', emitData);
      priceSocket.on(event, (updatedInstrument) => {
        
        setLivePrices((prevState) => ({
          ...prevState,
          [trading_symbol]: updatedInstrument,
        }));
      });
    };
  
    // Flatten positions and subscribe to sockets
    Object.values(position.payload.positions).flat().forEach(subscribeToSocket);
  
    return () => {
      // Cleanup listeners
      Object.values(position.payload.positions).flat().forEach((item) => {
        const { token, exchange, symbol } = item;
        const event =
          exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
            ? `forexRoom${symbol?.toLowerCase()}`
            : `room${token}`;
        // console.warn("Unsubscribing from:", event);
        priceSocket.off(event);
      });
    };
  }, [priceSocket, position?.payload?.positions]);
  
  

  // Calculate dynamic balances
  useEffect(() => {
    const calculateBalances = () => {
      const currentPositionsIN = position?.payload?.positions?.['INR'] || [];
      const currentPositionsUS = position?.payload?.positions?.['USD'] || [];
      const dynamicPositionIN = calculateTotalProfitLoss(currentPositionsIN);
      const dynamicBalIndia = (
        Number(userAccount?.balance) + Number(dynamicPositionIN)
      ).toFixed(2);
      const dynamicPositionUS = calculateTotalProfitLoss(currentPositionsUS);
      const dynamicBalUSA = (
        Number(userAccount?.balance_usd) + Number(dynamicPositionUS)
      ).toFixed(2);

      setDynamicBalIN(isNaN(dynamicBalIndia)?0:dynamicBalIndia);
      setDynamicBalUS(isNaN(dynamicBalUSA)?0:dynamicBalUSA);
    };

    calculateBalances();
  }, [userAccount, priceSocket, livePrices, position]);

  const calculateTotalProfitLoss = (positions) =>
    positions.reduce(
      (total, item) => total + Number(calculateProfitLoss(item)),
      0
    ).toFixed(2);

  const calculateProfitLoss = (item) => {
    const livePrice =
      livePrices[item.trading_symbol]?.ltp ||
      livePrices[item.trading_symbol]?.bid_price || item.cmp;
      const factor = item?.type === 'sell' ? -1 : 1;
      const quantity = parseFloat(item.qty);
      const avgPrice = parseFloat(item.avg_price);
      const currentPrice = parseFloat(livePrice);

    return ((currentPrice - avgPrice) * quantity * factor).toFixed(2);
  };

  const handleNavigation = (path) => navigate(path);
  return (
  <> {loading?<> <div style={{height:"150px",textAlign:"center",display:"flex",alignItems:"center",justifyContent:'center'}} className="accountmiddleloader"><span className='spinner'></span></div></>:<> <div className="mt-3">
    <div className="accountmiddle">
      <h2 className="mb-1">{user?.payload?.username || ''}</h2>
      <div className="d-flex justify-content-between align-items-center">
        <h4 style={{ display: headingtext, marginBottom: '10px', marginTop: '10px', }} > {dynamicBalIN ? dynamicBalIN :user?.payload?.client_account?.balance} INR </h4>
        <h4 style={{ display: headingtext, marginBottom: '10px', marginTop: '10px', }} > {dynamicBalUS ? dynamicBalUS :user?.payload?.client_account?.balance_usd} USD </h4>
      </div>
      <div className="detailsdiv" style={{ display: propdata }}>
        <div onClick={() => handleNavigation('/accountdeposite')}>
          <FontAwesomeIcon icon={faArrowAltCircleUp} className="icons" />
          <p>Deposit</p>
        </div>
        <div onClick={() => handleNavigation('/accountwithdraw')}>
          <FontAwesomeIcon icon={faArrowAltCircleUp} className="icons" />
          <p>Withdraw</p>
        </div>
        <div onClick={() => handleNavigation('/accountdetails')}>
          <FontAwesomeIcon icon={faList} className="icons" />
          <p>Details</p>
        </div>
      </div>
      <div className="detailsdiv2" style={{ display: newprop }}>
        <div onClick={() => handleNavigation('/accountdetails')}>
          <FontAwesomeIcon icon={faList} className="icons" />
        </div>
      </div>
    </div>
  </div></>}</>
  );
};

export default AccountMiddleCard;
