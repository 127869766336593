import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { verifySignupEmail } from '../Redux/actions/authActions';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Forgotmodal from '../Components/Popupmodal/Forgotmodal';
import Otpmodal from '../Components/Popupmodal/Otpmodal';
import Resetpasswordmodal from '../Components/Popupmodal/Resetpasswordmodal';

const EnterEmail = () => {

  const [isModalVisible, setModalVisible] = useState(false);  // Forgot password modal
  const [isModalVisible1, setModalVisible1] = useState(false); // OTP modal
  const [isModalVisible2, setModalVisible2] = useState(false); // Reset password modal
  const [forgotkey, setForgotkey] = useState('');
  const [email, setEmail] = useState(''); // Email state to pass between modals
  const [loadingState, setLoadingState] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneRegExp = /^[0-9]{10}$/;
  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;


  const validationSchema = Yup.object({
    firstname: Yup.string()
      .min(3, "At least 3 characters long")
      .max(55, "Cannot be longer than 55 characters")
      .matches(/^[A-Za-z\s]+$/, "Only contain letters and spaces")
      .required("First name is required"),
    username: Yup.string()
      .min(3, "At least 3 characters long")
      .max(20, "Cannot be longer than 55 characters")      
      .required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Please enter a valid email address")
      .email("Invalid email format"),
    contact_no: Yup.string()
      .matches(phoneRegExp, 'Phone number must be 10 digits long')
      .required('Phone number is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[^\w]/, 'Password must contain a special character')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
  });

  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

  // const encryptData = (data) => {
  //   return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  // };

  const encryptData = (data) => {
    if (!ENCRYPTION_KEY) {
        return null;
    }    
    return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), 
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }
    ).toString();
};

  const formik = useFormik({
    initialValues: {
      firstname: "",
      username: "",
      email: "",
      contact_no: "",
      password: "",
      password_confirmation: ""
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        firstname: values.firstname,
        username: values.username,
        email: values.email,
        contact_no: values.contact_no.toString(),
        password: values.password,
        // password: encryptData(values.password),
      };

      // console.log("kfsdfkjslkfskjf",typeof(data.contact_no))

      // const encryptedPayload = encryptData(data);

      try {
        setLoadingState(true);
        const response = await dispatch(verifySignupEmail(data));
        if (response.status === "FAILED" && response.message === "The email has already been taken.") {
          setLoadingState(false);
          Swal.fire({
            width: "20rem",
            fontSize: "15px",
            title: `${response.message}`,
            timer: 6000,
            timerProgressBar: true,
            showConfirmButton: false,
            customClass: {
              title: 'swaltext'
            }
          });
          navigate('/signin');
        } else {
          const { password_confirmation, ...dataToSend } = data;
          navigate('/otpscreen', { state: { valuesData: dataToSend, response: response.message } });
        }
      } catch (error) {
        console.error("Signup verification failed:", error);
        setLoadingState(false); // Make sure to stop loading in case of error
      }
    },
  });

  return (
    <div className='signinmain'>
      <div className='header'>
        <Link to="/residence"><img src={BackArrow} alt="Back" /></Link>
        <h2>Enter your details.</h2>
      </div>
      <div className='refisterdiv'>
        <form onSubmit={formik.handleSubmit}>
          <div className="signupformmain">
            <div className='signinform'>
              {/* <p className='mb-1'><b>Use this email to sign in to {process.env.REACT_APP_COMPANY_NAME}</b></p> */}
              <div className='emailinput'>
                <label><b>Full name</b></label>
                <input type='text' name="firstname" placeholder='Enter Full name' value={formik.values.firstname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="Formikerrormsg">{formik.errors.firstname}</div>
                ) : null}
              </div>
              <div className='emailinput'>
                <label><b>Username</b></label>
                <input
                  type='text'
                  name='username'
                  placeholder='Enter username'
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="Formikerrormsg">{formik.errors.username}</div>
                ) : null}
              </div>
              <div className='emailinput'>
                <label><b>Email</b><small>(Optional)</small></label>
                <input
                  type='email'
                  name='email'
                  placeholder='Enter email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="Formikerrormsg">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className='emailinput'>
                <label><b>Mobile</b></label>
                <input
                  type='number'
                  name='contact_no'
                  placeholder='Enter mobile number'
                  value={formik.values.contact_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "e" || e.key === "E" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.touched.contact_no && formik.errors.contact_no ? (
                  <div className="Formikerrormsg">{formik.errors.contact_no}</div>
                ) : null}
              </div>

              <div className='emailinput'>
                <label><b>Password</b></label>
                <input
                  type='password'
                  name='password'
                  placeholder='Enter password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="Formikerrormsg">{formik.errors.password}</div>
                ) : null}
              </div>

              <div className='emailinput mb-5'>
                <label><b>Confirm Password</b></label>
                <input
                  type='password'
                  name='password_confirmation'
                  placeholder='Confirm your password'
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                  <div className="Formikerrormsg">{formik.errors.password_confirmation}</div>
                ) : null}
              </div>
            </div>
          </div>
         

          <div className='signregisterbtns'>
            {/* <button type="submit" className='button1'>
              {loadingState ? ("Registering...") : ("Register")}
            </button> */}
            <button type="submit" className="button1" disabled={loadingState} style={{marginLeft:"0"}}>
              {loadingState ?  <>Registering <span className="spinner"></span></> : "Register"}
            </button>
          </div>
        </form>
      </div>

      {/* Forgot Password Modal */}
      <Forgotmodal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        isModalVisible1={isModalVisible1}
        setModalVisible1={setModalVisible1}
        setEmail={setEmail} // Pass the email setter function
      />

      {/* OTP Modal */}
      <Otpmodal
        isModalVisible1={isModalVisible1}
        setModalVisible1={setModalVisible1}
        setModalVisible2={setModalVisible2}
        email={email} // Pass the email to OTP modal
        setForgotkey={setForgotkey}
      />

      {/* Reset Password Modal */}
      <Resetpasswordmodal
        isModalVisible2={isModalVisible2}
        setModalVisible2={setModalVisible2}
        email={email}
        forgot_key={forgotkey}  // Pass the forgot_key state
      />

    </div>
  );
};

export default EnterEmail;
