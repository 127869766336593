import React, { useState, useRef, useEffect } from 'react';
import './Customname.css'; // Import your custom styles

const Customselect = ({ options, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find(opt => opt.value === 'INR') || null);
  const dropdownRef = useRef(null);

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Handle option selection
  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option); // Pass selected option to parent component
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="custom-select-container" ref={dropdownRef}>
      <div className="custom-select-header" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? selectedOption.label : placeholder}
        <span className={`custom-select-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
      </div>

      {isOpen && (
        <ul className="custom-select-options">
          {options.map((option) => (
            <li
              key={option.value}
              className={`custom-select-option ${
                selectedOption?.value === option.value ? 'selected' : ''
              }`}
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Customselect;
