import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import "./Markets.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "../../helper/getToken";
import { fetchTopmoversList } from "../../Redux/actions/tradeAction";
import Stockdetails from "../Bottomdrawer/Stockdetails";
import { connectPriceSocket } from "../../Redux/actions/socketActions";
import { getCurrencySymbol2 } from "../../helper/commonFunctions";

const Topmovers = ({ currency }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({}); // Store live prices
  const navigate = useNavigate();
  const token = getUserToken();
  const dispatch = useDispatch();
  const { topMovers, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets);

  

  useEffect(() => {
    // const fetchTopMoversData = async () => {
    //   if (token) {
    //     try {
    //       await dispatch(fetchTopmoversList(token));
    //     } catch (error) {
    //       console.error("Failed to fetch top movers data:", error);
    //     }
    //   }
    // };
    // fetchTopMoversData();
    dispatch(connectPriceSocket()); 
  }, [dispatch, token]);

  

  useEffect(() => {
    if (topMovers?.payload?.FX && priceSocket) {
      topMovers.payload.FX?.forEach((instrument) => {
        const event = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US" ? `forexRoom${instrument.symbol.toLowerCase()}` : `room${instrument.token}`;
        const emitData = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US"  ? instrument.symbol.toLowerCase() : instrument.token; 
        priceSocket.emit('tokenData', emitData);        
        priceSocket.on(event, (updatedInstrument) => {          
          let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;          
          setLivePrices((prevState) => ({
            ...prevState,
            [typeNmae]: updatedInstrument,
          }));
        });
      });
    }
    
    return () => {
      if (topMovers?.payload && priceSocket) {
        topMovers.payload[currency]?.forEach((instrument) => {
          const event = instrument.exchange === "FOREX" 
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;  
        });
      }
    };
  }, [topMovers, priceSocket, currency]);

  const toggleDrawer = (moverData) => {
    if (moverData) {
      setSelectedToken(moverData.token);
      setSelectedMoverData(moverData);
      setIsDrawerOpen((prevState) => !prevState);
    } else {
      console.error("Invalid data passed to toggleDrawer:", moverData);
    }
  };

  
  const handleTopmoversshow = () => {
    navigate("/moretopmovers");
  };

 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Create graph data for live prices
  // const createGraphData = (moverData) => {    
  //   const priceHistory = livePrices[moverData.token]?.priceHistory || [];
  //   return priceHistory.map((dataPoint, index) => ({
  //     time: index,
  //     price: dataPoint,
  //   }));
  // };

  const createGraphData = (moverData) => {
    if (!moverData) {
      console.error("❌ Error: moverData is undefined");
      return [];
    }
    const openPrice = Number(moverData.open_price) || 0;
    let currentPrice = Number(moverData.ltp) || 0; 
    const priceHistory = [];
    for (let i = 0; i < 10; i++) {
      currentPrice = currentPrice + (Math.random() * 2 - 1);
      priceHistory.push(currentPrice);
    }
    return priceHistory.map((price, index) => ({
      time: index,
      price: price,
      priceDifference: price - openPrice, // Calculating the difference from the open price
    }));
  };

  return (
    <div className="topmoverdiv">
      <div className="topheader">
        <h4>TOP MOVERS (FX)</h4>
        <button onClick={handleTopmoversshow}>Show more</button>
      </div>

      <div className="allcarddiv mt-0">
        <div className="row">
          {/* {topMovers?.payload?.NFO?.length ? (
            topMovers.payload.NFO.map((moverData, index) => { */}
            {topMovers?.payload?.FX?.length ? (
              topMovers.payload.FX.map((moverData, index) => {
                let typeNmae = moverData.exchange === 'FOREX' || moverData.exchange=== "CRYPTO" || moverData.exchange=== "US" ? moverData.symbol.toLowerCase() : moverData.token;  
              const liveData = livePrices[typeNmae] || moverData;
              const { token, symbol, ltp, ask_price, percentage_change,bid_price } = liveData;
             

              return (
                <div className="col" key={index}>
                  
                  <button className="topmvbtn" style={{ border: "0", background: "transparent", width: "100%" }} onClick={() => { navigate('/stockdetails', { state: { idtoken: liveData.symbol, exchange: liveData.exchange, moverData: moverData, } }); }} >
                    <span>
                      <h4>
                        {symbol.length > 13 ? `${symbol.slice(0, 13)}...` : symbol}
                      </h4>
                    </span>
                    {
                      liveData && createGraphData(liveData).length > 0 ? (
                        <div className="graphdiv">
                      <ResponsiveContainer width="100%" height={20}>
                        <LineChart data={createGraphData(liveData)}>
                          <Line type="monotone" dataKey="price" stroke="#8884d8" dot={false} />
                          <XAxis dataKey="time" hide />
                          <YAxis hide />                          
                          {/* Reference Line for Open Price */}
                          <ReferenceLine
                            y={Number(liveData.open_price) || 0}
                            stroke="red"
                            strokeDasharray="5 5"
                            // label="Open Price"
                          />
                          <Line
                            type="monotone"
                            dataKey="priceDifference"
                            stroke={percentage_change > 0 ? "green" : "red"}
                            dot={false}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="graphdiv">No Data Available</div>
                      )
                    }

                    <p
                      className="text-center mb-1"
                      style={{ color: percentage_change > 0 ? "green" : "red" }}
                    >
                      <b>{getCurrencySymbol2(moverData.exchange)} {Number(ltp > 0 ? ltp : bid_price).toFixed(4)}</b>
                    </p>
                    <div className="statusdiv">
                      <span style={{ color: percentage_change > 0 ? "green" : "red" }}>
                        {percentage_change > 0 ? (
                          <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-arrow-down" aria-hidden="true"></i>
                        )}
                        {" "}
                        <b>{percentage_change} % </b>
                      </span>
                    </div>
                  </button>
                </div>
              );
            })
          ) : (
            <p>No top movers available</p>
          )}
        </div>
      </div>

      {/* Bottom Drawer for Stock Details */}
      <Stockdetails
        isDrawerOpen={isDrawerOpen}
        moverData={selectedMoverData}
        onClose={() => setIsDrawerOpen(false)}
      />
    </div>
  );
};

export default Topmovers;
