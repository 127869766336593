import React, { useState } from 'react';
import './Bottomdrawer.css';
import checked from '../../Images/checked.png';

const tradingTerminalData = [
  { id: 1, title: 'Today' },
  { id: 2, title: 'Week' },
  { id: 3, title: 'Month' },
  { id: 4, title: 'Year' }
];

const Bottomdrawer = ({ isDrawerOpen, changedrawer }) => {

  const [checkedItems, setCheckedItems] = useState(
    new Array(tradingTerminalData.length).fill(false)
  );

  const toggleCheck = (index) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        {changedrawer === 'All Acounts Details' && (
          <div>
            <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-real" type="button" role="tab" aria-controls="nav-real" aria-selected="true" > Real </button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-archived" type="button" role="tab" aria-controls="nav-archived" aria-selected="false" > Archive </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active text-start" id="nav-real" role="tabpanel" aria-labelledby="nav-home-tab" >
                {/* <h3>All real accounts</h3> */}
                <div className="accountlist">
                  <div className="topdiv">
                    <h4>#170048666</h4>
                    <span> <p>MT5</p> <p>STANDARD</p> </span>
                  </div>
                  <div className="bottomdiv">
                    <h4>0.00 USD</h4>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-archived" role="tabpanel" aria-labelledby="nav-profile-tab" >
                <h3 className="text-start mb-4">All real accounts</h3>
                <div className="accountlist">
                  <div className="topdiv">
                    <h4>#170048666</h4>
                    <span> <p>MT5</p> <p>STANDARD</p> </span>
                  </div>
                  <div className="bottomdiv">
                    <h4>0.00 USD</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {changedrawer === 'topmovers' && (
          <div className="tradingdiv mt-0">
            <h2 className='text-start mb-3'>Select period</h2>
            <div className="card" style={{border:"0", padding:"0"}}>
              <div className="card-body" style={{padding:"0"}}>
                {tradingTerminalData.map((tradedata, index) => (
                  <div className="indiceslist" key={tradedata.id} onClick={() => toggleCheck(index)} >
                    <div className="leftlist">
                      <p>{tradedata.title}</p>
                    </div>
                    <div className="rightlist">
                      <span style={{ marginRight: '10px' }}>
                        {checkedItems[index] && (
                          <img src={checked} alt="checked" />
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        {changedrawer === 'Past Days' && (
              <div className="tradingdiv mt-0">
                <h2 className='text-start mb-3'>Last 7 days</h2>
                <div className="card" style={{border:"0", padding:"0"}}>
                  <div className="card-body" style={{padding:"0"}}>
                    {tradingTerminalData.map((tradedata, index) => (
                      <div className="indiceslist" key={tradedata.id} onClick={() => toggleCheck(index)} >
                        <div className="leftlist">
                          <p>{tradedata.title}</p>
                        </div>
                        <div className="rightlist">
                          <span style={{ marginRight: '10px' }}>
                            {checkedItems[index] && (
                              <img src={checked} alt="checked" />
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
        )}
      </div>
    </div>
  );
};

export default Bottomdrawer;
