import React, { useEffect } from 'react'
import { useState } from 'react'
import './Emailverification.scss'
import close from '../../Images/close.png';
import Changeemail from '../Popupmodal/Changeemail'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { fetchUser } from '../../Redux/actions/userAction';
function Emailverification() {

  const token = getUserToken();
  const { user,loading,error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

   useEffect(() => {
          if (!user || !user.payload) {
            dispatch(fetchUser(token));
          }
    }, [dispatch, user, token]);
  

  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }



  return (

    <>
      <Modal.Header className='cusheader'>
        <div className='left'>
          <button onClick={() => setShow(false)}> <img src={close} alt="close" /> </button>
        </div>

      </Modal.Header>
      <div className=''>
        <Modal.Body className='accountsmain'>
          <div className='card'>
            <div className='card-body'>
              <div className='emailverification-container mt-2'>
                <h3>2-Step verification</h3>
                <p>
                  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                  must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text.
                </p>
                <hr/>
                <div className='emailverification-type'><b>Security type</b></div>
                <div>{user?.payload?.email}</div>
                <div className='emailverification-security '><p className='mt-0'>Low security</p></div>
                <button onClick={handleShow} className='emailverification-button'>Change</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
      <Modal className='cusmodal' show={show} onHide={() => setShow(false)}>
        <Changeemail name="" show={show} setShow={setShow} />
      </Modal>
    </>




  )
}

export default Emailverification
