
import { COONECT_PRICE_SOCKET, CONECT_NOTIFY_SOCKET } from "../reducers/actionType";
import io from "socket.io-client";

// const PRICE_SOCKET_URL = "https://ws1.bastionex.net";
// const NOTIFY_SOCKET_URL = "https://ws2.bastionex.net";

const PRICE_SOCKET_URL = process.env.REACT_APP_API_PRICE_SOCKET;
const NOTIFY_SOCKET_URL = process.env.REACT_APP_API_NOTIFICATION_SOCKET;



// Function to initialize and maintain WebSocket connection
const initializeSocket = (url, dispatch, actionType) => {
  let socket = io(url, {
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 5000,
  });

  // Heartbeat mechanism to prevent the socket from sleeping
  const heartbeatInterval = setInterval(() => {
    if (socket.connected) {
      socket.emit("ping");
      console.log("Sent heartbeat ping to", url);
    }
  }, 30000);

  socket.on("connect", () => {
    console.log("Connected to", url);
    dispatch({ type: actionType, payload: socket });
  });

  socket.on("disconnect", (reason) => {
    console.warn(`Disconnected from ${url}:`, reason);
    clearInterval(heartbeatInterval);
  });

  socket.on("error", (error) => {
    console.error(`WebSocket error on ${url}:`, error);
  });

  return socket;
};

// Function to connect to the Price WebSocket
export const connectPriceSocket = () => {
  return (dispatch, getState) => {
    const { priceSocket } = getState().sockets;
    if (!priceSocket || !priceSocket.connected) {
      initializeSocket(PRICE_SOCKET_URL, dispatch, COONECT_PRICE_SOCKET);
    }
  };
};

// Function to connect to the Notification WebSocket
export const connectNotifySocket = () => {
  return (dispatch, getState) => {
    const { notifySocket } = getState().sockets;
    if (!notifySocket || !notifySocket.connected) {
      initializeSocket(NOTIFY_SOCKET_URL, dispatch, CONECT_NOTIFY_SOCKET);
    }
  };
};



