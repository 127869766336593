import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Exittradedrawer from '../Bottomdrawer/Exittradedrawer';
import { getCurrencySymbol2 } from '../../helper/commonFunctions';
import axios from 'axios';
import { fetchOrder } from '../../Redux/actions/orderAction';
import Swal from 'sweetalert2';
import { closeDrawer, fetchUserPosition } from '../../Redux/actions/userAction';

const TradeListOpenorder = ({setCurrencyForMargin,data, loading, error,setIsDrawerOpen,isDrawerOpen }) => {
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedMoverData, setSelectedMoverData] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('INR');
    const [livePrices, setLivePrices] = useState({});
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [totalProfitLoss,setTotalProfitLoss]=useState(0);
    const { priceSocket } = useSelector((state) => state.sockets);
    const { user} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const token = getUserToken();
    const apiUrl = process.env.REACT_APP_API_FRONTEND;
    const [takeProfitPlus,setTakeProfitPlus]=useState({type:'in_price',val:null});
    const [stopLossPlus,setStopLossPlus]=useState({type:'in_price',val:null});
    const [tradeVol,setTradeVol]=useState({type:'usd',val:null});
    const [isError,setIsError]=useState(false);
    const [errorMsg,setErrorMsg]=useState('');
    
    function formatNumber(input) {
        try{
            const num = parseFloat(input);
            if (isNaN(num)) {
                throw new Error("Invalid number");
            }
            if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
                return parseFloat(num.toFixed(6));
            }
            return num;
        }catch(err){
            return 0;
        }
    }

    const currentPositions = data?.payload?.positions?.[selectedCurrency] || [];    
    const getPrice=(item)=>{
        let livePrice=livePrices[item.trading_symbol] || {};
        if(livePrice?.bid_price&&livePrice?.bid_price!=0){
            return formatNumber(livePrice?.bid_price);
        }else if(livePrice?.ltp&&livePrice?.ltp!=0){
            return formatNumber(livePrice?.ltp)
        }else if(livePrice?.price&&livePrice?.price!=0){
            return formatNumber(livePrice?.price)
        }else if(livePrice?.avg_price&&livePrice?.avg_price!=0){
            return formatNumber(livePrice?.avg_price)
        }
    }

    const getCurrencySymbol = (exchange) => {
        return ['us', 'forex', 'crypto'].includes(exchange.toLowerCase()) ? '$' : '₹';
      };
    
    useEffect(()=>{
        if(currentPositions.length!=0) return;
        calculateTotalProfitLoss(currentPositions);
    },[priceSocket,currentPositions,livePrices])

    useEffect(() => {
        // dispatch(fetchUserPosition(token));
        // dispatch(fetchOrder(token))
        dispatch(connectPriceSocket());
        calculateTotalProfitLoss(currentPositions)
    }, [dispatch, token]);   

    useEffect(() => {
        if (!priceSocket || !currentPositions.length) return;
    
        currentPositions.forEach((position) => {
            const { token, trading_symbol, exchange, symbol } = position;    
            const event =
                exchange === "FOREX" || exchange === "CRYPTO" || exchange === "US"
                    ? `forexRoom${symbol?.toLowerCase()}`
                    : `room${token}`;
            const emitData =
                exchange === "FOREX" || exchange === "CRYPTO" || exchange === "US"
                    ? symbol?.toLowerCase()
                    : token;
    
            // Emit the token data
            priceSocket.emit("tokenData", emitData);
            
            // Listen for updates
            priceSocket.on(event, (updatedInstrument) => {
                setLivePrices((prevState) => ({
                    ...prevState,
                    [trading_symbol]: updatedInstrument,
                }));
                calculateProfitLoss(currentPositions)
            });
        });      
    
        // Cleanup: Remove listeners for each event
        return () => {
            currentPositions.forEach((position) => {
                const { token, exchange, symbol } = position;    
                const event =
                    exchange === "FOREX" || exchange === "CRYPTO" || exchange === "US"
                        ? `forexRoom${symbol?.toLowerCase()}`
                        : `room${token}`;
    
                priceSocket.off(event);
            });
        };
    }, [priceSocket, currentPositions]);
    useEffect(()=>{
        const selectCurrency=localStorage.getItem("selectedcurrency");
        if(selectCurrency){
            handleSelectCurrency(selectCurrency)
        }
    },[])
    const handleSelectCurrency=(currency)=>{
        localStorage.setItem('selectedcurrency',currency);
        setSelectedCurrency(currency);
        setCurrencyForMargin(currency);
    }

    const handleClick = (position) => {              
        setSelectedMoverData(position);
        // console.log(position,'8888888888888889099999999999999')
        if(position?.take_profit){
            setTakeProfitPlus({type:position?.profit_loss_in,val:position?.take_profit})
        }else{
            setTakeProfitPlus({type:"in_price",val:0})
        }
        if(position?.stoploss){
            setStopLossPlus({type:position?.profit_loss_in,val:position?.stoploss})
        }else{
            setStopLossPlus({type:"in_price",val:0})
        }
        if(position?.qty){
            setTradeVol({type:"usd",val:position?.qty})
        }else{
            setTradeVol({type:"usd",val:0})
        }
        setIsDrawerOpen(true);
    };
    
    const showPopupMessage = (message, navigateAfter = false) => {
        setPopupMessage(message);
        setShowPopup(true);       
    };

    const handleCancelAllOrders=()=>{
        Swal.fire({
            text:"Do you want to close all orders?",
            showCancelButton:true,
            confirmButtonText:"Yes",
            cancelButtonText:"No",
            customClass:{
                confirmButton:"custom-confirm-button",
                cancelButton:"custom-cancel-button"
            }
        }).then(result=>{
            if(result.isConfirmed){
                handleCancelAllOrder();
            }
        })
    }
    const handleCancelAllOrder = async () => {        
        try {
            const body = { currency: selectedCurrency };          
            let response = await axios.post(`${apiUrl}client/closeAllOrder`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            // console.log("Response:", response);
            
            // if (response?.data?.status === 'success') {
                showPopupMessage(response?.data?.message, true);
            // } 
            // else {
            //     showPopupMessage(response?.data?.message || 'Transaction failed.', false);
            // }
            
            dispatch(fetchUserPosition(token));
        } catch (error) {
            console.error("Error deleting order:", error);
            showPopupMessage(error?.response?.data?.message || 'Transaction failed.', false);
        }
    };
    

    const calculateProfitLoss = (item) => {
        
        const livePrice = livePrices[item?.trading_symbol]?.ltp || livePrices[item?.trading_symbol]?.bid_price || livePrices[item?.trading_symbol]?.ask_price || livePrices[item?.trading_symbol]?.avg_price || item?.cmp;
        // console.log(livePrice,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        let factor=1;
        if(item?.type=='sell'){
            factor=-1;
        }
        const quantity = parseFloat(item?.qty);
        const avgPrice = parseFloat(item?.avg_price);
        const currentPrice = parseFloat(livePrice);
        
        const profitLoss = ((currentPrice - avgPrice) * quantity*factor);
        // console.log(item,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        return formatNumber(profitLoss);
    };
    
    const calculateTotalProfitLoss = (positions) => {        
        const total = positions.reduce((total, item) => {       
            return total + Number(calculateProfitLoss(item));
        }, 0);
      
        return formatNumber(total);
      };

      const handleCLoseDrawer=()=>{
        if(isDrawerOpen){
          setIsDrawerOpen(false)
        }
      }

    

    const renderTrades = (positions) =>
        positions.map((item, index) => {            
            
            const livePrice = livePrices[item.trading_symbol] || {};            
            const bidPrice = item.type ? livePrice?.bid_price || livePrice?.ltp || livePrice?.price || livePrice?.avg_price || item.bid_price || 0 : livePrice?.bid_price || livePrice?.ltp || livePrice?.price || livePrice?.avg_price || item.bid_price || 0;
            const profitLoss = calculateProfitLoss(item);
    
            return (
                <div className='listorder' key={index} onClick={() => handleClick(item)} >
                    <div className='listorderinner'>
                        <div className='listtop'>
                            <div className='listtopleft'>
                                <h5>{item.symbol}</h5>
                            </div>
                            <div className='listtopright'>
                                <p className='quantity mb-0'>
                                    {item.exchange==="CRYPTO"?
                                    <b> {getCurrencySymbol2(item.exchange)} {getPrice(item)} </b>:
                                    <div className='d-flex flex-column text-end'>
                                        <b> {getCurrencySymbol2(item.exchange)} {livePrice?.ltp&&livePrice?.ltp!=0?Number(livePrice?.ltp).toFixed(3):item.type === "sell" ? Number(livePrice.ask_price || 0).toFixed(3) : Number(livePrice.bid_price || 0).toFixed(3)}</b> 
                                        <small style={{fontSize:"10px"}}>{item.type === "buy" ? 'Avg' : "Avg" } price : {Number(item.avg_price).toFixed(2)}</small>                                       
                                    </div>
                                    }
                                </p>                                
                            </div>
                        </div>
                        <div className="listmiddle">
                            <div className="listmiddleleft">
                                <p className={`trantype ${item?.type === 'buy' ? 'buy' : 'sell'}`}>{item?.type}</p>                               
                            </div>
                            <div className='listmiddleright'>
                                <p className={`quantity ${item.type === 'buy' ? 'buy' : 'sell'}`}>
                                    <b>Qty. {item.qty}</b>
                                </p>
                            </div>
                            
                        </div>
                        <div className='listmiddle'>
                            <div className='listmiddleleft'>
                                <p>Used Margin: {item.used_margin}</p>
                            </div>
                            {/* <div className='listmiddleright'>
                                <p className={`quantity ${item.type === 'buy' ? 'buy' : 'sell'}`}>
                                    <b>Qty. {item.qty}</b>
                                </p>
                            </div> */}
                            <div className="listmiddleright">
                                {/* <small style={{fontSize:"12px"}}>{item.type === "buy" ? 'Avg' : "Avg" } price : {item.avg_price}</small> */}
                                {/* <b style={{fontSize:"12px"}}>{item.type === "buy" ? 'Avg' : "Avg" } price : {Number(item.avg_price).toFixed(2)}</b> */}
                                <p className={`exchange ${item?.exchange === 'fx' ? 'fx' : 'mcx'}`}>{item?.exchange}</p>
                            </div>
                        </div>
                        <div className='listbottom'>
                            <div className='profitloss' style={{ color: profitLoss > 0 ? 'green' : 'red', fontWeight: '500', }} >
                                <p> P&L: <span>{getCurrencySymbol2(item.exchange)} {profitLoss}</span> </p>
                            </div>
                            <div className='exitedat'>
                                <p>Date: {item.added_on}</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

    if (loading) return <p><span className="loader"></span></p>;
    if (error) return <p>Error fetching data: {error}</p>;

    return (
        <div >
            <div className='acctabbtndiv' style={{display:"flex",justifyContent:"space-between",alignItems:"center"}} >
                <div style={{cursor:"pointer"}}>
                    {currentPositions.length > 0 ? (<p className='' style={{color:"red",textDecoration:"underline"}} onClick={()=>handleCancelAllOrders()}>Close all</p>): ""}
                </div>
                <div>
                    {['INR','USD'].map((currency) => (
                        <button
                            key={currency}
                            className={selectedCurrency === currency ? 'active' : ''}
                            onClick={() =>handleSelectCurrency(currency)}
                        >
                            {currency=='INR'?'INR':"USD"}
                        </button>
                    ))}
                </div>
            </div>

           

            <div className='profitlossdiv'>
                <h2>
                    Total P/L:{' '}
                    <span
                        style={{
                            color: Number(calculateTotalProfitLoss(currentPositions)) > 0 ? 'green' : 'red',
                            fontWeight: '500',
                        }}
                    >
                       {selectedCurrency === "INR" ? "₹" : "$"} {formatNumber(calculateTotalProfitLoss(currentPositions))}
                    </span>
                </h2>
            </div>

            {currentPositions.length > 0 ? renderTrades(currentPositions) : <p>No trades available</p>}
           
           
        <Exittradedrawer
            isDrawerOpen={isDrawerOpen}
            moverData={selectedMoverData}
            onClose={() => setIsDrawerOpen(false)}
            setIsDrawerOpen={setIsDrawerOpen}
            livePrice={livePrices}
            setShowPopup={setShowPopup}
            setPopupMessage={setPopupMessage}
            takeProfitPlus={takeProfitPlus}
            setTakeProfitPlus={setTakeProfitPlus}
            stopLossPlus={stopLossPlus}
            setStopLossPlus={setStopLossPlus}
            tradeVol={tradeVol}
            setTradeVol={setTradeVol}
            isError={isError}
            errorMsg={errorMsg}
            setIsError={setIsError}
            setErrorMsg={setErrorMsg}
            calculateProfitLoss={calculateProfitLoss}
        />
         {showPopup && (
                <div className='popup-overlay'>
                    <div style={{width:"300px"}} className='popup-content'>
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TradeListOpenorder;
