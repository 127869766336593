import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getUserToken } from '../../helper/getToken';

const DISPLAY_COUNT = 20;
const SCROLL_UPDATE_COUNT = 15;

const Stockoverviews = ({ exchange, stocksymbol }) => {
    const [topNewsData, setTopNewsData] = useState([]);
    const [currentStartIndex, setCurrentStartIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [allNewsData, setAllNewsData] = useState([]);
    const containerRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const token = getUserToken();
    const app_backend = process.env.REACT_APP_API_FRONTEND;

    // Fetch all news data
    const fetchAllNews = async (page) => {
        setLoading(true);

        const bodyData = {
            exchange: exchange || "crypto",
            symbol: stocksymbol || "BTCUSD",
            from: "",
            to: "",
            limit: "200",
            page: page.toString(),
        };

        try {
            const response = await axios.post(
                `${app_backend}client/news/getNewsBySymbol`,
                bodyData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const newsPayload = response?.data?.payload || [];

            if (page === 0) {
                setAllNewsData(newsPayload);
                setTopNewsData(newsPayload.slice(0, DISPLAY_COUNT));
            } else {
                setAllNewsData((prev) => [...prev, ...newsPayload]);
            }

            setHasMore(newsPayload.length === 20);
        } catch (error) {
            console.error("Error fetching news:", error);
        } finally {
            setLoading(false);
        }
    };

    // Handle scroll event
    const handleScroll = useCallback(() => {
        if (!containerRef.current || loading) return;

        const container = containerRef.current;
        const { scrollTop, scrollHeight, clientHeight } = container;

        if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore) {
            if (currentStartIndex + DISPLAY_COUNT >= allNewsData.length) {
                setCurrentPage((prev) => {
                    const nextPage = prev + 1;
                    fetchAllNews(nextPage);
                    return nextPage;
                });
            } else {
                setCurrentStartIndex((prev) => {
                    const newIndex = prev + SCROLL_UPDATE_COUNT;
                    setTopNewsData(allNewsData.slice(newIndex, newIndex + DISPLAY_COUNT));
                    return newIndex;
                });
            }
        }

        if (scrollTop <= 20 && currentStartIndex > 0) {
            setCurrentStartIndex((prev) => {
                const newIndex = Math.max(0, prev - SCROLL_UPDATE_COUNT);
                setTopNewsData(allNewsData.slice(newIndex, newIndex + DISPLAY_COUNT));
                return newIndex;
            });
        }
    }, [loading, currentStartIndex, allNewsData, hasMore]);

    // Initial fetch
    useEffect(() => {
        if (exchange && stocksymbol) {
            fetchAllNews(0);
        }
    }, [exchange, stocksymbol]);

    // Attach scroll event
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    return (
        <div ref={containerRef} style={{ height: '600px', overflowY: 'auto' }}>
            {loading && <div className="loading">Loading...</div>}
            {topNewsData.length > 0 ? (
                topNewsData.map((news, index) => {
                    const formattedDate = news?.publishedDate
                        ? new Date(news.publishedDate).toLocaleDateString('en-US')
                        : "Unknown Date";

                    return (
                        <>
                            <div className="newslist" key={`${news.id}-${index}`}>
                                <div className="imgdiv">
                                    <img src={news.image} alt={news.title || "News"} />
                                </div>
                                <div className="textdiv" style={{textAlign:"left"}}>
                                    <p>
                                        <b>
                                            {news.title?.length > 20
                                                ? `${news.title.substring(0, 20)}...`
                                                : news.title}
                                        </b>
                                    </p>
                                    <p>
                                        {news?.text?.length > 30
                                            ? `${news?.text.substring(0, 30)}...`
                                            : news.text}
                                    </p>
                                    <small>{formattedDate}</small>
                                </div>
                            </div>                            
                        </>
                    );
                })
            ) : (
                <div className='mt-5'><p>No data found</p></div>
            )}
            {loading && <div className="loading">Loading...</div>}
        </div>
    );
};

export default Stockoverviews;
