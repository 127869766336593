import React, { useEffect, useState } from 'react';
import './Bottomdrawer.css';
import { useNavigate } from 'react-router-dom';
import { getUserToken } from '../../helper/getToken';
import { useSelector } from 'react-redux';
import { getCurrencySymbol2 } from '../../helper/commonFunctions';

const MoreStockdetails = ({stockDetails, isDrawerOpen, moverData, onClose }) => {
 
  const navigate = useNavigate();
  const { priceSocket } = useSelector((state) => state.sockets);
  const [livePrices, setLivePrices] = useState({});
  const token = getUserToken();

  // const getCurrencySymbol = (exchange) => {
  //   return exchange === "NFO" || exchange === "MCX" || exchange === "CDS" || exchange === "FX"
  //     ? "₹"
  //     : "$"; // Assuming non-INR markets use USD
  // };

 

useEffect(() => {
    if (moverData?.payload && priceSocket) {
     
      const event=stockDetails?.exchange=='FOREX'||stockDetails?.exchange=='CRYPTO'||stockDetails?.exchange=='US'?`forexRoom${stockDetails?.symbol.toLowerCase()}` : `room${stockDetails?.token}`;
      const emitData = stockDetails?.exchange === "FOREX" || stockDetails?.exchange=== "CRYPTO" || stockDetails?.exchange=== "US"  ? stockDetails?.symbol.toLowerCase() : stockDetails?.token;
      priceSocket.emit('tokenData', emitData); 
      priceSocket.on(event, (updatedInstrument) => {
        let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;          
        setLivePrices((prevState) => ({
          ...prevState,
          [typeNmae]: updatedInstrument,
        }));
      });
    }
  }, [moverData, priceSocket]);



  const currentData = livePrices[stockDetails?.token] || stockDetails;

  const percentChange =
    currentData?.percentage_change !== undefined
      ? parseFloat(currentData.percentage_change).toFixed(2)
      : 'N/A';

  const ltp =
    currentData?.ltp !== undefined
      ? parseFloat(currentData.ltp).toFixed(2)
      : 'N/A';

  const handleBuy = () => {
    navigate('/buy', { state: { currentData, moverData } });
  };

  const handleSell = () => {
    navigate('/sell', { state: { currentData, moverData } });
  };

  const calculateTotalQuantities = (data) => {
    if (!data || typeof data !== 'object') {
      return { totalBidQty: 0, totalAskQty: 0 };
    }

    const bidQtyKeys = ['bid_qty', 'bid_qty2', 'bid_qty3', 'bid_qty4', 'bid_qty5'];
    const askQtyKeys = ['ask_qty', 'ask_qty2', 'ask_qty3', 'ask_qty4', 'ask_qty5'];

    const totalBidQty = bidQtyKeys.reduce((sum, key) => sum + (parseInt(data[key]) || 0), 0);
    const totalAskQty = askQtyKeys.reduce((sum, key) => sum + (parseInt(data[key]) || 0), 0);

    return { totalBidQty, totalAskQty };
  };

  const { totalBidQty, totalAskQty } = calculateTotalQuantities(currentData);

  return (
    <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className="drawer-content" onClick={onClose}>
        {currentData ? (
          <div className="drawerconteninner">
            <div className="namediv">
              <div className="nameflex upperdiv">
                <h4>
                  {currentData.trading_symbol} ({currentData.exchange})
                </h4>
                <h4>
                  {getCurrencySymbol2(currentData.exchange)} {ltp}
                </h4>
              </div>
              <div className="nameflex lowerdiv">
                <p>Lot Size: {currentData.lotsize}</p>
                <p>
                  {getCurrencySymbol2(currentData.exchange)} {currentData.price_change} ({percentChange}%)
                </p>
              </div>
            </div>
            <div className="nameflex lowheighdiv">
              <div>
                <p>Open</p>
                <h4>{currentData.open_price || 'N/A'}</h4>
              </div>
              <div>
                <p>High</p>
                <h4>{currentData.high_price || 'N/A'}</h4>
              </div>
              <div>
                <p>Low</p>
                <h4>{currentData.low_price || 'N/A'}</h4>
              </div>
              <div>
                <p>Close</p>
                <h4>{currentData.previous_close || 'N/A'}</h4>
              </div>
            </div>
            {currentData.bid_qty > 0 && (
              <>
                <div className="dtydiv">
                  <table className="table table-responsive buysell">
                    <thead>
                      <tr>
                        <th>QTY.</th>
                        <th>BUY PRICE</th>
                        <th>SELL PRICE</th>
                        <th>QTY.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[1, 2, 3, 4, 5].map((level) => (
                        <tr key={level}>
                          <td>{currentData[`bid_qty${level}`] || 'N/A'}</td>
                          <td>
                            <div className="graph">
                              {currentData[`bid_price${level}`] || 'N/A'}
                            </div>
                          </td>
                          <td>
                            <div className="graph red">
                              {currentData[`ask_price${level}`] || 'N/A'}
                            </div>
                          </td>
                          <td>{currentData[`ask_qty${level}`] || 'N/A'}</td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>{totalBidQty}</b>
                        </td>
                        <td className="text-center" colSpan={2}>
                          <b>TOTAL QUANTITY</b>
                        </td>
                        <td>
                          <b>{totalAskQty}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="detailsdiv">
                  <div className="nameflex">
                    <p>Volume</p>
                    <p>{currentData.volume}</p>
                  </div>
                  <div className="nameflex">
                    <p>Last traded at</p>
                    <p>{currentData.ltp}</p>
                  </div>
                  <div className="nameflex">
                    <p>Lower circuit</p>
                    <p>{currentData.lower_circuit}</p>
                  </div>
                  <div className="nameflex">
                    <p>Upper circuit</p>
                    <p>{currentData.upper_circuit}</p>
                  </div>
                </div>
              </>
            )}
            <div className="nameflex btndiv2">
              <div className="sell">
                <button onClick={handleSell}>
                  <p>Sell</p>
                  <p>
                    <b>{ltp}</b>
                  </p>
                </button>
              </div>
              <div className="buy">
                <button onClick={handleBuy}>
                  <p>Buy</p>
                  <p>
                    <b>{ltp}</b>
                  </p>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default MoreStockdetails;
