import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopLoosersList, fetchTopmoversList } from '../../Redux/actions/tradeAction.js';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Stockdetails from '../Bottomdrawer/Stockdetails.js';
import { getUserToken } from '../../helper/getToken.js';
import { useNavigate } from 'react-router-dom';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';

const TopLoosers = ({ currency,alert }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({}); // Store live prices

  const token = getUserToken()
  const dispatch = useDispatch();
  const { topLooser, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets);
  useEffect(() => {    
    dispatch(fetchTopLoosersList(token));
    dispatch(connectPriceSocket());
  }, [dispatch,token]);

  // useEffect(() => {
  //   dispatch({ type: 'SET_ACTIVE_TAB', payload: "nav-looser" });
  // }, [ dispatch]);
  const [issetalert,setIssstAlert] = useState(false);
  
  useEffect(() => {
    if (topLooser?.payload && priceSocket) {
      topLooser.payload[currency]?.forEach((instrument) => {
        const event = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US" ? `forexRoom${instrument.symbol.toLowerCase()}` : `room${instrument.token}`;
        const emitData = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US"  ? instrument.symbol.toLowerCase() : instrument.token; 
        priceSocket.emit('tokenData', emitData);        
        priceSocket.on(event, (updatedInstrument) => {
          let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;          
          setLivePrices((prevState) => ({
            ...prevState,
            [typeNmae]: updatedInstrument,
          }));
        });
      });
    }
  
    return () => {
      if (topLooser?.payload && priceSocket) {
        topLooser.payload[currency]?.forEach((instrument) => {
          const event = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US"
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;  
        });
      }
    };
  }, [topLooser, priceSocket, currency]);

  const navigate = useNavigate();

  const handleClick = (moverData) => {  
    if(!alert){
      if (moverData.exchange === 'FOREX' || moverData.exchange === 'US' || moverData.exchange === 'CRYPTO') {      
        navigate('/stockdetails', { 
          state: { 
            idtoken: moverData.symbol, 
            exchange: moverData.exchange,
            moverData: moverData,
            // fromTab: activeTab
          } 
        });
      } else if (moverData.exchange === 'MCX' || moverData.exchange === 'NFO') {
        setSelectedMoverData(moverData);
        setIsDrawerOpen(true);
      } else {
        console.error("Exchange value is undefined or invalid.");
      }
    }else{
      setSelectedMoverData(moverData);
      setIssstAlert(true);
    }
  };

  const renderTopLooser = (topLooserList) =>
    topLooserList.map((topLooser, index) => {     
      let typeNmae = topLooser.exchange ==='FOREX' || topLooser.exchange === "US" || topLooser.exchange === "CRYPTO" ? topLooser.symbol.toLowerCase() : topLooser.token;
      const liveData = livePrices[typeNmae];
      const askPrice = liveData?.ask_price || topLooser.ask_price;
      const getPrice = (data) => { 
          const ltp = data.ltp;  
          const bidPrice = data.bid_price;
          return ltp > 0 ? formatNumber(ltp) : (bidPrice > 0 ? formatNumber(bidPrice) : 0);
      };
      const pricechange = liveData?.price_change || topLooser?.price_change;      
      const getCurrencySymbol = (exchange) => {
        return ['us', 'forex', 'crypto'].includes(exchange.toLowerCase()) ? '$' : '₹';
      };
      const price = getPrice(liveData || topLooser);
      const formattedPrice = !isNaN(price) ? Number(price).toFixed(2) : 0;
      function formatNumber(input) {
        try{
            const num = parseFloat(input);
            if (isNaN(num)) {
                throw new Error("Invalid number");
            }
            if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
                return parseFloat(num.toFixed(6));
            }
            return num;
        }catch(err){
            return 0;
        }
    }
      const percentChange = 
            liveData?.percentage_change !== undefined 
                ? parseFloat(liveData.percentage_change).toFixed(2) 
                : parseFloat(topLooser.percentage_change).toFixed(2);
      let currentDatetime = new Date();
      const formattedDate = currentDatetime.toLocaleDateString('en-GB');      
      const exchangename = topLooser.exchange;    

      return (
        <div className="card mb-2" key={index} onClick={() => handleClick(topLooser)}>
          <div className="card-body" style={{cursor:"pointer"}}>
           <div className="topdiv">
              <div className="text-start">
                <b>{topLooser?.symbol}</b>
              </div>
              <div>
                <p>{topLooser?.graphdata}</p>
              </div>
              <div className="text-end">
                <p style={{ color:'red'}}>
                  {/* <b>{getCurrencySymbol(topMover.exchange)} {formattedPrice}</b> */}
                  <b>{getCurrencySymbol(topLooser.exchange)}{price}</b>
                </p>
              </div>
            </div>
            <div className="bottomdiv">
              <div className="text-start">
                <p>{topLooser?.expiry ? topLooser.expiry : formattedDate}</p>
              </div>
              <div className="text-end">
                <span style={{ color:'red'}}>
                <i className="fa fa-arrow-down" aria-hidden="true"> </i>                   
                {pricechange} ({percentChange}) %
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });

  const renderCurrencyTrades = (currency) => {
    const trades = topLooser?.payload?.[currency] || [];
    return trades.length > 0 ? renderTopLooser(trades) : <p>No {currency} top symbols available</p>;
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}
      {renderCurrencyTrades(currency)}

      {selectedMoverData && (
        // <Stockdetails isDrawerOpen={isDrawerOpen} moverData={selectedMoverData} onClose={() => setIsDrawerOpen(false)}/>
        selectedMoverData.exchange === 'MCX' || selectedMoverData.exchange === 'NFO' ? (
          <Stockdetails 
            isDrawerOpen={isDrawerOpen} 
            moverData={selectedMoverData} 
            onClose={() => setIsDrawerOpen(false)} 
          />
        ) : null
      )}

      <AlertsetDrawer
            issetalert={issetalert}
            moverData={selectedMoverData}
            onClose={() => setIssstAlert(false)}
          />
    </div>
  );
};

export default TopLoosers;
