import React from 'react'
import Accountmiddlecard from '../AccountMiddle/Accountmiddlecard'
import img from '../../Images/news.svg'
import dawn_arrow from '../../Images/down-arrow.png'
import windowarrow from '../../Images/window.png'
// import './Topnewsdetails.css'
import './Topmorenews.css'
import NewHeader from '../Header/NewHeader'
const Topnewsdetails = () => {
  return (
    <div className='accountsmain'>
        <NewHeader  title="NZD/USD" addicon="none" />
        <Accountmiddlecard propdata="none" newprop="" headingtext="none" />
        {/* header  block */}
        <div className="topnewsdetailsdiv">
            <div className="header-1">
                <div className="topdiv">
                    <div className="image-news-1">
                        <img src={img} alt="img" />
                    </div>
                    <div className='headingdiv'>
                        <h2> NZD/USD</h2>
                        <p>New zealand Dollor vs US Dollar</p>
                    </div>
                    <p>0.62301</p>                    
                </div>                
            </div>
            <div className="background-img">
                <img src={img} alt="images" />
            </div>
            <div className="heading">
                <h1>NSD/USD drop to near 0.6250 due to diminishing odds of an aggressive rate cut by the fed</h1>
            </div>
            <div className="description-block">
                <p className="date"> 02.09.2024 14:17 BY Akhtar Faeuquii </p>
                <p className="description"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod animi tempore at, veritatis sapiente fuga voluptatum officiis eligendi recusandae consectetur, tempora explicabo ad. Dicta quis laboriosam, culpa cum illum nemo accusamus dignissimos pariatur quo sunt doloribus animi fugiat illo optio nam alias facere eos ratione dolor, minima harum esse corrupti? Ullam dolorem rem, itaque dolor modi, quvoluptatibus beatae aliquid quia quis perferendis unde quas voluptate necessitatibus odit provident expedita natus repellendus ipsam laudantium ullam et! Asperiores corrupti expedita possimus sequi aspernatur iusto laudcilis unde sequi possimus architecto beatae laudantium! </p>
            </div>
            <div className="second-description">
                <div className="sec-des-block">
                    <span className='logo-fxs'>
                        <img src={windowarrow} alt="" />
                    </span>
                    <span className='fxstree'> FXStreet </span>
                </div>
            </div>
            <hr />
            <div className="second-descrition">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem voluptas quidem ut asperiores ipsam similique doloremque nisi earum quaerat in recusandae fugit, dolor numquam dolorum inventore enim voluptatibus incidunt expedita harum sint hic et distinctio aut totam! Suscipit distinctio laborum, id blanditiis error quidem corrupti non maiores iusto repudiandae unde repellat excepturi cumque at repellendus dolore quibusdam labore aliquam minima doloremque accusamus recusandae? Voluptate dicta blanditiis atque minima nostrum maxime quae sapiente quibusdam quidem corporis at hic reiciendis tempora recusandae sint facilis, mollitia perspiciatis sit assumenda adipisci deserunt quasi magni animi aut! Vero, natus et. Modi voluptatibus reprehenderit eligendi atque!</p>
            </div>
            {/* <div className="footer">
                <div className="footer-button-1">
                    <span>
                        <p className='footer-price'>Sell</p>
                        <p  className='footer-price'> 0.62291</p>
                    </span>
                </div>
                <div className="footer-button-2">
                    <span>
                        <p className='footer-price'>Buy</p>
                        <p className='footer-price'>0.62308</p>
                    </span>
                </div>
            </div> */}
      </div>
    </div>
  )
}

export default Topnewsdetails
