import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectPriceSocket } from './Redux/actions/socketActions';

const TradingSocket = () => {
    const [tradingData, setTradingData] = useState([]);
    const dispatch = useDispatch();
    const { priceSocket, loading, error } = useSelector((state) => state.sockets || {});

    

    useEffect(() => {
        if (priceSocket) {            
            dispatch(connectPriceSocket());
        }
        if (priceSocket) {
            priceSocket.on('priceUpdate', (data) => {
                setTradingData((prevData) => [...prevData, data]);
            });
            priceSocket.emit('subscribeToPriceUpdates');
            return () => {
                priceSocket.off('priceUpdate');
                priceSocket.disconnect();
            };
        }
    }, [dispatch, priceSocket]);

    // Handle loading and error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h1>Live Trading Data</h1>
            <ul>
                {tradingData.map((data, index) => (
                    <li key={index}>{JSON.stringify(data)}</li>
                ))}
            </ul>
        </div>
    );
};

export default TradingSocket;
