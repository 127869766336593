import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../Redux/actions/userAction';
import { getUserToken } from '../helper/getToken';  // Import utility function
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Accountmiddlecard from '../Components/AccountMiddle/Accountmiddlecard';
import Accounttab from '../Components/Accounttabs/Accounttab';
import { Modal } from 'react-bootstrap';
import LegalDocument from '../Components/Profile/LegalDocument';
import Legalpopup from '../Components/Popupmodal/Legalpopup';

const Dashboard = () => {
  const dispatch = useDispatch();
  const token = getUserToken(); // Get token using utility function
  const { user, loading, error } = useSelector((state) => state.user);

  const [legadoc,setLegalPopUp] = useState(false)

  const isLoginFirst = false;
 

  // useEffect(() => {
  //   const fetchUserdata = async () => {
      
  //     if (token) {
  //       try {
  //         await dispatch(fetchUser(token));  // Pass token here
  //       } catch (error) {
  //         console.error("Failed to fetch user data:", error);
  //       }
  //     }
  //   };
  
  //   fetchUserdata();
  // }, [dispatch, token]);

  // useEffect(()=>{
  //   dispatch(fetchUser(token))
  //   if(!isLoginFirst === "true"){
  //     console.log("Opne Legal document")
  //   }else{
  //     console.log("Not open legal document")
  //   }
  // },[token, isLoginFirst])

  useEffect(() => {
      dispatch(fetchUser(token));
      if (isLoginFirst === true) {         
          setLegalPopUp(true);
      } else {          
          setLegalPopUp(false);      }
  }, [token, isLoginFirst]);

  

  return (
    <div className='accountsmain'>
      <Header props="Account" icon="block" settitng="none" />
      <Accountmiddlecard prop="block" userData={user? user : ""}  newprop="none" />
      <Accounttab />
      <Footer />

      <Modal className='cusmodal' show={legadoc}>
          <Legalpopup name="" legadoc={legadoc} setLegalPopUp={setLegalPopUp} onHide={() => setLegalPopUp(false)}/>
      </Modal>
    </div>
  );
};

export default Dashboard;
