import React from 'react'
import NewHeader from '../Header/NewHeader'
import './Negativebalanceprotection.scss'
function Negativebalanceprotection() {
  return (
    <div className='accountsmain'>
        <NewHeader  title="Negative Balance Protection" addicon="none"/>
        <div className='negativediv mt-2'>
            <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore tenetur veniam molestias soluta, sapiente sequi repellendus 
            </p>
            <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore tenetur veniam molestias soluta, voluptatum quas, minus officiis sapiente sequi repellendus consectetur explicabo inventore repudiandae iste commodi adipisci quis. Fugit neque unde alias voluptatum, animi at est, quas corrupti ratione
            </p>
        </div>
    </div>
  )
}

export default Negativebalanceprotection
