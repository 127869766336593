import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import close from '../../Images/close_svg.svg';
import deleteimg from '../../Images/delete1.png';
import addimg from '../../Images/add_svg.svg';
import Editmodal from './Editmodal';
import '../Tradetab/Tradetab.css';
import Currencybtn from '../Tradetab/Currencybtn';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { deleteWatchList, fetchWatchList } from '../../Redux/actions/tradeAction';
import Swal from 'sweetalert2';
import { formatNumber, getCurrencySymbol2 } from '../../helper/commonFunctions';

const Popmodel = ({ name, setShow, show, data, currency }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('MCX'); // Default currency
  const { watchlist, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets);
  const [livePrices, setLivePrices] = useState({});
  
  const dispatch = useDispatch();
  const userToken = getUserToken();
  
  useEffect(() => {
    // Fetch updated watchlist when currency changes
    dispatch(fetchWatchList(userToken));
  }, [selectedCurrency, dispatch, userToken]);

  useEffect(() => {
    if (!watchlist || !watchlist.payload) return;
    // Ensure we update trade data when Redux state changes
  }, [watchlist]);

  const handleEditModalShow = () => setShowEditModal(true);
  const handleEditModalClose = () => setShowEditModal(false);

  const handleDeleteClick = (watchlistToken) => {
    const token = getUserToken();
    Swal.fire({
      text: "Do you want to remove this item?",
      showCancelButton: true,      
      confirmButtonText: "Yes", 
      cancelButtonText: "No",     
      customClass: {
        confirmButton: 'custom-confirm-button', // You can add CSS class for buttons if needed
        cancelButton: 'custom-cancel-button'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, dispatch delete action
        handleDelete(watchlistToken, token);
        dispatch(fetchWatchList(token))
      }
    });
  };

  const handleDelete = async (watchlistToken) => {
    try {
      await dispatch(deleteWatchList(watchlistToken, userToken));
      dispatch(fetchWatchList(userToken));
    } catch (error) {
      console.error("Error deleting watchlist:", error);
    }
  };

  // Get the trade data for selected currency
  const currentPositions = watchlist?.payload?.[selectedCurrency] || [];

  // Live Price WebSocket Integration
  useEffect(() => {
    if (!currentPositions.length || !priceSocket) return;

    // Unsubscribe from old listeners first
    currentPositions.forEach((instrument) => {
      const event =
        /FOREX|CRYPTO|US/.test(instrument.exchange)
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;
      priceSocket.off(event);
    });

    // Subscribe to new trades for live price updates
    currentPositions.forEach((instrument) => {
      const event =
        /FOREX|CRYPTO|US/.test(instrument.exchange)
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;
      const emitData =
        /FOREX|CRYPTO|US/.test(instrument.exchange)
          ? instrument.symbol.toLowerCase()
          : instrument.token;

      priceSocket.emit('tokenData', emitData);
      priceSocket.on(event, (updatedInstrument) => {
        setLivePrices((prevState) => ({
          ...prevState,
          [emitData]: updatedInstrument,
        }));
      });
    });

    return () => {
      // Cleanup previous socket listeners
      currentPositions.forEach((instrument) => {
        const event =
          /FOREX|CRYPTO|US/.test(instrument.exchange)
            ? `forexRoom${instrument.symbol.toLowerCase()}`
            : `room${instrument.token}`;
        priceSocket.off(event);
      });
    };
  }, [currentPositions, priceSocket]);

  

  const getPrice = (watchlist, liveData) => {    
    if (liveData?.ltp && liveData?.ltp !== 0) {
      return liveData?.ltp;
    } else if (liveData?.bid_price && liveData?.bid_price !== 0) {
      return liveData?.bid_price;
    }
    if (watchlist?.ltp && watchlist?.ltp !== 0) {
      return watchlist?.ltp;
    } else if (watchlist?.bid_price && watchlist?.bid_price !== 0) {
      return watchlist?.bid_price;
    } else if (watchlist?.price && watchlist?.price !== 0) {
      return watchlist?.price;
    } else if (watchlist?.ask_price && watchlist?.ask_price !== 0) {
      return watchlist?.ask_price;
    } else {
      return 0;
    }
  };

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  // const renderFavorite = (tradeList) => {
  //   return tradeList.map((watchlist, index) => (
  //     <div className="card" key={index}>
  //       <button className='closebtn' onClick={() => handleDeleteClick(watchlist.token)}>
  //         <img src={deleteimg} width="20" alt="Delete" />
  //       </button>
  //       <div className="card-body">
  //         <div className="topdiv">
  //           <div className="text-start"><b>{watchlist?.symbol}</b></div>
  //           <div><p>{watchlist?.graphdata}</p></div>
  //           <div className="text-end">
  //             <p style={{ color: watchlist?.ask_price > watchlist?.pre_ask_price ? 'green' : 'red' }}>
  //               <b>{watchlist?.bid_price}</b>
  //             </p>
  //           </div>
  //         </div>
  //         <div className="bottomdiv">
  //           <div className="text-start"><p>{watchlist?.expiry || new Date().toISOString().split('T')[0]}</p></div>
  //           <div className="text-end">
  //             <span style={{ color: watchlist?.movepercent > 0 ? 'green' : 'red' }}>
  //               {watchlist?.movepercent > 0 ? (
  //                 <i className="fa fa-arrow-up" aria-hidden="true"></i>
  //               ) : (
  //                 <i className="fa fa-arrow-down" aria-hidden="true"></i>
  //               )}{' '}
  //               {watchlist?.percentage_change} %
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ));
  // };

   const renderFavorite = (tradeList) => {
      // console.log("tradeListtrade======render",tradeList);
      return tradeList.map((watchlist, index) => {
        // console.log("watchlistwatchlistwatchlistwatchlistwatchlistwatchlist",watchlist);
        const liveData = livePrices[watchlist?.symbol?.toLowerCase()] || livePrices[watchlist?.token] || {}; // Use live data if available
        // console.log("liveDataliveDataliveDataliveDataliveData======",liveData);
        const askPrice = getPrice(watchlist, liveData);
        const movePercent = liveData.percentage_change || watchlist?.percentage_change;  
        
        // console.log("askPriceaskPriceaskPriceaskPrice",askPrice);
  
        return (
          <div className="card mb-2" key={index} >
            <button className='closebtn' onClick={()=>handleDeleteClick(watchlist.token)}><img src={deleteimg} width="20" /></button>
            <div className="card-body" style={{cursor:"pointer"}}>
              <div className="topdiv">
                <div className="text-start">
                  <b>{watchlist?.symbol}</b>
                </div>
                <div>
                  <p>{watchlist?.graphdata}</p>
                </div>
                <div className="text-end">
                  <p style={{ color: movePercent && movePercent > 0 ? 'green' : 'red' }}>
                    <b>{getCurrencySymbol2(watchlist.exchange)} {formatNumber(askPrice)}</b>
                  </p>
                </div>
              </div>
              <div className="bottomdiv">
                <div className="text-start">
                  <p>{watchlist?.expiry? watchlist.expiry : formattedDate}</p>
                </div>
                <div className="text-end">
                  <span style={{ color: movePercent && movePercent > 0 ? 'green' : 'red' }}>
                  {/* {getCurrencySymbol2(watchlist.exchange)} */}
                    {movePercent && movePercent > 0 ? (
                      <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    )}{' '}
                    {movePercent} %
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      });
    };
  
    // const renderCurrencyTrades = (currency) => {
    //   const trades = trade?.payload?.[currency];
    //   if (trades?.length > 0) {
    //     return renderFavorite(trades);
    //   } else {
    //     return <p>No {currency} favourite symbol available</p>;
    //   }
    // };




  return (
    <div>
      {/* Modal Header */}
      <Modal.Header className="cusheader">
        <div className="left">
          <button onClick={() => setShow(false)} className="btn-close-modal">
            <img src={close} alt="Close" />
          </button>
          <h3 className='mb-0'>Edit {name}</h3>
        </div>
        <div className="right">
          <button onClick={handleEditModalShow} className="btn-add-item">
            <img src={addimg} alt="Add" />
          </button>
        </div>
      </Modal.Header>

      {/* Modal Body */}
      <Modal.Body>
        <Currencybtn currencybtn={selectedCurrency} setSelectedCurrency={setSelectedCurrency} />
        <div className="mb-4"></div>
        <div className='favouritepopup'>
          {loading && <span className="loader"></span>}
          {error && <p>Error loading trades: {error.message}</p>}
          {currentPositions.length > 0 ? renderFavorite(currentPositions) : <p>No {selectedCurrency} symbols available</p>}
        </div>
      </Modal.Body>

      {/* Edit Modal */}
      <Modal className="cusmodal" show={showEditModal} onHide={handleEditModalClose}>
        <Editmodal name="Favorite" show={showEditModal} setShow={setShowEditModal} />
      </Modal>
    </div>
  );
};

export default Popmodel;
