import React, { useEffect } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Benefits from '../Components/Profile/Benefits';
import Referalprogram from '../Components/Profile/Referalprogram';
import Socialtrading from '../Components/Profile/Socialtrading';
import Support from '../Components/Profile/Support';
import Logout from '../Components/Profile/Logout';
import Userdetails from '../Components/Profile/Userdetails';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../helper/getToken';
import { fetchUser } from '../Redux/actions/userAction';

const Profile = () => {
  const { user, loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  
  return (
    <div className='accountsmain'>
      <Header props="Profile" icon="none" settitng="block" />
      <div className='topmovermain'>
        <Userdetails userdata={user} />
        <Benefits />
        <Referalprogram />
        <Socialtrading />
        <Support />
        <Logout userdata={user}/>
        <div className='mb-5'></div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
