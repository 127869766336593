import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faClock } from '@fortawesome/free-regular-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Notificationmodal from '../Popupmodal/Notificationmodal';
import Modal from 'react-bootstrap/Modal';
import Editmodal from '../Popupmodal/Editmodal';
import Searchpopup from '../Popupmodal/Searchpopup';

const Header = ({props,icon,settitng}) => {  
  const [show, setShow] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  // const [showsearch, setShowsearch] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/settings');
  };



  const  handleAlertShow = ()=>{
      navigate('/pricealert')
  }
  function handleNotificationShow(){
    setShow(true);
  }

  const handleEditModalShow = () => {
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };


  return (
    <div>
        <div className='accountsheader'>
            <h2>{props}</h2>
            <div className='rightelements' style={{display:icon}}>
              <FontAwesomeIcon icon={faPlus} className='headericon' onClick={() => handleEditModalShow()}/>
              <FontAwesomeIcon icon={faClock} className='headericon' onClick={() => handleAlertShow()}/>
              <FontAwesomeIcon icon={faBell} className='headericon' onClick={() => handleNotificationShow()}/>                
            </div>
            <div className='rightelements' style={{display:settitng}}>
                <FontAwesomeIcon icon={faGear} className='headericon' onClick={()=>handleClick()}/>
            </div>
        </div>

        <Modal className='cusmodal' show={show}  onHide={() => setShow(false)}>
           {/* <Popmodel  name="Favoriate" show={show} setShow={setShow}/> */}
           <Notificationmodal name="Notification" show={show} setShow={setShow}/>
        </Modal>
        {/* <Modal className="cusmodal" show={showEditModal} onHide={handleEditModalClose}>
            <Editmodal name="Favorite" show={showEditModal} setShow={setShowEditModal} />
        </Modal> */}
        <Modal className="cusmodal" show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Searchpopup show={showEditModal} setShow={setShowEditModal} />
        </Modal>
    </div>
  )
}

export default Header