import { act, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPosition } from "../../Redux/actions/userAction";
import { getUserToken } from "../../helper/getToken";
import axios from "axios";
import { fetchLeverage } from "../../helper/api_helper";
import { fetchAllLeverage } from "../../Redux/actions/tradeAction";
import { formatNumber } from "../../helper/commonFunctions";



const Marketorderset = ({
    action, 
    isToggleOn, 
    volumeplus, 
    setVolumeplus, 
    profitplus, 
    setProfitplus, 
    stoplossplus, 
    selectedvolume,
    setStoplossplus, 
    onDropdownVolumeChange, 
    onDropdownProfitChange, 
    onDropdownLossChange ,
    moverData,
    selectedprofit,
    handleChangeVolume,
    handleChangeStopLoss,
    handleChangeTakeProfit,
    getTotalPriceLive,
    livePrice,
    isError,
    getQuantity,
    setIsError,
    errorMsg,
    setErrorMsg,
    // incrementVolume,
    // decrementVolume,
    // handleSelectChangeVolume
}) => {
    const apiUrl = process.env.REACT_APP_API_FRONTEND;
    
    const [selectVolType,setSelectVolType]=useState('lots');
    const dispatch=useDispatch();
    const { position,loading,error } = useSelector((state) => state.user); 
    const { leverage} = useSelector((state) => state.trade);     
    const token=getUserToken();
    const [conversionRate,setConversionRate]=useState(1);
    useEffect(()=>{
        dispatch(fetchUserPosition(token))
    },[dispatch,token])

    const bodyData = {
        exchange:moverData.exchange
    }

    
  
    useEffect(()=>{
        setProfitplus(moverData?.price);
        setStoplossplus(moverData?.price)
        getConversionRateInUSD(moverData?.symbol);
        dispatch(fetchAllLeverage(token,bodyData))
    },[])

    

    
    const getConversionRateInUSD=(symbol)=>{
        if(typeof symbol!=='string'||symbol.length!=6){
            setConversionRate(1);
            return 1;
        }else if(symbol.substring(0,3)=='USD'||symbol.substring(3,6)=='USD'){
            setConversionRate(1);
            return 1;
        }else{
            let firstSymbol=symbol.substring(0,3);
            let fullSymbol=firstSymbol+'USD';
            let data={
                symbol:fullSymbol
            }
            let config={
                url:`${apiUrl}client/market/fetch-stock-by-symbol`,
                method:"POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data:data
            }
            axios.request(config).then(res=>{
               
                let price=1;
                if(res?.data?.payload){
                    price=res?.data?.payload?.price||res?.data?.payload?.ask_price||res?.data?.payload?.bid_price||res?.data?.payload?.ltp;
                }
            setConversionRate(Number(price));
                return price;
            }).catch(err=>{
                console.log(err,'error in fetching conversion rate')
            setConversionRate(1);
                return 1;
            })
        }
    }
    
    

    const incrementVolume = () => {
        setVolumeplus((prev) => {
            let currentValue=parseFloat(prev)||0;
            if (selectVolType === 'lots') {
                return parseFloat((currentValue + 0.01).toFixed(2));
            } else {
                return currentValue + 1;
            }
        });
    };
    const decrementTakeProfit = () => {
      let currentVal = parseFloat(profitplus) || 0;
      let newVal = parseFloat(currentVal - 0.1).toFixed(2);
      if(selectedprofit=='inmoney'){
        setIsError(false)
        setErrorMsg('')
        if(newVal>0) setProfitplus(newVal)
        else setProfitplus(0);
        return;
      }
      if (newVal <= livePrice&&action=='Buy') {
        setIsError(true);
        setErrorMsg("Take profit field must be greater than live price");
      }else if(stoplossplus>=livePrice&&action=='Buy'){
        setIsError(true);
        setErrorMsg("Stop Loss field must be lesser than live price");
      }
      if (newVal >= livePrice&&action=='Sell') {
        setIsError(true);
        setErrorMsg("Take profit field must be lesser than live price");
      }else if(stoplossplus<=livePrice&&action=='Sell'){
        setIsError(true);
        setErrorMsg("Stop Loss field must be greater than live price");
      }
      else {
        setIsError(false);
        setErrorMsg("");
      }
      if (newVal > 0) setProfitplus(newVal);
      else setProfitplus(0);
    };
    const incrementTakeProfit = () => {
      let currentVal = parseFloat(profitplus) || 0;
      let newVal = parseFloat(currentVal + 0.1).toFixed(2);
      if(selectedprofit=='inmoney'){
        setIsError(false)
        setErrorMsg('')
        if(newVal>0) setProfitplus(newVal)
        else setProfitplus(0);
        return;
      }
      if (newVal <= livePrice&&action=='Buy') {
        setIsError(true);
        setErrorMsg("Take profit field must be greater than live price");
      }else if(stoplossplus>=livePrice&&action=='Buy'){
        setIsError(true);
        setErrorMsg("Stop loss field must be lesser than live price");
      } 
      if (newVal >= livePrice&&action=='Sell') {
        setIsError(true);
        setErrorMsg("Take profit field must be lesser than live price");
      }else if(stoplossplus<=livePrice&&action=='Sell'){
        setIsError(true);
        setErrorMsg("Stop loss field must be greater than live price");
      }
      else {
        setIsError(false);
        setErrorMsg("");
      }
      setProfitplus(newVal);
    };
    const decrementStopLoss = () => {
        let currentVal = parseFloat(stoplossplus) || 0;
        let newVal = parseFloat(currentVal - 0.1).toFixed(2);
        if(selectedprofit=='inmoney'){
            setIsError(false)
            setErrorMsg('')
            if(newVal>0) setStoplossplus(newVal)
            else setStoplossplus(0);
            return;
          }
        if (newVal >= livePrice&&action=='Buy') {
          setIsError(true);
          setErrorMsg("Stop Loss field must be lesser than live price");
        }else if(profitplus<=livePrice&&action=='Buy'){
            setIsError(true);
            setErrorMsg("Take profit field must be greater than live price");
        }
        else if(newVal<=livePrice&&action=='Sell'){
            setIsError(true);
            setErrorMsg("Stop Loss field must be greater than live price");
        }
        else if(profitplus>=livePrice&&action=='Sell'){
            setIsError(true);
            setErrorMsg("Take profit field must be lesser than live price");
        }
         else {
          setIsError(false);
          setErrorMsg("");
        }
        if (newVal > 0) setStoplossplus(newVal);
        else setStoplossplus(0);
      };
     
      const incrementStopLoss = () => {
        let currentVal = parseFloat(stoplossplus) || 0;
        let newVal = parseFloat(currentVal + 0.1).toFixed(2);
        if(selectedprofit=='inmoney'){
            setIsError(false)
            setErrorMsg('')
            if(newVal>0) setStoplossplus(newVal)
            else setStoplossplus(0);
            return;
          }
        if (newVal >= livePrice&&action=='Buy') {
          setIsError(true);
          setErrorMsg("Stop Loss field must be lesser than live price");
        }else if(profitplus<=livePrice&&action=='Buy'){
            setIsError(true);
            setErrorMsg("Take profit field must be greater than live price");
        }
        else if(newVal<=livePrice&&action=='Sell'){
            setIsError(true);
            setErrorMsg("Stop Loss field must be greater than live price");
        }
        else if(profitplus>=livePrice&&action=='Sell'){
            setIsError(true);
            setErrorMsg("Take profit field must be lesser than live price");
        }
         else {
          setIsError(false);
          setErrorMsg("");
        }
        setStoplossplus(newVal);
      };
    const decrementVolume = () => {
      setVolumeplus((prev) => {
        let currentValue=parseFloat(prev)||0;
          if (selectVolType === 'lots') {
              const newValue = parseFloat((currentValue - 0.01).toFixed(2));
            //   return newValue < 0.1 ? 0.1 : newValue; // Minimum 0.1 for lots
            return newValue < 0.01 ? 0.01 : newValue; // Minimum 0.1 for lots
          } else {
              const newValue = currentValue - 1;
              return newValue < 1 ? 1 : newValue; // Minimum 1 for USD
          }
      });
    };
    
    const handleSelectChangeVolume = (e) => {
      const value = e.target.value;        
      onDropdownVolumeChange(value);
      setErrorMsg('')
      setIsError(false)
      setSelectVolType(value)
      if(value=='lots'){
          setVolumeplus(0.01)
      }else{
          setVolumeplus(1);
      }
    };
    const handleSelectChangeProfit = (e) => {
        const value = e.target.value;
        onDropdownProfitChange(value);
    };
    const handleSelectChangeLoss = (e) => {
        const value = e.target.value;
        onDropdownLossChange(value);
        
    };

    

    const getFeesCalculated = () => {
        // Ensure `moverData` exists and handle safely        
        if (!moverData?.spread ) {
            // console.error("Invalid moverData or spread");
            return 0; // Return 0 for invalid data
        }       
        // let pipsCalculated = 0;
        // const symbol = moverData.symbol;
        const spread = moverData.spread;    
        // if (symbol.substring(0, 3) === "JPY" || symbol.substring(3, 6) === "JPY") {
            // Special calculation for JPY pairs
           let pipsCalculated = spread ;
        // } else {
            // Standard calculation for other symbols
            // const spreadString = spread.toFixed(4); // Ensure spread is treated as a string with decimals
            // const decimalPart = spreadString.split(".")[1]; // Extract decimal part
    
            // if (decimalPart && decimalPart.length > 2) {
                // const result = `0.00${decimalPart.slice(2)}`; // Form the pips value from the third decimal onward
                // pipsCalculated = spread; // Convert back to a number
            // } 
            // else {
            //     pipsCalculated = 0; // Default to 0 if no significant decimal part
            // }
        // }
    
        
        return Number(pipsCalculated);
    };
    
    // getFeesCalculated();

    // const feesCalculated = getFeesCalculated();
    // const bidPrice = Number(moverData?.bid_price);
    // const lotSize = Number(moverData?.lotsize);
    // const volumePlus = Number(volumeplus);
    // const dynamicPricing=getTotalPriceLive(selectedvolume,livePrice,volumeplus)
    const feesCalculated = getFeesCalculated();
    const bidPrice = Number(moverData?.bid_price);
    const lotSize = Number(moverData?.lotsize);
    const volumePlus = Number(volumeplus);
    const dynamicPricing=getTotalPriceLive(selectedvolume,livePrice,volumeplus)

    
    
    return (
        <div>
            <div className="forexbottomdrawer" style={{
                minHeight: isToggleOn ? 'auto' : '150px',
                marginBottom: "20px",
                overflow: 'hidden',
                transition: 'height 0.3s ease-in-out',
            }}>
                {!isToggleOn ? (
                    <div className="foresmain">
                        <div className="forexclear mt-3">
                            <label>Volume</label>
                            <button>Clear</button>
                        </div>
                        <div className="d-flex align-items-center w-100">
                            <div className="volume-input">
                                <button onClick={decrementVolume}>-</button>
                                <input type="text" value={volumeplus} onChange={handleChangeVolume} />
                                <button onClick={incrementVolume}>+</button>
                            </div>
                           {moverData?.exchange=="CRYPTO"? <select className="cusselect" onChange={handleSelectChangeVolume}>
                                <option value="usd">USD</option>
                            </select>: <select className="cusselect" onChange={handleSelectChangeVolume}>
                                <option value="lots">Lots</option>
                                <option value="usd">Units</option>
                            </select>}
                        </div>
                        <div>
                        {/* <p className="details">{` Fees: ~ ${moverData?.high_price!=0?Number(Number(moverData?.high_price)-Number(moverData?.low_price)).toFixed(4):0.2} USD | Margin: ${Number(getTotalPriceLive(selectedvolume,livePrice,volumeplus)/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                        {/* <p className="details">{` Fees: ~ ${Number((Number(getFeesCalculated()*Number(moverData?.lotsize))*Number(volumeplus)).toFixed(4))} USD | Margin: ${Number(Number(getTotalPriceLive(selectedvolume,livePrice,volumeplus))/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                        
                        {/* <p className="details">{` Fees: ~ ${Number((Number((getFeesCalculated() / moverData.bid_price)*Number(moverData?.lotsize))*Number(volumeplus)).toFixed(4))} USD | Margin: ${Number(Number(getTotalPriceLive(selectedvolume,livePrice,volumeplus))/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                        {/* <p className="details">{` Fees: ~ ${Number((feesCalculated  * lotSize * volumePlus).toFixed(4))} USD | Margin: ${Number(Number(dynamicPricing)/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                        <p style={{fontWeight:"500"}} className="details">{`Quantity : ${getQuantity(selectedvolume,volumePlus,moverData)}`}</p>
                        <p className="details">{` Fees: ~ ${moverData.exchange =="CRYPTO" ? Number(((dynamicPricing * 5/100)/(leverage?.payload?.intraday)).toFixed(4)):Number((feesCalculated  * lotSize * volumePlus*conversionRate).toFixed(2))} USD | Margin: ${moverData?.exchange=="CRYPTO"?formatNumber(Number(volumeplus)/Number(leverage?.payload?.intraday)):Number(Number(dynamicPricing)/(leverage?.payload?.intraday)).toFixed(4)} USD | Leverage: 1 : ${leverage?.payload?.intraday}`} </p>
                        
                        
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="foresmain">
                            <div className="forexclear mt-3">
                                <label>Volume</label>
                                <button onClick={() => setVolumeplus(0.00)}>Clear</button>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <div className="volume-input">
                                    <button onClick={decrementVolume}>-</button>
                                    <input type="text" value={volumeplus} onChange={handleChangeVolume} />
                                    <button onClick={incrementVolume}>+</button>
                                </div>
                                {moverData?.exchange=="CRYPTO"?<select className="cusselect" onChange={handleSelectChangeVolume}>
                                    <option value="usd">USD</option>
                                </select>:<select className="cusselect" onChange={handleSelectChangeVolume}>
                                    <option value="lots">Lots</option>
                                    <option value="usd">Units</option>
                                </select>}
                            </div>
                            <div>
                                {/* <p className="details">{` Fees: ~ ${Number((Number(getFeesCalculated()*Number(moverData?.lotsize))*Number(volumeplus)).toFixed(4))} USD | Margin: ${getTotalPriceLive(volumeplus,livePrice,volumeplus)} USD | Leverage: 1:500`} </p> */}
                                {/* <p className="details">{` Fees: ~ ${Number(((feesCalculated / bidPrice) * lotSize * volumePlus).toFixed(4))} USD | Margin: ${Number(Number(getTotalPriceLive(selectedvolume,livePrice,volumeplus))/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                                {/* <p className="details">{` Fees: ~ ${Number((((feesCalculated / bidPrice) * lotSize * volumePlus) * 0.5).toFixed(4))} USD | Margin: ${Number(Number(dynamicPricing)/500).toFixed(4)} USD | Leverage: 1:500`} </p> */}
                                
                                <p style={{fontWeight:"500"}} className="details">{`Quantity : ${getQuantity(selectedvolume,volumePlus,moverData)}`}</p>
                                <p className="details">{` Fees: ~ ${moverData.exchange =="CRYPTO" ? Number(((dynamicPricing * 5/100)/(leverage?.payload?.intraday)).toFixed(4)):Number((feesCalculated  * lotSize * volumePlus*conversionRate).toFixed(2))} USD | Margin: ${moverData?.exchange=="CRYPTO"?formatNumber(Number(volumeplus)/Number(leverage?.payload?.intraday)):Number(Number(dynamicPricing)/(leverage?.payload?.intraday)).toFixed(4)} USD | Leverage: 1: ${leverage?.payload?.intraday}`} </p>
                            </div>
                        </div>
                        <div className="foresmain">
                            <div className="forexclear mt-1">
                                <label>Take Profit</label>
                                <button onClick={() => setProfitplus(0.00)}>Clear</button>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <div className="volume-input">
                                    {/* <button onClick={() => setProfitplus((prev) => parseFloat((prev - 0.10).toFixed(2)))}>-</button> */}
                                    {profitplus > 0 ? (<button onClick={decrementTakeProfit}>-</button>): (<button onClick={decrementTakeProfit}>-</button>)}

                                    <input type="text" value={profitplus} onChange={handleChangeTakeProfit} />
                                    <button onClick={incrementTakeProfit}>+</button>
                                </div>
                                <select className="cusselect" value={selectedprofit} onChange={handleSelectChangeProfit}>
                                    <option value="in_price">Price</option>
                                    <option value="in_asset_price">By asset price</option>
                                    <option value="in_money">In money</option>
                                    <option value="in_percentage">In % of equity</option>
                                    <option value="in_pip">In pips</option>
                                </select>
                            </div>
                        </div>
                        <div className="foresmain">
                            <div className="forexclear mt-1">
                                <label>Stop Loss</label>
                                <button onClick={() => setStoplossplus(0.00)}>Clear</button>
                            </div>
                            <div className="d-flex align-items-center w-100">
                                <div className="volume-input">
                                    {/* <button onClick={() => setStoplossplus((prev) => parseFloat((prev - 0.10).toFixed(2)))}>-</button> */}
                                    {stoplossplus > 0 ? (<button onClick={decrementStopLoss}>-</button>): (<button onClick={decrementStopLoss}>-</button>)}

                                    <input type="text" value={stoplossplus} onChange={handleChangeStopLoss} />
                                    <button onClick={incrementStopLoss}>+</button>
                                </div>
                                <select className="cusselect" value={selectedprofit} onChange={handleSelectChangeProfit}>
                                    <option value="in_price">Price</option>
                                    <option value="in_asset_price">By asset price</option>
                                    <option value="in_money">In money</option>
                                    <option value="in_percentage">In % of equity</option>
                                    <option value="in_pip">In pips</option>
                                </select>
                            </div>
                        </div>
                    </>
                )}
                        {isError==true?<div style={{display:"flex",marginTop:"10px"}}>
                            <p style={{color:"red"}}>{errorMsg}</p>
                        </div>:<></>}
            </div>
        </div>
    );
};

export default Marketorderset;
