// import { COONECT_PRICE_SOCKET, CONECT_NOTIFY_SOCKET } from "./actionType";
// import io from 'socket.io-client';

// let initialState = {
//   priceSocket: io('https://ws1.bastionex.net'),
//   notifySocket: io('https://ws2.bastionex.net'),
  
// };
// const socket = (state = initialState, action) => {
//   switch (action.type) {
//     case COONECT_PRICE_SOCKET:
//       if (state["priceSocket"]) {
//         return state
//       }
//       return {
//         ...state,
//         priceSocket: action.payload
//       };
//     case CONECT_NOTIFY_SOCKET:
//       if (state["notifySocket"]) {
//         return state
//       }
//       return {
//         ...state,
//         notifySocket: action.payload
//       };
//     default:
//       return state;
//   }
// };
// export default socket;

import { COONECT_PRICE_SOCKET, CONECT_NOTIFY_SOCKET } from "./actionType";

const initialState = {
  priceSocket: null,
  notifySocket: null,
};

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case COONECT_PRICE_SOCKET:
      return {
        ...state,
        priceSocket: action.payload,
      };

    case CONECT_NOTIFY_SOCKET:
      return {
        ...state,
        notifySocket: action.payload,
      };

    default:
      return state;
  }
};

export default socketReducer;

