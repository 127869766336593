import React from 'react'
import Header from '../Components/Header/Header'
import Accountmiddlecard from '../Components/AccountMiddle/Accountmiddlecard'
import Footer from '../Components/Footer/Footer'
import Topmovers from '../Components/Topmovers/Topmovers'
import Trandingsignals from '../Components/Trandingsignals/Trandingsignals'
import Upcommingevents from '../Components/Upcommingevents/Upcommingevents'
import Topnews from '../Components/Topnews/Topnews'

const Marketpage = () => {
  return (
    <div className='accountsmain'>
        <Header props="Markets" icon="block" settitng="none"/>
        {/* <Accountmiddlecard  prop="none" /> */}
        <Accountmiddlecard  propdata="none" newprop="block"/>
        <div className='topmovermain'>
          <Topmovers/>
          {/* <Trandingsignals/> */}
          <Upcommingevents/>
          <Topnews/>
        </div>
        
        <Footer/>
    </div>
  )
}

export default Marketpage