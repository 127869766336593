import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import axios from "axios";
import { getUserToken } from "../helper/getToken";

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); // Detect mobile device

const TransactionHistory = () => {
  const [history, setHistory] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("INR");

  const token = getUserToken();
  const query = useQueryParams();

  const status = query.get("status") || "";
  const amount = query.get("amount") || "";
  const orderid = query.get("orderid") || "";
  const merchantTxn = query.get("merchantTxn") || "";

  const queryParams = new URLSearchParams({
    status,
    amount,
    orderid,
    merchantTxn,
  }).toString();

  const navigate = useNavigate();

  // ✅ Prevent Infinite Redirect Loop
  useEffect(() => {
    
}, []);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(`${baseUrl}client/fund/fetchTransactions?${queryParams}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setHistory(response?.data?.payload || {});
        // if (isMobile) {
        //     console.log("Mobile detected - trying to open WebView app");
        //     window.location.replace("com.bastionex.tradelearning://transactionhistory");
        //     // setTimeout(() => {
        //     //     console.log("App not detected, redirecting to website...");
        //     //     window.location.href = "https://tradeyaar.in/transactionhistory";
        //     // }, 3000);
        // } else {
        //     console.log("Web detected - navigating within React Router");
        //     navigate("/transactionhistory"); // Navigate within web
        // }

      } catch (error) {
        console.error("Error fetching transaction history:", error);
      }
    };

    if (token) {
      fetchHistory();
    }
  }, [token, queryParams]);

  const convertToIST = (timestamp) => {
    if (!timestamp) return "Invalid Date";

    let date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format:", timestamp);
      return "Invalid Date";
    }

    const istOffset = 5.5 * 60 * 60 * 1000;
    date = new Date(date.getTime() + istOffset);

    return date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const filteredTransactions =
    history?.transactions?.filter((transc) => transc.currency === selectedCurrency) || [];

  return (
    <div className="accountsmain">
      <Header props="Transaction History" icon="block" settitng="none" />
      <div className="acctabbtndiv" style={{ height: "85vh", overflow: "scroll", marginBottom: "40px" }}>
        <div className="d-flex justify-content-between align-items-center gap-10">
          <div className="currencydiv">
            <p>
              <b>INR: {history?.balance}</b>
            </p>
          </div>
          <div className="currencydiv">
            <p>
              <b>USD: {history?.balance_usd}</b>
            </p>
          </div>
        </div>
        <div className="mt-2">
          {["INR", "USD"].map((currency) => (
            <button key={currency} className={selectedCurrency === currency ? "active" : ""} onClick={() => setSelectedCurrency(currency)}>
              {currency === "INR" ? "INR" : "USD"}
            </button>
          ))}
        </div>

        {selectedCurrency && (
          <>
            {filteredTransactions.length > 0 ? (
              filteredTransactions.map((transc, index) => (
                <div key={index} className="transactioncard">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="datetext">{convertToIST(transc?.added_on)}</p>
                    <p className="pricetext">
                      {transc?.amount} {transc?.currency}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="typetext">
                      <i className={`fa ${transc?.type === "deposit" ? "fa-arrow-up" : "fa-arrow-down"} arrowdiv`}></i>
                      {transc?.type}
                    </p>
                    <p
                      className={
                        transc?.status === "cancel"
                          ? "statuscancel"
                          : transc?.status === "complete"
                          ? "statussuccess"
                          : "statuspending"
                      }
                    >
                      {transc?.status}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No {selectedCurrency} transactions found.</p>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TransactionHistory;
