// import axios from 'axios';
import { 
    FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE,
    FETCH_USER_POSITION_REQUEST, FETCH_USER_POSITION_SUCCESS, FETCH_USER_POSITION_FAILURE,
    ADD_FUND_REQUEST, ADD_FUND_SUCCESS, ADD_FUND_FAILURE,
    WITHDRAW_FUND_REQUEST, WITHDRAW_FUND_SUCCESS, WITHDRAW_FUND_FAILURE,
    BUY_TRADE_REQUEST, BUY_TRADE_SUCCESS, BUY_TRADE_FAILURE,OPEN_DRAWER,CLOSE_DRAWER,TOGGLE_DRAWER,FETCH_ALERTS_FAILURE,FETCH_ALERTS_REQUEST,FETCH_ALERTS_SUCCESS,DELETE_ALERTS_REQUEST,DELETE_ALERTS_SUCCESS,DELETE_ALERTS_FAILURE
    
} from '../authCostants';
import {addFunddata, fetchUserData,fetchUserPositionData,withdrawFunddata,userBuyTrade, fetchUserAlertsApi,deleteAlertbyId} from "../../helper/api_helper"

export const fetchUserAlerts=(token)=> async (dispatch)=>{
  dispatch({type:FETCH_ALERTS_REQUEST});
  try{
    const response=await fetchUserAlertsApi(token);
    dispatch({type:FETCH_ALERTS_SUCCESS,payload:response.data})
  }catch(error){
    dispatch({
      type:FETCH_ALERTS_FAILURE,
      payload:error?.response?.data?.message||error?.message
    })
  }
}

export const deleteUserAlert = (alertId, token) => async (dispatch) => {
  try {
      const response = await deleteAlertbyId(alertId, token);
      if (response.status === 200 || response.status === 204) {
          dispatch({ type: DELETE_ALERTS_SUCCESS, payload: alertId });
      } else {
          console.error("Delete failed, unexpected response:", response);
      }
  } catch (error) {
      console.error("Error deleting alert:", error);
  }
};


export const fetchUser = (token) => async (dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });
  try {
    const response = await fetchUserData(token);  // Pass token here    
    dispatch({ type: FETCH_USER_SUCCESS, payload: response.data });
  } catch (error) {
    
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};

export const fetchUserPosition = (token) => async (dispatch) => {
  dispatch({ type: FETCH_USER_POSITION_REQUEST });
  try {
    const response = await fetchUserPositionData(token);  // Pass token here
    
    dispatch({ type: FETCH_USER_POSITION_SUCCESS, payload: response.data });
  } catch (error) {
    console.log("Fetch User Error:", error.response?.data || error.message);
    dispatch({
      type: FETCH_USER_POSITION_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};


// Action to fetch user data with token

export const addFundInaccount = (formData, token) => async (dispatch) => {
  dispatch({ type: ADD_FUND_REQUEST });
  try {
    const response = await addFunddata(formData, token);
       
    dispatch({ type: ADD_FUND_SUCCESS, payload: response.data });
    // navigate('/accounts');
    //return 'success';
    return response.data;
  } catch (error) {
    dispatch({
      type: ADD_FUND_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

export const withdrawFund = (formData, token,navigate) => async (dispatch) => {
  dispatch({ type: WITHDRAW_FUND_REQUEST });
  try {
    const response = await withdrawFunddata(formData, token); // Pass token correctly
    dispatch({ type: WITHDRAW_FUND_SUCCESS, payload: response.data });
    // navigate('/accounts');
    return response.data;
  } catch (error) {
    dispatch({
      type: WITHDRAW_FUND_FAILURE,
      payload: error.response?.data?.message || error.message,
    });
  }
};

// export const userTradeBuy = (formData, token,navigate) => async (dispatch) => {
//   dispatch({ type: BUY_TRADE_REQUEST });
//   try {
//     const response = await userBuyTrade(formData, token); // Pass token correctly
//     dispatch({ type: BUY_TRADE_SUCCESS, payload: response.data });
//     // navigate('/accounts');
//     return 'success';
//   } catch (error) {
//     dispatch({
//       type: BUY_TRADE_FAILURE,
//       payload: error.response?.data?.message || error.message,
//     });
//   }
// };

export const userTradeBuy = (formData, token, navigate) => async (dispatch) => {
  dispatch({ type: BUY_TRADE_REQUEST });
  try {
    const response = await userBuyTrade(formData, token); // API call
    dispatch({ type: BUY_TRADE_SUCCESS, payload: response.data });
    
    // Return success status based on API response.
    return { status: response.data.status, message: response.data.message };
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch({
      type: BUY_TRADE_FAILURE,
      payload: errorMessage,
    });

    // Return failure status with message.
    return { status: 'FAILED', message: errorMessage };
  }
};

// export const userTradeSell = (formData, token, navigate) => async (dispatch) => {
//   dispatch({ type: BUY_TRADE_REQUEST });
//   try {
//     const response = await userBuyTrade(formData, token); // API call
//     dispatch({ type: BUY_TRADE_SUCCESS, payload: response.data });
    
//     // Return success status based on API response.
//     return { status: 'success', message: response.data.message };
//   } catch (error) {
//     const errorMessage = error.response?.data?.message || error.message;
//     dispatch({
//       type: BUY_TRADE_FAILURE,
//       payload: errorMessage,
//     });

//     // Return failure status with message.
//     return { status: 'FAILED', message: errorMessage };
//   }
// };

export const openDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const toggleDrawer = () => ({
  type: TOGGLE_DRAWER,
});

