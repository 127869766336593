

import { Link, useNavigate } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { useState } from 'react';

const Forgotpassword = () => {
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  
  const validationSchema = Yup.object({    
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[0-9]/, 'Password must contain a number')
      .matches(/[a-z]/, 'Password must contain a lowercase letter')
      .matches(/[A-Z]/, 'Password must contain an uppercase letter')
      .matches(/[^\w]/, 'Password must contain a special character')
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
  });
  
  const formik = useFormik({
    initialValues: {      
      email: "",
      password: "",
      password_confirmation: ""
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        // Submit data logic can go here
        
        if(data){
          navigate('/otpscreen',{state:{valuesData:data,otp:"6666"}})
        }
        
        resetForm();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <div className='signinmain'>
      <div className='header'>
        <Link to="/residence"><img src={BackArrow} alt="Back" /></Link>
        <h2>Enter your details.</h2>
      </div>
      <div className='refisterdiv mt-5'>
      <form onSubmit={formik.handleSubmit}>        
          <div className='signinform'>
            <p><b>Use this email to sign in to {process.env.REACT_APP_COMPANY_NAME}</b></p>            
            <div className='emailinput'>
              <label><b>Email</b></label>
              <input
                type='email'
                name='email'
                placeholder='Enter email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="Formikerrormsg">{formik.errors.email}</div>
              ) : null}
            </div>           

            <div className='emailinput'>
              <label><b>Password</b></label>
              <input
                type='password'
                name='password'
                placeholder='Enter password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="Formikerrormsg">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className='emailinput'>
              <label><b>Confirm Password</b></label>
              <input
                type='password'
                name='password_confirmation'
                placeholder='Confirm your password'
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                <div className="Formikerrormsg">{formik.errors.password_confirmation}</div>
              ) : null}
            </div>         
        </div>
        <div className='signregisterbtns'>
          <div className='button1'><button type="submit" >Submit</button></div>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Forgotpassword;
