import React, { act, useEffect, useState } from 'react';
import './Bottomdrawer.css';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from './Toggleswitch';
import Buylimitset from './Buylimitset';
import Marketorderset from './Marketorderset';
import {formatNumber} from '../../helper/commonFunctions'
import axios from 'axios';
import { getUserToken } from '../../helper/getToken';
import BuystopSet from './BuystopSet';
import { useDispatch } from 'react-redux';
import { fetchUserPosition } from '../../Redux/actions/userAction';

const Forexdetailsdrawer = ({moverData,livePrice, isDrawerOpen, onClose,action,setShowPopup,setPopupMessage }) => {

  
  
  const navigate = useNavigate();
  const [isToggleOn, setIsToggleOn] = useState(false);
  const token=getUserToken();
  const [volumeplus,setVolumeplus] = useState(0.01);
  const [orderType,setOrderType]=useState('market');
  const [priceplus,setPriceplus] = useState(0);
  const dispatch=useDispatch();
  const [profitplus,setProfitplus] = useState(0.10);
  const [stoplossplus,setStoplossplus] = useState(0.10); 
  const[selectedvolume,setSelectedVolume] = useState("lots");
  const[selectedprice,setSelectedPrice] = useState("");
  const [isError,setIsError]=useState(false);
  const [errorMsg,setErrorMsg]=useState('')
  const[selectedprofit,setSelectedProfit] = useState("in_price");
  const[selectedloss,setSelectedLoss] = useState("in_price");

  let spreadData = Number(moverData.open_price - moverData.ltp);

const handleChangeVolume=(e)=>{
  const value=e.target.value;
  const regex= /^[0-9]*\.?[0-9]*$/
  if(moverData?.exchange=="CRYPTO"){
    if(value<1){
      setErrorMsg("Value must be greater than 1")
      setIsError(true)
    }else{
      setErrorMsg("")
      setIsError(false)
    }
  }
  else{
    if(selectedvolume=='lots'){
      if(value<0.01){
        setErrorMsg("Lot size must be greater than 0.01")
        setIsError(true);
      }
      else{
        setErrorMsg('')
        setIsError(false)
      }
    }else{
      if(value<1){
        setErrorMsg("volume must be greater than 1")
        setIsError(true);
      }
      else{
        setErrorMsg('')
        setIsError(false)
      }
    }
  }
  if(regex.test(value)){
      setVolumeplus(value)
  }else{
      e.preventDefault();
  }
}
useEffect(()=>{
  if(moverData?.exchange=="CRYPTO"){
    setVolumeplus(1);
  }
  return ()=>{
    setVolumeplus(0.01)
  }
},[])
const getQuantity=(selectedVolume,volumeplus,moverData)=>{
  let price=getPrice();
  
  if(moverData?.exchange=="CRYPTO"){
      let cryptoQty=volumeplus?formatNumber(Number(volumeplus)/Number(price)):0;
      return `${cryptoQty} ${moverData?.symbol}`;
  }
  if(moverData?.lotsize){
      if(selectedVolume=='usd'){
          return `${volumeplus} units`
      }else{
          return `${Number(moverData?.lotsize)*Number(volumeplus)} units`
      }
  }else{
      if(selectedVolume=='usd'){
          return `${volumeplus} units`
      }else{
          return `${volumeplus} lots`
      }
  }
}

const handleChangeStopLoss=(e)=>{
  const value=e.target.value;
  const regex= /^[0-9]*\.?[0-9]*$/
  if(regex.test(value)){
      setStoplossplus(value)
      if(selectedprofit=='inmoney'){
        setIsError(false);
        setErrorMsg('')
        return;
      }
  if(value>=livePrice&&action=='Buy'){
    setIsError(true);
    setErrorMsg("Stop Loss field must be lesser than live price")
  }else if(value<=livePrice&&action=='Sell'){
    setIsError(true);
    setErrorMsg("Stop Loss field must be greater than live price")
  }
  else if(profitplus<=livePrice&&action=='Buy'){
    setIsError(true);
    setErrorMsg("Take Profit field must be greater than live price")
  }else if(profitplus>=livePrice&&action=='Sell'){
    setIsError(true);
    setErrorMsg("Take Profit field must be lesser than live price")
  }
  else{
    setIsError(false);
    setErrorMsg('')
  }
}else{
  e.preventDefault();
}

}
const handleChangeTakeProfit=(e)=>{
  const value=e.target.value;
  const regex= /^[0-9]*\.?[0-9]*$/
  if(regex.test(value)){
      
  setProfitplus(value)
  if(selectedprofit=='inmoney'){
    setIsError(false);
    setErrorMsg('')
    return;
  }
  if(value<=livePrice&&action=='Buy'){
    setIsError(true);
    setErrorMsg("Take profit field must be greater than live price")
  }else if(value>=livePrice&&action=='Sell'){
    setIsError(true);
    setErrorMsg("Take profit field must be lesser than live price")
  }
  else if(stoplossplus<=livePrice&&action=='Sell'){
    setIsError(true);
    setErrorMsg("Stop Loss field must be greater than live price")
  }
  else if(stoplossplus>=livePrice&&action=='Buy'){
    setIsError(true);
    setErrorMsg("Stop Loss field must be lesser than live price")
  }
  else{
    setIsError(false);
    setErrorMsg('')
    
  }

}else{
  e.preventDefault();
}
  
}

 
  const handleBuy = () => {
    navigate('/buy');
  };

  const handleSell = () => {
    navigate('/sell');
  };

  const handleToggle = (isOn) => {   
    setIsToggleOn(isOn);
  };
  
  const handleVolumeChange = (value) => {
    setSelectedVolume(value);
   
  };
  const handleProfitChange = (value)=>{
    setStoplossplus(0);
    setProfitplus(0);
    setErrorMsg('')
    setIsError(false);
    setSelectedProfit(value);   
  }

    
  const getPrice=()=>{
    if(priceplus!=0){
      return parseFloat(priceplus);
    }else if(moverData?.price&&moverData?.price!=0){
      return parseFloat(moverData?.price)
    }else if(moverData?.bid_price&&moverData?.bid_price!=0){
      return parseFloat(moverData?.bid_price)
    }else if(moverData?.ltp&&moverData?.ltp!=0){
      return parseFloat(moverData?.ltp)
    }else{
      return 0;
    }
  }
  const handlePriceChange = (value)=>{
    setSelectedPrice(value);
    
  }
  const handleLossChange = (value)=>{
    setSelectedLoss(value);
    
  }
  // const getTotalPriceLive=(volume,price,lots)=>{
  //   if(volume=='lots'){
  //     return priceplus!=0?Number(priceplus*lots*100000).toFixed(3):Number(price*lots*100000).toFixed(3)
  //   }else{
  //     return priceplus!=0?Number(priceplus*lots).toFixed(3):Number(price*lots).toFixed(3);
  //   }
  // }
  const getButtonColor=(action)=>{
    
    if(action=='Buy'){
      if(isError){
        return '#536f53'
      }else{
        return 'green'
      }
    }else{
      if(isError){
return '#954b4b'
      }else{
        return 'red'
      }
    }
  }
  const getTotalPriceLive = (volume, price, lots) => {
    
    let totalPrice;
    let lotsize=moverData?.lotsize||1;
    if (volume === 'lots') {
      totalPrice = priceplus != 0 
        ? Number(priceplus * lots * lotsize) 
        : Number(price * lots * lotsize);
    } else {
      totalPrice = priceplus != 0 
        ? Number(priceplus * lots) 
        : Number(price * lots);
    }
  
    // Apply leverage
    const leveragedPrice = totalPrice;
   
    return leveragedPrice.toFixed(3);
  };

 
  
 const handleBuySell=()=>{
  let qty=0;
  let price=getPrice();
  if(selectedvolume=='lots'){
    qty=volumeplus* moverData.lotsize;
   
    // qty=volumeplus*100000;
  }else{
    qty=volumeplus
   
  }
  if(moverData?.exchange=="CRYPTO"){
     qty=volumeplus?formatNumber(Number(volumeplus)/Number(price)):0;
    
  }


 

  
  let data = isToggleOn ? {
    // "exchange": moverData?.exchange,
    // "symbol": moverData?.symbol,
    // "price": getPrice(),
    // "order_type": "market",
    // "type": action === 'Buy' ? 'buy' : 'sell',
    // "qty": qty,
    // "from_custom_device_id": "web",
    "exchange": moverData?.exchange, 
    "symbol": moverData?.symbol, 
    "price": getPrice(), 
    "order_type": orderType?orderType:"market", 
    "type": action === 'Buy' ? 'buy' : 'sell', 
    "qty":qty, 
    "from_custom_device_id": "web", 
    "take_profit": profitplus, 
    "stoploss": stoplossplus ,
    "profit_loss_in": selectedprofit
  } : {
    "exchange": moverData?.exchange,
    "symbol": moverData?.symbol,
    "price": getPrice(),
    "order_type": orderType?orderType:"market",
    "type": action === 'Buy' ? 'buy' : 'sell',
    "qty": qty,
    "from_custom_device_id": "web",
  };

  
  
  if(moverData.exchange ==="CRYPTO"){
    let config={
      url:`${process.env.REACT_APP_API_FRONTEND}client/order/pushCrypto`,
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data:data
    }
    axios.request(config).then(response=>{
      setPopupMessage(response?.data?.message);
      setShowPopup(true);
      localStorage.setItem('selectedcurrency','USD'); 
              localStorage.setItem('ordertabname','open');   
      // navigate('/accounts')
      if(response.data.status=="SUCCESS")
      setTimeout(() => {
        setShowPopup(false); // Hide popup if desired
        navigate('/accounts'); // Replace with your navigation logic
      }, 2000); 
      dispatch(fetchUserPosition(token))
    }).catch(err=>{
      setPopupMessage(err?.response?.data?.message||err?.message||"something went wrong")
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    })
  }else{
    let config={
      url:`${process.env.REACT_APP_API_FRONTEND}client/order/pushForex`,
      method:"POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data:data
    }
    axios.request(config).then(response=>{
      setPopupMessage(response?.data?.message); 
      localStorage.setItem('selectedcurrency','USD'); 
              localStorage.setItem('ordertabname','open');       
      setShowPopup(true);
      if(response.data.status=='SUCCESS'){
        setTimeout(() => {
          setShowPopup(false);
          navigate('/accounts'); 
        }, 2000);
      }
      dispatch(fetchUserPosition(token));
    }).catch(err=>{
      setPopupMessage(err?.response?.data?.message||err?.message||"something went wrong")
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false); 
      }, 2000);
    })
  }
  
 
 }

 

  return (
    <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <p className="drawerclose" onClick={onClose}> X </p>
        <div className="">
          {
            isToggleOn ? (
              <ul className="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#marketorder" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={()=>setOrderType('market')} > Market order </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#buylimit" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={()=>setOrderType('limit')} > {`${action} Limit`} </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#buystop" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={()=>setOrderType('stoploss')} >{` ${action} Stop `}</button>
                </li>
              </ul>
            ) :""
          }
          <div className="tab-content" id="myTabContent" style={{ marginTop: '0' }}>
            <div className="tab-pane fade show active" id="marketorder" role="tabpanel">
              <Marketorderset 
              action={action}
                isError={isError}
                setIsError={setIsError}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                isToggleOn={isToggleOn} 
                setIsToggleOn={setIsToggleOn}
                volumeplus={volumeplus}
                setVolumeplus={setVolumeplus}
                priceplus={priceplus}
                setPriceplus={setPriceplus}
                profitplus={profitplus}
                setProfitplus={setProfitplus}
                stoplossplus={stoplossplus}
                setStoplossplus={setStoplossplus}
                selectedvolume = {selectedvolume}
                selectedprofit = {selectedprofit}
                selectedloss = {selectedloss}
                setSelectedVolume = {setSelectedVolume}
                setSelectedProfit = {setSelectedProfit}
                selectedLoss = {setSelectedLoss}
                onDropdownVolumeChange={handleVolumeChange}
                onDropdownProfitChange={handleProfitChange} 
                onDropdownLossChange={handleLossChange} 
                moverData={moverData}
                livePrice={livePrice}
                handleChangeVolume={handleChangeVolume}
                handleChangeStopLoss={handleChangeStopLoss}
                handleChangeTakeProfit={handleChangeTakeProfit}
                getTotalPriceLive={getTotalPriceLive}
                getQuantity={getQuantity}
                // incrementVolume={incrementVolume}
                // decrementVolume={decrementVolume}
                // handleSelectChangeVolume={handleSelectChangeVolume}
                
              />
            </div>
            <div className="tab-pane fade" id="buylimit" role="tabpanel">
              <Buylimitset 
              action={action}
                isError={isError}
                setIsError={setIsError}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                isToggleOn={isToggleOn} 
                setIsToggleOn={setIsToggleOn}
                volumeplus={volumeplus}
                setVolumeplus={setVolumeplus}
                priceplus={priceplus}
                setPriceplus={setPriceplus}
                profitplus={profitplus}
                setProfitplus={setProfitplus}
                stoplossplus={stoplossplus}
                setStoplossplus={setStoplossplus}
                selectedvolume = {selectedvolume}
                selectedprofit = {selectedprofit}
                selectedloss = {selectedloss}
                setSelectedVolume = {setSelectedVolume}
                setSelectedProfit = {setSelectedProfit}
                selectedLoss = {setSelectedLoss}  
                onDropdownVolumeChange={handleVolumeChange}
                onDropdownPriceChange={handlePriceChange}
                onDropdownProfitChange={handleProfitChange} 
                onDropdownLossChange={handleLossChange} 
                livePrice={livePrice}          
                moverData={moverData}
                getTotalPriceLive={getTotalPriceLive}
                handleChangeVolume={handleChangeVolume}
                handleChangeStopLoss={handleChangeStopLoss}
                handleChangeTakeProfit={handleChangeTakeProfit} 
                getQuantity={getQuantity} 
              />
            </div>
            <div className="tab-pane fade" id="buystop" role="tabpanel">
            <BuystopSet 
                action={action}
                isError={isError}
                setIsError={setIsError}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                isToggleOn={isToggleOn} 
                setIsToggleOn={setIsToggleOn}
                volumeplus={volumeplus}
                setVolumeplus={setVolumeplus}
                priceplus={priceplus}
                setPriceplus={setPriceplus}
                profitplus={profitplus}
                setProfitplus={setProfitplus}
                stoplossplus={stoplossplus}
                setStoplossplus={setStoplossplus}
                selectedvolume = {selectedvolume}
                selectedprofit = {selectedprofit}
                selectedloss = {selectedloss}
                getTotalPriceLive={getTotalPriceLive}
                selectedVolume = {setSelectedVolume}
                selectedProfit = {setSelectedProfit}
                selectedLoss = {setSelectedLoss}  
                onDropdownVolumeChange={handleVolumeChange}
                onDropdownPriceChange={handlePriceChange}
                onDropdownProfitChange={handleProfitChange} 
                onDropdownLossChange={handleLossChange} 
                livePrice={livePrice}        
                moverData={moverData}
                handleChangeVolume={handleChangeVolume}
                handleChangeStopLoss={handleChangeStopLoss}
                handleChangeTakeProfit={handleChangeTakeProfit}
                getQuantity={getQuantity}    
              />
            </div>           
          </div>
          <div>
              <div className="forebottom">
                  <div className="togglediv">
                      <p>Order settings</p>
                      <ToggleSwitch onToggle={handleToggle} />
                  </div>
                  <div className="forebtndiv">
                      <div> <button  onClick={onClose} className="btncancel">Cancel</button> </div>
                      {moverData?.exchange=="CRYPTO"?<div> <button disabled={isError}  onClick={handleBuySell} className="btnconfirm" style={{ background:getButtonColor(action) }}>{`Confirm ${action} ${moverData?.symbol} in`}<br />{livePrice}</button> </div>:<div> <button disabled={isError}  onClick={handleBuySell} className="btnconfirm" style={{ background:getButtonColor(action) }}>{`Confirm ${action} ${volumeplus} ${selectedvolume=='usd'?'units':selectedvolume} in`}<br />{livePrice}</button> </div>}
                  </div>                  
              </div>
             
          </div>
       
        </div>
       
      </div>
      
    </div>
  );
};

export default Forexdetailsdrawer;
