import React, { useState } from 'react';
import closebtn from '../../Images/close-button.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const Resetpasswordmodal = ({ isModalVisible2,setModalVisible1, setModalVisible2,setModalVisible3, email, forgot_key,mobile }) => {    
    const [loadingstate,setLoadingState] = useState(false);

    const navigate = useNavigate();
    const validationSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[!@#$%^&*]/, 'Password must contain at least one special character')
            .required('Password is required'),
    });

    // Initialize Formik with form submission handling and validation
    // const formik = useFormik({
    //     initialValues: {
    //         password: "",
    //     },
    //     validationSchema,
    //     onSubmit: async (values, { resetForm }) => {
    //         const formData = {
    //             contact:mobile,
    //             email,
    //             forgot_key,
    //             password: values.password,  // Use Formik's password value
    //         };

    //         console.log("formDataformDataformDataformDataformDataformData",formData);

    //         try {
    //             setLoadingState(true);
    //             let response = await axios.post(`${baseUrl}resetPassword`, formData);
    //             if (response.data.status === "SUCCESS") {
    //                 setLoadingState(false);
    //                 Swal.fire({
    //                     width: "20rem",
    //                     title: 'Password reset success.',
    //                     timer: 2000,  // Auto close after 2 seconds
    //                     timerProgressBar: true,  // Show a progress bar for the timer
    //                     showConfirmButton: false, // Hide the OK button                        
    //                     customClass: {
    //                         title: 'swaltext'
    //                     } 
    //                 });
    //                 resetForm();  // Clear form fields after success
    //                 setModalVisible1(false);
    //                 setModalVisible3(false);  // Close the Reset Password modal
    //                 navigate('/signin');
                    
    //             } else {
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Oops...',
    //                     text: response.data.message,
    //                     timer: 6000,  
    //                     timerProgressBar: true,  
    //                     showConfirmButton: false,  
    //                     customClass: {
    //                         title: 'swaltext'
    //                     } 
    //                 });
    //             }
    //         } catch (error) {
    //             console.log(error);
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: 'Something went wrong, please try again later.',
    //             });
    //         }
    //     },
    // });

    const formik = useFormik({
        initialValues: {
            password: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = {
                contact: mobile,
                email,
                forgot_key,
                password: values.password,
            };
    
            try {
                setLoadingState(true);
                let response = await axios.post(`${baseUrl}resetPassword`, formData);
                if (response.data.status === "SUCCESS") {
                    setLoadingState(false);
                    Swal.fire({
                        width: "20rem",
                        title: 'Password reset success.',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            title: 'swaltext'
                        } 
                    });
    
                    resetForm();
                    // Close all modals and navigate to sign-in
                    setModalVisible1(false);
                    setModalVisible2(false);
                    setModalVisible3(false);
                    navigate('/signin');
    
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                        timer: 6000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            title: 'swaltext'
                        } 
                    });
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later.',
                });
            }
        },
    });
    

    if (!isModalVisible2) return null;

    return (
        <div className="modal-overlay" style={{ backgroundColor: "#151514a8" }}>
            <div className="modal" style={{ display: "block", background: "rgba(21, 21, 20, 0.66)" }}>
                <div className='cusmodal2'>
                    <button className="close-btn" onClick={() => setModalVisible2(false)}>
                        <img src={closebtn} alt="Close" width="10px" />
                    </button>
                    <div>
                        <h2 className='text-center mb-4'>Enter Password</h2>
                        <div>
                            <form className='w-100' onSubmit={formik.handleSubmit}>
                                <input
                                    type='password'
                                    name="password"
                                    // className='form-control h-40'
                                    className={`form-control h-40 ${formik.touched.password && formik.errors.password ? '' : 'mb-3'}`}
                                    placeholder='Enter password'
                                    value={formik.values.password}  // Formik password value
                                    onChange={formik.handleChange}  // Formik change handler
                                    onBlur={formik.handleBlur}      // Formik blur handler
                                />
                                {/* Display validation errors */}
                                {formik.touched.password && formik.errors.password ? (
                                    <p className="mb-3" style={{ color: "red" }}>{formik.errors.password}</p>
                                ) : null}

                                <button type='submit' className='form-control'>{loadingstate ? (<>Submiting <span className='spinner'></span></>):("Submit")}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resetpasswordmodal;
