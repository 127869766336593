import { useEffect, useRef, useState } from 'react';
import Forexdetailsdrawer from '../Bottomdrawer/Forexdetailsdrawer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const TradingViewChart = ({ tradesymbol,moverData,onPriceUpdate }) => {
  if(tradesymbol[0]=="^"){
    
    tradesymbol=tradesymbol.split("^")[1]
  }

  // console.log("moverDatamoverDatamoverDatamoverDatamoverData",moverData)
  
  
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const chartContainerRef = useRef(null);
  const [livePrices, setLivePrices] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
  const [action,setAction]=useState('');
  const {priceSocket}=useSelector((state)=>state.sockets); 
  useEffect(()=>{
    if(moverData&&priceSocket){
      const event = moverData?.exchange === "FOREX" || moverData?.exchange=== "CRYPTO" || moverData?.exchange=== "US" ? `forexRoom${moverData?.symbol.toLowerCase()}` : `room${moverData?.token}`;
        const emitData = moverData?.exchange === "FOREX" || moverData?.exchange=== "CRYPTO" || moverData?.exchange=== "US"  ? moverData?.symbol.toLowerCase() : moverData?.token; 
               
        priceSocket.emit('tokenData', emitData);        
        priceSocket.on(event, (updatedInstrument) => {
          let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;  
          setLivePrices((prevState) => ({
            ...prevState,
            [typeNmae]: updatedInstrument,
          }));
          // Extract latest price and pass to parent
          // const latestPrice = updatedInstrument.ltp || updatedInstrument.price || updatedInstrument.open_price;
          const latestPrice = updatedInstrument.percentage_change || 0;
          if (onPriceUpdate) {
              onPriceUpdate(latestPrice); // Send price to parent
          }
        });
    } 
    return ()=>{
      setLivePrices({})
    }
  },[moverData,tradesymbol,priceSocket,onPriceUpdate])

  useEffect(() => {
    const loadTradingViewChart = () => {
      if (window.TradingView) {
        new window.TradingView.widget({
          symbol: tradesymbol  || 'NASDAQ:AAPL',
          container_id: chartContainerRef.current.id,
          width: '100%',
          height: chartContainerRef.current.offsetHeight || 400,
          theme: 'light',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          height:"310px",
          enable_publishing: false,
          // hide_top_toolbar: true,
          // allow_symbol_change: true,
          interval: 'D',
        });
      } else {
        console.warn("TradingView is not available.");
      }
    };
    if (typeof window.TradingView !== 'undefined') {
      loadTradingViewChart();
    } else {
      const interval = setInterval(() => {
        if (typeof window.TradingView !== 'undefined') {
          clearInterval(interval);
          loadTradingViewChart();
        }
      }, 100); 
    }
    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.innerHTML = ''; 
      }
    };
  }, [tradesymbol]);

  const handleClick = (action) => {    
      // setSelectedMoverData();
      setAction(action)
      setIsDrawerOpen(true);   
    }
    function formatNumber(input) {
      try{
          const num = parseFloat(input);
          if (isNaN(num)) {
              throw new Error("Invalid number");
          }
          if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
              return parseFloat(num.toFixed(6));
          }
          return num;
      }catch(err){
          return 0;
      }
  }
  const getPrice=(action)=>{
    
    let typeName=moverData?.exchange ==='FOREX' || moverData?.exchange === "US" || moverData?.exchange === "CRYPTO" ? moverData?.symbol.toLowerCase() : moverData?.token;
    const liveData=livePrices[typeName];
    
   
    if(liveData?.bid_price&&liveData?.bid_price!=0&&action=='Sell'){
      return formatNumber(liveData?.bid_price);
    }else if(liveData?.ask_price&&liveData?.ask_price!=0&&action=='Buy'){
      return formatNumber(liveData?.ask_price)
    }else if(liveData?.ltp&&liveData?.ltp!=0){
      return formatNumber(liveData?.ltp)
    }
    else if(liveData?.price&&liveData?.price){
      return formatNumber(liveData?.price)
    }
    else if(liveData?.open_price&&liveData?.open_price){
      return formatNumber(liveData?.open_price)
    }else 
    if(moverData?.bid_price&&moverData?.bid_price!=0){
      return formatNumber(moverData?.bid_price);
    }else if(moverData?.ltp&&moverData?.ltp!=0){
      return formatNumber(moverData?.ltp)
    }
    else if(moverData?.price&&moverData?.price!=0){
      return formatNumber(moverData?.price)
    }
    else{
      return formatNumber(moverData?.open_price)
    }
  }

  

  return (
    <>
      <div ref={chartContainerRef} id="tradingview_chart" ></div>
      <div className='d-flex justify-content-center gap-2'>
        <div class="sell" style={{width:"100%"}}>
            <button style={{padding:"1px 10px", width:"100%",border:"none",borderRadius:"8px",background:"red",color:"#fff", marginTop:"5px"}} onClick={() => handleClick("Sell")}><p>Sell</p><p><b>{getPrice('Sell')}</b></p></button>
        </div>
        <div class="buy" style={{width:"100%"}}>
          <button style={{padding:"1px 10px", width:"100%",border:"none",borderRadius:"8px",background:"green",color:"#fff",marginTop:"5px"}} onClick={() => handleClick("Buy")}><p>Buy</p><p><b>{getPrice('Buy')}</b></p></button>
        </div>
      </div>
      {showPopup && (
                <div className='popup-overlay'>
                    <div style={{width:"300px"}} className='popup-content'>
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
      <Forexdetailsdrawer
            setPopupMessage={setPopupMessage}
            setShowPopup={setShowPopup}
            action={action}
            isDrawerOpen={isDrawerOpen} 
            moverData={moverData}
            livePrice={getPrice(action)} 
            onClose={() => setIsDrawerOpen(false)} 
      />
    </>
  )
  
};

export default TradingViewChart;
