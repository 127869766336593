import React, { useEffect, useState } from 'react';
import './Tradetab.css';
import sortingimg from '../../Images/sorting.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import gbpusd from '../../Images/gbpusd.svg';
// import usdjpy from '../../Images/usdjpy.svg';
// import ustech from '../../Images/ustech.svg';
// import eurusd from '../../Images/eurusd.svg';
import Favoriatecard from '../Favoriatecard/Favoriatecard';
import Popmodel from '../Popupmodal/Popmodel';
import Modal from 'react-bootstrap/Modal';
import search from '../../Images/search.png';
import Searchpopup from '../Popupmodal/Searchpopup';
import tradeData from '../../data.js';
import Currencybtn from './Currencybtn.jsx';
import Alltabbtn from './Alltabbtn.jsx';
import TopMovers from '../Favoriatecard/TopMovers.js';
import TopLoosers from '../Favoriatecard/TopLoosers.js';
import { useDispatch, useSelector } from 'react-redux'; 
import Allstocks from '../Favoriatecard/Allstocks.jsx';
import Metals from '../Favoriatecard/Metals.jsx';
import SortingDrawer from '../Bottomdrawer/SortingDrawer.jsx';
import Popular from '../Favoriatecard/popular.jsx';
import Indices from '../Favoriatecard/Indices.jsx';
import Energies from '../Favoriatecard/Energies.jsx';
import Minors from '../Favoriatecard/Minors.jsx';
import Exotic from '../Favoriatecard/Exotic.jsx';
import All from '../Favoriatecard/All.jsx';
import Majors from '../Favoriatecard/Majors.jsx';
import Crypto from '../Favoriatecard/Crypto.jsx';
import { fetchCurrenciesList } from '../../Redux/actions/tradeAction.js';
import { getUserToken } from '../../helper/getToken.js';
import { useLocation } from 'react-router-dom';
// import tradeType from '../../tradeType.js';

const Tradetab = ({ display, searchicon, hideSearchIcon,alert }) => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [showsearch, setShowsearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const { trade, loading, error } = useSelector((state) => state.trade);
  const token=getUserToken();
  const dispatch=useDispatch();

  const location = useLocation();

// const [activeTab, setActiveTab] = useState(() => location.state?.fromTab || 'nav-fav');
const activeTab = useSelector((state) => state.trade.activeTab);
useEffect(()=>{
  const fetchTopMoversData = async () => {
      if (token) {
        try {
          await dispatch(fetchCurrenciesList(token));
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        }
      }
    };

      fetchTopMoversData();
},[])
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleShowsearch() {
    setShowsearch(true);
  }

  return (
    <div className='tradetabdiv'>
      <nav>
        <div className="nav nav-tabs mb-3 cusnavtab" id="nav-tab" role="tablist">
          {!hideSearchIcon && (
            <div onClick={handleShowsearch} style={{ display: searchicon, cursor:"Pointer" }}>
              <img src={search} width="30" alt="Search Icon" />
            </div>
          )}
          <Alltabbtn activeTab = {activeTab}/>
        </div>
      </nav>

      <div className="tab-content p-3" id="nav-tabContent">
        <div className={`tab-pane fade ${activeTab === "nav-fav" ? "show active" : ""}`} id="nav-fav" role="tabpanel" aria-labelledby="nav-fav-tab">
          <div className="sortingdiv" style={{ display: display }}>
              {/* <div>
                <span><img src={sortingimg}/> Sorted manually</span>
              </div> */}
              <Currencybtn currencybtn={selectedCurrency} setSelectedCurrency={setSelectedCurrency}/>           
            <div>
              <FontAwesomeIcon icon={faEdit} className="headericon" onClick={() => handleShow()} />
            </div>
          </div>
          {/* <Favoriatecard data={tradeData} /> */}
          <Favoriatecard data={trade} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>           
        </div>

        {/* Additional tab content can be populated below */}
        <div className={`tab-pane fade ${activeTab === "nav-mov" ? "show active" : ""}`} id="nav-mov" role="tabpanel" aria-labelledby="nav-mov-tab">          
            <div className="sortingdiv" style={{ display: display }}>              
              <Currencybtn currencybtn={selectedCurrency} setSelectedCurrency={setSelectedCurrency}/>
          </div>
          {/* <Favoriatecard data={tradeData} /> */}
          <TopMovers data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/> 
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-looser" ? "show active" : ""}`} id="nav-looser" role="tabpanel" aria-labelledby="nav-major-tab">  
          <div className="sortingdiv" style={{ display: display }}>          
            <Currencybtn currencybtn={selectedCurrency} setSelectedCurrency={setSelectedCurrency}/>
          </div>
          <TopLoosers data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/> 
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-stocks" ? "show active" : ""}`} id="nav-stocks" role="tabpanel" aria-labelledby="nav-stocks-tab">
          <div className="sortingdiv" style={{ display: display }}>          
            <Currencybtn currencybtn={selectedCurrency} setSelectedCurrency={setSelectedCurrency} displaypropallstock="none"/><SortingDrawer /> 
          </div>
          <Allstocks data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-metal" ? "show active" : ""}`} id="nav-metal" role="tabpanel" aria-labelledby="nav-metal-tab">
          <Metals data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-pop" ? "show active" : ""}`} id="nav-pop" role="tabpanel" aria-labelledby="nav-pop-tab">
          <Popular data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-crypto" ? "show active" : ""}`} id="nav-crypto" role="tabpanel" aria-labelledby="nav-crypto-tab">
          <Crypto data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-indices" ? "show active" : ""}`} id="nav-indices" role="tabpanel" aria-labelledby="nav-indices-tab">
          <Indices data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-energies" ? "show active" : ""}`} id="nav-energies" role="tabpanel" aria-labelledby="nav-energies-tab">
           <Energies data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-majors" ? "show active" : ""}`} id="nav-majors" role="tabpanel" aria-labelledby="nav-majors-tab">
          <Majors data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-exotic" ? "show active" : ""}`} id="nav-exotic" role="tabpanel" aria-labelledby="nav-exotic-tab">
          <Exotic data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-minors" ? "show active" : ""}`} id="nav-minors" role="tabpanel" aria-labelledby="nav-minors-tab">
          <Minors data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
        <div className={`tab-pane fade ${activeTab === "nav-all" ? "show active" : ""}`} id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
          <All data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} alert={alert}/>
        </div>
      </div>

      {/* Modals for Edit and Search */}
      <Modal className="cusmodal" show={show} onHide={() => setShow(false)}>
        <Popmodel name="Favorite" show={show} setShow={setShow} data={tradeData} currency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} />
      </Modal>
      <Modal className="cusmodal" show={showsearch} onHide={() => setShowsearch(false)}>
        <Searchpopup show={showsearch} setShow={setShowsearch} />
      </Modal>
    </div>
  );
};

export default Tradetab;
