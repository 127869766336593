import React, { useEffect, useState } from 'react';
import aiimage from '../../Images/aiimage.svg';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserToken } from '../../helper/getToken';

const baseURL = process.env.REACT_APP_API_FRONTEND;

const Topnews = () => {
    const [topnewdata, setTopNewsData] = useState(null); // Changed to null for clarity
    const token = getUserToken();
    const navigate = useNavigate();

    const TopnewsHandler = () => {
        navigate('/topmorenews');
    };

    const getTopNews = async () => {
        try {
            const response = await axios.post(
                `${baseURL}client/news/getGeneralNews`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
           
            setTopNewsData(response.data);
        } catch (error) {
            console.log("Error fetching news:", error);
        }
    };

    useEffect(() => {
        getTopNews();
    }, []);

    

    // Determine the news array based on response structure
    const newsArray = Array.isArray(topnewdata) 
        ? topnewdata 
        : Array.isArray(topnewdata?.payload) 
            ? topnewdata.payload 
            : [];

    return (
        <div className='topnews' style={{ marginBottom: "100px" }}>
            <div className='topheader'>
                <h4>Top News</h4>
                <button onClick={TopnewsHandler}>Show more</button>
            </div>
            {newsArray.length > 0 ? (
                newsArray.slice(0, 10).map((news, index) => {
                    const formattedDate = new Date(news.publishedDate).toLocaleDateString('en-US');
                    return (
                        <Link to="" key={index}>
                            <div className='newslist'>
                                <div className='imgdiv'>
                                    <img src={news.image} alt={news.title || 'News Image'} />
                                </div>
                                <div className='textdiv'>
                                    <p>
                                        <b>
                                            {news.title?.length > 20
                                                ? `${news.title.substring(0, 20)}...`
                                                : news.title}
                                        </b>
                                    </p>
                                    <p>
                                        {news?.text?.length > 30
                                            ? `${news.text.substring(0, 30)}...`
                                            : news.text}
                                    </p>
                                    <small>{formattedDate}</small>
                                </div>
                            </div>
                        </Link>
                    );
                })
            ) : (
                <p>No news available</p>
            )}
        </div>
    );
};

export default Topnews;