import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png';
import { signUp } from '../Redux/actions/authActions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const Otpscreen = () => {
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [count, setCount] = useState(60);
    const [loadingState, setLoadingState] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [message,setMessage] = useState();

    const { valuesData = {}, response } = location.state || {};

    // Countdown Timer
    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
    }, []); // This useEffect is always called on mount and will run every second.

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return; // Allow only numeric values
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1); // Take only the last digit (if user pastes input)
        setOtp(newOtp);
        // Move to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            const nextSibling = document.getElementById(`otp-input-${index + 1}`);
            nextSibling?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            if (otp[index] === "" && index > 0) {
                // Move to the previous input field
                const prevSibling = document.getElementById(`otp-input-${index - 1}`);
                prevSibling?.focus();
            }
        }
    };

    const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

    // const encryptData = (data) => {
    //     return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    // };

     const encryptData = (data) => {
        if (!ENCRYPTION_KEY) {
            return null;
        }    
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), 
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString();
    };

    const otpData = encryptData(otp.join("")); // Combine OTP digits into one string
    valuesData.otp = otpData; // Add the encrypted OTP to the valuesData

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         setLoadingState(true);
    //         const response = await dispatch(signUp(valuesData));
    //         setMessage(response?.data?.message)
    //         if (response?.data?.status === "SUCCESS") {
    //             setLoadingState(false);
    //             Swal.fire({
    //                 width: "20rem",
    //                 title: `${response.data.message}`,
    //                 timer: 6000,
    //                 timerProgressBar: true,
    //                 showConfirmButton: false,
    //                 customClass: {
    //                     title: 'swaltext',
    //                 },
    //             });

    //             setTimeout(() => {
    //                 navigate('/signin');
    //             }, 2000);
    //         } else {
    //             setLoadingState(false);
    //             setOtp(["", "", "", ""]); // Reset OTP input fields
    //             Swal.fire({
    //                 width: "20rem",
    //                 title: `${response?.data?.message || 'Something went wrong'}`,
    //                 timer: 6000,
    //                 timerProgressBar: true,
    //                 showConfirmButton: false,
    //                 customClass: {
    //                     title: 'swaltext',
    //                 },
    //             });
    //             // navigate('/otpscreen');
    //             navigate('/enteremail');
                
    //         }
    //     } catch (error) {
    //         setLoadingState(false);
    //         console.error("Error during signup:", error);
    //         alert('Signup failed. Please try again.');
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         setLoadingState(true);
    //         const response = await dispatch(signUp(valuesData, navigate));  // Pass navigate here

    //         console.log("responseresponseresponseresponseresponseresponse",response);
            
    //         // If the response is a string or contains unwanted characters, clean it
    //         // const jsonString = response.match(/\{.*\}/)?.[0]; 
    //         // let cleanedResponse = jsonString ? JSON.parse(jsonString) : response;
    
    //         // console.log("Parsed Response: ", cleanedResponse);
    
    //         setMessage(response?.message);
    
    //         if (response?.status === "SUCCESS") {
    //             setLoadingState(false);
    //             Swal.fire({
    //                 width: "20rem",
    //                 title: `${response.message}`,
    //                 timer: 6000,
    //                 timerProgressBar: true,
    //                 showConfirmButton: false,
    //                 customClass: {
    //                     title: 'swaltext',
    //                 },
    //             });
    
    //             // Redirect to /signin if signup is successful
    //             // setTimeout(() => {
    //             //     navigate('/account');
    //             // }, 2000);
    //         } else {
    //             setLoadingState(false);
    //             setOtp(["", "", "", ""]); // Reset OTP input fields
    //             Swal.fire({
    //                 width: "20rem",
    //                 title: `${response?.message || 'Something went wrong'}`,
    //                 timer: 6000,
    //                 timerProgressBar: true,
    //                 showConfirmButton: false,
    //                 customClass: {
    //                     title: 'swaltext',
    //                 },
    //             });
    //             navigate('/enteremail'); // Navigate to email entry page on failure
    //         }
    //     } catch (error) {
    //         setLoadingState(false);
    //         console.error("Error during signup:", error);
    //         Swal.fire({
    //             width: "20rem",
    //             title: "Signup failed. Please try again.",
    //             timer: 6000,
    //             timerProgressBar: true,
    //             showConfirmButton: false,
    //             customClass: {
    //                 title: 'swaltext',
    //             },
    //         });
    //     }
    // };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoadingState(true);
            const response = await dispatch(signUp(valuesData, navigate));  
            setMessage(response?.message);
    
            if (response?.status === "SUCCESS") {
                setLoadingState(false);
                Swal.fire({
                    width: "20rem",
                    title: `${response.message}`,
                    timer: 6000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    customClass: {
                        title: 'swaltext',
                    },
                });
                // ✅ No need for manual navigation, it happens in the action
            } else {
                setLoadingState(false);
                setOtp(["", "", "", ""]); 
                Swal.fire({
                    width: "20rem",
                    title: `${response?.message || 'Something went wrong'}`,
                    timer: 6000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    customClass: {
                        title: 'swaltext',
                    },
                });
                navigate('/enteremail'); 
            }
        } catch (error) {
            setLoadingState(false);
            console.error("Error during signup:", error);
            Swal.fire({
                width: "20rem",
                title: "Signup failed. Please try again.",
                timer: 6000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    title: 'swaltext',
                },
            });
        }
    };
    

    return (
        <div className="signinmain">
            <div className="header">
                <Link to="/enteremail">
                    <img src={BackArrow} alt="Back" />
                </Link>
                <h2>Enter OTP</h2>
            </div>
            <div className="mt-5 mb-5">
                <p>Please check your mobile / email for OTP.</p>
                <small>OTP is only valid for {count} seconds</small>
            </div>
            <div className="otpdiv">
                <form onSubmit={handleSubmit}>
                    <div className="otpdivinner">
                        {otp.map((value, index) => (
                            <div key={index}>
                                <input
                                    id={`otp-input-${index}`}
                                    className="otp-input"
                                    type="text"
                                    name="otp"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onFocus={(e) => e.target.select()} // Select text when input gains focus
                                />
                            </div>
                        ))}
                    </div>
                    <small style={{ color: "green" }}>{response ? response:message}</small>
                    <button type="submit" className="submit-btn" disabled={loadingState}>
                        {loadingState ? (
                            <>
                                Submitting <span className="spinner"></span>
                            </>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Otpscreen;
