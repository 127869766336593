import React from 'react'
import './Profile.css'
import swapfree from '../../Images/swapfree.svg'
import negative from '../../Images/negative.svg'
import virtual from '../../Images/virtual.svg'
import { useNavigate } from 'react-router-dom'

const Benefits = () => {
  const navigate = useNavigate();
  const negativeBalanceHandler=()=>{
   navigate('/negativebalance')
  }
  const swapfreeHandler =()=>{
    navigate('/swapefree')
  }
  const privateserverHandler=()=>{
    navigate('/privateserver')
  }
  return (
    // <div className='profilediv'>
    //         <h4 className='profileheading'>Benefits</h4>
    //         <div className="card">
    //             <div className="card-body">
    //                 <div className="btndiv">
    //                     <button onClick={swapfreeHandler}><img src={swapfree}/>Swap-free</button>
    //                     <button onClick={negativeBalanceHandler}><img  src={negative}/>Negative Balance Protection</button>
    //                     <button onClick={privateserverHandler} ><img src={virtual}/>Virtual Private Server</button>
    //                 </div>
    //             </div>
    //         </div>
    // </div>
    <></>
  )
}

export default Benefits