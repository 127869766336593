import React, { useEffect, useState, useCallback } from 'react';
import NewHeader from '../Header/NewHeader';
import './Showmoreevents.scss';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { getUserToken } from '../../helper/getToken';

const baseUrl = process.env.REACT_APP_API_FRONTEND;
const flagBaseUrl = process.env.REACT_APP_FLAG_WEBSITE_API;

function Showmoreevents() {
    const [allevents, setAllEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const todaydate = new Date().toISOString().split("T")[0];
    const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().split("T")[0];
    const token = getUserToken();

    // IntersectionObserver ref to detect when to load more
    const { ref, inView } = useInView({
        threshold: 0,
    });

    const getAllEvents = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${baseUrl}client/news/getUpcomingEvent?from=${todaydate}&to=${yesterdayDate}&page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            console.log("API Response for page", page, ":", response.data); // Log to debug

            // Ensure response.data is iterable
            const newEvents = Array.isArray(response.data)
                ? response.data
                : Array.isArray(response.data?.payload)
                    ? response.data.payload
                    : [];
            
            setAllEvents((prevEvents) => [...prevEvents, ...newEvents]);
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoading(false);
        }
    }, [page, todaydate, yesterdayDate, token]); // Added all dependencies

    useEffect(() => {
        getAllEvents();
    }, [getAllEvents]);

    // When inView changes to true, fetch the next page
    useEffect(() => {
        if (inView && !loading) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [inView, loading]);

    return (
        <div className="accountsmain">
            <NewHeader title="Economic Calendar" addicon="none" />
            <div className='mt-5'></div>
            {allevents.length > 0 ? (
                allevents.map((eventsdata, index) => (
                    <div className="past-data" key={index}>
                        <div className="past-data-upper">
                            <img
                                src={`${flagBaseUrl}/${eventsdata?.country?.toLowerCase()}.png`}
                                alt={`${eventsdata?.country || 'Country'} flag`}
                                width="20px"
                                onError={(e) => (e.target.src = `${flagBaseUrl}/un.png`)} // Fallback flag
                            />
                            <div className="past-data-upper-heading">
                                <span>{eventsdata?.event || 'No Event Name'}</span>
                            </div>
                        </div>
                        <div className="past-data-lower">
                            <div className="past-data-lower-left">
                                <p>{eventsdata?.country || 'N/A'}</p>
                            </div>
                            <div className="past-data-lower-middle">
                                <div className="past-data-lower-middle-1"></div>
                                <div className="past-data-lower-middle-1"></div>
                                <div className="past-data-lower-middle-1"></div>
                            </div>
                            <div className="past-data-lower-right">
                                <p>{eventsdata?.date?.slice(0, 16) || 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                !loading && <p>No events available</p>
            )}
            {loading && (
                <div
                    style={{
                        height: "80px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    className="accountmiddleloader"
                >
                    <span className='spinner'></span>
                </div>
            )}
            <div ref={ref} style={{ height: '1px' }} />
        </div>
    );
}

export default Showmoreevents;