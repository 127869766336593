import React, { useState } from 'react';
import './Changeemail.scss';
import { useNavigate } from 'react-router-dom';
import close from '../../Images/close.png';
import Modal from 'react-bootstrap/Modal';
import NewHeader from '../Header/NewHeader';
import Mobileverification from '../2-stepverification/Mobileverification';
import Aouthenticationpp from '../2-stepverification/Aouthenticationpp';

function Changeemail() {
  const navigate = useNavigate(); 
  const [selectedOption, setSelectedOption] = useState('');
  const [show, setShow] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Modal.Header className='cusheader'>
        <div className='left'>
          <button onClick={() => setShow(false)}>
            <img src={close} alt="close" />
          </button>
        </div>
      </Modal.Header>

      <div className='step-2-change-email-bg'>
        <NewHeader title='Back' />
        <Modal.Body className='accountsmain'>
          <div className='step-2-change-email'>
            <h1>Change 2-step verification</h1>
            <div className='step-2-change-email-verification'>
              <label className="radio-container">
                <input type='radio' name='verification' value='Email' onChange={handleOptionChange} />
                <span>Email</span>
              </label>
              <label className="radio-container">
                <input type='radio' name='verification' value='New phone Number' onChange={handleOptionChange} />
                <span>New phone Number</span>
              </label>
              <label className="radio-container">
                <input type='radio' name='verification' value='Authentication app' onChange={handleOptionChange} />
                <span>Authentication app</span>
              </label>
              <label className="radio-container">
                <input type='radio' name='verification' value='Push notification' onChange={handleOptionChange} />
                <div className='radio-container-recommanded'>
                  <span>Push notification</span>
                  <span className='radio-container-recommanded-right'>Recommended</span>
                </div>
              </label>
            </div>
          </div>

          <button 
            onClick={handleShow}
            className={`step-2-change-email-button ${selectedOption !== 'Email' && selectedOption !== '' ? 'enabled' : ''}`} 
            disabled={selectedOption === 'Email' || selectedOption === ''}
          >
            Next
          </button>
        </Modal.Body>

        {/* Conditionally render the respective component based on the selected option */}
        <Modal className='cusmodal' show={show} onHide={() => setShow(false)}>
          {selectedOption === 'New phone Number' && <Mobileverification show={show} setShow={setShow} />}
          {selectedOption === 'Authentication app' && <Aouthenticationpp show={show} setShow={setShow} />}
          {/* You can add more conditions for other options if needed */}
        </Modal>
      </div>
    </>
  );
}

export default Changeemail;
