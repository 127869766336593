import React from 'react'
import { Link } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png'

const ChoosePassword = () => {
  return (
    <div className='signinmain'>
            <div className='header'>
              
                <Link to="/residence"><img src={BackArrow}/></Link>
                <h2>Choose a password</h2>
            </div>
            <div className='signinform'>
              <div className='emailinput'>
                <label>Password</label>
                <input type='text'/>
                <ul>
                    <li>Use from 8 to 15 characters</li>
                    <li>Use both uppercase and lowercase letters</li>
                    <li>Use a combination of numbers and English letters and supported special characters</li>
                </ul>
              </div>
            </div>
            <div className='signregisterbtns'>
                {/* <div className='button1'><Link to="/signin">Register</Link></div>  */}
                <div className='button1'><Link to="/signin">Continue</Link></div> 
                {/* <p>I forgot my password</p> */}
            </div>
        </div>
  )
}

export default ChoosePassword