// reducers/authReducer.js
const initialState = {
    loading: false,
    order: null,
    error: null,
  };
  
  export const orderReducer = (state = initialState, action) => {
    switch (action.type) {       
      case 'FETCH_ORDER_REQUEST':    
        return {
          ...state,
          loading: true,
          error: null,
        }; 
        case 'FETCH_ORDER_SUCCESS': 
        return {
          ...state,
          loading: false,
          order: action.payload,
          error: null,
        };
        case 'FETCH_ORDER_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };      
      default:
        return state;
    }
  };
  