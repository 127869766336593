import React, { useEffect } from 'react'
import './Profile.css'

const Userdetails = ({userdata}) => {
    let data = userdata;
   
  return (
    <div className='profilediv'>
        <h4 className='profileheading'>Details</h4>
        <div className="card">
            <div className="card-body profiledetails">
                <ul>
                    <li><p><i class="fa fa-user"></i> User name :-</p><p>{data?.payload?.firstname} {data?.payload?.lastname} </p></li>
                    <li><p><i class="fa fa-id-card"></i> Login Id :-</p><p>{data?.payload?.username}</p></li>
                    <li><p><i class="fa fa-envelope"></i> Email :-</p><p>{data?.payload?.email}</p></li>
                    <li><p><i class="fa fa-phone"></i> Mobile :-</p><p>{data?.payload?.contact}</p></li>
                    <li><p><i class="fa fa-circle" style={{color:data?.payload?.status ==="active"? "green" :"red"}}></i> Status :-</p><p style={{color:data?.payload?.status ==="active"? "green" :"red",textTransform:"uppercase"}}>{data?.payload?.status}</p></li>
                    <li><p>Balance INR:-</p><p style={{color:"green",fontWeight:"600"}}><span>&#8377;</span> {data?.payload?.client_account.balance}</p></li>
                    <li><p>Balance USD:-</p><p style={{color:"green",fontWeight:"600"}}><span>&#36;</span> {data?.payload?.client_account.balance_usd}</p></li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Userdetails