import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStocksList } from '../../Redux/actions/tradeAction.js';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Stockdetails from '../Bottomdrawer/Stockdetails.js';
import { getUserToken } from '../../helper/getToken.js';
import { useNavigate } from 'react-router-dom';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';


const Allstocks = ({ currency,alert }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({}); // Store live prices

  const token = getUserToken();
  const dispatch = useDispatch();
  const { allStocks, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets);
  const navigate = useNavigate();

   useEffect(() => {
    if (token) {
      dispatch(fetchAllStocksList(token));
      dispatch(connectPriceSocket());
    }
  }, [dispatch, token]);

  const [issetalert,setIssstAlert] = useState(false);

  useEffect(() => {
    if (allStocks?.payload && priceSocket) {
      allStocks.payload[currency]?.forEach((instrument) => {
        const event =
          ['FOREX', 'CRYPTO', 'US'].includes(instrument.exchange)
            ? `forexRoom${instrument.symbol.toLowerCase()}`
            : `room${instrument.token}`;
        const emitData =
          ['FOREX', 'CRYPTO', 'US'].includes(instrument.exchange)
            ? instrument.symbol.toLowerCase()
            : instrument.token;

        priceSocket.emit('tokenData', emitData);
        priceSocket.on(event, (updatedInstrument) => {
          const typeName =
            ['FOREX', 'CRYPTO', 'US'].includes(updatedInstrument.exchange)
              ? updatedInstrument.symbol.toLowerCase()
              : updatedInstrument.token;

          setLivePrices((prevState) => ({
            ...prevState,
            [typeName]: updatedInstrument,
          }));
        });
      });
    }

    return () => {
      if (allStocks?.payload && priceSocket) {
        allStocks.payload[currency]?.forEach((instrument) => {
          const event =
            ['FOREX', 'CRYPTO', 'US'].includes(instrument.exchange)
              ? `forexRoom${instrument.symbol.toLowerCase()}`
              : `room${instrument.token}`;
          priceSocket.off(event);
        });
      }
    };
  }, [allStocks, priceSocket, currency]);

  const handleClick = (moverData) => {
    if(!alert){
      if (['FOREX', 'US', 'CRYPTO'].includes(moverData.exchange)) {
        navigate('/stockdetails', {
          state: {
            idtoken: moverData.symbol,
            exchange: moverData.exchange,
          },
        });
      } else if (['MCX', 'NFO'].includes(moverData.exchange)) {
        setSelectedMoverData(moverData);
        setIsDrawerOpen(true);
      } else {
        console.error('Exchange value is undefined or invalid.');
      }
    }else{
      setSelectedMoverData(moverData);
      setIssstAlert(true);      
    }
  };

  const renderTopLooser = (allStockList) =>
    allStockList.map((allstock, index) => {
      const typeName =
        ['FOREX', 'US', 'CRYPTO'].includes(allstock.exchange)
          ? allstock.symbol.toLowerCase()
          : allstock.token;
      const liveData = livePrices[typeName];
      const price = liveData?.ltp || liveData?.bid_price || allstock.ltp || 0;
      const formattedPrice = !isNaN(price) ? Number(price).toFixed(2) : '0.00';
      const percentChange =
        liveData?.percentage_change ?? allstock?.percentage_change ?? 0;

      return (
        <div className="card mb-2" key={index} onClick={() => handleClick(allstock)} >
          <div className="card-body" style={{cursor:"pointer"}}>
            <div className="topdiv d-flex justify-content-between">
              <div className="text-start"> <b>{allstock.symbol}</b> </div>
              <div className="text-end"><p style={{ color: percentChange > 0? "green" : 'red' }}> <b>$ {formattedPrice}</b> </p> </div>
            </div>
            <div className="bottomdiv">
              <div className="text-start"> <p>{allstock.expiry || new Date().toLocaleDateString('en-GB')}</p> </div>
              <div className="text-end">
                <span style={{ color:percentChange > 0 ? "green" : 'red' }}> <i className="fa fa-arrow-down" aria-hidden="true"></i> {`${percentChange}%`} </span>
              </div>
            </div>
          </div>
        </div>
      );
    });

  const renderCurrencyTrades = (currency) => {
    const trades = allStocks?.payload?.[currency] || [];
    return trades.length > 0 ? renderTopLooser(trades) : <p>No symbols available</p>;
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}
      {renderCurrencyTrades(currency)}
     
      {selectedMoverData && (
        <Stockdetails
          isDrawerOpen={isDrawerOpen}
          moverData={selectedMoverData}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}

        <AlertsetDrawer
            issetalert={issetalert}
            moverData={selectedMoverData}
            onClose={() => setIssstAlert(false)}
          />
    </div>
  );
};

export default Allstocks;
