import React, { useState } from 'react';
import axios from 'axios';
import closebtn from '../../Images/close-button.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const Forgotmodal = ({ isModalVisible, setModalVisible, setModalVisible3, setEmail,setMobile }) => {
    const [message, setMessage] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Formik validation schema
    const validationSchema = Yup.object({
        email: Yup.string()
            .email("Please enter a valid email address")
            .required("Email is required"),
        mobile: Yup.string()
            .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
            .required("Mobile number is required"),
    });

    // Initialize Formik with validation and form submission handling
    const formik = useFormik({
        initialValues: {
            email: "",
            mobile: ""
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = { email: values.email, contact: values.mobile };

            try {
                setLoadingState(true);
                setMessage("");
                setErrorMessage("");

                const response = await axios.post(`${baseUrl}forgotPassword`, formData);
                setLoadingState(false);

                if (response.data.status === "SUCCESS") {
                    setMessage(response.data.message);
                    setEmail(values.email);
                    setMobile(values.mobile);
                    resetForm();
                    setModalVisible(false);
                    // setModalVisible1(true);
                    setModalVisible3(true); 
                    navigate('/signin');
                } else {
                    setErrorMessage(response.data.message || "Something went wrong. Please try again.");
                }
            } catch (error) {
                setLoadingState(false);
                setErrorMessage("Failed to submit. Please check your details and try again.");
            }
        },
    });

    if (!isModalVisible) return null;

    return (
        <div className="modal-overlay">
            <div className="modal" style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#151514a8" }}>
                <div className='cusmodal2'>
                    <button className="close-btn" onClick={() => setModalVisible(false)}>
                        <img src={closebtn} alt="Close" width="10px" />
                    </button>
                    <div>
                        <h3 className='text-center mb-4'>Enter Mobile / Email Id</h3>
                        <form className='w-100' onSubmit={formik.handleSubmit}>
                            
                            {/* Mobile Number Input */}
                            <input
                                type='text'
                                name="mobile"
                                className={`form-control h-40 ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : 'mb-3'}`}
                                placeholder='Enter mobile number'
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ fontSize: "13px" }}
                            />
                            {formik.touched.mobile && formik.errors.mobile && (
                                <div className="invalid-feedback">{formik.errors.mobile}</div>
                            )}

                            <p className='text-center'>- OR -</p>

                            {/* Email Input */}
                            <input
                                type='email'
                                name="email"
                                className={`form-control h-40 ${formik.touched.email && formik.errors.email ? 'is-invalid' : 'mb-3'}`}
                                placeholder='Enter email id'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ fontSize: "13px" }}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className="invalid-feedback">{formik.errors.email}</div>
                            )}

                            {/* Display Messages */}
                            {message && <p style={{ color: "green" }}>{message}</p>}
                            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

                            {/* Submit Button */}
                            <button type='submit' className='form-control' disabled={loadingState}>
                                {loadingState ? "Submitting..." : "Submit"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forgotmodal;
