// import axios from 'axios';
import { 
    FETCH_ORDER_REQUEST, FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE
} from '../authCostants';
import {fetchOrderdata} from "../../helper/api_helper"


// Action to fetch order data with token
export const fetchOrder = (token) => async (dispatch) => {    
    dispatch({ type: FETCH_ORDER_REQUEST });
    try {
      const response = await fetchOrderdata(token);  // Pass token here      
      dispatch({ type: FETCH_ORDER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: FETCH_ORDER_FAILURE,
        payload: error.response?.data.message || error.message,
      });
    }
};