import React from 'react'
import Header from '../Components/Header/Header'
import Performance from '../Components/Performance/Performance'
import Footer from '../Components/Footer/Footer'

const Performancepage = () => {
  return (
    <div className='accountsmain'>
        <Header props="Performance" icon="none" settitng="none"  />
        <div className='topmovermain2'>
          <Performance/>
        </div>
        <Footer/>
    </div>
  )
}

export default Performancepage