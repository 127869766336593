import React from 'react'
import deleteimg from '../../Images/delete.png'
import rightimage from "../../Images/right.png"

const Deletecomp = () => {
  return (
    <div className='settingdiv mt-5'>
        <div className="card">
          <div className="card-body py-2">
              <div className="btndiv">
                <button>
                    <div><p><i class="fa fa-trash" aria-hidden="true" style={{color: "red", fontSize: "1.3em",marginRight:"10px"}}></i> Delete account</p> </div>
                    <div> <span><img src={rightimage} width="25px" /></span> </div>
                </button>
              </div>
          </div>
        </div>
    </div>
  )
}

export default Deletecomp