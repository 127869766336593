import React, { useState } from "react";
import './Bottomdrawer.css';
const ToggleSwitch = ({ onToggle }) => {
    const [isOn, setIsOn] = useState(false);

    const handleToggle = () => {
      const newIsOn = !isOn;
      setIsOn(newIsOn);
      if (onToggle) {
        onToggle(newIsOn);
      }
  
     
    };
  
    return (
      <div className="toggle-switch">        
        <div className={`switch ${isOn ? "on" : "off"}`} onClick={handleToggle} role="button" aria-checked={isOn} >
          <div className="toggle-thumb" />
        </div>
      </div>
    );
  };
export default ToggleSwitch;
