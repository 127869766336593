import React, { useEffect, useState } from 'react'
import './Performance.css'
import calendar from '../../Images/calendar.png'
import danger from "../../Images/danger.png"
import Bottomdrawer from '../Bottomdrawer/Bottomdrawer'
import warning from '../../Images/warning.png'
import Protectiondrawer from '../Bottomdrawer/Protectiondrawer'
import { useDispatch, useSelector } from 'react-redux'
import { getUserToken } from '../../helper/getToken'
import TradeListOpenorder from '../Accounttabs/TradeListOpenorder'
import { fetchUser, fetchUserPosition } from '../../Redux/actions/userAction'
import { useNavigate } from 'react-router-dom'
import { connectPriceSocket } from '../../Redux/actions/socketActions'
import { fetchIndexfundList } from '../../Redux/actions/tradeAction'

const Performance = () => {
    const [protecction,setProtection] = useState(' ')
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isprotectionDrawerOpen, setIsProtectionDrawerOpen] = useState(false);
    const [changeDrawername,setchangeDrawername] = useState();
    const [currency,setCurrency]=useState('INR');
    const [tabName1,setTabName1]=useState('summary');
    useEffect(()=>{
      const perTabName=localStorage.getItem("perftabname")
      if(perTabName){
        setTabName1(perTabName)
      }
    },[])
    const handleChangeTabName1=(tabName)=>{
      localStorage.setItem('perftabname',tabName)
      setTabName1(tabName);
    }
    // const [changeNumberOfDays,setchangeNumberOfDays] = " Last 7 days";
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
        setchangeDrawername("All Acounts Details")
    };
    const toggleDrawer1 = () => {
        setIsDrawerOpen(!isDrawerOpen);
        setchangeDrawername("Past Days")

    };

    const toggleProtectionDrawer = () => {
        setIsProtectionDrawerOpen(!isprotectionDrawerOpen);
    };
    const dispatch = useDispatch();
    const token = getUserToken();
    const { user,position,loading,error } = useSelector((state) => state.user);  
    const { priceSocket } = useSelector((state) => state.sockets);



    useEffect(() => {
        const fetchData = async () => {
            if (token) {
                try {
                    await Promise.all([                        
                        dispatch(fetchUserPosition(token)),
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                }
            }
        };
    
        fetchData();
    }, [dispatch, token]);

    
      const navigate = useNavigate(); 
     
    
      const [livePrices, setLivePrices] = useState({});
      const [userAccount, setUserAccount] = useState(null);
      const [dynamicBalIN, setDynamicBalIN] = useState(0);
      const [dynamicBalUS, setDynamicBalUS] = useState(0);
    
      // Fetch user data if not available
      useEffect(() => {
        if (!user || !user.payload) {
          dispatch(fetchUser(token));
        }
      }, [dispatch, user, token]);
    
      // Set user account data
      useEffect(() => {
        if (user?.payload?.client_account) {
          setUserAccount(user.payload.client_account);
        }
      }, [user]);
    
      // Fetch fund list and connect to WebSocket
      useEffect(() => {       
        dispatch(connectPriceSocket());
      }, [dispatch, token]);
    
      
    
      // Subscribe to WebSocket updates
      useEffect(() => {
        if (!priceSocket || typeof position?.payload?.positions !== 'object') return;
      
        const subscribeToSocket = (item) => {
          const { token, trading_symbol, exchange, symbol } = item;
          const event =
            exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
              ? `forexRoom${symbol?.toLowerCase()}`
              : `room${token}`;
          const emitData =
            exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
              ? symbol?.toLowerCase()
              : token;
      
          // console.warn("Subscribing to:", event);
      
          priceSocket.emit('tokenData', emitData);
          priceSocket.on(event, (updatedInstrument) => {
           
            setLivePrices((prevState) => ({
              ...prevState,
              [trading_symbol]: updatedInstrument,
            }));
          });
        };
      
        // Flatten positions and subscribe to sockets
        Object.values(position.payload.positions).flat().forEach(subscribeToSocket);
      
        return () => {
          // Cleanup listeners
          Object.values(position.payload.positions).flat().forEach((item) => {
            const { token, exchange, symbol } = item;
            const event =
              exchange === 'FOREX' || exchange === 'CRYPTO' || exchange === 'US'
                ? `forexRoom${symbol?.toLowerCase()}`
                : `room${token}`;
            // console.warn("Unsubscribing from:", event);
            priceSocket.off(event);
          });
        };
      }, [priceSocket, position?.payload?.positions]);
      
      
    
      // Calculate dynamic balances
      useEffect(() => {
        const calculateBalances = () => {
          const currentPositionsIN = position?.payload?.positions?.['INR'] || [];
          const currentPositionsUS = position?.payload?.positions?.['USD'] || [];
          const dynamicPositionIN = calculateTotalProfitLoss(currentPositionsIN);
          const dynamicBalIndia = (
            Number(userAccount?.balance) + Number(dynamicPositionIN)
          ).toFixed(2);
          const dynamicPositionUS = calculateTotalProfitLoss(currentPositionsUS);
          const dynamicBalUSA = (
            Number(userAccount?.balance_usd) + Number(dynamicPositionUS)
          ).toFixed(2);
    
          setDynamicBalIN(dynamicBalIndia);
          setDynamicBalUS(dynamicBalUSA);
        };
    
        calculateBalances();
      }, [userAccount, priceSocket, livePrices, position]);
    
      const calculateTotalProfitLoss = (positions) =>
        positions.reduce(
          (total, item) => total + Number(calculateProfitLoss(item)),
          0
        ).toFixed(2);
    
      const calculateProfitLoss = (item) => {
        const livePrice =
          livePrices[item.trading_symbol]?.ltp ||
          livePrices[item.trading_symbol]?.bid_price ||
          item.cmp;
    
        const factor = item?.type === 'sell' ? -1 : 1;
        const quantity = parseFloat(item.qty);
        const avgPrice = parseFloat(item.avg_price);
        const currentPrice = parseFloat(livePrice);
    
        return ((currentPrice - avgPrice) * quantity * factor).toFixed(2);
      };

    

  return (
    <div className='performancediv mt-2'>
        <nav>
			<div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
				<button onClick={()=>handleChangeTabName1("summary")} class={tabName1=='summary'?"nav-link active":"nav-link"} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-summary" type="button" role="tab" aria-controls="nav-home" aria-selected={tabName1=='summary'?"true":"false"}>Summary</button>
				<button onClick={()=>handleChangeTabName1("benefit")} class={tabName1=='benefit'?"nav-link active":"nav-link"} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-benefits" type="button" role="tab" aria-controls="nav-profile" aria-selected={tabName1=='benefit'?"true":"false"}>{process.env.REACT_APP_COMPANY_NAME} benefits</button>				
			</div>
		</nav>
		<div class="tab-content " id="nav-tabContent">
			<div class={tabName1=='summary'?"tab-pane fade active show text-start":"tab-pane fade"} id="nav-summary" role="tabpanel" aria-labelledby="nav-home-tab">
               
                <div className="preformanceladger p-0 mb-2">
                    <div className="d-grid" style={{gridTemplateColumns:"1fr 1fr 1fr",textAlign:"center"}}>
                        <div className="card">
                            <div className="card-body p-1">
                                <h4>{currency=='INR'?'₹' : '$'}{currency=="INR"?dynamicBalIN:dynamicBalUS}</h4>
                                <p>Ledger</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-1">
                                {/* <h4>{currency=='INR'?'₹' : '$'}{currency=="INR"?Number(Number(position?.payload?.balance) - Number(position?.payload?.cnc_margin_req)).toFixed(2):Number(Number(position?.payload?.balance_usd)-Number(position?.payload?.cnc_margin_req_usd)).toFixed(2)}</h4> */}
                                {/* <h4>{currency=='INR'?'₹' : '$'}{currency=="INR"?Number(Number(position?.payload?.balance) - Number(position?.payload?.used_balance)).toFixed(2):Number(Number(position?.payload?.balance_usd) - Number(position?.payload?.used_balance_usd)).toFixed(2)}</h4> */}
                                <h4>{currency=='INR'?'₹' : '$'}{currency === "INR"
                                    ? (Number(position?.payload?.balance ?? 0) - Number(position?.payload?.used_balance ?? 0)).toFixed(2)
                                    : (Number(position?.payload?.balance_usd ?? 0) - Number(position?.payload?.used_balance_usd ?? 0)).toFixed(2)}</h4>
                                <p>Margin Available</p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-1">
                                {/* <h4>{currency=='INR'?'₹' : '$'}{currency=="INR"?position?.payload?.used_balance:position?.payload?.used_balance_usd}</h4> */}
                                <h4>{currency=='INR'?'₹' : '$'}{currency === "INR" ? (position?.payload?.used_balance ?? 0): (position?.payload?.used_balance_usd ?? 0)}</h4>
                                <p>Margin Used</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="preformanceladger p-0 mb-2">
                    <div className="d-grid" style={{gridTemplateColumns:"1fr 1fr",textAlign:"center"}}>
                        <div className="card">
                            <div className="card-body p-1">
                                {/* <h4>{position?.payload.balance}</h4> */}
                                <p><b>M2M: {currency=='INR'?'₹' : '$'}{currency=="INR"?position?.payload?.balance:position?.payload?.balance_usd}</b></p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-1">                                
                                <p><b>Holding: {currency=='INR'?'₹' : '$'}{currency=="INR"?position?.payload?.used_balance:position?.payload?.used_balance_usd}</b></p>
                            </div>
                        </div>                       
                    </div>
                </div>           
                <div class="tab-content p-1 " id="nav-tabContent">
                    <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">             
                        <TradeListOpenorder setCurrencyForMargin={setCurrency} data={position} loading={loading} error={error} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
                        <div  style={{marginBottom:"70px"}}></div>
                    </div>                
                </div>
			</div>
			<div class={tabName1=='benefit'?"tab-pane fade active show text-start":"tab-pane fade"} id="nav-benefits" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div className='topmovermain'>
                <div className='btndiv text-start'>
                    <button onClick={toggleDrawer} >#170048666</button>
                    {/* <button onClick={toggleDrawer}><img src={calendar} width="20"/> Last 7 days</button> */}                    
                </div>
                <div className='benefitheading'>
                    <h4>Our benefits have saved you</h4>
                    <img src={warning}/>
                </div>
                <div className='exnodata'>
                    <img src={danger}/>
                    <h4>You dont't have any saving data for this account.</h4>
                    <p>Start trading to see how our better-than-market conditions reduce your trading costs and protect against stop outs.</p>
                    <button style={{background:"#ffc400", borderRadius:"10px"}}>Start Trading</button> 
                </div>
                {/* <div className='benefitsdiv text-start mb-4'>
                    <h4 className='mb-3 mt-3'>Available benefits</h4>
                    <div className='card'>
                        <div className='card-body'>                           
                            <button onClick={()=>{setProtection("stopprotection");toggleProtectionDrawer();}}>Stop Out Protection</button>
                            <button onClick={()=>{setProtection("swapfree");toggleProtectionDrawer();}}>Swap-Free</button>
                            <button onClick={()=>{setProtection("negativebalance");toggleProtectionDrawer();}}>Negative Balance Protection</button>
                        </div>
                    </div>
                </div> */}
                </div>
			</div>			
		</div>
        {/* <Bottomdrawer isDrawerOpen={isDrawerOpen} changedrawer = {changeDrawername}/> */}
        <Protectiondrawer isprotectionDrawerOpen={isprotectionDrawerOpen} setIsProtectionDrawerOpen={setIsProtectionDrawerOpen} protecction={protecction} />
    </div>
  )
}

export default Performance