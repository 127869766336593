import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopmoversList } from '../../Redux/actions/tradeAction.js';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Stockdetails from '../Bottomdrawer/Stockdetails.js';
import { getUserToken } from '../../helper/getToken.js';
import eurousd  from '../../Images/eurusd.svg'
import Stockdetailsmain from '../../Pages/Stockdetailsmain.jsx';
import { useNavigate } from 'react-router-dom';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';

const TopMovers = ({ currency,alert }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({});
  const token = getUserToken()
  const dispatch = useDispatch();
  const { topMovers, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets); 
  const [issetalert,setIssstAlert] = useState(false);

  const navigate = useNavigate();

  
  useEffect(() => {
    // const fetchTopMoversData = async () => {
    //   if (token) {
    //     try {
    //       await dispatch(fetchTopmoversList(token));
    //     } catch (error) {
    //       console.error('Failed to fetch user data:', error);
    //     }
    //   }
    // };

    // fetchTopMoversData();
    dispatch(fetchTopmoversList(token));
    dispatch(connectPriceSocket());

  }, [dispatch,token]); 

 

  useEffect(() => {
    if (topMovers?.payload && priceSocket) {
      topMovers.payload[currency]?.forEach((instrument) => {
        const event = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US" ? `forexRoom${instrument.symbol.toLowerCase()}` : `room${instrument.token}`;
        const emitData = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US"  ? instrument.symbol.toLowerCase() : instrument.token; 
        priceSocket.emit('tokenData', emitData);        
        priceSocket.on(event, (updatedInstrument) => {
          let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;          
          setLivePrices((prevState) => ({
            ...prevState,
            [typeNmae]: updatedInstrument,
          }));
        });
      });
    }
  
    return () => {
      if (topMovers?.payload && priceSocket) {
        topMovers.payload[currency]?.forEach((instrument) => {
          const event = instrument.exchange === "FOREX" 
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;  
        });
      }
    };
  }, [topMovers, priceSocket, currency]);

 

  // const handleClick = (moverData,alert) => {  
  //   if (moverData.exchange === 'FOREX' || moverData.exchange === 'US' || moverData.exchange === 'CRYPTO') {      
  //     navigate('/stockdetails', { 
  //       state: { 
  //         idtoken: moverData.symbol, 
  //         exchange: moverData.exchange,
  //         moverData: moverData,
  //       } 
  //     });
  //   } else if (moverData.exchange === 'MCX' || moverData.exchange === 'NFO') {
  //     setSelectedMoverData(moverData);
  //     setIsDrawerOpen(true);
  //   } else {
  //     console.error("Exchange value is undefined or invalid.");
  //   }
  // };

  const handleClick = (moverData) => {    
    if(!alert){
        if (moverData.exchange === 'FOREX' || moverData.exchange === 'US' || moverData.exchange === 'CRYPTO') {
          navigate('/stockdetails', {
            state: {
              idtoken: moverData.symbol,
              exchange: moverData.exchange,
              moverData:moverData
            },
          });
        } else if (moverData.exchange === 'MCX' || moverData.exchange === 'NFO') {
          setSelectedMoverData(moverData);
          setIsDrawerOpen(true);
        } else {
          console.error("Exchange value is undefined or invalid.");
        }
    }else{
      setSelectedMoverData(moverData);
      setIssstAlert(true);
    }
  };

    

  const renderTopMovers = (topMoversList) =>    
    topMoversList.map((topMover, index) => { 
      let typeNmae = topMover.exchange ==='FOREX' || topMover.exchange === "US" || topMover.exchange === "CRYPTO" ? topMover.symbol.toLowerCase() : topMover.token;
      const liveData = livePrices[typeNmae];
      const askPrice = liveData?.ask_price || topMover.ask_price;
      const getPrice = (data) => { 
          const ltp = data.ltp;  
          const bidPrice = data.bid_price;
          return ltp > 0 ? formatNumber(ltp) : (bidPrice > 0 ? formatNumber(bidPrice) : "NA");
      };
      const pricechange = liveData?.price_change || topMover?.price_change;      
      const getCurrencySymbol = (exchange) => {
        return ['us', 'forex', 'crypto'].includes(exchange.toLowerCase()) ? '$' : '₹';
      };
      const price = getPrice(liveData || topMover);
      const formattedPrice = !isNaN(price) ? Number(price).toFixed(2) : 'N/A';
      function formatNumber(input) {
        try{
            const num = parseFloat(input);
            if (isNaN(num)) {
                throw new Error("Invalid number");
            }
            if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
                return parseFloat(num.toFixed(6));
            }
            return num;
        }catch(err){
            return 0;
        }
    }
      const percentChange = 
            liveData?.percentage_change !== undefined 
                ? parseFloat(liveData.percentage_change).toFixed(2) 
                : parseFloat(topMover.percentage_change).toFixed(2);
      let currentDatetime = new Date();
      const formattedDate = currentDatetime.toLocaleDateString('en-GB'); 
      
      return (
        <div className="card mb-2" key={index} onClick={() => handleClick(topMover)}>
          <div className="card-body" style={{cursor:"pointer"}}>
            <div className="topdiv">
              <div className="text-start">
                <b>{topMover?.symbol}</b>
              </div>
              <div>
                <p>{topMover?.graphdata}</p>
              </div>
              <div className="text-end">
                <p style={{ color:'green'}}>
                  {/* <b>{getCurrencySymbol(topMover.exchange)} {formattedPrice}</b> */}
                  <b>{getCurrencySymbol(topMover.exchange)}{price}</b>
                </p>
              </div>
            </div>
            <div className="bottomdiv">
              <div className="text-start">
                <p>{topMover?.expiry ? topMover.expiry : formattedDate}</p>
              </div>
              <div className="text-end">
                <span style={{ color:'green'}}>
                <i className="fa fa-arrow-up" aria-hidden="true"> </i>                   
                {pricechange} ({percentChange}) %
                </span>
              </div>
            </div>
          </div>         
        </div>
      );
    });
       

  const renderCurrencyTrades = (currency) => {
    const trades = topMovers?.payload?.[currency] || [];
    return trades.length > 0 ? renderTopMovers(trades) : <p>No {currency} symbols available</p>;
  };

  return (
    <div>
    
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}
      {renderCurrencyTrades(currency)}
      {/* {selectedMoverData && (
        <Stockdetails isDrawerOpen={isDrawerOpen} moverData={selectedMoverData} onClose={() => setIsDrawerOpen(false)}/>
      )} */}
      {selectedMoverData && (
        selectedMoverData.exchange === 'MCX' || selectedMoverData.exchange === 'NFO' ? (
          <Stockdetails 
            isDrawerOpen={isDrawerOpen} 
            moverData={selectedMoverData} 
            onClose={() => setIsDrawerOpen(false)} 
          />
        ) : null
      )}
      <AlertsetDrawer
            issetalert={issetalert}
            moverData={selectedMoverData}
            onClose={() => setIssstAlert(false)}
          />
    </div>
    
  );
};

export default TopMovers;


