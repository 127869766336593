import React from 'react'
import { Link } from 'react-router-dom';
// import Logo from '../Images/logo.svg'
import Logo from '../Images/tL.png'
import whatsappimg from '../Images/whatsapp.png'
import logotradelayer from '../Images/logotradelayer.png'
import downloadapp from '../Images/downloadapp.png'
import app_store from '../Images/app_store.svg'
import play_store from '../Images/play_store.svg'

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const SignRegister = () => {

  const handleWhatsAppOpen = () => {
      // const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
      // const message = "Hello, I want to register!";
      // const whatsappURL = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(message)}`;
      // window.open(whatsappURL, "_blank");
      const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
      const message = "Hello, I want to register!";
      const whatsappWebURL = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(message)}`;
      const whatsappAppURL = `intent://send?phone=91${phoneNumber}&text=${encodeURIComponent(message)}#Intent;scheme=whatsapp;package=com.whatsapp;end;`;
      const isAndroidWebView = /wv/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);
      if (isAndroidWebView) {
        window.location.href = whatsappAppURL; // Works in WebView
      } else {
        window.open(whatsappWebURL, "_blank"); // Works in web browsers
      }
  };

 



  return (
    <>
        <div className='signregistermain'>
            {/* <div className='downloadappicon'><img style={{height:"40px"}} src={downloadapp}/></div> */}
            <div className='logo-container' ><img src={logotradelayer} alt='' style={{width:"300px"}}/> </div>
            <div className='signregisterbtns'>
                <Link to="/signin" className='button1'>Sign In</Link>
                  <small className='text-center text-bold'>-OR-</small>
                <Link onClick={handleWhatsAppOpen} className='button2' style={{border:"1px solid #49c858",color:"#49c858",background:"transparent",borderRadius:"8px"}}>Register on <img src={whatsappimg} style={{marginLeft:"10px"}}/></Link>
                <Link to="/residence" className='button2' style={{color:"#42A4FF",fontWeight:"600"}}>Create new account</Link>
                <span className="d-flex align-items-center justify-content-evenly">
                  <p className="mb-0 text-nowrap">Download App</p>
                  <div className="d-flex gap-10">
                    <Link to={`${baseUrl}download-ipa`} target='_blank'>
                      <img src={app_store} alt="iOS" width="35"/>
                    </Link>
                    <Link to={`${baseUrl}download-apk`} target='_blank'>
                      <img src={play_store} alt="Android" width="35"/>
                    </Link>
                  </div>
                </span>
                
            </div>
        </div>
    </>
  )
}

export default SignRegister