import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png';
import NextArrow from '../Images/right-arrow.png';
import GlobalIcon from '../Images/globalicon.png'
import logo from '../Images/logo.svg';
import logotradelayer from '../Images/logotradelayer.png'
import './LoginSignup.css';

const Residence = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isChecked,setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    label: "Country / Region",    
    image: GlobalIcon 
  });

  const countries = [
    {
      value: "in",
      label: "India",
    },
    {
      value: "us",
      label: "United States",
    },
    {
      value: "ca",
      label: "Canada",
    },
    {
      value: "au",
      label: "Australia",
    },
    {
      value: "ae",
      label: "UAE",
    },
  ];

  const flagBaseUrl = "https://flagcdn.com/w40"; // Example flag API base URL

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCountry = (country) => {
    // setSelectedCountry(country);
    setSelectedCountry({
      label: country.label,
      image: `${flagBaseUrl}/${country.value}.png`,
    });
    setIsDropdownOpen(false); 
  };

  const handleTermCheck = (e)=> {
    setIsChecked(e.target.checked);
  }

  return (
    <div className='signinmain'>
      <div className='header'>
        <Link to="/"><img src={BackArrow} alt="Back Arrow" /></Link>
        <h2>Your Residence</h2>
      </div>
      <div className='signinform'>
        <p>Select your country of residence</p>
        <div className="selectregion">
          <div className='globalicon' onClick={toggleDropdown}>
            <div className="globalicon">
              <img src={selectedCountry.image} alt={selectedCountry.label} />
              <p>{selectedCountry.label}</p>
            </div>
            <img src={NextArrow} alt="Next Arrow" />
          </div>
          {isDropdownOpen && (
            <div className="dropdown">
              {countries.map((country, index) => (
                <div key={index} className="dropdown-item" onClick={() => selectCountry(country)} >
                  <img src={`${flagBaseUrl}/${country.value}.webp`} alt={country.label} className="dropdown-flag" style={{marginRight:"10px"}}/> 
                  {country.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="logo-container1">
        <img src={logotradelayer} alt="logo"/>
      </div>
      <div className='signregisterbtns'>
        <div className='declare'>
          <input type="checkbox" checked={isChecked} onChange={handleTermCheck}/>
          <h3>I declare and confirm that I am not a citizen or resident of <b>{selectedCountry.label}</b> for tax purposes.</h3>
        </div>
        {
          isChecked ?<>
            <Link to="/enteremail" className='button1'>Continue</Link>
          </>:<><Link to="/enteremail" className='button1' style={{pointerEvents:'none',opacity:"0.4",cursor:"not-allowed"}}>Continue</Link></>
        }
      </div>
    </div>
  );
};

export default Residence;
