// actions/authActions.js
import { signUpUser, signInUser, verifyEmail } from '../../helper/api_helper';
import Cookies from 'js-cookie';
import { 
  SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE,
  SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  VERIFYEMAIL_REQUEST, VERIFYEMAIL_SUCCESS, VERIFYEMAIL_FAILURE,
  SIGNOUT
} from '../authCostants';
import { useNavigate } from 'react-router-dom';

//const navigate = useNavigate();


// export const signIn = (credentials, navigate) => async (dispatch) => {
//   dispatch({ type: SIGNIN_REQUEST });
//   try {
//     const response = await signInUser(credentials);  
//     console.log("responseresponseresponse",response);
//     dispatch({ 
//         type: SIGNIN_SUCCESS, 
//         payload: { 
//               data: response.data, 
//               message: response.data.message 
//             }  
//     });     
//     localStorage.setItem('userData', JSON.stringify(response.data));
//     // return response.data;    
//     navigate('/accounts');
//     return response.data;    
//   } catch (error) {
//     dispatch({ 
//       type: SIGNIN_FAILURE, 
//       payload: { 
//         message: error.response?.data.message || error.message 
//       }
//     });
//   }
// };
export const signIn = (credentials, navigate) => async (dispatch) => {
  dispatch({ type: SIGNIN_REQUEST });
  try {
    const response = await signInUser(credentials);  
    
    dispatch({ 
      type: SIGNIN_SUCCESS, 
      payload: response.data 
    });     
    localStorage.setItem('userData', JSON.stringify(response.data));
    Cookies.set('userData', JSON.stringify(response.data), { expires: 1 });
    navigate('/accounts');
    return response.data; // Return the response data explicitly
  } catch (error) {
    const errorMessage = error.response?.data.message || error.message;
    dispatch({ 
      type: SIGNIN_FAILURE, 
      payload: errorMessage 
    });
    throw new Error(errorMessage); // Throw the error to be caught by the caller
  }
};




// Verify Email for Signup (OTP Request)
export const verifySignupEmail = (userData) => async (dispatch) => {
  dispatch({ type: VERIFYEMAIL_REQUEST });
  try {
    const response = await verifyEmail(userData);
    
    dispatch({ type: VERIFYEMAIL_SUCCESS, payload: response.data });
    return response.data;    
  } catch (error) {
    dispatch({ type: VERIFYEMAIL_FAILURE, payload: error.response?.data.message || error.message });
  }
};


// export const signUp = (userData,navigate) => async (dispatch) => {
//   dispatch({ type: SIGNUP_REQUEST });
//   // try {
//   //   const response = await signUpUser(userData);
//   //   dispatch({ type: SIGNUP_SUCCESS, payload: response.data });
//   //   return response;
//   // } catch (error) {
//   //   dispatch({ type: SIGNUP_FAILURE, payload: error.response?.data.message || error.message });
//   //   return null;
//   // }
//   try {
//     const response = await signUpUser(userData);
//     dispatch({ type: SIGNUP_SUCCESS, payload: response.data });
//     // Store user data in localStorage
//     localStorage.setItem("userData", JSON.stringify(response.data));
//     // Navigate to accounts page after successful signup
//     navigate("/accounts");
//     return response.data;
//   } catch (error) {
//     dispatch({
//       type: SIGNUP_FAILURE,
//       payload: error.response?.data.message || error.message,
//     });

//     return null;
//   }
// };

// export const signUp = (userData, navigate) => async (dispatch) => {
//   dispatch({ type: SIGNUP_REQUEST });
//   try {
//     const response = await signUpUser(userData);
//     const jsonString = response.data.match(/\{.*\}/)?.[0]; 
//     const parsedResponse = jsonString ? JSON.parse(jsonString) : response.data;
//     dispatch({ type: SIGNUP_SUCCESS, payload: parsedResponse });
//     if (parsedResponse.status === "SUCCESS") {
//       localStorage.setItem("userData", JSON.stringify(parsedResponse));
//       navigate("/accounts");
//     }
//     return parsedResponse; // This is what `handleSubmit` will receive
//   } catch (error) {
//     dispatch({
//       type: SIGNUP_FAILURE,
//       payload: error.response?.data.message || error.message,
//     });
//     return { status: "ERROR", message: "Signup failed" };
//   }
// };


export const signUp = (userData, navigate) => async (dispatch) => {
  dispatch({ type: "SIGNUP_REQUEST" });

  try {
    const response = await signUpUser(userData);

    // Extract JSON response safely
    const jsonString = typeof(response.data)=='string';
    const parsedResponse = jsonString ? JSON.parse(jsonString) : response.data;
    dispatch({ type: "SIGNUP_SUCCESS", payload: parsedResponse });

    if (parsedResponse.status === "SUCCESS") {
      // ✅ Store user data in localStorage
      localStorage.setItem("userData", JSON.stringify(parsedResponse));

      // ✅ Dispatch sign-in success action to update Redux state
      dispatch({ type: "SIGNIN_SUCCESS", payload: parsedResponse });

      // ✅ Auto-login and redirect to the accounts page
      navigate("/accounts");
    }

    return parsedResponse;
  } catch (error) {
    dispatch({
      type: "SIGNUP_FAILURE",
      payload: error.response?.data.message || error.message,
    });
    return { status: "ERROR", message: "Signup failed" };
  }
};


// // Sign Out Action
export const signOut = (navigate) => (dispatch) => {
  dispatch({ type: SIGNOUT });
  // navigate('/signin');
  navigate('/');
};

// export const signOut = () => (dispatch) => {
//   localStorage.removeItem("userData"); // ✅ Clear localStorage
//   dispatch({ type: "SIGNOUT" });
// };
