import React, { useState, useEffect } from 'react';
import leftarrow from '../../Images/angle-left.png';
import Modal from 'react-bootstrap/Modal';
import gbpusd from '../../Images/gbpusd.svg';
import checked from '../../Images/checked.png';
import Searchheader from './Searchheader';
import Currencybtn from '../Tradetab/Currencybtn';
import { getUserToken, debounce } from '../../helper/getToken';
import { useDispatch, useSelector } from 'react-redux';
import { addWatchList, deleteWatchList, fetchWatchList, searchWatchList } from '../../Redux/actions/tradeAction';
import { Card } from 'react-bootstrap';
import './Allmodal.css';
import Swal from 'sweetalert2';

const Editmodal = ({ setShow, show }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('ALL');
  const [checkedItems, setCheckedItems] = useState(Array(25).fill(false));
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({ instruments: [], watchlist_tokens: [] });
  const [loadingItem, setLoadingItem] = useState(null); // Track the loading state for a specific item
  const { trade, loading, error } = useSelector((state) => state.trade);
  const usertoken = getUserToken();
  const dispatch = useDispatch();

  const toggleCheck = (index) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  // const fetchSearchResults = async (query) => {
  //   setSearchResults({ instruments: [], watchlist_tokens: [] });
  //   if (!query) return;
  //   const token = getUserToken();
  //   const formData = {
  //     query: searchTerm,
  //     exchange: selectedCurrency,
  //   };

  //   try {
  //     const results = await dispatch(searchWatchList(token, formData));
  //     if (results.status === 'SUCCESS') {
  //       setSearchResults(results.payload);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching search results:', error);
  //   }
  // };

  //  const fetchSearchResults = async (query) => {
  //     if (!query) return;
  //     const token = getUserToken();
  //     const formData = {
  //       query: searchTerm,
  //       exchange: selectedCurrency,
  //     };
    
  //     try {
  //       setSearchResults({ instruments: [], watchlist_tokens: [] }); // Clear previous results
  //       const results = await dispatch(searchWatchList(token, formData));
  //       if (results.status === 'SUCCESS') {
  //         // Filter unique instruments
  //         const uniqueInstruments = Array.from(
  //           new Map(results.payload.instruments.map(item => [item.token, item])).values()
  //         );
  //         const uniquewatchlisttoken = Array.from(
  //           new Map(results.payload.watchlist_tokens.map(item => [item.token, item])).values()
  //         );
  //         setSearchResults({ 
  //           instruments: uniqueInstruments, 
  //           watchlist_tokens: uniquewatchlisttoken 
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error fetching search results:', error);
  //     }
  //   };

  // const fetchSearchResults = async (query) => {
  //   if (!query.trim()) {
  //     // Clear results immediately if query is empty or whitespace
  //     setSearchResults({ instruments: [], watchlist_tokens: [] });
  //     return;
  //   }
  
  //   const token = getUserToken();
  //   const formData = {
  //     query,
  //     exchange: selectedCurrency,
  //   };
  
  //   try {
  //     const results = await dispatch(searchWatchList(token, formData));
  //     if (results.status === 'SUCCESS') {
  //       // Filter unique instruments and watchlist tokens
  //       const uniqueInstruments = Array.from(
  //         new Map(results.payload.instruments.map((item) => [item.token, item])).values()
  //       );
  //       const uniqueWatchlistTokens = Array.from(
  //         new Map(results.payload.watchlist_tokens.map((item) => [item.token, item])).values()
  //       );
  //       setSearchResults({
  //         instruments: uniqueInstruments,
  //         watchlist_tokens: uniqueWatchlistTokens,
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching search results:', error);
  //   }
  // };

  const fetchSearchResults = async (query) => {
    if (!query.trim()) {
      setSearchResults({ instruments: [], watchlist_tokens: [] });
      return;
    }
  
    const token = getUserToken();
    const formData = {
      query,
      exchange: selectedCurrency, // Send selectedCurrency to the API
    };
  
    try {
      const results = await dispatch(searchWatchList(token, formData));
      if (results.status === 'SUCCESS') {
        let uniqueInstruments = Array.from(
          new Map(results.payload.instruments.map((item) => [item.token, item])).values()
        );
        let uniqueWatchlistTokens = Array.from(
          new Map(results.payload.watchlist_tokens.map((item) => [item.token, item])).values()
        );
  
        // **Filter results based on exchange**
        if (selectedCurrency !== 'ALL') {
          uniqueInstruments = uniqueInstruments.filter(
            (instrument) => instrument.exchange === selectedCurrency
          );
        }
  
        setSearchResults({
          instruments: uniqueInstruments,
          watchlist_tokens: uniqueWatchlistTokens,
        });
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  

  

  const debouncedFetch = debounce(fetchSearchResults, 300);

  // useEffect(() => {
  //   debouncedFetch(searchTerm);
  //   return () => {
  //     setSearchResults({ instruments: [], watchlist_tokens: [] });
  //   };
  // }, [searchTerm, selectedCurrency]);

  // useEffect(() => {
  //   if (!searchTerm.trim()) {
  //     // Clear results when search field is empty
  //     setSearchResults({ instruments: [], watchlist_tokens: [] });
  //   } else {
  //     // Fetch search results if the field has valid input
  //     debouncedFetch(searchTerm);
  //   }
  // }, [searchTerm, selectedCurrency]);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults({ instruments: [], watchlist_tokens: [] });
    } else {
      debouncedFetch(searchTerm);
    }
  }, [searchTerm, selectedCurrency]); 

  const handleDeleteClick = (watchlistToken) => {
    const token = getUserToken();
    Swal.fire({
      text: 'Do you want to remove this item?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingItem(watchlistToken); // Start loading for the specific item
        handleDelete(watchlistToken, usertoken).then((res) => {
          if (res?.status === 'SUCCESS') {
            console.log('Successfully deleted');
          } else {
            console.error('Failed to delete:', res?.error);
          }
          setLoadingItem(null); // Stop loading
        });
      }
      dispatch(fetchWatchList(token));
    });
  };

  const handleDelete = async (watchlistToken, usertoken) => {
    const token = getUserToken();
    try {
      const response = await dispatch(deleteWatchList(watchlistToken, usertoken));
      if (response.status === 'SUCCESS') {
        setSearchResults((prevResults) => ({
          ...prevResults,
          watchlist_tokens: prevResults.watchlist_tokens.filter((token) => token !== watchlistToken),
        }));
      }
    } catch (error) {
      console.error('Error deleting watchlist:', error);
    }
  };

  const addWatchlist = async (watchlistToken) => {
    const token = getUserToken();
    setLoadingItem(watchlistToken); // Start loading for the specific item
    try {
      const response = await dispatch(addWatchList(watchlistToken, token));
      if (response?.status === 'SUCCESS') {
        setSearchResults((prevState) => ({
          ...prevState,
          watchlist_tokens: [...prevState.watchlist_tokens, watchlistToken],
        }));
        dispatch(fetchWatchList(token));
      } else {
        throw new Error('Failed to add the watchlist item');
      }
    } catch (error) {
      console.error('Error adding to watchlist:', error);
    } finally {
      setLoadingItem(null); // Stop loading
    }
  };

  const labelClass = {
    NFO: 'label-red',
    MCX: 'label-blue',
    FOREX: 'label-green',
    CRYPTO: 'label-blue',
    US: 'label-red',
    CDS: 'label-blue',
  };

  return (
    <div className="editmodaldiv">
      <Searchheader show={show} setShow={setShow} />
      <div className="acctabbtndiv2 p-2 mb-2">
        {['ALL', 'MCX', 'NFO', 'FOREX', 'US', 'CRYPTO', 'CDS'].map((exchange) => (
          <button key={exchange} className={`btn ${selectedCurrency === exchange ? 'currencybtn active' : ''}`} onClick={() => setSelectedCurrency(exchange)} style={{fontSize:"11px",padding:"0"}} > {exchange} </button>
        ))}
      </div>
      <div className="p-2 mb-2">        
        <input
          type="text"
          placeholder="Search by trading symbol..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>
      <Modal.Body>
        <div className="mb-4"></div>
        <div>
          {error && <p>Error loading trades: {error.message}</p>}
          {searchResults.instruments.length > 0 ? (
            searchResults.instruments.map((instrument) => (
              <Card className="border-0 p-0 mb-2 w-100" key={instrument.token}>
                <Card.Body className="p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-2">
                        <p className="font-m mb-0 lh-1" style={{ fontWeight: '500' }}>
                          {instrument.trading_symbol}{' '}
                          <span className={`${labelClass[instrument?.exchange]} bage`}>{instrument.exchange}</span>
                        </p>
                      </div>
                    </div>

                    {searchResults.watchlist_tokens.includes(instrument.token) ? (
                      <span
                        className="label label-fill-blue py-0 added"
                        onClick={() => handleDeleteClick(instrument.token)}
                      >
                        {loadingItem === instrument.token ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fas fa-check"></i>
                        )}
                      </span>
                    ) : (
                      <span
                        className="label label-border-blue py-0 notadded"
                        onClick={() => addWatchlist(instrument.token)}
                      >
                        {loadingItem === instrument.token ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fas fa-plus"></i>
                        )}
                      </span>
                    )}
                  </div>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No results found.</p>
          )}
        </div>
      </Modal.Body>
    </div>
  );
};

export default Editmodal;
