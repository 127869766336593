// Utility function to get the token from localStorage
import Cookies from 'js-cookie';

export const getUserToken = () => {
    // const usertokendata = localStorage.getItem("userData");
    // if (usertokendata) {
    //   const parsedUserData = JSON.parse(usertokendata); 
    //   return parsedUserData.payload?.token; // Access token safely
    // }
    // return null;

    let usertokendata = localStorage.getItem("userData");

    if (!usertokendata) {
      // If not found in localStorage, check Cookies
      usertokendata = Cookies.get("userData");
    }
    if (usertokendata) {
      try {
        const parsedUserData = JSON.parse(usertokendata);
        return parsedUserData?.payload?.token || null; // Safely return token
      } catch (error) {
        console.error("Error parsing user token data:", error);
        return null;
      }
  };

}


 // Debounce utility function
 export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const gethasFlag = (countryCode) => {
  const flagBaseUrl = "https://flagcdn.com/w320/"; // Example flag image CDN
  return countryCode ? `${flagBaseUrl}${countryCode.toLowerCase()}.png` : null;
};


export const getPrice=(item)=>{
  if(item?.ltp&&item?.ltp!=0){
      return formatNumber(item?.ltp)
  }else  if(item?.bid_price&&item?.bid_price!=0){
      return formatNumber(item?.bid_price)
  }else  if(item?.price&&item?.price!=0){
      return formatNumber(item?.price)
  }else  if(item?.ask_price&&item?.ask_price!=0){
      return formatNumber(item?.ask_price)
  }else {
      return 0;
  }
}

function formatNumber(input) {
  const num = parseFloat(input);
    if (isNaN(num)) {
        throw new Error("Invalid number");
    }
    if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
        return parseFloat(num.toFixed(6));
    }
    return num;
}


  