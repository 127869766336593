import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getUserToken } from '../../helper/getToken';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrder } from '../../Redux/actions/orderAction';
import { convertToIndianTime, formatNumber } from '../../helper/commonFunctions';
const ListOrder = ({ data }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const apiUrl = process.env.REACT_APP_API_FRONTEND;
  const token = getUserToken();
  const [livePrices, setLivePrices]=useState({})
  const {priceSocket}=useSelector((state)=>state.sockets)
  const dispatch = useDispatch();
  const { order, loading, error } = useSelector((state) => state.order);
  const { user} = useSelector((state) => state.user);
  
  useEffect(()=>{
    const pendingPositions=getCurrencyData(selectedCurrency)    
    if(!priceSocket||!pendingPositions.length!=0) return;
    pendingPositions.forEach((position)=>{
      const { token, exchange } = position;   
      const trading_symbol=position?.trading_symbol||position?.symbol;
      const event= exchange=="FOREX"||exchange=="CRYPTO"||exchange=='US'
      ?`forexRoom${trading_symbol?.toLowerCase()}`
      :`room${token}`
      const emitData=
      exchange=='FOREX'||exchange=="CRYPTO"||exchange=="US"
      ?trading_symbol?.toLowerCase()
      :token;
      priceSocket.emit("tokenData",emitData);
      priceSocket.on(event,(updatedInstrument)=>{
        
        setLivePrices((prevState)=>({
          ...prevState,
          [trading_symbol]:updatedInstrument,
        }))
      })
    })
    return ()=>{
      pendingPositions.forEach((position)=>{
        const {token,exchange}=position;
        const trading_symbol=position?.trading_symbol||position?.symbol;
        const event=
        exchange=="FOREX"||exchange=="CRYPTO"||exchange=="US"
        ?`forexRoom${trading_symbol?.toLowerCase()}`
        :`room${token}`
        priceSocket.off(event);
      })
      
    }
  },[priceSocket,selectedCurrency,data])

  useEffect(()=>{
    const selectCurrency=localStorage.getItem('selectedcurrency')
    if(selectCurrency){
      handleSelectCurrency(selectCurrency)
    }
  },[])

  const handleSelectCurrency=(currency)=>{
    localStorage.setItem("selectedcurrency",currency)
    setSelectedCurrency(currency)
  }
  
  // console.log(livePrices,'jjjjjjhhhhhhhhhgggggggggg')
  // Delete order and refresh list
  const deleteOrder = async (orderid) => {
    try {
      const body = { order_id: orderid };
      await axios.post(`${apiUrl}client/order/cancel`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Swal.fire("Success", "Order deleted successfully", "success");
      dispatch(fetchOrder(token));
    } catch (error) {
      console.error("Error deleting order:", error);
      Swal.fire("Error", "Failed to delete order", "error");
    }
  };

  const handleDeleteAllPendingOrders=()=>{
    Swal.fire({
      text:"Do you want to cancel all orders?",
      showCancelButton:true,
      confirmButtonText:"Yes",
      cancelButtonText:"No",
      customClass:{
        confirmButton:"custom-confirm-button",
        cancelButton:"custom-cancel-button"
      }
    }).then(result=>{
      if(result.isConfirmed){
        handleDeleteall();
      }
    })
  }

  const handleDeleteall = async () => {   
    try {
      const body = { id: user?.payload.id };
      await axios.post(`${apiUrl}client/order/cancelAll`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Swal.fire("Success", "All Order canceled successfully", "success");
      dispatch(fetchOrder(token));
    } catch (error) {
      console.error("Error deleting order:", error);
      Swal.fire("Error", "Failed to delete order", "error");
    }
  };

 

  const handleDeleteClick = (orderid) => {
    Swal.fire({
      text: "Do you want to cancel this order?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(orderid);
        
      }
    });
  };

  const getCurrencySymbol = (exchange) => {
    return ['us', 'forex', 'crypto'].includes(exchange.toLowerCase()) ? '$' : '₹';
  };

  const renderOrders = (order) => {
    return order.map((item, index) => {
    const livePrice=livePrices[item?.trading_symbol||item?.symbol]||{};
    const ltpToShow=livePrice?.ltp||livePrice?.bid_price||livePrice?.ask_price||livePrice?.price;

   return (
    <div className="listorder" key={index} onClick={() => handleDeleteClick(item.id)}>
    <div className="listorderinner">
      <div className="listtop">
        <div>
          <h5>{item?.trading_symbol}</h5>
        </div>
        <div className="topright">
          {/* <p>
            <b>{getCurrencySymbol(item.exchange)} {formatNumber(ltpToShow)}</b>
            <small style={{fontSize:"9px"}}>`Avg Price : {item?.avg_price||item?.price}`</small>
          </p> */}
          <div className='d-flex flex-column text-end'>
              <b>{getCurrencySymbol(item.exchange)} {formatNumber(ltpToShow)}</b>
              <small style={{fontSize:"9px"}}>Avg Price : {formatNumber(item?.avg_price||item?.price)}</small>
          </div>
        </div>
      </div>
      <div className="listmiddle">
        <div className="listmiddleleft">
          <p className={`trantype ${item?.type === 'buy' ? 'buy' : 'sell'}`}>{item?.type}</p>
          <p className={`exchange ${item?.exchange === 'fx' ? 'fx' : 'mcx'}`}>{item?.exchange}</p>
        </div>
        <div className="listmiddleright">
          <small>{convertToIndianTime(item?.added_on)}</small> 
        </div>
      </div>
      <div className="listbottom">
        <div className="listbottomleft">
          <p className="ordertype">{item?.order_type}</p>
          <p className="quantity">Qty. {formatNumber(item?.qty)}</p>
        </div>
        <div className="listbottomright">
          <p className="pending">{item?.status}</p>
        </div>
      </div>
    </div>
  </div>
   )
    }
    );
  };

  const renderNoDataMessage = (currency) => (
    <p>No pending {currency} trades available</p>
  );

  const getCurrencyData = (currency) => {
    // console.log(data?.payload?.pending,'oooooooiiiiiiiiuuuuuuuuuyyyyyyyyy')
    return data?.payload?.pending?.[currency] || [];
  };

  return (
    <div>
      
      <div className="acctabbtndiv" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        {/* <div style={{cursor:"pointer"}}>
            <p className='' style={{color:"red",textDecoration:"underline"}} onClick={()=>handleDeleteall()}>Cancel all</p>
        </div> */}
        
            <div style={{ cursor: "pointer" }}>
                {getCurrencyData(selectedCurrency).length > 0 && (
                  <p className='' style={{ color: "red", textDecoration: "underline" }} onClick={() => handleDeleteAllPendingOrders()}>
                    Cancel all
                  </p>
                )}
            </div>
         
        <div>
            <button className={selectedCurrency === 'INR' ? 'active' : ''} onClick={() => handleSelectCurrency('INR')} > INR </button>
            <button className={selectedCurrency === 'USD' ? 'active' : ''} onClick={() => handleSelectCurrency('USD')} > USD </button>
        </div>
      </div>
      {['INR', 'USD'].map((currency) =>
        selectedCurrency === currency && (
          <div key={currency}>
            {getCurrencyData(currency).length > 0
              ? renderOrders(getCurrencyData(currency))
              : renderNoDataMessage(currency)}
          </div>
        )
      )}
    </div>
  );
};

export default ListOrder;
