import React,{useState,useEffect} from 'react'
import {useDispatch, useSelector } from 'react-redux';
import { fetchAllLeverage } from '../../Redux/actions/tradeAction';
import { getUserToken } from '../../helper/getToken';
import axios from 'axios';
import { formatNumber } from '../../helper/commonFunctions';

const BuystopSet = ({
  isToggleOn,
  action,
  setIsToggleOn,
  volumeplus,
  setVolumeplus,
  priceplus,
  setPriceplus,
  profitplus,
  setProfitplus,
  stoplossplus,
  setStoplossplus,
  onDropdownVolumeChange,
  onDropdownProfitChange,
  onDropdownLossChange,
  onDropdownPriceChange,
  livePrice,
  moverData,
  handleChangeVolume,
  handleChangeStopLoss,
  handleChangeTakeProfit,
  getTotalPriceLive,
  selectedvolume,
  setSelectedVolume,
  isError,
  getQuantity,
  setIsError,
  errorMsg,
  setErrorMsg,
  selectedprofit,
}) => {
  const apiUrl = process.env.REACT_APP_API_FRONTEND;
  const { position, loading, error } = useSelector((state) => state.user);
  const { leverage } = useSelector((state) => state.trade);
  const dispatch = useDispatch();
  const token = getUserToken();
  const [conversionRate, setConversionRate] = useState(1);
  const bodyData = {
    exchange: moverData.exchange,
  };
  
  useEffect(() => {
    setProfitplus(moverData?.price);
    setStoplossplus(moverData?.price);
    getConversionRateInUSD(moverData?.symbol);
    dispatch(fetchAllLeverage(token, bodyData));
  }, []);
  const handleSelectChangevolume = (e) => {
    const value = e.target.value;
    onDropdownVolumeChange(value);
  };
  const getConversionRateInUSD = (symbol) => {
    if (typeof symbol !== "string" || symbol.length != 6) {
      setConversionRate(1);
      return 1;
    } else if (
      symbol.substring(0, 3) == "USD" ||
      symbol.substring(3, 6) == "USD"
    ) {
      setConversionRate(1);
      return 1;
    } else {
      let firstSymbol = symbol.substring(0, 3);
      let fullSymbol = firstSymbol + "USD";
      let data = {
        symbol: fullSymbol,
      };
      let config = {
        url: `${apiUrl}client/market/fetch-stock-by-symbol`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          let price = 1;
          if (res?.data?.payload) {
            price =
              res?.data?.payload?.price ||
              res?.data?.payload?.ask_price ||
              res?.data?.payload?.bid_price ||
              res?.data?.payload?.ltp;
          }
          setConversionRate(Number(price));
          return price;
        })
        .catch((err) => {
          console.log(err, "error in fetching conversion rate");
          setConversionRate(1);
          return 1;
        });
    }
  };
  const decrementTakeProfit = () => {
    let currentVal = parseFloat(profitplus) || 0;
    let newVal = parseFloat(currentVal - 0.1).toFixed(2);
    if (newVal <= livePrice&&action=='Buy') {
      setIsError(true);
      setErrorMsg("Take profit field must be greater than live price");
    }else if(newVal>=livePrice&&action=='Sell'){
      setIsError(true);
      setErrorMsg("Take profit field must be lesser than live price");
    }else if(stoplossplus>=livePrice&&action=='Buy'){
      setIsError(true);
      setErrorMsg("Stop loss field must be lesser than live price");
    }else if(stoplossplus<=livePrice&&action=='Sell'){
      setIsError(true);
      setErrorMsg("Stop loss field must be greater than live price");
    }
     else {
      setIsError(false);
      setErrorMsg("");
    }
    if (newVal > 0) setProfitplus(newVal);
    else setProfitplus(0);
  };
  const incrementTakeProfit = () => {
    let currentVal = parseFloat(profitplus) || 0;
    let newVal = parseFloat(currentVal + 0.1).toFixed(2);
    if (newVal <= livePrice&&action=='Buy') {
      setIsError(true);
      setErrorMsg("Take profit field must be greater than live price");
    }else if (newVal >= livePrice&&action=='Sell') {
      setIsError(true);
      setErrorMsg("Take profit field must be lesser than live price");
    }else if (stoplossplus >= livePrice&&action=='Buy') {
      setIsError(true);
      setErrorMsg("Stop loss field must be lesser than live price");
    }else if (stoplossplus <= livePrice&&action=='Sell') {
      setIsError(true);
      setErrorMsg("Stop loss field must be greater than live price");
    } else {
      setIsError(false);
      setErrorMsg("");
    }
    setProfitplus(newVal);
  };
  const decrementStopLoss = () => {
    let currentVal = parseFloat(stoplossplus) || 0;
    let newVal = parseFloat(currentVal - 0.1).toFixed(2);
    if (newVal >= livePrice) {
      setIsError(true);
      setErrorMsg("Stop Loss field must be lesser than live price");
    } else {
      setIsError(false);
      setErrorMsg("");
    }
    if (newVal > 0) setStoplossplus(newVal);
    else setStoplossplus(0);
  };
  
  const incrementStopLoss = () => {
    let currentVal = parseFloat(stoplossplus) || 0;
    let newVal = parseFloat(currentVal + 0.1).toFixed(2);
    if (newVal >= livePrice&&action=='Buy') {
      setIsError(true);
      setErrorMsg("Stop Loss field must be lesser than live price");
    }else if(newVal<=livePrice&&action=="Sell"){
      setIsError(true);
      setErrorMsg("Stop Loss field must be greater than live price");
    }else if(profitplus<=livePrice&&action=='Buy'){
      setIsError(true);
      setErrorMsg("Take Profit field must be greater than live price");
    }else if(profitplus>=livePrice&&action=='Sell'){
      setIsError(true);
      setErrorMsg("Take Profit field must be lesser than live price");
    }
     else {
      setIsError(false);
      setErrorMsg("");
    }
    setStoplossplus(newVal);
  };
  const handleChangePriceValue = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setPriceplus(value);
    } else {
      e.preventDefault();
    }
  };
  const getFeesCalculated = () => {
    // Ensure `moverData` exists and handle safely
    if (!moverData?.spread) {
      // console.error("Invalid moverData or spread");
      return 0; // Return 0 for invalid data
    }
    // let pipsCalculated = 0;
    // const symbol = moverData.symbol;
    const spread = moverData.spread;
    // if (symbol.substring(0, 3) === "JPY" || symbol.substring(3, 6) === "JPY") {
    // Special calculation for JPY pairs
    let pipsCalculated = spread;
    // } else {
    // Standard calculation for other symbols
    // const spreadString = spread.toFixed(4); // Ensure spread is treated as a string with decimals
    // const decimalPart = spreadString.split(".")[1]; // Extract decimal part

    // if (decimalPart && decimalPart.length > 2) {
    // const result = `0.00${decimalPart.slice(2)}`; // Form the pips value from the third decimal onward
    // pipsCalculated = spread; // Convert back to a number
    // }
    // else {
    //     pipsCalculated = 0; // Default to 0 if no significant decimal part
    // }
    // }

    return Number(pipsCalculated);
  };
  const handleSelectChangeprofit = (e) => {
    const value = e.target.value;
    onDropdownProfitChange(value);
  };

  const handleSelectChangeloss = (e) => {
    const value = e.target.value;
    onDropdownLossChange(value);
  };

  const handleSelectChangeprice = (e) => {
    const value = e.target.value;
    onDropdownPriceChange(value);
  };
  const feesCalculated = getFeesCalculated();
  const bidPrice = Number(moverData?.bid_price);
  const lotSize = Number(moverData?.lotsize);
  const volumePlus = Number(volumeplus);
  const dynamicPricing = getTotalPriceLive(
    selectedvolume,
    livePrice,
    volumeplus
  );
  return (
    <div>
      <div
        className="forexbottomdrawer"
        style={{
          minHeight: isToggleOn ? "auto" : "150px",
          marginBottom: "20px",
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
        }}
      >
        {!isToggleOn ? (
          <div className="foresmain">
            <div className="forexclear mt-3">
              <label>Volume</label>
              <button>Clear</button>
            </div>
            <div className="d-flex align-items-center w-100">
              <div className="volume-input">
                <button
                  onClick={() =>
                    setVolumeplus((prev) =>
                      parseFloat((prev - 0.01).toFixed(2))
                    )
                  }
                >
                  -
                </button>
                <input
                  type="text"
                  value={volumeplus}
                  onChange={handleChangeVolume}
                />
                <button
                  onClick={() =>
                    setVolumeplus((prev) =>
                      parseFloat((prev + 0.01).toFixed(2))
                    )
                  }
                >
                  +
                </button>
              </div>
             {moverData?.exchange=="CRYPTO"? <select className="cusselect" onChange={handleSelectChangevolume}>
                <option value="usd">USD</option>
              </select>: <select className="cusselect" onChange={handleSelectChangevolume}>
                <option value="lots">Lots</option>
                <option value="usd">Units</option>
              </select>}
            </div>
            <div>
            <p style={{fontWeight:"500"}} className="details">{`Quantity : ${getQuantity(selectedvolume,volumePlus,moverData)}`}</p>
              <p className="details">
                {` Fees: ~ ${
                  moverData.exchange == "CRYPTO"
                    ? Number(
                        (
                          (dynamicPricing * 5) /
                          100 /
                          leverage?.payload?.intraday
                        ).toFixed(4)
                      )
                    : Number(
                        (
                          feesCalculated *
                          lotSize *
                          volumePlus *
                          conversionRate
                        ).toFixed(2)
                      )
                } USD | Margin: ${moverData?.exchange=="CRYPTO"?formatNumber(Number(volumeplus)/Number(leverage?.payload?.intraday)):Number(
                  Number(dynamicPricing) / leverage?.payload?.intraday
                ).toFixed(4)} USD | Leverage: 1 : ${
                  leverage?.payload?.intraday
                }`}{" "}
              </p>
            </div>
          </div>
        ) : (
          // Render the full section if the toggle is on
          <>
            <div className="foresmain">
              <div className="forexclear mt-3">
                <label>Volume</label>
                <button onClick={() => setVolumeplus(0.0)}>Clear</button>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="volume-input">
                  <button
                    onClick={() =>
                      setVolumeplus((prev) =>
                        parseFloat((prev - 0.01).toFixed(2))
                      )
                    }
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={volumeplus}
                    onChange={handleChangeVolume}
                  />
                  <button
                    onClick={() =>
                      setVolumeplus((prev) =>
                        parseFloat((prev + 0.01).toFixed(2))
                      )
                    }
                  >
                    +
                  </button>
                </div>
                {moverData?.exchange=="CRYPTO"?<select
                  className="cusselect"
                  onChange={handleSelectChangevolume}
                >
                  <option value="usd">USD</option>
                </select>:<select
                  className="cusselect"
                  onChange={handleSelectChangevolume}
                >
                  <option value="lots">Lots</option>
                  <option value="usd">Units</option>
                </select>}
              </div>
              <div>
              <p style={{fontWeight:"500"}} className="details">{`Quantity : ${getQuantity(selectedvolume,volumePlus,moverData)}`}</p>
                <p className="details">
                  {` Fees: ~ ${
                    moverData.exchange == "CRYPTO"
                      ? Number(
                          (
                            (dynamicPricing * 5) /
                            100 /
                            leverage?.payload?.intraday
                          ).toFixed(4)
                        )
                      : Number(
                          (
                            feesCalculated *
                            lotSize *
                            volumePlus *
                            conversionRate
                          ).toFixed(2)
                        )
                  } USD | Margin: ${moverData?.exchange=="CRYPTO"?formatNumber(Number(volumeplus)/Number(leverage?.payload?.intraday)):Number(
                    Number(dynamicPricing) / leverage?.payload?.intraday
                  ).toFixed(4)} USD | Leverage: 1 : ${
                    leverage?.payload?.intraday
                  }`}{" "}
                </p>
              </div>
              <div></div>
              <div></div>
            </div>
            <div className="foresmain">
              <div className="forexclear mt-1">
                <label>Price</label>
                <button onClick={() => setPriceplus(0.0)}>Clear</button>
              </div>
              <div className="d-flex align-items-center w-100">
                <div
                  className="volume-input w-100"
                  style={{ borderRadius: "5px" }}
                >
                  <button
                    onClick={() =>
                      setPriceplus((prev) =>
                        parseFloat((prev - 0.1).toFixed(2))
                      )
                    }
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={priceplus}
                    onChange={handleChangePriceValue}
                    className="w-100"
                  />
                  <button
                    onClick={() =>
                      setPriceplus((prev) =>
                        parseFloat((prev + 0.1).toFixed(2))
                      )
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                <p className="details">Max {livePrice}</p>
              </div>
            </div>
            <div className="foresmain">
              <div className="forexclear mt-1">
                <label>Take Profit</label>
                <button onClick={() => setProfitplus(0.0)}>Clear</button>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="volume-input">
                  {profitplus > 0 ? (
                    <button onClick={decrementTakeProfit}>-</button>
                  ) : (
                    <button onClick={decrementTakeProfit}>-</button>
                  )}
                  <input type="text" value={profitplus} onChange={handleChangeTakeProfit} />
                  <button onClick={incrementTakeProfit}>+</button>
                </div>
                <select className="cusselect" value={selectedprofit} onChange={handleSelectChangeprofit} >
                  {/* <option value="price">Price</option>
                  <option value="assetprice">By asset price</option>
                  <option value="inmoney">In money</option>
                  <option value="equitypercent">In % of equity</option>
                  <option value="inpips">In pips</option> */}
                  <option value="in_price">Price</option>
                  <option value="in_asset_price">By asset price</option>
                  <option value="in_money">In money</option>
                  <option value="in_percentage">In % of equity</option>
                  <option value="in_pip">In pips</option>
                </select>
              </div>
            </div>
            <div className="foresmain">
              <div className="forexclear mt-1">
                <label>Stop Loss</label>
                <button onClick={() => setStoplossplus(0.0)}>Clear</button>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="volume-input">
                  {stoplossplus > 0 ? (
                    <button onClick={decrementStopLoss}>-</button>
                  ) : (
                    <button onClick={decrementStopLoss}>-</button>
                  )}
                  <input type="text" value={stoplossplus} onChange={handleChangeStopLoss} />
                  <button onClick={incrementStopLoss}>+</button>
                </div>
                <select className="cusselect" value={selectedprofit} onChange={handleSelectChangeprofit} >
                  <option value="in_price">Price</option>
                  <option value="in_asset_price">By asset price</option>
                  <option value="in_money">In money</option>
                  <option value="in_percentage">In % of equity</option>
                  <option value="in_pip">In pips</option>
                </select>
              </div>
            </div>
          </>
        )}
        {isError == true ? (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <p style={{ color: "red" }}>{errorMsg}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BuystopSet