import React from 'react'
import NewHeader from '../Header/NewHeader'
import './Upcomingeventsdetails.scss'
const Upcomingeventsdetails = () => {
  return (
    <div  className='accountsmain'>
      <NewHeader title="CZK" addicon="none" />
      <div className='upcomingdetailsdiv'>
      <div className='card'>
        <div className='card-body'>
            <section className='eventsdetails-heading'>
                <h2> Budget Balance</h2>
            </section>
            <div className='eventsdetails-block'>
                <div className='eventsdetails-block-left'>
                    <p> Sep 2,2024 5:30:00 PM </p>         
                </div>
                <div className='eventsdetails-block-right'>
                    <p>Volatility</p>
                    <div className='eventsdetailsdiv' >
                        <div className='div-1'></div>
                        <div className='div-2'></div>
                        <div className='div-3'></div>
                    </div>
                </div>
            </div>
            <div className='eventsdetails-block-2'>
                <div className='eventsdetails-block-2-left'>
                    <span className='preview'>Actual</span>
                    <span className='numbers'>-</span>
                </div>
                <div className='eventsdetails-block-2-middle'>
                    <span className='preview'>Consensus</span>
                    <span className='numbers'>-</span>
                </div>
                <div className='eventsdetails-block-2-right'>
                  <span className='preview'>Previous</span>
                  <span className='numbers'>-192.3</span>
                </div>
            </div>
            <div className='eventsdetails-block-description'>
                <p>
                    img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 19:33:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 40:29:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 47:33:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Upcomingeventsdetails
