const initialState = {
  loading: false,
  trade: null,
  watchlist:null,
  topMovers: [],
  topLooser: null,
  allStocks: null,
  indexes: null,
  major:null,
  minor:null,
  energies:null,
  crypto:null,
  exotic:null,
  populartrade:null,
  alltrades:null,
  leverage:null,
  indianmargin:null,
  activeTab: "nav-fav",
  error: null,


};

export const tradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TRADE_REQUEST':
    case 'FETCH_WATCHLIST_REQUEST':
    case 'DELETE_WATCHLIST_REQUEST':
    case 'ADD_WATCHLIST_REQUEST':
    case 'FETCH_TOPMOVERS_REQUEST':
    case 'FETCH_TOPLOOSER_REQUEST':
    case 'FETCH_ALLSTOCKS_REQUEST':   
    case 'INDEXFUND_REQUEST':
    case 'INDICES_FUND_REQUEST':
    case 'METALS_REQUEST':
    case 'FETCH_CURRENCIES_REQUEST':
    case 'FETCH_ALLTRADEDATA_REQUEST':
    case 'FETCH_LEVERAGE_REQUEST':
    case 'FETCH_MARGIN_REQUEST':
    
      return {
        ...state,
        loading: true,
        error: null,
      };

    // Success Cases: Handles success states and updates the trade data
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload };
    case 'FETCH_TRADE_SUCCESS':
      return {
        ...state,
        loading: false,
        trade: action.payload,  // Ensure the data is correctly structured
        error: null,
      };
    case 'FETCH_WATCHLIST_SUCCESS':
      return {
        ...state,
        loading: false,
        watchlist: action.payload, 
        error: null,
      };

    // Success Case for Adding to Watchlist
    case 'ADD_WATCHLIST_SUCCESS':
      return {
        ...state,
        loading: false,
        watchlist: action.payload,
        error: null,
      };
    case 'DELETE_WATCHLIST_SUCCESS':
      if (!state.watchlist || !state.watchlist.payload || !action.payload.currency) {
        console.error("State or currency is undefined in reducer");
        return state;
      }
      
      return {
        ...state,
        loading: false,
        watchlist: {
          ...state.watchlist,
          payload: {
            ...state.watchlist.payload,
            [action.payload.currency]: state.watchlist.payload[action.payload.currency]?.filter(
              item => item.token !== action.payload.token
            ) || [],
          },
        },
        error: null,
      };

    // Success Case for Top Movers
    case 'FETCH_TOPMOVERS_SUCCESS':
      return {
        ...state,
        loading: false,
        topMovers: action.payload,  // Store top movers data
        error: null,
      };

    // Success Case for Top Losers
    case 'FETCH_TOPLOOSER_SUCCESS':
      return {
        ...state,
        loading: false,
        topLooser: action.payload,  // Store top losers data
        error: null,
      };

      case 'FETCH_ALLSTOCKS_SUCCESS':
        return {
          ...state,
          loading: false,
          allStocks: action.payload,
          error: null,
        };

    case 'FETCH_ALLTRADEDATA_SUCCESS':
      return{
        ...state,
        loading: false,
        alltrades: action.payload,
        error: null,
      }
    // Success Case for Index Funds
    case 'INDEXFUND_SUCCESS':
    
    return {
      ...state,
      loading: false,
      indexes: action.payload,  // Ensure the data is correctly structured
      error: null,
    };

    case 'INDICES_FUND_SUCCESS':

    return {
      ...state,
      loading:false,
      indices:action.payload,
      error:null,
    }

    case 'METALS_SUCCESS':
    return {
      ...state,
      loading:false,
      metals:action.payload,
      error:null,
    }

    case 'FETCH_CURRENCIES_SUCCESS':
    return {
      ...state,
      loading:false,
      major:action.payload,
      minor:action.payload,
      exotic:action.payload,
      populartrade:action.payload,
      energies:action.payload,
      crypto:action.payload,
    }
    case 'FETCH_LEVERAGE_SUCCESS':{
      return{
        ...state,
        loading:false,
        leverage:action.payload,
        error:null
      }
    }
    case 'FETCH_MARGIN_SUCCESS':{
      return{
        ...state,
        loading:false,
        indianmargin:action.payload,
        error:null
      }
    }
    
    // Failure Cases: Handles error states
    case 'FETCH_TRADE_FAILURE':
    case 'FETCH_WATCHLIST_FAILURE':
    case 'DELETE_WATCHLIST_FAILURE':
    case 'ADD_WATCHLIST_FAILURE':
    case 'FETCH_TOPMOVERS_FAILURE':
    case 'FETCH_TOPLOOSER_FAILURE':
    case 'FETCH_ALLSTOCKS_FAILURE':
    case 'INDEXFUND_FAILURE':
    case 'INDICES_FUND_FAILURE':
    case 'METALS_FAILURE':
    case 'FETCH_CURRENCIES_FAILURE':
    case 'FETCH_ALLTRADEDATA_FAILURE':
    case 'FETCH_LEVERAGE_FAILURE':
    case 'FETCH_MARGIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,  // Store the error message
      };

    // Update Top Movers
    case 'UPDATE_TOP_MOVERS':
      return {
          ...state,
          topMovers: {
              ...state.topMovers,
              payload: {
                  ...state.topMovers.payload,
                  MCX: state.topMovers.payload.MCX.map(mover => 
                      mover.token === action.payload.token ? { ...mover, ...action.payload } : mover
                  ),
              },
          },
      };

    default:
      return state;
  }
};


const updateTopMoversList = (currentTopMovers = [], newTopMovers) => {
  const updatedMovers = [...currentTopMovers];
  newTopMovers.forEach((newMover) => {
    const index = updatedMovers.findIndex((mover) => mover.id === newMover.id);
    if (index > -1) {
      // Update existing mover
      updatedMovers[index] = newMover;
    } else {
      // Add new mover if it doesn't exist
      updatedMovers.push(newMover);
    }
  });

  return updatedMovers;
};

export default tradeReducer;
