import React from 'react'
import briefcase from '../../Images/briefcase.svg'
import investors from '../../Images/investoricons.svg'
const socialdata = [
    {
        id:1,
        image:`${briefcase}`,
        title:"For Professional traders",
        subtitle:"Boost your income by sharing your trading stratgies",
    },
    {
        id:1,
        image:`${investors}`,
        title:"For Investors",
        subtitle:"Copy successfull stratgies of other traders",
    }
]

const Socialtrading = () => {
  return (
    <div className='socialtradingdiv'>
        <h4 className='profileheading'>Social Trading</h4>
        <div className="card">
            <div className="card-body">
                {
                    socialdata && socialdata.map((sociald,index)=>{
                        return(
                            <div className='innerdiv' key={index}>
                                <div>
                                    <img src={sociald.image} alt="" />
                                </div>
                                <div>
                                    <p>{sociald.title}</p>
                                    <small>{sociald.subtitle}</small>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div className='innerdiv'>
                    <div>
                        <img src={investors} alt="" />
                    </div>
                    <div>
                        <p>For Investors</p>
                        <small>Copy successfull stratgies of other traders</small>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Socialtrading