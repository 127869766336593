import React, { useState } from 'react';
import checked from '../../Images/checked.png'
import NewHeader from '../Header/NewHeader';

const languageData = [
  { id: 1, language: 'English' },
  { id: 2, language: 'Español' },
  { id: 3, language: 'Français' },
  { id: 4, language: 'हिन्दी' }
];

const Changelanguage = () => {
  const [checkedItems, setCheckedItems] = useState(new Array(languageData.length).fill(false));

  const toggleCheck = (index) => {
    setCheckedItems((prevCheckedItems) =>
      prevCheckedItems.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className='accountsmain'>
        <NewHeader title="Language" addicon="none"/>
        <div className='languagediv'>
            <div className="card">
                <div className="card-body">
                    {languageData.map((item, index) => (
                        <div className='indiceslist' key={item.id} onClick={() => toggleCheck(index)}>
                            <div className='rightlist'>
                                    <span style={{ marginRight: '10px' }}>
                                    {/* {checkedItems[index] ? '✅' : ''}              */}
                                        {checkedItems[index] ? <div><img src={checked}/></div> : ''}
                                    </span>
                            </div>
                            <div className='leftlist'>
                                <p>{item.language}</p>
                            </div>                        
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Changelanguage;
