import React from 'react'
import './Mobileverification.scss'
import { useState } from 'react';
import close from '../../Images/close.png';
import Modal from 'react-bootstrap/Modal';
import NewHeader from '../Header/NewHeader'
function Mobileverification() {
    const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Modal.Header className='cusheader'>
        <div className='left'>
          <button onClick={() => setShow(false)}> <img src={close} alt="close" /> </button>
        </div>

      </Modal.Header>
      <Modal.Body>
      <div className=''>
       <div className='mobileverificaton-container accountsmain'>
       <NewHeader title='Back' addicon='none'/>

       <h1 className='mobile-number-heading'>New phone number</h1>
       <div className='new-mobile-number-container'>
       <p>New phone number</p>
       <input type='number'className='type-number' placeholder='+91'/>
       <button className='mobile-code-send'>Send me a code</button>
       </div>
       </div>
       
       </div> 
      </Modal.Body>
    </>
   
  )
}

export default Mobileverification
