export function formatNumber(num) {
    
    if (typeof num !== 'number') {
      num=Number(num);
      if(isNaN(num)){
        // console.log('input must be a number');
        return 0;
      }
    }
  
    if (Number.isInteger(num)) {
      return num; // Return integer as it is
    }
  
    const decimalPart = num.toString().split('.')[1];
  
    if (decimalPart && decimalPart.length > 4) {
      return parseFloat(num.toFixed(4)); // Return number with 4 decimal places
    }
  
    return num; // Return the floating-point number as it is
  }

  export function getCurrencySymbol(selectedCurrency){
    return selectedCurrency === "INR" ? "₹" : "$";
  };

  export function getCurrencySymbol2(exchange){
    return exchange === "MCX" || exchange === "NFO" ? "₹" : "$";
  };

 export const convertToIndianTime=(timestamp)=>{
      const date = new Date(timestamp);
      date.setMinutes(date.getMinutes() + 330); // UTC se IST shift karne ke liye +5:30 add karna padega
  
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month 0-based hota hai
      const dd = String(date.getDate()).padStart(2, '0');
      const hh = String(date.getHours()).padStart(2, '0');
      const min = String(date.getMinutes()).padStart(2, '0');
      const ss = String(date.getSeconds()).padStart(2, '0');
  
      return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
    }