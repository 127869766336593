// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import { authReducer } from './Redux/reducers/authReducer';
import { userReducer } from './Redux/reducers/userReducer';
import { orderReducer } from './Redux/reducers/orderReducer';
import { tradeReducer } from './Redux/reducers/tradeReducer';
import socketReducer from './Redux/reducers/socketReducer';


// Combine your reducers (in case you have more in the future)
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  order: orderReducer,
  trade: tradeReducer,
  sockets: socketReducer,
});

// Create Redux store with thunk middleware for handling async actions
const store = createStore(
  rootReducer, 
  {}, // initial state (optional if reducers handle this)
  applyMiddleware(thunk)
);

export default store;

