import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlltrade } from '../../Redux/actions/tradeAction.js';
import { getUserToken } from '../../helper/getToken.js';
import { useNavigate } from 'react-router-dom';
import SortingDrawer from '../Bottomdrawer/SortingDrawer.jsx';
import { getCurrencySymbol2 } from '../../helper/commonFunctions.js';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';

const All = ({ currency,alert }) => {
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [displayedData, setDisplayedData] = useState([]); // Append data for all pages
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const token = getUserToken(); // Fetch token
  const dispatch = useDispatch();
  const { alltrades, loading, error } = useSelector((state) => state.trade);
  const [issetalert,setIssstAlert] = useState(false);
  const navigate = useNavigate();
  const [selectedMoverData,setSelectedMoverData]=useState(null);
  // console.log("alertalertalertalertalertalertalert",alert);

  // Fetch data whenever the page changes
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await dispatch(fetchAlltrade(token, currentPage));

          const trades = alltrades?.data || []; // Extract the trades from the payload
          const meta = alltrades?.meta || {}; // Extract pagination details
          const { total_pages } = meta;

          // Update state with new data and pagination details
          setDisplayedData((prevData) => [...prevData, ...trades]); // Append new items
          setTotalPages(total_pages); // Set total pages
        } catch (err) {
          console.error('Error fetching trades:', err);
        }
      }
    };
    fetchData();
  }, [dispatch, token, currentPage]); // Refetch only when token or page changes

  // Function to format numbers (e.g., to avoid excessive decimal points)
  function formatNumber(input) {
    try {
      const num = parseFloat(input);
      if (isNaN(num)) {
        throw new Error("Invalid number");
      }
      if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
        return parseFloat(num.toFixed(6));
      }
      return num;
    } catch (err) {
      return 0;
    }
  }

  // Price getter function
  const getPrice = (trade) => {
      const livePrice = displayedData[trade.trading_symbol] || displayedData[trade.token] || {};
      if (livePrice?.bid_price && livePrice?.bid_price !== 0) {
          return formatNumber(livePrice?.bid_price);
      } else if (livePrice?.ltp && livePrice?.ltp !== 0) {
          return formatNumber(livePrice?.ltp);
      } else if (trade?.price && trade?.price !== 0) {
          return formatNumber(trade?.price);
      } else if (livePrice?.avg_price && livePrice?.avg_price !== 0) {
          return formatNumber(livePrice?.avg_price);
      }
      return "N/A";
  };

  // Load more data when "Load More" is clicked
  const loadMoreData = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment page
    }
  };

  // Handle navigation to stock details
  const handleTradeClick = (trade) => {
   if(!alert){
    navigate('/stockdetails', {
      state: {
        idtoken: trade.symbol,
        exchange: trade.exchange,
        moverData: trade,
      },
    });
   }else{
      setIssstAlert(true);
      setSelectedMoverData(trade)      
   }
  };

  // Render individual trade card
  const renderTradeCard = (trade, index) => (  
    
    <div className="card mb-2" key={index} onClick={() => handleTradeClick(trade)}>
      <div className="card-body">
        <div className="topdiv">
          <div className="text-start">
            <b>{trade?.symbol}</b>
          </div>
          <div className="text-start">
            <b></b>
          </div>
          <div className="text-end">
            <p>
              <b>{getCurrencySymbol2(trade?.exchange)}{getPrice(trade)}</b>
            </p>
          </div>
        </div>
        <div className="bottomdiv">
          <div className="text-start">
            <p>{trade?.trading_symbol || trade?.expiry || 'No Expiry'}</p>
          </div>
          <div className="text-end">
            <span style={{ color: 'green' }}>
              <i className="fa fa-arrow-up" aria-hidden="true"> </i>
              {(Number(trade?.price_change || 0).toFixed(2))} ({(Number(trade?.percentage_change) || 0).toFixed(2)}%)
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}
      <SortingDrawer />
      {displayedData.length > 0
        ? displayedData.map((trade, index) => renderTradeCard(trade, index))
        : <p>No symbols available</p>}

      {/* Load More Button */}
      {currentPage < totalPages && (
        <button onClick={loadMoreData} className="btn btn-primary mt-3">
          {
            loading ? <p>Loading ...</p> : <p>Load more</p>
          }
        </button>
      )}
      <AlertsetDrawer
        issetalert={issetalert}
        moverData={selectedMoverData} 
        onClose={() => setIssstAlert(false)}
        />
    </div>
  );
};

export default All;
