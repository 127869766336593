import React, { useState } from 'react';
import closebtn from '../../Images/close-button.png';
import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isAuthenticated } from '../../helper/authToken';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const Otpmodal2 = ({ isModalVisible3, setModalVisible1, setModalVisible2,setModalVisible3, email,mobile, setForgotkey,forgot_key }) => {
    const [message, setMessage] = useState('');
    const [loadingstate, setLoadingState] = useState(false);
  
    const navigate = useNavigate();
    
    // Validation schema using Yup
    const validationSchema = Yup.object({
        otp: Yup.string()
            .min(4, 'OTP must be at least 4 characters long')
            .matches(/^[0-9]+$/, 'OTP must contain only numbers')
            .required('OTP is required'),
    });

    const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
    
    
    // const encryptData = (data) => {
    //     return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    // };
     const encryptData = (data) => {
        if (!ENCRYPTION_KEY) {            
            return null;
        }    
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), 
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString();
    };
    
    // const formik = useFormik({
    //     initialValues: {
    //         otp: "",
    //     },
    //     validationSchema,
    //     onSubmit: async (values, { resetForm }) => {
    //         const formData = { email, otp: encryptData(values.otp),contact:mobile }; 

    //         try {
    //             setLoadingState(true);
    //             let response = await axios.post(`${baseUrl}verifyPassword`, formData);
                
    //             if (isAuthenticated() && response?.data?.status === "SUCCESS") {
    //                 const forgotKey = response?.data?.payload?.forgot_key;                    
    //                 navigate('/changepassword', { state: { email, forgotKey } });
    //             } else {
    //                 setLoadingState(false);
    //                 if (response?.data?.status === "SUCCESS") {
    //                     const forgotKey = response?.data?.payload?.forgot_key;
    //                     console.log("inside res[onse")
    //                     setForgotkey(forgotKey);
    //                     resetForm();
    //                     setMessage("");
    //                     setModalVisible1(false);  // Close OTP modal
    //                     // setModalVisible2(true);   // Open Reset Password modal
    //                     setModalVisible2(true); 
    //                 } else {
    //                     setMessage(response?.data?.message);  // Display error message
    //                 }
    //             }
    //         } catch (error) {
    //             console.log(error);
    //             setLoadingState(false);
    //             setMessage("Something went wrong, please try again later.");  // Handle network or server errors
    //         }
    //     },
    // });

    const formik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = { email, otp: encryptData(values.otp), contact: mobile };
    
            try {
                setLoadingState(true);
                let response = await axios.post(`${baseUrl}verifyPassword`, formData);
    
                if (response?.data?.status === "SUCCESS") {
                    const forgotKey = response?.data?.payload?.forgot_key;
                    setForgotkey(forgotKey);  // Save the forgot_key for reset password
                    resetForm();
                    setMessage("");
    
                    // Close OTP modal before opening Reset Password modal
                    setModalVisible3(false);  
                    setModalVisible2(true);  
    
                } else {
                    setMessage(response?.data?.message || "Invalid OTP, please try again."); 
                }
            } catch (error) {
                console.error(error);
                setLoadingState(false);
                setMessage("Something went wrong, please try again later.");
            }
        },
    });
    

    if (!isModalVisible3) return null;

    return (
        <div className="modal-overlay" style={{ backgroundColor: "#151514a8" }}>
            <div className="modal" style={{ display: "block", background: "rgba(21, 21, 20, 0.66)" }}>
                <div className='cusmodal2'>
                    <button className="close-btn" onClick={() => setModalVisible1(false)}>
                        <img src={closebtn} alt="Close" width="10px" />
                    </button>
                    <div>
                        <h2 className='text-center mb-4'>Enter OTP</h2>
                        <form onSubmit={formik.handleSubmit}>
                            <input
                                type='text'
                                name='otp'
                                className={`form-control h-40 ${formik.touched.otp && formik.errors.otp ? 'is-invalid' : 'mb-3'}`} // Conditionally add the invalid class
                                placeholder='Enter OTP'
                                value={formik.values.otp}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {/* Display validation error */}
                            {formik.touched.otp && formik.errors.otp && (
                                <div className="invalid-feedback">{formik.errors.otp}</div>
                            )}

                            {/* Display API response message */}
                            {message && <p style={{ color: "green" }}>{message}</p>}

                            <button type='submit' className='form-control' disabled={loadingstate}>
                                {loadingstate ? (
                                    <>Submitting <span className='spinner'></span></>
                                ) : (
                                    "Submit OTP"
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otpmodal2;
