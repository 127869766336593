import React, { useState } from 'react';
import NewHeader from '../Components/Header/NewHeader';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Swal from 'sweetalert2';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios'; // Ensure axios is imported
import { useLocation, useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const Changepassword = () => {
    const [showPassword, setShowPassword] = useState(false);  
    const [loadingstate,setLoadingState] = useState(false);
    const [isModalVisible2, setModalVisible2] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const location = useLocation();    
    const { email,forgotKey} = location.state || {};

   

    const validationSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[!@#$%^&*]/, 'Password must contain at least one special character')
            .required('Password is required'),
    });

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: "",  // Formik state for password
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const formData = {
                email:email,  // Ensure email is available in the scope
                forgot_key:forgotKey,  // Ensure forgot_key is available in the scope
                password: values.password,
            };
            

            try {
                setLoadingState(true);
                let response = await axios.post(`${baseUrl}resetPassword`, formData);
                if (response?.data?.status === "SUCCESS") {
                    setLoadingState(false);
                    Swal.fire({
                        width: "20rem",
                        title: 'Password reset success.',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            title: 'swaltext',
                        },
                    });
                    resetForm();
                    setModalVisible2(false);
                    navigate('/signin'); 
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response?.data?.message,
                        timer: 6000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        customClass: {
                            title: 'swaltext',
                        },
                    });
                }
            } catch (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later.',
                });
            }
        },
    });

    // Return null if modal is not visible, ensure `isModalVisible2` is defined or remove this check
    // if (!isModalVisible2) return null;

    return (
        <div className='accountsmain'>
            <NewHeader title="Password change" addicon="none" />       
            <div className='passworddivmain'>
                <form className='w-100' onSubmit={formik.handleSubmit}>
                    <p>Trading password is a password you use to log in to Meta Trader.</p>
                    <small className='mb-2'>Create a new trading password </small>
                    <div className="form-group passworddiv">
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                id="password"
                                placeholder="Enter your password"
                                value={formik.values.password}
                                onChange={formik.handleChange}  // Formik change handler
                                onBlur={formik.handleBlur}      // Formik blur handler
                            />
                            <div className="input-group-append">
                                <span
                                    className="input-group-text"
                                    onClick={toggleShowPassword}
                                    style={{ cursor: 'pointer' }}>
                                    <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                </span>
                            </div>
                        </div>
                        {formik.errors.password && formik.touched.password && (
                            <div className="text-danger">{formik.errors.password}</div>
                        )}
                    </div>
                    <div>
                        <ul className='list-style-circle'>
                            <li>Use from 8 to 15 characters</li>
                            <li>Use both uppercase and lowercase letters</li>
                            <li>Use a combination of numbers, English letters, and supported special characters</li>
                        </ul>
                    </div>
                    <p>Save your trading password right away as it can't be sent to your email due to security purposes.</p>
                    <div className="footerbuttonfixed">
                        <button type="submit" disabled={!formik.isValid || formik.isSubmitting}>Continue</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Changepassword;
