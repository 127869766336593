import React, { useEffect, useRef } from 'react'

const Protectiondrawer = ({isprotectionDrawerOpen,setIsProtectionDrawerOpen,protecction}) => {
    const drawerRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setIsProtectionDrawerOpen(false);
        }
        };
        if (isprotectionDrawerOpen) {
        document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isprotectionDrawerOpen, setIsProtectionDrawerOpen]);


  return (
    <div>
        <div ref={drawerRef} className={`drawer ${isprotectionDrawerOpen ? "open" : ""}`}>
            <div className="drawer-content">                
                {
                    protecction === "stopprotection" &&(
                        <div>
                            <div className='linemain'>
                                <div className='linediv'></div>
                            </div>
                            <div className='text-start'>
                                <p>Stop out Protection helps delay and sometimes completely avoid stop outs.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim. In dui erat, laoreet at mauris id, fermentum scelerisque ante.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim.</p>
                                <div className='btndiv'>
                                    <button>Ok</button>
                                    <button>Open Help center</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    protecction === "swapfree" &&(
                        <div>
                            <div className='linemain'>
                                <div className='linediv'></div>
                            </div>
                            <div className='text-start'>
                                <p>Stop out Protection helps delay and sometimes completely avoid stop outs.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim. In dui erat, laoreet at mauris id, fermentum scelerisque ante.</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim.</p>
                                <div className='btndiv'>
                                    <button>Ok</button>
                                    <button>Open Help center</button>
                                </div> */}
                            </div>
                        </div>
                    )
                }
                {
                    protecction === "negativebalance" &&(
                        <div>
                            <div className='linemain'>
                                <div className='linediv'></div>
                            </div>
                            <div className='text-start'>
                                <p>Stop out Protection helps delay and sometimes completely avoid stop outs.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim. In dui erat, laoreet at mauris id, fermentum scelerisque ante.</p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra nisl commodo nisl maximus dignissim.</p>
                                <div className='btndiv'>
                                    <button>Ok</button>
                                    <button>Open Help center</button>
                                </div> */}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default Protectiondrawer