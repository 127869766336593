import React, { useEffect, useState, Suspense, lazy } from 'react';
import '../TopCard/Topcard.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import sortingimg from '../../Images/sorting.png';
import Listorder from './Listorder';
import { useDispatch, useSelector } from 'react-redux';
import { getUserToken } from '../../helper/getToken';
import { fetchOrder } from '../../Redux/actions/orderAction';
import { fetchTrade } from '../../Redux/actions/tradeAction';
import { closeDrawer, fetchUserPosition } from '../../Redux/actions/userAction';

// Lazy-loaded components
const TradeListOpenorder = lazy(() => import('./TradeListOpenorder'));
const Listorderexecuted = lazy(() => import('./Listorderexecuted'));

const Accounttab = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [tabName,setTabName]=useState('open');
   const handleSelectOrderTab=(tabName)=>{
    setTabName(tabName)
    localStorage.setItem('ordertabname',tabName);
  }
  const dispatch = useDispatch();
  const token = getUserToken();

  const { order, loading, error } = useSelector((state) => state.order);
  const { trade } = useSelector((state) => state.trade);
  const { position } = useSelector((state) => state.user);
  

  useEffect(() => {
    if (token) {
      dispatch(fetchOrder(token));
      dispatch(fetchTrade(token));
      dispatch(fetchUserPosition(token));
    }
  }, [dispatch, token]);

 useEffect(()=>{
  const activeTabOrders=localStorage.getItem('ordertabname')
  if(activeTabOrders){
    handleSelectOrderTab(activeTabOrders)
  }
 })

  return (
    <div className='tabdiv'>
      <nav >
        <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
          <button onClick={()=>handleSelectOrderTab('open')} className={tabName=='open'?'nav-link active':'nav-link'} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected={tabName=='open'?'true':'false'}>
            Open
          </button>
          <button onClick={()=>handleSelectOrderTab('pending')} className={tabName=='pending'?'nav-link active':'nav-link'} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected={tabName=='pending'?'true':'false'}>
            Pending
          </button>
          <button onClick={()=>handleSelectOrderTab('close')} className={tabName=='close'?'nav-link active':'nav-link'} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected={tabName=='close'?'true':'false'}>
            Closed
          </button>
        </div>
      </nav>

      <div className="tab-content p-1" id="nav-tabContent">
        <div className={tabName=='open'?"tab-pane fade active show":"tab-pane fade"} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <Suspense fallback={<div>Loading Open Orders...</div>}>
            <TradeListOpenorder setCurrencyForMargin={() => {}} data={position} loading={loading} error={error} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
          </Suspense>
          <div style={{ marginBottom: '70px' }}></div>
        </div>

        <div className={tabName=='pending'?"tab-pane fade active show":"tab-pane fade"} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <Listorder data={order} />
          <div style={{ marginBottom: '70px' }}></div>
        </div>

        <div className={tabName=='close'?"tab-pane fade active show":"tab-pane fade"} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
          <Suspense fallback={<div>Loading Executed Orders...</div>}>
            <Listorderexecuted data={trade} />
          </Suspense>
          <div style={{ marginBottom: '70px' }}></div>
        </div>
      </div>

     
    </div>
  );
};

export default Accounttab;
