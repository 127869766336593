import React from 'react'
import Header from '../Components/Header/Header'
import Accountmiddlecard from '../Components/AccountMiddle/Accountmiddlecard'
import Footer from '../Components/Footer/Footer'
import Tradetab from '../Components/Tradetab/Tradetab'

const Tradepages = () => {
  return (
    <div className='accountsmain'>
        <Header props="Trade" icon="block" settitng="none"/>
          <div className='topmovermain2'>
              <Accountmiddlecard  propdata="none" newprop="block"/>
              <Tradetab/>
          </div>
        <Footer/>
    </div>
  )
}

export default Tradepages