import { useEffect } from "react";

function AntiInspect() {
  useEffect(() => {
    const checkDevTools = setInterval(() => {
      let before = performance.now();
      debugger; // If DevTools is open, it will take longer to execute this line
      let after = performance.now();
      
      if (after - before > 100) {
        alert("DevTools detected! Access denied.");
        window.location.href = "https://google.com"; // Redirect or freeze
      }
    }, 1000);

    return () => clearInterval(checkDevTools);
  }, []);

  return null;
}

export default AntiInspect;
