import React from 'react'
import NewHeader from '../Header/NewHeader'
import img1 from '../../Images/warning-2.png'
import speedimg from '../../Images/speed.svg'
import stabilityimg from '../../Images/stability.svg'
import hoursimg from '../../Images/24hourstrading.svg'
import portabilityimg from '../../Images/mobility.svg'

import './Privateserver.scss'
function Privateserver() {
    const privateServer = [
        {
            img: `${speedimg}`,
            heading: 'Speed',
            description: 'must have an alt prop, either with  text, or an emptyges we wergth '
        },
        {
            img: `${stabilityimg}`,
            heading: 'Stability',
            description: 'must have an alt prop, either with  text, or an emptyges we wergth  '
        },
        {
            img: `${hoursimg}`,
            heading: '24-hour trading',
            description: 'must have an alt prop, either with  text, or an emptyges we wergth  ',
        },
        {
            img: `${portabilityimg}`,
            heading: 'Mobility & portability',
            description: 'must have an alt prop, either with  text, or an emptyges we wergth  '
        }
    ]
    return (
        <div className='accountsmain'>
            <NewHeader title='Virtual Private Server'  addicon="none"/>
            <div className='topmovermain2'>
            <div className='privateserverdiv'>
                <div className='private-header mt-1'>
                    <p className='private-header-heading mt-4'>Virtual Private Server</p>
                    <p>Line 25:59:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images</p>
                </div>

            <div className='privater-server-block mt-5'>
                <div className='privater-server-container mt-4'>
                    <div className='privater-server-container-img'>
                        <img src={img1} alt=''></img>
                    </div>
                    <div className='privater-server-container-title'>
                        <span> You do not currently quality of a free VPS  </span>
                    </div>
                </div>
                <p className='privte-p-1 mt-1'> meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 27:65:  img elements must have an alt prop, either with meanin</p>

                <div className='private-server-number-1'>1</div>

                <p className='privte-p-1 mt-1'> meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 27:65:  img elements must have an alt prop, either with meanin  jsx-a11y/alt-text
                    Line 27:65:  img elements must have an alt prop, either with
                </p>

                <p className='balance-rquired'>Balance rquired  <span className=' mt--4'> 2,000 USD</span></p>

                <div className="privateserer-progress-container mt-2">
                    <div className="progress-bar"></div>
                </div>
                <div className='progress-bar-description'>
                    <p> 0 USD</p>
                    <p> 2,000 USD</p>
                </div>

                <div className='private-server-number-2'>OR</div>
                <div className='private-server-number-3'>2</div>

                <p className='privte-p-1 mt-1'> meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 27:65:  img elements must have an alt prop, either with meanin  jsx-a11y/alt-text
                    Line 27:65:  img elements must have an alt prop, either with
                </p>

                <p className='balance-rquired'>Balance rquired  <span className=' mt--4'> 500 USD</span></p>

                <div className="privateserer-progress-container mt-2">
                    <div className="progress-bar"></div>
                </div>
                <div className='progress-bar-description'>
                    <p> 0 USD</p>
                    <p className='usd-500'>500 USD</p>
                    <p> 2,000 USD</p>
                </div>

                <p className='balance-rquired'>Trading Volume required
                    <span className=' mt--4'>1,500,000 USD</span></p>
                <div className="privateserer-progress-container mt-2">
                    <div className="progress-bar"></div>
                </div>
                <div className='progress-bar-description'>
                    <p> 0 USD</p>
                    <p> 1,500,000 USD</p>
                </div>
            </div>
            {
                privateServer.map((privateserver, index) => {
                    return (
                        <div key={index} className='pvt-svr-block mt-5'>
                            <div className='pvt-svr-block-img'>
                                <img src={privateserver.img} alt=''></img>
                            </div>
                            <div className='pvt-svr-block-heading mt-3'>
                                <h3>{privateserver.heading}</h3>
                            </div>
                            <p> {privateserver.description}</p>
                        </div>
                    )
                })
            }
            </div>
            </div>
        </div>
    )
}

export default Privateserver
