import React, { useState } from 'react'
import leftarrow from '../../Images/chevronleft.svg';
import Modal from 'react-bootstrap/Modal';

const Searchheader = ({ setShow }) => {
  // const [show, setShow] = useState(false);
  const handleClose = ()=>{
    setShow(false)
  }
  return (
    <div className='searcheader'>
        <Modal.Header className='cusheader' style={{padding:"10px 10px"}}>
            <div className='left'>
            <button onClick={handleClose} > <img src={leftarrow} alt="back"/> </button>
            <Modal.Title>
                {/* <input type='search' placeholder='Search' className='form-control cusformctrl'/> */}
                Search
            </Modal.Title>
            </div>       
      </Modal.Header>
    </div>
  )
}

export default Searchheader