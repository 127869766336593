import React from 'react'
import './Aouthenticationapp.scss'
function Aouthenticationpp() {
  return (
    <div>
      this is aouthentication app pages
    </div>
  )
}

export default Aouthenticationpp
