import gbpusd from './Images/gbpusd.svg'
import usdjpy from './Images/usdjpy.svg'
import ustech from './Images/ustech.svg'
import eurusd from './Images/eurusd.svg'

const  tradeData = [
    {
      id:1,
      image:`${gbpusd}`,
      coinname:"GBP/USD",
      graphdata:"------",
      price:"1.31716",
      desc:"Great Bretain Pound vs US Doller",
      movepercent:"0.56",
      color:"#3498db"
    },
    {
      id:2,
      image:`${usdjpy}`,
      coinname:"USD/JPY",
      graphdata:"------",
      price:"145.009",
      desc:"US Doller vs Japanese Yen",
      movepercent:"0.03",
      color:"#3498db"
    },
    {
      id:3,
      image:`${ustech}`,
      coinname:"USTEC",
      graphdata:"------",
      price:"19436.13",
      desc:"US Tech 100 Index",
      movepercent:"0.56",
      color:"#3498db"
    },
    {
      id:4,
      image:`${eurusd}`,
      coinname:"ABBV",
      graphdata:"------",
      price:"194.89",
      desc:"AbbVie Inc.",
      movepercent:"0.06",
      color:"#3498db"
    },
    
  ]

  export default tradeData;
  