import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWatchList } from '../../Redux/actions/tradeAction.js';
import { getUserToken } from '../../helper/getToken';
import Stockdetails from '../Bottomdrawer/Stockdetails.js';
import { connectPriceSocket } from '../../Redux/actions/socketActions.js';
import { useNavigate } from 'react-router-dom';
import { formatNumber, getCurrencySymbol2 } from '../../helper/commonFunctions.js';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';

const Favoriatecard = ({ currency,alert }) => {
  const {watchlist, loading, error } = useSelector((state) => state.trade);
  const {priceSocket } = useSelector((state) => state.sockets);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [issetalert,setIssstAlert] = useState(false);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({});
  const currentPositions = watchlist?.payload?.[currency] || [];


  const dispatch = useDispatch();
  const token = getUserToken();
  const navigate = useNavigate();
  // useEffect(() => {
  //     dispatch({ type: 'SET_ACTIVE_TAB', payload: "top-looser" });
  //   }, [ dispatch]);

  useEffect(() => {
    // Fetch watchlist and establish socket connection
    const fetchWatchlistdata = async () => {
      if (token) {
        try {
          await dispatch(fetchWatchList(token));
        } catch (error) {
          console.error('Failed to fetch watchlist data:', error);
        }
      }
    };
    fetchWatchlistdata();
    dispatch(connectPriceSocket());
  }, [dispatch, token]);

  useEffect(() => {
    if (!currentPositions.length || !priceSocket) return;
    // Subscribe to price updates for each position
    currentPositions.forEach((instrument) => {
      const event =
        instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;
      const emitData =
        instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
          ? instrument.symbol.toLowerCase()
          : instrument.token;

      priceSocket.emit('tokenData', emitData);
      priceSocket.on(event, (updatedInstrument) => {
        setLivePrices((prevState) => ({
          ...prevState,
          [emitData]: updatedInstrument,
        }));
      });
    });

    // Cleanup socket listeners
    return () => {
      currentPositions.forEach((instrument) => {
        const event =
          instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
            ? `forexRoom${instrument.symbol.toLowerCase()}`
            : `room${instrument.token}`;
        priceSocket.off(event);
      });
    };
  }, [currentPositions, priceSocket]);

  

  const handleClick = (moverData) => {    
    if(!alert){
        if (moverData.exchange === 'FOREX' || moverData.exchange === 'US' || moverData.exchange === 'CRYPTO') {
          navigate('/stockdetails', {
            state: {
              idtoken: moverData.symbol,
              exchange: moverData.exchange,
              moverData:moverData
            },
          });
        } else if (moverData.exchange === 'MCX' || moverData.exchange === 'NFO') {
          setSelectedMoverData(moverData);
          setIsDrawerOpen(true);
        } else {
          console.error("Exchange value is undefined or invalid.");
        }
    }else{
      setSelectedMoverData(moverData);
      setIssstAlert(true);      
    }
  };

  const getPrice = (watchlist, liveData) => {
    
    if (liveData?.ltp && liveData?.ltp !== 0) {
      return liveData?.ltp;
    } else if (liveData?.bid_price && liveData?.bid_price !== 0) {
      return liveData?.bid_price;
    }
    if (watchlist?.ltp && watchlist?.ltp !== 0) {
      return watchlist?.ltp;
    } else if (watchlist?.bid_price && watchlist?.bid_price !== 0) {
      return watchlist?.bid_price;
    } else if (watchlist?.price && watchlist?.price !== 0) {
      return watchlist?.price;
    } else if (watchlist?.ask_price && watchlist?.ask_price !== 0) {
      return watchlist?.ask_price;
    } else {
      return 0;
    }
  };

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  

 

  const renderFavorite = (tradeList) => {
    // console.log("tradeListtrade======render",tradeList);
    return tradeList.map((watchlist, index) => {
      // console.log("watchlistwatchlistwatchlistwatchlistwatchlistwatchlist",watchlist);
      const liveData = livePrices[watchlist?.symbol?.toLowerCase()] || livePrices[watchlist?.token] || {}; // Use live data if available
      // console.log("liveDataliveDataliveDataliveDataliveData======",liveData);
      const askPrice = getPrice(watchlist, liveData);
      const movePercent = liveData.percentage_change || watchlist?.percentage_change;  
      
      // console.log("askPriceaskPriceaskPriceaskPrice",askPrice);

      return (
        <div className="card mb-2" key={index} onClick={() => handleClick(watchlist)}>
          <div className="card-body" style={{cursor:"pointer"}}>
            <div className="topdiv">
              <div className="text-start">
                <b>{watchlist?.symbol}</b>
              </div>
              <div>
                <p>{watchlist?.graphdata}</p>
              </div>
              <div className="text-end">
                <p style={{ color: movePercent && movePercent > 0 ? 'green' : 'red' }}>
                  <b>{getCurrencySymbol2(watchlist.exchange)} {formatNumber(askPrice)}</b>
                </p>
              </div>
            </div>
            <div className="bottomdiv">
              <div className="text-start">
                <p>{watchlist?.expiry? watchlist.expiry : formattedDate}</p>
              </div>
              <div className="text-end">
                <span style={{ color: movePercent && movePercent > 0 ? 'green' : 'red' }}>
                {/* {getCurrencySymbol2(watchlist.exchange)} */}
                  {movePercent && movePercent > 0 ? (
                    <i className="fa fa-arrow-up" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                  )}{' '}
                  {movePercent} %
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderCurrencyTrades = (currency) => {
    const trades = watchlist?.payload?.[currency];
    // console.log(currency,watchlist?.payload,'kkkkkkkkkkkkkkkkkkkkkkkkjjjjjjjjjjjjjjjjjjjjjggggggggggggggggggggggggddddddddddddddd')
    if (trades?.length > 0) {
      return renderFavorite(trades);
    } else {
      return <p>No {currency} favourite symbol available</p>;
    }
  };

  

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}
      {renderCurrencyTrades(currency)}
      {selectedMoverData && (
        selectedMoverData.exchange === 'MCX' || selectedMoverData.exchange === 'NFO' ? (
          <Stockdetails
            isDrawerOpen={isDrawerOpen}
            moverData={selectedMoverData}
            onClose={() => setIsDrawerOpen(false)}
          />
        ) : null
      )}

        <AlertsetDrawer
            issetalert={issetalert}
            moverData={selectedMoverData}
            onClose={() => setIssstAlert(false)}
          />
    </div>
  );
};

export default Favoriatecard;
