import React, { useState } from 'react';
import './Bottomdrawer.css';
import checkedimg from '../../Images/checked.png';
import arrow from "../../Images/arrow-up-down.svg"

const tradingTerminalData = [
    { id: 1, title: 'Popularity' },
    { id: 2, title: 'Ticker ↑' },  
    { id: 3, title: 'Ticker ↓' },
    { id: 4, title: 'Daily Change ↑' },
    { id: 5, title: 'Daily Change ↓' },
  ];

const SortingDrawer = ({setDrawer}) => {

  const [checked, setChecked] = useState("1")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
   <div className='sorting-drawer'>
    <div className='sort-icon-div' onClick={()=>{setIsDrawerOpen(!isDrawerOpen)}}><img src={arrow} alt="" /> <span>Sorted by {tradingTerminalData[checked-1]?.title}</span></div>
     <div className={`drawer ${setDrawer == false ? '' :  isDrawerOpen ? 'open':"" }`}>
      <div className="drawer-content">
         <div className="tradingdiv mt-0">
            {/* <h2 className='text-start mb-3'>Select period</h2> */}
            <div className="card" style={{border:"0", padding:"0"}}>
              <div className="card-body" style={{padding:"0"}}>
                {tradingTerminalData.map((tradedata, index) => (
                  <div className="indiceslist" key={tradedata.id} onClick={() => setChecked(tradedata.id)} >
                    <div className="leftlist">
                      <p>{tradedata.title}</p>
                    </div>
                    <div className="rightlist">
                      <span style={{ marginRight: '10px' }}>
                        { tradedata.id == checked && (
                          <img src={checkedimg} alt="checked" />
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
    
      
     


      </div>
    </div>
   </div>
  );
};

export default SortingDrawer;
