// components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './authToken';

const ProtectedRoute = ({ children }) => {
  
  if (!isAuthenticated()) {
    // Redirect to login if not authenticated
    // return <Navigate to="/signin" />;
    return <Navigate to="/" />;
    
  }
  return children;
};


export default ProtectedRoute;
