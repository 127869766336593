import React, { useEffect, useState } from 'react';
import NewHeader from '../Components/Header/NewHeader';
import { getUserToken } from '../helper/getToken';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_FRONTEND;

const LedgerPage = () => {
    const [selectedCurrency, setSelectedCurrency] = useState("INR");
    const [ledgerdata, setLedgerData] = useState({});

    const handleSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
    };

    useEffect(() => {
        const fetchLedgerData = async () => {
            let token = getUserToken();
            console.log("Token being sent:", token);

            try {
                const response = await axios.get(`${baseURL}client/ledgerMaster`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log("API Response:", response.data);

                // Ensure we store an object and not an array
                setLedgerData(response?.data?.payload || {});

            } catch (error) {
                console.error("Error fetching ledger data:", error);
                if (error.response) {
                    console.error("Server Response:", error.response.data);
                }
            }
        };

        fetchLedgerData();
    }, []);

    return (
        <div className='accountsmain'>
            <NewHeader title='Ledger' addicon='none' />
            <div className="accountsmain">
                <div className='acctabbtndiv'>
                    <div>
                        {['INR', 'USD'].map((currency) => (
                            <button
                                key={currency}
                                className={selectedCurrency === currency ? 'active' : ''}
                                onClick={() => handleSelectCurrency(currency)}
                            >
                                {currency}
                            </button>
                        ))}
                    </div>
                </div>

                {ledgerdata[selectedCurrency] && Array.isArray(ledgerdata[selectedCurrency]) ? (
                    ledgerdata[selectedCurrency].map((item, index) => (
                        <div className="card mb-1 p-0" key={index}>                           
                            <div className="card-body p-1">
                                <div className="d-flex justify-content-between">
                                    <p className="mb-1">{item.description}</p>
                                    <p className="mb-1">{item.added_on}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="mb-1"> <strong>{item.amount > 0 ? "Credit" : "Debit"}</strong> </p>
                                        <p className="mb-1" style={{ color: item.amount > 0 ? "green" : "red" }}>
                                            <i className={`fa ${item.amount > 0 ? "fa-arrow-down" : "fa-arrow-up"} `}></i> 
                                            {" "} {Number(item.amount).toFixed(2)}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-1 text-end"><strong>Closing</strong></p>
                                        <p className="mb-1">{Number(item.closing).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No data available</p>
                )}

            </div>
        </div>
    );
};

export default LedgerPage;
