import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import rightimage from "../../Images/right.png"
import logout from '../../Images/logout.png'
import Emailverification from './Emailverification';

const Securities = () => {
    const [show, setShow] = useState(false);

  function handleShow() {
      setShow(true);
  }

  return (
    
    <div className='settingdiv'>
    <h4 className='profileheading'>SECURITY</h4>
    <div className="card">
        <div className="card-body">
            <div className="btndiv">
                <button onClick={handleShow}>
                    <div> <p>Security type</p> </div>
                    <div> <span style={{fontSize:"14px"}}>Email <img src={rightimage} width="25px"/></span> </div>
                </button>
                <button>
                    <div><p>Change passcode</p> </div>
                    <div> <span><img src={rightimage} width="25px"/></span> </div>
                </button>
                <div className='button'>
                    <div> <p>Hide screen content on recent tasks/disable screeshots</p> </div>
                    <div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>                        
                        </div>
                    </div>
                </div>
                <div className='button'>
                    <div> <p>Hide balance with a flip gesture</p> </div>
                    <div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" height="20px"/>                        
                        </div>
                    </div>
                </div>
                <button>
                    <div className='logoutdiv mt-3 mb-2'> 
                        <div>
                            {/* <img src={logout}/> */}
                            <i class="fa fa-sign-out" aria-hidden="true" style={{color: "red", fontSize: "1.3em"}}></i>
                        </div>
                        <div>
                            <p><b>Secure my account</b></p> 
                            <small style={{fontSize:"12px"}}>Logout from all devices expect this one</small>
                        </div>
                    </div>
                    <div> <span><img src={rightimage} width="25px"/></span> </div>
                </button>
            </div>
        </div>
    </div>
    <Modal className='cusmodal' show={show} onHide={() => setShow(false)}>
        <Emailverification name="" show={show} setShow={setShow} />
    </Modal>
</div>

  )
}

export default Securities