import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NewHeader from '../Header/NewHeader';
import './Markets.css';
import { useDispatch, useSelector } from 'react-redux';
import { getPrice, getUserToken } from '../../helper/getToken';
import { userTradeBuy } from '../../Redux/actions/userAction';
import { fetchAllIndianMargin } from '../../Redux/actions/tradeAction';
import { formatNumber } from '../../helper/commonFunctions';

const Buypage = () => {
    // const [selectedOption, setSelectedOption] = useState('LIMIT');
    const [selectedOption, setSelectedOption] = useState('MARKET');
    //const [qty, setQty] = useState(1);
    // const [lots, setLots] = useState(1); // Initial lot value
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    
    const { user } = useSelector((state) => state.user);
    const [livePrices,setLiveprices]=useState({})
    const {priceSocket}=useSelector(state=>state.sockets)
    const dispatch = useDispatch();
    const token = getUserToken();
    const navigate = useNavigate();
    const location = useLocation();    
    // const { currentData: moverData = {}, positionMoverdata = {} } = location.state || {};
    const { currentData = {}, moverData = {} } = location.state || {};
    const [price, setPrice] = useState(moverData?.bid_price);
    const {indianmargin,loading,error} = useSelector((state)=>state.trade)

      const calculateProfitLoss = (item) => {
            
            const livePrice = item?.ltp || item?.bid_price || item?.ask_price || item?.avg_price ;
            // console.log(currentData,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
            let factor=1;
            if(item?.type=='sell'){
                factor=-1;
            }
            const quantity = parseFloat(item?.qty);
            const avgPrice = parseFloat(item?.avg_price);
            const currentPrice = parseFloat(livePrice);
            
            const profitLoss = ((currentPrice - avgPrice) * quantity*factor);
            // console.log(item,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
            return formatNumber(profitLoss);
        };
    const handleClick = (option) => {
        setSelectedOption(option);
        // if (option === 'MARKET') {
            setPrice(moverData.ltp); // Set to market price
        // } else {
            // setPrice(''); // Clear price for limit orders
        // }
    };
    const handlePriceChange = (event) => {
        const { value } = event.target;
        if (selectedOption === 'LIMIT') {
            const regex=/^[0-9]*\.?[0-9]*$/
            if(regex.test(value)){                
                setPrice(value);
            }else{
                event.preventDefault();
            }            
        }
    };
    const handleAddQty=(e)=>{
        const value=e.target.value;
        const regex=/^[0-9]*\.?[0-9]*$/
        if(regex.test(value)){
            setQty(value);
            // const newLots=parseFloat(Number(value)/Number(moverData.lotsize)).toFixed(2)
            const newLots=parseFloat(Number(value)/Number(currentData.lotsize)).toFixed(2)

            setLots(newLots)
        }else{
            e.preventDefault();
        }
    };
    const handleAddLots=(e)=>{
        const value=e.target.value;
        const regex=/^[0-9]*\.?[0-9]*$/
        if(regex.test(value)){
            setLots(value)
            // const newQty=Number(value)*Number(moverData.lotsize);
            const newQty=Number(value)*Number(currentData.lotsize);
            setQty(newQty)
        }else{
            e.preventDefault();
        }
    };
    const handleQtyChange = (amount) => {
        const newQty=amount>0?Number(qty)+1:Number(qty)-1;
        setQty((prevQty) => amount>0?Math.max(1, Number(prevQty) + 1):Math.max(1, Number(prevQty) - 1));
        // const newLots=parseFloat(Number(newQty)/Number(moverData.lotsize)).toFixed(2);
        const newLots=parseFloat(Number(newQty)/Number(currentData.lotsize)).toFixed(2);
        
        setLots(newLots);
    };

    const handleLotsChange = (amount) => {        
        const newLots = Math.max(0, Math.round(lots + amount)); // Prevent negative lots
        setLots(newLots);    
        // const newQty = newLots * moverData.lotsize; // Calculate quantity based on lots

        const newQty = newLots * currentData.lotsize;
        setQty(newQty);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            token: currentData.token,
            price: selectedOption === 'MARKET' ? (currentData.ltp) : (price),           
            order_type: selectedOption.toLowerCase(),
            type: 'buy',
            qty: Math.floor(qty),
            from_custom_device_id: 'web',
        };

        // try {
        //     const response = await dispatch(userTradeBuy(formData, token, navigate)); 
        //     console.log("responseresponseresponseresponse=====",response);  

        //     if (response.status === 'success') {
        //         showPopupMessage(response.message,response.status, true);
        //     } 
        //     else {
        //          showPopupMessage(response.message, response.status, false);
        //     }
        // } catch (error) {
        //     console.error('Error adding funds:', error);
        //     showPopupMessage('Failed to add amount.', false);
        // }
        try {
            const response = await dispatch(userTradeBuy(formData, token, navigate)); 
            console.log("Response:", response);  
    
            if (response && response.status === 'SUCCESS') {
                localStorage.setItem('selectedcurrency','INR');
                localStorage.setItem("ordertabname",'open')
                showPopupMessage(response.message, 'SUCCESS', true);
            } else {
                showPopupMessage(response?.message || 'Trade failed.', 'error', false);
            }
        } catch (error) {
            console.error('Error executing trade:', error);
            showPopupMessage('Trade request failed.', 'error', false);
        }
    };

    // const showPopupMessage = (message,status, navigateAfter = false) => {
    //     console.log("statusstatusstatusstatusstatusstatus======",status);
    //     setPopupMessage(message);
    //     setShowPopup(true);
    //     if(status ==="success"){
    //         if (navigateAfter) {
    //             setTimeout(() => {
    //                 navigate('/accounts');
    //             }, 2000);
    //         }
    //     }else{
    //         setTimeout(() => {
    //             navigate('/buy');
    //         }, 2000);
    //     }
    // };

    const showPopupMessage = (message, status, navigateAfter = false) => {
        console.log("Popup Status:", status);
        setPopupMessage(message);
        setShowPopup(true);
    
        setTimeout(() => {
            if (status === 'SUCCESS' && navigateAfter) {
                navigate('/accounts');
            } 
            // else {
            //     // navigate('/buy');
            // }
        }, 2000);
    };

    const bodyData={
        token:moverData.token
    }
   
    useEffect(()=>{
        if(moverData&&priceSocket){
            const event=moverData?.exchange==="FOREX"||moverData?.exchange==="CRYPTO"||moverData?.exchange==="US"?`forexRoom${moverData?.symbol.toLowerCase()}` : `room${moverData?.token}`
            const emitData=moverData?.exchange==="FOREX"||moverData?.exchange==="CRYPTO"||moverData?.exchange==="US"?moverData?.symbol.toLowerCase():moverData?.token
            priceSocket.emit('tokenData',emitData);
            priceSocket.on(event,(updatedInstrument)=>{
                let typeNmae=updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;
                // console.log(typeNmae,updatedInstrument,'uuuuuuuuuuuuuuuuuurrrrrrrrrrrrrrrrrrrrrrrrr')
                setLiveprices((prevState)=>({
                    ...prevState,
                    [typeNmae]:updatedInstrument
                }))
            })
        }
        return ()=>{
            setLiveprices({})
        }
    },[priceSocket])

    useEffect(() => {
        // WebSocket logic placeholder
        // Example: openWebSocketConnection();
        dispatch(fetchAllIndianMargin(token,bodyData));
    }, [dispatch,moverData]);

    // const [qty, setQty] = useState(moverData.lotsize);   
    const [qty, setQty] = useState(moverData.qty ? moverData.qty : currentData.lotsize); 
    const buyorselllot = Number(qty / currentData.lotsize);
    

    const [lots, setLots] = useState(buyorselllot ? buyorselllot : 1); // Initial lot value  

    let intradaymargin = indianmargin?.payload?.MIS?.buy?.margin || 0;
    let overnightmargin = indianmargin?.payload?.CNC?.buy?.margin || 0;
    let typeName=moverData?.exchange ==='FOREX' || moverData?.exchange === "US" || moverData?.exchange === "CRYPTO" ? moverData?.symbol.toLowerCase() : moverData?.token;
    const liveData=livePrices[typeName]
    // console.log(liveData,'uuuuuuuuuuuuuuuuuurrrrrrrrrrrrrrrrrrrrrrrrr')
    let profitLoss=calculateProfitLoss({...moverData,...liveData})
    
    return (
        <div className='buyerdiv'>
            <NewHeader title={currentData.trading_symbol} addicon='none' />

            <div className='namediv'>
                <div className='nameflex upperdiv'>
                    <h4>{currentData.trading_symbol} ({currentData.exchange})</h4>
                    <h4>{liveData?.ltp||currentData.ltp||moverData?.ltp}</h4>
                </div>
                <div className='nameflex lowerdiv'>
                    {/* <p>Lot Size: {currentData.lotsize}</p> */}
                    <p>Lot Size: {currentData.lotsize?currentData.lotsize :moverData.lotsize}</p>
                    <p className='fontstylesmall' style={liveData?.price_change||currentData?.price_change>=0?{color:"green"}:{color:"red"}} ><b>{`${liveData?.price_change||currentData?.price_change||0} (${liveData?.percentage_change||currentData?.percentage_change||0}%)`}</b></p>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className='pricedivmain'>
                    <p>Price</p>
                    <div className='pricediv'>
                        <div className='w-50'>
                            <input
                                type='number'
                                className='form-control'
                                name='price'
                                value={selectedOption === 'MARKET' ? liveData?.ltp||currentData.ltp : price}
                                onChange={handlePriceChange}
                                disabled={selectedOption === 'MARKET'}
                                placeholder={selectedOption === 'MARKET' ? 'Market Price' : 'Limit Price'}
                            />
                        </div>
                        <div className='switcher-container'>
                            <button
                                type='button'
                                className={`switcher-button ${selectedOption === 'LIMIT' ? 'active' : ''}`}
                                onClick={() => handleClick('LIMIT')}
                            >
                                LIMIT
                            </button>
                            <button
                                type='button'
                                className={`switcher-button ${selectedOption === 'MARKET' ? 'active' : ''}`}
                                onClick={() => handleClick('MARKET')}
                            >
                                MARKET
                            </button>
                        </div>
                    </div>
                </div>
                
                <div className='qty-lots-container'>
                    <div className='qty-control'>
                        <p>Qty</p>
                        <div className='control-buttons'>
                            <button type='button' onClick={() => handleQtyChange(-1)}>-</button>
                            <input type='text' value={qty} onChange={handleAddQty} />
                            <button type='button' onClick={() => handleQtyChange(1)}>+</button>
                        </div>
                    </div>

                    <div className='lots-control'>
                        <p>Lots</p>
                        <div className='control-buttons'>
                            <button type='button' onClick={() => handleLotsChange(-1)}>-</button>
                            <input type='text' value={lots} onChange={handleAddLots} />
                            <button type='button' onClick={() => handleLotsChange(1)}>+</button>
                        </div>
                    </div>
                </div>

                <div className='balancediv'>
                    <h4>Available Balance: {user?.payload?.client_account.balance}</h4>
                </div>
                <div className='graphdiv'></div>
                <div className='intradaydiv'>                   
                    <button type='button'>Intraday: {Number((qty * getPrice(liveData||currentData)) / intradaymargin).toFixed(2)}</button>
                    <button type='button'>Overnight: {Number((qty * getPrice(liveData||currentData)) / overnightmargin).toFixed(2)}</button>
                </div>
                {moverData?.type!=null&&moverData?.type!=undefined&&<div style={{display:'flex',padding:"0 15px"}}><p>P & L : </p><strong><p style={profitLoss<0?{color:"red",marginLeft:"10px"}:{color:"green",marginLeft:"10px"}}>{profitLoss}</p></strong></div>}
                <div className='buybtn'>
                    <button type='submit'>BUY</button>
                </div>
            </form>

            {showPopup && (
                <div className='popup-overlay'>
                    <div className='popup-content'>
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Buypage;
