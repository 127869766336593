import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getUserToken } from '../../helper/getToken';

const Stockspecifications = ({exchange,stocksymbol,stocktoken}) => {
    const [specificationdata,setSpecifictionData] = useState()
    const token = getUserToken();
    const app_backend = process.env.REACT_APP_API_FRONTEND;
    const getStockSpecificationdata = async()=>{
        const bodydata={
            token:stocktoken
        }
        try {
           let response =  await axios.post(`${app_backend}client/market/specification`,bodydata,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
           })
           setSpecifictionData(response?.data?.payload);           
        } catch (error) {
            console.log("Something went wrong");
        }
    }  

    useEffect(()=>{
        getStockSpecificationdata();
    },[])

  return (
    <div className='specificationdiv'>
        <h4>Info</h4>
        <div>
            <div className='specificationdivinner'>
                <p >Minimum volume,lots</p>
                <p>{specificationdata?.minimum_volume_lots}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Maximum volume,lots</p>
                <p>{specificationdata?.maximum_volume_lots}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Step</p>
                <p>{specificationdata?.swap}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Contract size</p>
                <p>{specificationdata?.contract_size}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Spread units</p>
                <p>{specificationdata?.spread_units ? specificationdata?.spread_units : 0}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Stop level, pips</p>
                <p>{specificationdata?.stop_level_pips}</p>
            </div>
            <div className='specificationdivinner'>
                <p>Swap long,pips</p>
                <p>{specificationdata?.swap_long_pips}</p>
            </div>
        </div>
    </div>
  )
}

export default Stockspecifications