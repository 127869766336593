import React, { useState } from 'react'
import rightimage from "../../Images/right.png"
import './settings.css'
import { useNavigate } from 'react-router-dom';
import Devicesettingdrawer from '../Bottomdrawer/Devicesettingdrawer';

const Preferences = () => {

    const [isDeviceDrawerOpen, setIsDeviceDrawerOpen] = useState(false);

    const toggleDevicedrawer = () => {
        setIsDeviceDrawerOpen(!isDeviceDrawerOpen);
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/tradingterminal');
    };
    const handleClickNotification = () => {
        navigate('/notificationsetting');
    };
    const toggleLanguagechange = () => {
        navigate('/languagesetting');
    };
    

    

  return (
    <div className='settingdiv'>
            <h4 className='profileheading'>Preferences</h4>
            <div className="card">
                <div className="card-body">
                    <div className="btndiv">
                        <button onClick={()=>handleClick()}>
                            <div> <p>Trading terminal</p> </div>
                            <div> <span style={{fontSize:"14px"}}>{process.env.REACT_APP_COMPANY_NAME} <img src={rightimage} width="25px"/></span> </div>
                        </button>
                        <button onClick={()=>handleClickNotification()}>
                            <div> <p>Notifications</p> </div>
                            <div> <span><img src={rightimage} width="25px"/></span> </div>
                        </button>
                        <button onClick={toggleLanguagechange}>
                            <div> <p>Language</p> </div>
                            <div> <span><img src={rightimage} width="25px"/></span> </div>
                        </button>
                        <button onClick={toggleDevicedrawer}>
                            <div> <p>Appearance</p> </div>
                            <div> <span style={{fontSize:"14px"}}>Device settings <img src={rightimage} width="25px"/></span> </div>
                        </button>
                    </div>
                </div>
            </div>
            
            <Devicesettingdrawer isDeviceDrawerOpen={isDeviceDrawerOpen} setIsDeviceDrawerOpen={setIsDeviceDrawerOpen} changedrawer = "Device setting"/>
    </div>
  )
}

export default Preferences