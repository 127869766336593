import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopmoversList } from '../../Redux/actions/tradeAction';
import { getPrice, getUserToken } from '../../helper/getToken';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Stockdetails from '../Bottomdrawer/Stockdetails';
import { useNavigate } from 'react-router-dom';
import MoreStockdetails from '../Bottomdrawer/MoreStockdetails';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import { formatNumber } from '../../helper/commonFunctions';

const MoverCard = React.memo(({ moverData, liveData, openTradebuySale, curencysymbol }) => {
  const createGraphData = useMemo(() => {
  if (!liveData) return [];

  const openPrice = Number(liveData.open_price) || 0;
  let currentPrice = Number(liveData.ltp) || 0;
  const priceHistory = [];

  for (let i = 0; i < 10; i++) {
    currentPrice += (Math.random() * 2 - 1); // Simulate minor price fluctuations
    priceHistory.push(currentPrice);
  }

  return priceHistory.map((price, index) => ({
    time: index,
    price,
    priceDifference: price - openPrice,
  }));
}, [liveData?.ltp]);  // ✅ Trigger re-computation when price changes


  return (
    <div className="col" onClick={() => openTradebuySale(moverData.exchange === 'FOREX' || moverData.exchange === "CRYPTO" || moverData.exchange === "US" ? moverData.symbol.toLowerCase() : moverData.token, moverData.exchange, moverData)}>
      <span><h4>{liveData.symbol}</h4></span>
      {liveData && createGraphData.length > 0 ? (
        <div className="graphdiv">
          <ResponsiveContainer width="100%" height={20}>
            <LineChart data={createGraphData}>
              <Line type="monotone" dataKey="price" stroke="#8884d8" dot={false} />
              <XAxis dataKey="time" hide />
              <YAxis hide />
              <ReferenceLine y={getPrice(liveData) || 0} stroke="red" strokeDasharray="5 5" />
              <Line type="monotone" dataKey="priceDifference" stroke={liveData?.percentage_change > 0 ? "green" : "red"} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="graphdiv">No Data Available</div>
      )}
      <p className="text-center mb-1" style={{ color: liveData?.percentage_change >= 0 ? 'green' : 'red' }}>
        <b>{curencysymbol} {getPrice(liveData)}</b>
      </p>
      <div className="statusdiv">
        <span style={{ color: liveData?.percentage_change >= 0 ? 'green' : 'red' }}>
          {liveData?.percentage_change >= 0 ? <i className="fa fa-arrow-up" aria-hidden="true"></i> : <i className="fa fa-arrow-down" aria-hidden="true"></i>}
          {' '} {liveData?.percentage_change} %
        </span>
      </div>
    </div>
  );
});

const AllMovers = ({ title, curencysymbol }) => {
  const { topMovers, loading, error } = useSelector((state) => state.trade);
  const [stockDetailsOpen, setStockDetailsOpen] = useState(false);
  const [livePrices, setLivePrices] = useState({});
  const [stockDetailsPopUp, setStockDetailsPopUp] = useState({});
  const { priceSocket } = useSelector((state) => state.sockets);
  const token = getUserToken();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectPriceSocket());
  }, [dispatch, token]);


const getPrice=(stockData)=>{
  if(stockData?.bid_price&&stockData?.bid_price!=0){
    return formatNumber(stockData?.bid_price)
  }else if(stockData?.price&&stockData?.price!=0){
    return formatNumber(stockData?.price)
  }else if(stockData?.ltp&&stockData?.ltp!=0){
    return formatNumber(stockData?.ltp)
  }else if(stockData?.avg_price&&stockData?.avg_price!=0){
    return formatNumber(stockData?.avg_price)
  }else{
    return 0;
  }
}

useEffect(() => {
  if (!topMovers?.payload?.[title] || !priceSocket) return;

  topMovers.payload[title].forEach((instrument) => {
    const event = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
      ? `forexRoom${instrument.symbol.toLowerCase()}`
      : `room${instrument.token}`;

    const emitData = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
      ? instrument.symbol.toLowerCase()
      : instrument.token;

    priceSocket.emit('tokenData', emitData);

    priceSocket.on(event, (updatedInstrument) => {
      setLivePrices((prevPrices) => ({
        ...prevPrices,
        [emitData]: { ...(prevPrices[emitData] || {}), ...updatedInstrument }
      }));
    });
  });

  return () => {
    topMovers.payload[title]?.forEach((instrument) => {
      const event = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
        ? `forexRoom${instrument.symbol.toLowerCase()}`
        : `room${instrument.token}`;
      priceSocket.off(event);
    });
  };
}, [topMovers, priceSocket, title]);



  // useEffect(() => {
  //   if (topMovers?.payload?.[`${title}`] && priceSocket) {
  //     topMovers?.payload?.[`${title}`]?.forEach((instrument) => {
  //       const event = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
  //         ? `forexRoom${instrument.symbol.toLowerCase()}`
  //         : `room${instrument.token}`;

  //       const emitData = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
  //         ? instrument.symbol.toLowerCase()
  //         : instrument.token;

  //       priceSocket.emit('tokenData', emitData);

  //       priceSocket.on(event, (updatedInstrument) => {
  //         setLivePrices((prevState) => ({
  //           ...prevState,
  //           [emitData]: updatedInstrument,
  //         }));
  //       });
  //     });
  //   }

  //   return () => {
  //     if (topMovers?.payload?.[`${title}`] && priceSocket) {
  //       topMovers.payload?.[`${title}`]?.forEach((instrument) => {
  //         const event = instrument.exchange === "FOREX" || instrument.exchange === "CRYPTO" || instrument.exchange === "US"
  //           ? `forexRoom${instrument.symbol.toLowerCase()}`
  //           : `room${instrument.token}`;
  //         priceSocket.off(event);
  //       });
  //     }
  //   };
  // }, [topMovers, priceSocket, title]);

  const navigate = useNavigate();

  const openTradebuySale = useCallback((tokendata, exchange, moverData) => {
    if (exchange.toLowerCase() === "forex" || exchange.toLowerCase() === 'crypto' || exchange.toLowerCase() === 'us') {
      navigate('/stockdetails', { state: { idtoken: `${tokendata}`, exchange: `${exchange}`, moverData: moverData } });
    } else {
      setStockDetailsOpen(true);
      setStockDetailsPopUp(moverData);
    }
  }, [navigate]);

  return (
    <div>
      <div className='topheader'>
        <h4 className='mb-0 mt-3'>{title}</h4>
      </div>
      <div className="allcarddiv mt-0">
        <div className="row">
          {topMovers?.payload?.[title]?.map((moverdata, index) => {
            let typeNmae = moverdata.exchange === 'FOREX' || moverdata.exchange === "CRYPTO" || moverdata.exchange === "US" ? moverdata.symbol.toLowerCase() : moverdata.token;
            const liveData = livePrices[typeNmae] || moverdata;
            return (
              <MoverCard
                key={index}
                moverData={moverdata}
                liveData={liveData}
                openTradebuySale={openTradebuySale}
                curencysymbol={curencysymbol}
              />
            );
          })}
        </div>
      </div>
      {/* {stockDetailsOpen && <MoreStockdetails stockDetails={stockDetailsPopUp} isDrawerOpen={stockDetailsOpen} moverData={topMovers} onClose={() => setStockDetailsOpen(false)} />}
        <div className="allcarddiv mt-0">
            <div className="row">                
                {
                    topMovers?.payload?.[title] && topMovers?.payload?.[title]?.map((moverdata,index)=>{
                        let typeNmae = moverdata.exchange === 'FOREX' || moverdata.exchange=== "CRYPTO" || moverdata.exchange=== "US" ? moverdata.symbol.toLowerCase() : moverdata.token;  
                        const liveData = livePrices[typeNmae] || moverdata;
                        return(
                            <div className="col" onClick={()=>openTradebuySale(moverdata.exchange === 'FOREX' || moverdata.exchange=== "CRYPTO" || moverdata.exchange=== "US" ? moverdata.symbol.toLowerCase() : moverdata.token,moverdata.exchange,moverdata)} >
                                <span><h4>{liveData.symbol}</h4></span>
                                <div className='graphdiv'>
                                    ----------------
                                </div>
                                <p className='text-center mb-1'style={{ color: liveData?.movepercent >= 0 || liveData?.percentage_change >= 0 ? 'green' : 'red' }}><b>{curencysymbol} {getPrice(liveData)}</b></p>
                                <div className="statusdiv">                                   
                                    <span style={{ color: liveData?.movepercent >= 0 || liveData?.percentage_change >= 0 ? 'green' : 'red' }}>
                                        {(liveData?.movepercent >= 0 || liveData?.percentage_change >= 0) ? (
                                            <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                        )}
                                        {' '}
                                        {liveData?.percentage_change} %
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div> */}
        {stockDetailsOpen&&<MoreStockdetails stockDetails={stockDetailsPopUp} isDrawerOpen={stockDetailsOpen} moverData={topMovers} onClose={()=>setStockDetailsOpen(false)}/>}
    </div>
  );
};

export default AllMovers;
