import React, { useEffect, useState } from 'react'
import NewHeader from '../Header/NewHeader'
import astropay from '../../Images/astropay-logo.png'
import bianance from '../../Images/Binance_LOGO.png'
import neteller from '../../Images/neteller.png'
import perfectpay from '../../Images/perfect-money.png'
import skrillmoney from '../../Images/skrill_logo.png'
import stickeypay from '../../Images/STATICPAY.png'
import upiicon from '../../Images/upi-id.svg'
import bankicon from '../../Images/bankicon.svg'
import { useFormik } from "formik";
import * as Yup from "yup";
import './Customname.css';
import { useDispatch, useSelector } from 'react-redux'
import { withdrawFund } from '../../Redux/actions/userAction'
import { getUserToken } from '../../helper/getToken'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';




const Accountwithdraw = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('upi');

  const [popupMessage, setPopupMessage] = useState(''); // State for popup message
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [loadingstate,setLoadingState] = useState(false);

  const { user, loading, error } = useSelector((state) => state.user);


  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const token = getUserToken();

  const phoneRegExp = /^[0-9]{10}$/;
  const amountRegExp = /^[0-9]+$/;
  const ifscRegExp = /^[A-Z]{4}0[A-Z0-9]{6}$/;

  const upiValidationSchema = Yup.object({
    mobile: Yup.string()
      .matches(phoneRegExp, 'Phone number must be 10 digits long')
      .required('Mobile number is required'),
    amount: Yup.string()
      .matches(amountRegExp, 'Amount must be a number')
      .min(3, 'Amount must be minimum 100')
      .required('Amount is required'),
    upiid: Yup.string()
      .matches(/^[\w.\-_]{2,256}@[a-zA-Z]{2,64}$/, 'Invalid UPI ID format')
      .required('UPI ID is required'),
  });

  const bankValidationSchema = Yup.object({
    mobile: Yup.string()
      .matches(phoneRegExp, 'Phone number must be 10 digits long')
      .required('Mobile number is required'),
    amount: Yup.string()
      .matches(amountRegExp, 'Amount must be a number')
      .min(3, 'Amount must be minimum 100')
      .required('Amount is required'),
    account: Yup.string()
    .min(8, 'Account number must be at least 8 characters')
    .max(16, 'Account number cannot exceed 16 characters')
    .required('Account number is required'),
    ifsc: Yup.string()
      .matches(ifscRegExp,'IFSC Code must be valid')
      .required('IFSC code is required'),
  });

  // Formik for UPI
  const formikUpi = useFormik({
    initialValues: {
      mobile: '',
      amount: '',
      upiid : "",
      payout_method: selectedPaymentMethod,
      currency:selectedCurrency,
    },
    validationSchema: upiValidationSchema,
    // onSubmit: async(values) => {
    //   setLoadingState(true)
    //   const formData = {
    //     mobile: values.mobile,
    //     amount: values.amount,
    //     upi :values.upiid,
    //     payout_method: values.payout_method,
    //     currency:selectedCurrency
    //   };
    //   if(formData.amount < user?.payload?.client_account?.balance){
    //     try {
    //       // Dispatch action to add fund
    //       const response = await dispatch(withdrawFund(formData, token,navigate));        
    //       if (response === 'success') {
    //         setPopupMessage(`${response.message}`);
    //         setShowPopup(true);          
    //         setTimeout(() => {
    //           navigate('/accounts'); // Navigate after showing popup for a few seconds
    //         }, 2000); // 2-second delay
    //       }else{
    //         setPopupMessage(`${response.message}`);
    //         setShowPopup(true);          
    //         setTimeout(() => {
    //           navigate('/accounts'); // Navigate after showing popup for a few seconds
    //         }, 2000); // 2-second delay
    //       }
    //       setLoadingState(false)
    //     } catch (error) {
    //       console.error("Error adding funds:", error);
    //       setPopupMessage('Failed to add amount.');
    //       setShowPopup(true);
    //       setLoadingState(false)
    //     }
    //   }else{
    //     setPopupMessage('Amount should be less tahn balance');
    //     setLoadingState(false)
    //   }
    // },
    onSubmit: async (values) => {
      setLoadingState(true);    
      const formData = {
        mobile: values.mobile,
        amount: parseFloat(values.amount), // Ensure amount is a number
        upi: values.upiid,
        payout_method: values.payout_method,
        currency: selectedCurrency,
      };
    
      const userBalance = parseFloat(user?.payload?.client_account?.balance) || 0;    
      if (formData.amount >= userBalance) {
        Swal.fire({
          width: "20rem",
          title: "You don't have sufficient balance",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            title: 'swaltext'
          }            
        });   
        setLoadingState(false);    
        return; // Exit function to prevent submission     
      }else{
        try {
          // Dispatch action to withdraw funds
          const response = await dispatch(withdrawFund(formData, token, navigate));         
          if (response?.data?.status=== 'success') {
            setPopupMessage(`${response.message}`);
            setShowPopup(true);
            localStorage.setItem('selectedcurrency','INR'); 
            localStorage.setItem('ordertabname','open');   
            setTimeout(() => {
              navigate('/accounts');
            }, 2000);
          }else{
            setPopupMessage(`${response.message}`);
            setShowPopup(true);
            localStorage.setItem('selectedcurrency','INR'); 
            localStorage.setItem('ordertabname','open');   
            // setTimeout(() => {
            //   navigate('/accounts');
            // }, 2000);
          }
        } catch (error) {
          console.error("Error processing withdrawal:", error);
          setPopupMessage(error?.response?.data?.message||"Failed to process withdrawal.");
          setShowPopup(true);
        } finally {
          setLoadingState(false);
        }
      }   
     
    },
    
  });

  // Formik for Bank
  const formikBank = useFormik({
    initialValues: {
      mobile: '',
      amount: '',
      account: '',
      ifsc: '',
      payout_method:selectedPaymentMethod,
      currency:selectedCurrency
    },
    validationSchema: bankValidationSchema,
    onSubmit: async(values) => {
      setLoadingState(true)
      const formData = {
        mobile: values.mobile,
        amount: values.amount,
        account: values.account,
        ifsc: values.ifsc,
        payout_method: values.payout_method,
        currency:selectedCurrency
      };
      const userBalance = parseFloat(user?.payload?.client_account?.balance) || 0;
      if (formData.amount >= userBalance) {
        setLoadingState(false);
        Swal.fire({
          width: "20rem",
          title: "You don't have sufficient balance",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            title: 'swaltext'
          }            
        });   
           
        return; // Exit function to prevent submission
      }else{
        try {
          // Dispatch action to add fund         
          const response = await dispatch(withdrawFund(formData, token,navigate));
          if (response?.data?.status=== 'success') {
            setPopupMessage(`${response.message}`);
            setShowPopup(true);
            localStorage.setItem('selectedcurrency','INR'); 
              localStorage.setItem('ordertabname','open');   
            setTimeout(() => {
              navigate('/accounts');
            }, 2000);
          }else{
            setPopupMessage(`${response.message}`);
            setShowPopup(true);
            localStorage.setItem('selectedcurrency','INR'); 
              localStorage.setItem('ordertabname','open');   
            // setTimeout(() => {
            //   navigate('/accounts');
            // }, 2000);
          }
          setLoadingState(false)
        } catch (error) {
          console.error("Error adding funds:", error);
          setPopupMessage(error?.response?.data?.message||'Failed to add amount.');
          setShowPopup(true);
          setLoadingState(false)
        }
      }
      
      // dispatch your action here
    },
  });

  useEffect(() => {
    formikUpi.setFieldValue('payout_method', selectedPaymentMethod);
    formikBank.setFieldValue('payout_method', selectedPaymentMethod);
  }, [selectedPaymentMethod]);
 

  return (
    <div className='accountsmain'>
        <NewHeader title="Withdrawal" addicon="none"/>
        <div className='topmovermain2'>
          <div className='detailsheading mt-4 mb-4'>
              <h4>All Payment methods</h4>
          </div>
          <div className="withdrawaldiv">
              {/* {
                paymentMethod && paymentMethod.map((paymentm,index)=>{
                  return(
                    <div className="card">
                      <div className="card-header">
                            <img src={paymentm.logo}/>
                            <h4>{paymentm.name}</h4>
                      </div>                              
                      <div className="card-body">
                        <p>Processing tine {paymentm.processtime}</p>
                        <p>Fee {paymentm.fee}%</p>
                        <p>Limits {paymentm.limits} USD</p>
                      </div>
                    </div>
                  )
                })
              } */}
              <div className='acctabbtndiv'>
                <button className={selectedCurrency === 'INR' ? 'active' : ''} onClick={() => setSelectedCurrency('INR')}>INR</button>
                <button className={selectedCurrency === 'USD' ? 'active' : ''} onClick={() => setSelectedCurrency('USD')}>Forex</button>
                <button className={selectedCurrency === 'CRYPTO' ? 'active' : ''} onClick={() => setSelectedCurrency('CRYPTO')}>Crypto</button>
            </div>

            {
                selectedCurrency === 'INR' && (
                    <div className='inrwithdraw'>
                      <div className='radiodiv'>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="paymentMethod" id="upiid" value="upi" checked={selectedPaymentMethod === 'upi'} onChange={handlePaymentChange} />
                            <label className="form-check-label" htmlFor="upiid">
                              <span>UPI <img src={upiicon} alt="UPI Icon" width="24px" /></span>
                            </label>
                          </div>      
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="paymentMethod" id="bankid" value="bank" checked={selectedPaymentMethod === 'bank'} onChange={handlePaymentChange} />
                            <label className="form-check-label" htmlFor="bankid">
                              <span><img src={bankicon} alt="Bank Icon" width="24px" /> Bank</span>
                            </label>
                          </div>
                      </div>
                      {
                        selectedPaymentMethod === "upi" &&(
                          <div className='formdiv'>
                              <form onSubmit={formikUpi.handleSubmit}>
                                  <input type="text" name="mobile" className='form-control' placeholder='Mobile No.' value={formikUpi.values.mobile} onChange={formikUpi.handleChange} onBlur={formikUpi.handleBlur} />
                                   {formikUpi.touched.mobile && formikUpi.errors.mobile ? (
                                      <div className="Formikerrormsg">{formikUpi.errors.mobile}</div>
                                    ) : null}
                                  <input type="text" name='amount' className='form-control' placeholder='Amount' value={formikUpi.values.amount} onChange={formikUpi.handleChange} onBlur={formikUpi.handleBlur} />
                                   {formikUpi.touched.amount && formikUpi.errors.amount ? (
                                      <div className="Formikerrormsg">{formikUpi.errors.amount}</div>
                                    ) : null}
                                  <input type="text" name='upiid' className='form-control' placeholder='UPI ID.' value={formikUpi.values.upiid} onChange={formikUpi.handleChange} onBlur={formikUpi.handleBlur} />
                                   {formikUpi.touched.upiid && formikUpi.errors.upiid ? (
                                      <div className="Formikerrormsg">{formikUpi.errors.upiid}</div>
                                    ) : null}
                                  <button type="ssubmit" className='form-control' disabled={loadingstate}>{loadingstate ? (<>Submit<span className='spinner'></span></>):"Submit"}</button>
                              </form>
                          </div>
                        )                        
                      } 
                      {
                        selectedPaymentMethod ==='bank' && (
                          <div className='formdiv'>
                              {/* <form action="">
                                  <input type="text" className='form-control' placeholder='Mobile No.'/>
                                  <input type="text" className='form-control' placeholder='Amount'/>
                                  <input type="text" className='form-control' placeholder='Account No.'/>
                                  <input type="text" className='form-control' placeholder='IFSC CODE'/>
                                  <button type="ssubmit" className='form-control mt-4'>Submit</button>
                              </form> */}
                            <form onSubmit={formikBank.handleSubmit}>

                              <input type="text" name="mobile" className='form-control' placeholder='Mobile No.' value={formikBank.values.mobile} onChange={formikBank.handleChange} onBlur={formikBank.handleBlur} />
                              {formikBank.touched.mobile && formikBank.errors.mobile ? (
                                <div className="Formikerrormsg">{formikBank.errors.mobile}</div>
                              ) : null}

                              <input type="text" name="amount" className='form-control' placeholder='Amount' value={formikBank.values.amount} onChange={formikBank.handleChange} onBlur={formikBank.handleBlur} />
                              {formikBank.touched.amount && formikBank.errors.amount ? (
                                <div className="Formikerrormsg">{formikBank.errors.amount}</div>
                              ) : null}

                              <input type="text" name="account" className='form-control' placeholder='Account No.' value={formikBank.values.account} onChange={formikBank.handleChange} onBlur={formikBank.handleBlur} />
                              {formikBank.touched.account && formikBank.errors.account ? (
                                <div className="Formikerrormsg">{formikBank.errors.account}</div>
                              ) : null}
                              <input type="text" name="ifsc" className='form-control' placeholder='IFSC CODE' value={formikBank.values.ifsc} onChange={formikBank.handleChange} onBlur={formikBank.handleBlur} />
                              {formikBank.touched.ifsc && formikBank.errors.ifsc ? (
                                <div className="Formikerrormsg">{formikBank.errors.ifsc}</div>
                              ) : null}
                              <button type="ssubmit" className='form-control mt-4' disabled={loadingstate}>{loadingstate ? (<>Submit <span className="spinner"></span></>):"Submit"}</button>
                            </form>
                            </div> 
                          )
                        }                    
                    </div>
                )
            }        

            {
                selectedCurrency === 'USD' && (
                    <div className='usdwithdraw'>
                        <p>USD</p>
                    </div>
                )
            }

            {
                selectedCurrency === 'CRYPTO' && (
                    <div className='cryptowithdraw'>
                        <p>Crypto</p>
                    </div>
                )
            }
                      
          </div>
        </div>
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <p>{popupMessage}</p>
              <button onClick={() => setShowPopup(false)} >Close</button>
            </div>
          </div>
        )}
    </div>
  )
}

export default Accountwithdraw