import React from 'react'
import NewHeader from '../Components/Header/NewHeader'
import '../Components/Topmovers/Markets.css'
import graphimage from '../Images/graphimage.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faClock, faClockFour } from '@fortawesome/free-regular-svg-icons'
import gbpusd from '../Images/gbpusd.svg'
import uparroegreen from '../Images/up-arrow-green.png'
import tradingcentral from '../Images/trandingcentral.svg'
import arrowupwjite from '../Images/arrow-white-new.png'
import arrowrightwhite from '../Images/arrow-right-white.png'
import ProgressBar from 'react-bootstrap/ProgressBar';

const TradingDetails = () => {
  return (
    <div className='accountsmain'>
        <NewHeader title="Trading Signals" addicon="none" />
        {/* <Header props="Trading Signals"  icon="block" settitng="none"/> */}
        <div className='topmovermain2'>
            <div className='tradingsignalmain'>
                <div className='card tradingsignal'>                
                    <div className='card-header '>
                        <div className='signalleft'>
                            <img src={gbpusd} alt="" />
                        </div>
                        <div className='signalright'>
                            <span className='righttop'><h4>XAG/USD</h4> <small>28.326</small></span>
                            <span className='rightbottom'><p>Silver vs US Doller</p> <small className='smallflex'><img src={uparroegreen}/>0.25%</small></span>
                        </div>                    
                    </div>
                    <div className='card-body'>
                        <div className='crdbdinner'>
                            <img src={graphimage}/>
                        </div>
                    </div>                                
                </div>
                <div className='intradaydiv'>
                    <h4><img src={arrowupwjite}/> Intraday</h4>
                    <div className='intradayinner'>
                        <p>Sep 5,2024 10:27:00 AM</p>
                        <img src={tradingcentral}/>
                    </div>                
                </div>
                <div className="intradaydiv2">
                    <h2>Intraday: bullish bias above 28.07</h2>
                    <p>XAG/USD may rise 0.18 - 0.33 USD</p>
                    <h4>Our Preformance</h4>
                    <p>Long position above 28.07 with target at 28.40 & 28.55 in extension.</p>
                    <div className="resistance">
                        <div className='resilist'>
                            <p>28.7000</p>
                            <p>Resistance .</p>
                        </div>
                        <div className='resilist'>
                            <p>28.5500</p>
                            <p>Resistance ..</p>
                        </div>
                        <div className='resilist'>
                            <p>28.4000</p>
                            <p>Resistance ...</p>
                        </div>
                    </div>
                    <div className="last">
                        <div className='lastlist'>
                            <p>28.200</p>
                            <p>Last</p>
                        </div>
                    </div>
                    <div className="pivot">
                        <div className='pivotlist'>
                            <p>28.0700</p>
                            <p>Pivot</p>
                        </div>
                    </div>
                    <div className="support">
                        <div className='supportlist'>
                            <p>28.0700</p>
                            <p>Support .</p>
                        </div>
                        <div className='supportlist'>
                            <p>28.8500</p>
                            <p>Support ..</p>
                        </div>
                        <div className='supportlist'>
                            <p>28.7000</p>
                            <p>Support ...</p>
                        </div>
                    </div>
                    <div className="comment mt-2">
                        <h4>Comment</h4>
                        <p>This RSi is mixed to boolish</p>
                    </div>
                    <div className="alternative">
                        <h4>Alternative scenario</h4>
                        <p>Below 28.07 look further downside with 27.85 & 27.70 as target</p>
                    </div>
                    <div className="currenttrends">
                        <h4>Current trends</h4>
                        <div className='currenttrendinner'>
                            <div className='intraday'>
                                <p><b>Intraday</b></p>
                                {/* <h4 className='intradaybtn'><img src={arrowupwjite}/>Intraday</h4> */}
                                <h4 className='intradaybtn'><i className='fa fa-arrow-up'></i>Intraday</h4>
                                <small></small>
                            </div>
                            <div className='shortterm'>
                                <p><b>Short term</b></p>
                                {/* <h4><img src={arrowrightwhite}/>Short term</h4> */}
                                <h4><i className='fa fa-arrow-right'></i>Short term</h4>
                                <small>no change</small>
                            </div>
                            <div className='mediumterm'>
                                <p><b>Medium term</b></p>
                                {/* <h4><img src={arrowrightwhite}/>Medium term</h4> */}
                                <h4><i className='fa fa-arrow-right'></i>Medium term</h4>
                                <small>no change</small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="outerfooter" style={{position:"relative"}}>
                <div className="footerbtnfixed">
                    <div className='sell'>
                        <button className=''><p>Sell</p><p><b>28.702</b></p></button>
                        <ProgressBar variant="danger" now={40} />
                        <small>40%</small>
                    </div>
                    <div className='buy'> 
                        <button className=''><p>Buy</p><p><b>28.702</b></p></button>
                        <ProgressBar variant="success" now={60} />
                        <small>60%</small>
                    </div>                
                </div>
            </div>
            <div className='moverpercent'>
                <p>0.04</p>
            </div>
        </div>
    </div>
  )
}

export default TradingDetails