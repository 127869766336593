import React, { useEffect, useState } from 'react'
import NewHeader from '../Components/Header/NewHeader'
import '../Components/Pricealerts/Pricealerts.css'
import Addalert from '../Components/Bottomdrawer/Addalert';
import ustech from '../Images/ustech.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAlert, fetchUserAlerts } from '../Redux/actions/userAction';
import { getUserToken } from '../helper/getToken';
import { convertToIndianTime } from '../helper/commonFunctions';
import Swal from 'sweetalert2';

const Pricealerts = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [userAlerts,setUserAlerts]=useState(null);
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp.replace(" ", "T")); // Ensure correct format

    // Add 7 days
    date.setDate(date.getDate() + 7);

    // Extract required date parts
    const day = date.getDate().toString().padStart(2, "0"); // Two-digit day
    const month = date.toLocaleString("en-US", { month: "short" }).toLowerCase(); // Short month name
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Two-digit minutes
    const period = hours >= 12 ? "PM" : "AM"; // AM/PM

    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${hours}:${minutes} ${period}`;
    }
    const dispatch=useDispatch()
    const token =getUserToken();
    const {alerts}=useSelector((state)=>state.user);
    const renderAlertsData=(alerts)=>{
        if(alerts==null||alerts==undefined){
            return <></>
        }else if(Array.isArray(alerts)&&alerts.length==0){
            return <> <p style={{ textAlign: "center", color: "#888", fontSize: "16px" }}>
            No alerts available
        </p></>
        }else if(Array.isArray(alerts)&&alerts.length>0){
            return (<>
                {alerts.map(val=><> <div className='divinneralert'>
                {/* <img src={ustech}/> */}
                <p><b>{val?.exchange=='CRYPTO'?val?.token:val?.token}</b></p>
            </div>
            <div className="card">
                <div className="card-body" style={{position:"relative"}}>                    
                    <span className='d-flex justify-content-between'>
                        <p><b>{`${val?.price_type=='BID'?'Bid':'Ask'} at ${val?.price}`}</b></p>
                        <p>{val?.status=="ACTIVE"?<small className='activealert' ><i class="fa fa-caret-right" aria-hidden="true"></i> Active</small>:<small className='triggeredalert'><i class="fa fa-check" aria-hidden="true"></i> Triggered</small>}</p>
                    </span>
                    {/* <p><b>{`${val?.price_type=='BID'?'Bid':'Ask'} at ${val?.price}`}</b></p> */}
                    <span>{`Until ${formatTimestamp(convertToIndianTime(val?.added_on))}`}</span>
                    <div style={{position:"absolute", top:"-5px", right:"0"}}>
                        <button className='closebtn' style={{padding:"0 5px", background:"red", color:"#fff",fontSize:"13px",borderRadius:"50%"}} onClick={()=>handleDeleteAlert(val.id)}>X</button>
                    </div>
                </div>
            </div></>)}
            </>)
        }else{
            return <></>
        }
    }
    const toggleDrawer = () => {
        // setIsDrawerOpen(!isDrawerOpen);
        setIsDrawerOpen(true);
    };
    useEffect(()=>{
        dispatch(fetchUserAlerts(token))        
    },[dispatch, token])
    useEffect(()=>{
        setUserAlerts(alerts?.payload?.data)
    },[alerts])

const handleDeleteAlert = async (alertid) => {
    const result = await Swal.fire({
        text: "Do you want to cancel this alert?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
            confirmButton: "custom-confirm-button",
            cancelButton: "custom-cancel-button"
        }
    });

    if (result.isConfirmed) {
        try {
            await dispatch(deleteUserAlert(alertid, token)); // Dispatch delete action
            dispatch(fetchUserAlerts(token)); // Fetch updated alerts
            Swal.fire("Deleted!", "The alert has been deleted.", "success");
        } catch (error) {
            console.error("Error deleting alert:", error);
            Swal.fire("Error", "Failed to delete the alert. Try again.", "error");
        }
    }
};  

  return (
    <div className='alertdiv'>
        <NewHeader title="Price alerts" addicon="block" />
        <div className='alertdivinner' style={{justifyContent:"left", marginTop:"20px"}}>
            {renderAlertsData(userAlerts)}            
        </div>
        <Addalert isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} onClose={() => setIsDrawerOpen(false)}/> 
    </div>
  )
}

export default Pricealerts