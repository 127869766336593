// services/apiService.js
import axios from 'axios';

const apiClient = axios.create({
  //baseURL: "https://tbe.bastionex.net/api/",
  baseURL: process.env.REACT_APP_API_FRONTEND  
});

// Sent OTP 

export const verifyEmail = (userData) => {
  return apiClient.post('/sendOtp', userData);
};

// Signup User =========================

export const signUpUser = (userData) => {
    return apiClient.post('/signup', userData);
};

// Sign In User =======================

export const signInUser = (credentials) => {
  return apiClient.post('/clientLogin', credentials);
};

// Fetch user data ====================

export const fetchUserData = (token) => {
  return apiClient.get('/client/profile/fetch', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fetch order data ====================

export const fetchOrderdata = (token) => {
  return apiClient.get('/client/order/fetch', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fetch trade data ====================

export const fetchTradedata = (token) => {
  return apiClient.get('/client/trade/fetch', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Fetch trade data ====================

export const fetchIndexfund = (token) => { 
  return apiClient.get('/client/market/indexes', {
    
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchIndicesFund=(token)=>{
  return apiClient.get('/client/market/market-indexes',{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const fetchMetals=(token)=>{
  return apiClient.get('/client/market/metals',{
    headers:{
      Authorization: `Bearer ${token}`,
    }
  })
}
// Fetch watch list data ===================

export const fetchWatchListdata = (token) => {
  return apiClient.get('/client/watchlist/fetch', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Delete watchlist data====================
// export const deleteWatchListdata = (watchlisttoken,token) => {
//   return apiClient.post('/client/watchlist/delete',watchlisttoken, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

export const deleteWatchListdata = (watchlisttoken, token) => {
  return apiClient.post(
    '/client/watchlist/delete', 
    { token: watchlisttoken },  // Wrap the watchlisttoken in an object
    {
      headers: {
        Authorization: `Bearer ${token}`,  // Pass user token in the header
      },
    }
  );
};

// Add watch list =======================================

// export const addWatchListdata = (watchlisttoken, token) => {
//   console.log("watchlisttoken, tokenwatchlisttoken, token",watchlisttoken, token);
//   return apiClient.post(
//     '/client/watchlist/add', 
//     { token: watchlisttoken },  // Wrap the watchlisttoken in an object
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,  // Pass user token in the header
//       },
//     }
//   );
// };

export const addWatchListdata = (watchlistToken, token) => {
  return apiClient.post(
    '/client/watchlist/add',
    { token: watchlistToken },
    {
      headers: {
        Authorization: `Bearer ${token}`,  // Ensure the token is passed correctly
      },
    }
  );
};


export const searchWatchListdata = (token,formData) => {
  return apiClient.post(
    '/client/watchlist/search',formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,  // Pass user token in the header
      },
    }
  );
};

export const searchMarketListdata = (token,formData) => {
  return apiClient.post(
    // '/client/watchlist/search',formData,
    'client/market/searchMarketInstrument',formData,
    
    {
      headers: {
        Authorization: `Bearer ${token}`,  // Pass user token in the header
      },
    }
  );
};




// Fetch topmovers data ====================

export const fetcTopMoverdata = (token) => {
  return apiClient.get('/client/market/gainers', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


// Fetch top loosers data =============

export const fetcTopLooserdata = (token) => {
  return apiClient.get('/client/market/loosers', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetcAllstocksdata = (token) => {  
  return apiClient.post('/client/market/getAllStocks',{}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchAlltradeData = (token) => {  
  return apiClient.get('client/market/fetch-all-pairs', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};



// Add fund ==============================

export const addFunddata = (formData,token) => {
  return apiClient.post('/client/fund/deposite',formData, {    
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Withdraw funds ============================

export const withdrawFunddata = (formData,token) => {
  return apiClient.post('/client/fund/generateWithdrawRequest',formData, {    
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// fetch user position data =====================

export const fetchUserPositionData = (token) => {
  return apiClient.get('/client/position/fetch',{    
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const userBuyTrade = (formData,token)=>{
  return apiClient.post('/client/order/push',formData,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const fetchUserAlertsApi=(token)=>{
  return apiClient.get('/client/notification/getAlertPrice',{
    headers:{
      Authorization: `Bearer ${token}`,
    },
  });
}

// export const deleteAlertbyId=(data, token)=>{
//   const bodyData = { id: data };
//   console.log("datadatadatadatadatadatadatadatadatadatadata",bodyData);
//   return apiClient.post('/client/notification/deleteAlertPrice',bodyData,{
//     headers:{
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

export const deleteAlertbyId = (alertId, token) => {
  const bodyData = { id: alertId };
  console.log("Sending Delete Request with Data:", bodyData);

  return apiClient.post('/client/notification/deleteAlertPrice', bodyData, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });
};


// export const userSellTrade = (formData,token)=>{
//   return apiClient.post('/client/order/push',formData,{
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

export const fetchCurrencies=(token)=>{
  return apiClient.get('/client/market/fetch-currencies',{
    headers:{
      Authorization:`Bearer ${token}`,
    }
  })
}


/////////Fetch Leverage==========================

export const fetchLeverage=(token,bodyData)=>{
  return apiClient.post('/client/market/leverage', bodyData,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
  }); 
}

export const fetchNewLeverage=(token,bodyData)=>{
  return apiClient.post('client/market/getAllLeverage', bodyData,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
  }); 
}



/////// Fetch Margin===========================

export const fetchMargin=(token,bodyData)=>{
  return apiClient.post('/client/order/fetchMargin', bodyData,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
  }); 
}


export const fetchGeneralNews=(token,bodyData)=>{
  return apiClient.post('client/news/getGeneralNews', bodyData,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
  }); 
}

export const fetchNotification=(token,bodyData)=>{
  return apiClient.post('/user/client/fetchRejectionLogsByClientId', bodyData,{
      headers:{
        Authorization:`Bearer ${token}`,
      }
  }); 
}







