import React, { useEffect, useState } from 'react';
import topwhite from '../../Images/top_white.png';
import bottowhite from '../../Images/arrow-down-white.png';
import './Topmorenews.scss';
import NewHeader from '../Header/NewHeader';
import aiimage from '../../Images/aiimage.svg';
import downarrow from '../../Images/downarrowcolored.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserToken } from '../../helper/getToken';

const baseURL = process.env.REACT_APP_API_FRONTEND;

function Topmorenews() {
    const navigate = useNavigate();
    const [topnewdata, setTopNewsData] = useState(null); // Changed to null for clarity
    const [loadingdata, setLoadingData] = useState(false); // Fixed typo in setLoadinData
    const token = getUserToken();

    const newsDetailspage = () => {
        navigate('/topnewsdetails');
    };

    const getTopNews = async () => {
        setLoadingData(true); // Show loading spinner
        try {
            const response = await axios.post(
                `${baseURL}client/news/getGeneralNews`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            
            setTopNewsData(response.data);
        } catch (error) {
            console.log("Error fetching news:", error);
        } finally {
            setLoadingData(false); // Hide spinner regardless of success or failure
        }
    };

    useEffect(() => {
        getTopNews();
    }, []);

    // Determine the news array based on response structure
    const newsArray = Array.isArray(topnewdata)
        ? topnewdata
        : Array.isArray(topnewdata?.payload)
            ? topnewdata.payload
            : [];

    return (
        <div className='accountsmain'>
            <NewHeader title="News" addicon="none" />
            <div className="tradingsignaldiv">
                <nav>
                    <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-favoriate"
                            type="button"
                            role="tab"
                            aria-controls="nav-real"
                            aria-selected="true"
                        >
                            Favourites
                        </button>
                        <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-all"
                            type="button"
                            role="tab"
                            aria-controls="nav-archived"
                            aria-selected="false"
                        >
                            All
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active text-start"
                        id="nav-favoriate"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                    >
                        <div className='topmovermain'>
                            <h3>Favourites</h3>
                            {loadingdata ? (
                                <span className='spinner text-center'></span>
                            ) : (
                                <>
                                    {newsArray.length > 0 ? (
                                        newsArray.slice(0, 10).map((news, index) => {
                                            const formattedDate = new Date(news.publishedDate).toLocaleDateString('en-US');
                                            return (
                                                <Link to="" key={index}>
                                                    <div className='newslist'>
                                                        <div className='imgdiv'>
                                                            <img src={news.image} alt={news.title || 'News Image'} />
                                                        </div>
                                                        <div className='textdiv'>
                                                            <p>
                                                                <b>
                                                                    {news.title?.length > 20
                                                                        ? `${news.title.substring(0, 20)}...`
                                                                        : news.title}
                                                                </b>
                                                            </p>
                                                            <p>
                                                                {news?.text?.length > 30
                                                                    ? `${news.text.substring(0, 30)}...`
                                                                    : news.text}
                                                            </p>
                                                            <small>{formattedDate}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    ) : (
                                        <p>No news available</p>
                                    )}
                                </>
                            )}
                            <button
                                className='form-control mt-4 py-2'
                                style={{ background: "#a5c0be87", fontSize: "18px" }}
                            >
                                Show more
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topmorenews;