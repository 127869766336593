import React from 'react'
import NewHeader from '../Header/NewHeader'
import './Swapfree.scss'
import img1 from '../../Images/exit-top-right.png'
function Swapefree() {
    return (
        <div className='accountsmain'>
            <NewHeader title="Swap-free" addicon="none"/>
            
            <div className='swapfreediv'>
                <div className='swap-free-header mt-4'>
                    src\Components\Profile\Benefits.jsx
                    Line 22:59:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images
                </div>
                <div className='swap-free-second-block mt-4'>
                    <div className='swap-free-second-block-left'>
                        <p>Swap-Fre Level:</p>
                    </div>
                    <div className='swap-free-second-block-right'>
                        <p>Extended</p>
                    </div>
                </div>
                <div class="progress-container">
                    <div class="progress-bar"></div>
                </div>
                <p className='mt-4 '> Line 22:59:  img elements must have an alt prop, either with meaningful text</p>
                <p>
                    Line 22:59:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images  jsx-a11y/alt-text
                    Line 23:66:  img elements must have an
                </p>
                <p>Line 22:59:  img elements must have an alt prop, either with meaningful text, or an empty string for decorative images   </p>
                <div className='swapfree-link'>
                    <a href='/#'>Read more in our help centre
                        <hr/></a>
                    <div className='swapfre-img '>
                        <img src={img1} alt='images'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Swapefree
