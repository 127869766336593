import React, { useState } from 'react';
import closebtn from '../../Images/close-button.png';
import axios from 'axios';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isAuthenticated } from '../../helper/authToken';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { getUserToken } from '../../helper/getToken';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const Otpmodal = ({ isModalVisible1, setModalVisible1, setModalVisible2, email, setForgotkey }) => {
    const [message, setMessage] = useState('');
    const [loadingState, setLoadingState] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCnfPassword, setShowCnfPassword] = useState(false);

    const navigate = useNavigate();
    const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

    const encryptData = (data) => {
        if (!ENCRYPTION_KEY) return null;
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString();
    };

    const validationSchema = Yup.object({
        oldPassword: Yup.string()
            .min(6, "Old password must be at least 6 characters")
            .required("Old password is required"),
        password: Yup.string()
            .min(6, "New password must be at least 6 characters")
            .required("New password is required"),
        cnfPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords must match")
            .required("Confirm password is required"),
    });

    let token = getUserToken();

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            password: "",
            cnfPassword: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setLoadingState(true);
            setMessage("");

            const formData = {
                old_password: values.oldPassword,
                password: values.password,
            };

            try {
                let response = await axios.post(`${baseUrl}client/profile/changePassword`, formData, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setLoadingState(false);

                if (response?.data?.status === "SUCCESS") {
                    resetForm();
                    setModalVisible1(false);
                    setModalVisible2(true);
                } else {
                    setMessage(response?.data?.message || "Invalid OTP. Please try again.");
                }
            } catch (error) {
                console.log(error);
                setLoadingState(false);
                setMessage("Something went wrong, please try again later.");
            }
        },
    });

    if (!isModalVisible1) return null;

    return (
        <div className="modal-overlay" style={{ backgroundColor: "#151514a8" }}>
            <div className="modal" style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "rgba(21, 21, 20, 0.66)" }}>
                <div className='cusmodal2'>
                    <button className="close-btn" onClick={() => setModalVisible1(false)}>
                        <img src={closebtn} alt="Close" width="10px" />
                    </button>

                    <h3 className='mb-4'>Change Password</h3>
                    <form onSubmit={formik.handleSubmit} className="w-100">
                        {/* Old Password */}
                        <div className="password-field">
                            <input
                                type={showOldPassword ? 'text' : 'password'}
                                name="oldPassword"
                                className={`form-control h-40 ${formik.touched.oldPassword && formik.errors.oldPassword ? 'is-invalid' : 'mb-3'}`}
                                placeholder='Enter old password'
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ fontSize: "13px" }}
                            />
                            <span className="eye-icon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                            <div className="invalid-feedback">{formik.errors.oldPassword}</div>
                        )}

                        {/* New Password */}
                        <div className="password-field">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                className={`form-control h-40 ${formik.touched.password && formik.errors.password ? 'is-invalid' : 'mb-3'}`}
                                placeholder='Enter new password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ fontSize: "13px" }}
                            />
                            <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                            <div className="invalid-feedback">{formik.errors.password}</div>
                        )}

                        {/* Confirm New Password */}
                        <div className="password-field">
                            <input
                                type={showCnfPassword ? 'text' : 'password'}
                                name="cnfPassword"
                                className={`form-control h-40 ${formik.touched.cnfPassword && formik.errors.cnfPassword ? 'is-invalid' : 'mb-3'}`}
                                placeholder='Confirm new password'
                                value={formik.values.cnfPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ fontSize: "13px" }}
                            />
                            <span className="eye-icon" onClick={() => setShowCnfPassword(!showCnfPassword)}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {formik.touched.cnfPassword && formik.errors.cnfPassword && (
                            <div className="invalid-feedback">{formik.errors.cnfPassword}</div>
                        )}

                        {/* API Response Message */}
                        {message && <p style={{ color: "red" }}>{message}</p>}

                        {/* Submit Button */}
                        <button type='submit' className='form-control' disabled={loadingState}>
                            {loadingState ? (<>Submitting <span className='spinner'></span></>) : ("Submit")}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Otpmodal;
