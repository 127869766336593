import React, { useEffect, useRef, useState } from 'react';
import './Bottomdrawer.css';
import darktheme from '../../Images/dark.svg'
import lighttheme from '../../Images/light.svg'
import devicesetting from '../../Images/devicesetting.svg'


const tradingTerminalData = [
  { id: 1, title: 'Today' },
  { id: 2, title: 'Week' },
  { id: 3, title: 'Month' },
  { id: 4, title: 'Year' }
];

const Devicesettingdrawer = ({ isDeviceDrawerOpen, changedrawer,setIsDeviceDrawerOpen }) => {

    const drawerRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setIsDeviceDrawerOpen(false);
        }
        };
        if (isDeviceDrawerOpen) {
        document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDeviceDrawerOpen, setIsDeviceDrawerOpen]);

  const [checkedItems, setCheckedItems] = useState(
    new Array(tradingTerminalData.length).fill(false)
  );

  
  return (
    <div className={`drawer ${isDeviceDrawerOpen ? 'open' : ''}`}>
        <div className='' style={{padding:"10px"}}>
            <h4 className='p-2'>Choose appearance</h4>
            <div className='devicesettingdiv'>
                <div className='devicesettingdivinner'>
                    <img src={lighttheme} alt="" width="100"/>
                    <p>Always light</p>
                    <div className='radiodiv'>
                        <input type="radio" id="test1" name="radio-group" />
                    </div>
                </div>
                <div className='devicesettingdivinner'>
                    <img src={darktheme} alt="" width="100"/>
                    <p>Always dark</p>
                    <div className='radiodiv'>
                        <input type="radio" id="test1" name="radio-group" />
                    </div>
                </div>
                <div className='devicesettingdivinner'>
                    <img src={devicesetting} alt="" width="100"/>
                    <p>Device settings</p>
                    <div className='radiodiv'>
                        <input type="radio" id="test1" name="radio-group" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Devicesettingdrawer;
