import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrenciesList, fetchTopmoversList } from '../../Redux/actions/tradeAction.js';
import { connectPriceSocket } from '../../Redux/actions/socketActions';
import Stockdetails from '../Bottomdrawer/Stockdetails.js';
import { getUserToken } from '../../helper/getToken.js';
import eurousd  from '../../Images/eurusd.svg'
import Stockdetailsmain from '../../Pages/Stockdetailsmain.jsx';
import { useNavigate } from 'react-router-dom';
import SortingDrawer from '../Bottomdrawer/SortingDrawer.jsx';
import AlertsetDrawer from '../Bottomdrawer/AlertsetDrawer.jsx';

const Popular = ({ currency,alert }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMoverData, setSelectedMoverData] = useState(null);
  const [livePrices, setLivePrices] = useState({});
  const token = getUserToken()
  const dispatch = useDispatch();
  const { topMovers,populartrade, loading, error } = useSelector((state) => state.trade);
  const { priceSocket } = useSelector((state) => state.sockets); 

const [issetalert,setIssstAlert] = useState(false);

  const navigate = useNavigate();

  
  useEffect(() => {
    // const fetchTopMoversData = async () => {
    //   if (token) {
    //     try {
    //       await dispatch(fetchCurrenciesList(token));
    //     } catch (error) {
    //       console.error('Failed to fetch user data:', error);
    //     }
    //   }
    // };

    // fetchTopMoversData();
    // dispatch(fetchCurrenciesList(token));
    dispatch(connectPriceSocket());

  }, [dispatch,token]); 

  useEffect(() => {
    if (populartrade?.payload?.popular && priceSocket) {
      populartrade?.payload?.popular?.forEach((instrument) => {
        const event = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US" ? `forexRoom${instrument.symbol.toLowerCase()}` : `room${instrument.token}`;
        const emitData = instrument.exchange === "FOREX" || instrument.exchange=== "CRYPTO" || instrument.exchange=== "US"  ? instrument.symbol.toLowerCase() : instrument.token; 
        priceSocket.emit('tokenData', emitData);        
        priceSocket.on(event, (updatedInstrument) => {
          let typeNmae = updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;          
          setLivePrices((prevState) => ({
            ...prevState,
            [typeNmae]: updatedInstrument,
          }));
        });
      });
    }
  
    return () => {
      if (populartrade?.payload?.popular && priceSocket) {
        populartrade?.payload?.popular?.forEach((instrument) => {
          const event = instrument.exchange === "FOREX" 
          ? `forexRoom${instrument.symbol.toLowerCase()}`
          : `room${instrument.token}`;  
        });
      }
    };
  }, [populartrade, priceSocket, currency]);

 
  const handleClick = (moverData) => { 
      if(!alert){
        navigate('/stockdetails', { 
          state: { 
            idtoken: moverData.symbol, 
            exchange: moverData.exchange,
            moverData: moverData,
          } 
        });
      }else{
        setSelectedMoverData(moverData);
        setIssstAlert(true);
      }
    
  };

    

  const renderTopMovers = (topMoversList) =>    
    topMoversList.map((topMover, index) => { 
      let typeNmae = topMover.exchange ==='FOREX' || topMover.exchange === "US" || topMover.exchange === "CRYPTO" ? topMover.symbol.toLowerCase() : topMover.token;
      const liveData = livePrices[typeNmae];
      const askPrice = liveData?.ask_price || topMover.ask_price;
      const getPrice = (data) => { 
          const ltp = data.ltp;  
          const bidPrice = data.bid_price;
          return ltp > 0 ? ltp : (bidPrice > 0 ? bidPrice : "NA");
      };
      const pricechange = liveData?.price_change || topMover?.price_change;      
      const getCurrencySymbol = (exchange) => {
        return ['us', 'forex', 'crypto'].includes(exchange.toLowerCase()) ? '$' : '₹';
      };
      const price = getPrice(liveData || topMover);
      const formattedPrice = !isNaN(price) ? Number(price).toFixed(2) : 'N/A';
      const percentChange = 
            liveData?.percentage_change !== undefined 
                ? parseFloat(liveData.percentage_change).toFixed(2) 
                : parseFloat(topMover.percentage_change).toFixed(2);
      let currentDatetime = new Date();
      const formattedDate = currentDatetime.toLocaleDateString('en-GB'); 
            
      return (
        <div className="card mb-2" key={index} onClick={() => handleClick(topMover)}>
          <div className="card-body" style={{cursor:"pointer"}}>
            <div className="topdiv">
              <div className="text-start">
                <b>{topMover?.symbol}</b>
              </div>
              <div>
                <p>{topMover?.graphdata}</p>
              </div>
              <div className="text-end">
                <p style={percentChange<0?{ color:'red'}:{ color:'green'}}>
                  {/* <b>{getCurrencySymbol(topMover.exchange)} {formattedPrice}</b> */}
                  <b>{getCurrencySymbol(topMover.exchange)}{price}</b>
                </p>
              </div>
            </div>
            <div className="bottomdiv">
              <div className="text-start">
                {/* <p>{topMover?.expiry ? topMover.expiry : formattedDate}</p> */}
                <p>{topMover?.currency_names ? topMover?.currency_names : topMover?.expiry ? topMover.expiry : formattedDate  }</p>
                {/* <p>{topMover?.trading_symbol ? topMover?.trading_symbol : topMover?.expiry ? topMover.expiry : formattedDate  }</p> */}
              </div>
              <div className="text-end">
                <span style={percentChange<0?{ color:'red'}:{ color:'green'}}>
                <i className={percentChange<0?"fa fa-arrow-down":"fa fa-arrow-up"} aria-hidden="true"> </i>                   
                {pricechange} ({percentChange}) %
                </span>
              </div>
            </div>
          </div>         
        </div>
      );
    });


  const renderCurrencyTrades = (currency) => {
    const trades = populartrade?.payload?.popular|| [];
    return trades.length > 0 ? renderTopMovers(trades) : <p>No {currency} symbols available</p>;
  };

  return (
    <div>
    
      {loading && <p>Loading...</p>}
      {error && <p>Error loading trades: {error.message}</p>}

      <SortingDrawer setDrawer={false}/>
      {renderCurrencyTrades(currency)}
      {/* {selectedMoverData && (
        <Stockdetails isDrawerOpen={isDrawerOpen} moverData={selectedMoverData} onClose={() => setIsDrawerOpen(false)}/>
      )} */}
      {selectedMoverData && (
        selectedMoverData.exchange === 'MCX' || selectedMoverData.exchange === 'NFO' ? (
          <Stockdetails 
            isDrawerOpen={isDrawerOpen} 
            moverData={selectedMoverData} 
            onClose={() => setIsDrawerOpen(false)} 
          />
        ) : null
      )}
      <AlertsetDrawer
          issetalert={issetalert}
          moverData={selectedMoverData}
          onClose={() => setIssstAlert(false)}
        />
    </div>
    
  );
};

export default Popular;
