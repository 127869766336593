import React, { useEffect, useRef, useState } from 'react';
import './Bottomdrawer.css';
import Tradetab from '../Tradetab/Tradetab';

const tradingTerminalData = [
  { id: 1, title: 'Today' },
  { id: 2, title: 'Week' },
  { id: 3, title: 'Month' },
  { id: 4, title: 'Year' }
];

const Addalert = ({ isDrawerOpen, setIsDrawerOpen }) => {
    const drawerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                setIsDrawerOpen(false);
            }
        };

        if (isDrawerOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDrawerOpen, setIsDrawerOpen]);

    const [checkedItems, setCheckedItems] = useState(new Array(tradingTerminalData.length).fill(false));

    const toggleCheck = (index) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.map((item, i) => (i === index ? !item : item))
        );
    };

    return (
        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
            {/* Prevent closing when clicking inside */}
            <div className="drawer-content" ref={drawerRef}>
                <button className="close-btn alertclosebtn" onClick={() => setIsDrawerOpen(false)}>✖</button>
                <Tradetab display="block" searchicon="none" hideSearchIcon={true} alert="alert" />
            </div>
        </div>
    );
};

export default Addalert;
