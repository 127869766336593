import React, { useEffect, useRef, useState } from 'react';
import './Bottomdrawer.css';
import { useNavigate } from 'react-router-dom';
import { getUserToken } from '../../helper/getToken';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { fetchOrder } from '../../Redux/actions/orderAction';
import { fetchUserPosition } from '../../Redux/actions/userAction';
import { getCurrencySymbol2 } from '../../helper/commonFunctions';
import CancelOrderdrawer from './CancelOrderdrawer';
import PartialOrderclosedrawe from './PartialOrderclosedrawe';

const Exittradedrawer = ({setShowPopup,setPopupMessage,livePrice, isDrawerOpen,setIsDrawerOpen, moverData, onClose, takeProfitPlus,
  setTakeProfitPlus,
  stopLossPlus,
  setStopLossPlus,
  tradeVol,
  setTradeVol,
  isError,
  errorMsg,
  setIsError,
  setErrorMsg,
  calculateProfitLoss
}) => { 
  const[opencancelorder,setOpenCancelOrder] = useState(false); 
  const[opendetailsorder,setOpenDetailsOrder] = useState(false);
  const [isUpdating,setIsUpdating]=useState(false)
  const positionMoverdata = moverData;

  

  
  const navigate = useNavigate();  
  const { priceSocket } = useSelector((state) => state.sockets);
  const [livePrices, setLivePrices] = useState({}); 
  function formatNumber(input) {
    try{
        const num = parseFloat(input);
        if (isNaN(num)) {
            throw new Error("Invalid number");
        }
        if (num.toString().includes('.') && num.toString().split('.')[1].length > 6) {
            return parseFloat(num.toFixed(6));
        }
        return num;
    }catch(err){
        return 0;
    }
}
  // const getLivePrice=(livePrices)=>{
  //   // console.log("Getprice   ===",livePrices)
  //   if(moverData?.type ==="sell"){
  //     if(livePrices?.ltp&&livePrices?.ltp!=0){
  //       return formatNumber(livePrices?.ltp)
  //     }else if(livePrices?.ask_price&&livePrices?.ask_price!=0){
  //       return formatNumber(livePrices?.ask_price)
  //     }else if(livePrices?.price&&livePrices?.price!=0){
  //       return formatNumber(livePrices?.price)
  //     }else if(livePrices?.open_price&&livePrices?.open_price!=0){
  //       return formatNumber(livePrices?.open_price);
  //     }else {
  //       return null;
  //     }
  //   }else{
  //     if(livePrices?.ltp&&livePrices?.ltp!=0){
  //       return formatNumber(livePrices?.ltp)
  //     }else if(livePrices?.bid_price&&livePrices?.bid_price!=0){
  //       return formatNumber(livePrices?.bid_price)
  //     }else if(livePrices?.price&&livePrices?.price!=0){
  //       return formatNumber(livePrices?.price)
  //     }else if(livePrices?.open_price&&livePrices?.open_price!=0){
  //       return formatNumber(livePrices?.open_price);
  //     }else {
  //       return null;
  //     }
  //   }
  // }

  const getLivePrice=(livePrices)=>{
    if(moverData?.type ==="sell"){
      if(livePrices?.ltp&&livePrices?.ltp!=0){
        return formatNumber(livePrices?.ltp)
      }else if(livePrices?.ask_price&&livePrices?.ask_price!=0){
        return formatNumber(livePrices?.ask_price)
      }else if(livePrices?.price&&livePrices?.price!=0){
        return formatNumber(livePrices?.price)
      }else if(livePrices?.open_price&&livePrices?.open_price!=0){
        return formatNumber(livePrices?.open_price);
      }else {
        return null;
      }
    }else{
      if(livePrices?.ltp&&livePrices?.ltp!=0){
        return formatNumber(livePrices?.ltp)
      }else if(livePrices?.bid_price&&livePrices?.bid_price!=0){
        return formatNumber(livePrices?.bid_price)
      }else if(livePrices?.price&&livePrices?.price!=0){
        return formatNumber(livePrices?.price)
      }else if(livePrices?.open_price&&livePrices?.open_price!=0){
        return formatNumber(livePrices?.open_price);
      }else {
        return null;
      }
    }
  }

  

  const dispatch = useDispatch();
  const token = getUserToken();
  const exchangedata = moverData?.exchange
  const exitPosition=()=>{     
    let data={
      exchange:moverData?.exchange,
      symbol:moverData?.symbol.split('/').join(''),
      price:getLivePrice(livePrices[moverData?.token])||moverData?.avg_price,
      order_type:"market",
      type:moverData?.type=='sell'?'buy':'sell',
      qty:parseInt(moverData?.qty),
      "from_custom_device_id": "web",
    }
    // let config = {
    //   url: `${process.env.REACT_APP_API_FRONTEND}client/order/pushForex`,
    
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: data,
    // };
    let config = {
      url: exchangedata === "CRYPTO"
        ? `${process.env.REACT_APP_API_FRONTEND}client/order/pushCrypto`
        : `${process.env.REACT_APP_API_FRONTEND}client/order/pushForex`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios.request(config).then(response=>{
      // alert(response.data.message)
      setPopupMessage(response.data.message);
      setShowPopup(true) 
      dispatch(fetchUserPosition(token));     
    }).catch(err=>{
      setPopupMessage(err?.response?.data?.message||err?.message||"something went wrong")
      setShowPopup(true);
      console.log(err)
    })
  }
  // useEffect(() => {
  //   if (moverData && priceSocket) {
  //     const event = `room${moverData.token}`;
  //     priceSocket.emit("tokenData", moverData.token);  
  //     const handlePriceUpdate = (updatedInstrument) => {
  //       if (updatedInstrument && updatedInstrument.token) {
  //         setLivePrices((prev) => ({
  //           ...prev,
  //           [updatedInstrument.token]: {
  //             ...prev[updatedInstrument.token],
  //             ...updatedInstrument,
  //           },
  //         }));
  //       }
  //     };
  
  //     priceSocket.on(event, handlePriceUpdate);  
  //     return () => {
  //       priceSocket.off(event, handlePriceUpdate);
  //     };
  //   }
  // }, [moverData, priceSocket]);

  useEffect(() => {
    if (moverData && priceSocket) {
      // Conditionally set the event based on exchange type
      const event = 
        moverData.exchange === "FOREX" || moverData.exchange === "CRYPTO" || moverData.exchange === "US"
          ? `forexRoom${moverData.symbol?.toLowerCase()}`
          : `room${moverData.token}`;
      
      // Emit the tokenData
      priceSocket.emit("tokenData", moverData.token);
  
      // Handle price update
      const handlePriceUpdate = (updatedInstrument) => {
        if (updatedInstrument && updatedInstrument.token) {
          setLivePrices((prev) => ({
            ...prev,
            [updatedInstrument.token]: {
              ...prev[updatedInstrument.token],
              ...updatedInstrument,
            },
          }));
        }
      };
  
      // Listen for price updates on the correct event
      priceSocket.on(event, handlePriceUpdate);
  
      // Cleanup the event listener
      return () => {
        priceSocket.off(event, handlePriceUpdate);
      };
    }
  }, [moverData, priceSocket]);

  const currentData = livePrices[moverData?.token] || moverData;
  // console.log(currentData,'jjjjjjjjjjjjjjjjjjjjjjjj')
 

 
  
  const handleBuy = () => { 
      navigate('/buy', { state: {currentData,moverData} });
  };

  

  const handleSell = () => {
    navigate('/sell', { state: {currentData,moverData} });
  };
  
  const handleAddExitAction = () => {
    if (moverData?.type === "buy" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleBuy();
    } else if (moverData?.type === "sell" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleSell();
    } else {
      exitPosition();
    }
  };

  const handleExitAction = () => {
    if (moverData?.type === "sell" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleBuy();
    } else if (moverData?.type === "buy" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleSell();
    } else {
      exitPosition();
    }
  };

  const handleCancelOrder = (e) => {
    e.stopPropagation();
    if (moverData?.type === "sell" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleBuy();
    } else if (moverData?.type === "buy" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleSell();
    } else {
      // exitPosition();
      
      setIsDrawerOpen(false);  
      setOpenCancelOrder(true);
    }
  };

  const handleDetailsorder = () => {
    if (moverData?.type === "sell" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleBuy();
    } else if (moverData?.type === "buy" && (moverData?.exchange === "MCX" || moverData?.exchange === "NFO")) {
      handleSell();
    } else {
      // exitPosition();
     
      setOpenDetailsOrder(true)
    }
  };
  const drawerRef = useRef(null);
  const handleDrawerClick = (e) => {
      // Check if the click was outside the drawer content
      if (drawerRef.current && !drawerRef.current.contains(e.target)) {
          onClose();  // Trigger the onClose function passed as prop
      }
  };
  const incrementTakeProfit=(takeprofit,type)=>{
    let currentVal=parseFloat(takeprofit.val)||0;
    let newVal=parseFloat(currentVal+0.1).toFixed(2);
    type=='profit'?setTakeProfitPlus({type:takeprofit.type,val:newVal}):setStopLossPlus({type:takeprofit.type,val:newVal});
  }
  const decrementTakeProfit=(takeprofit,type)=>{
    let currentVal=parseFloat(takeprofit.val)||0;
    let newVal=parseFloat(currentVal-0.1).toFixed(2);
    if(!takeprofit.val||takeprofit.val==0){
      type=='profit'?setTakeProfitPlus({...tradeVol,val:0}):setStopLossPlus({...tradeVol,val:0});
      return;
    }
    type=='profit'?setTakeProfitPlus({type:takeprofit.type,val:newVal}):setStopLossPlus({type:takeprofit.type,val:newVal});
  }
  const clearData=(takeprofit,typeField)=>{
    let type=takeprofit.type;
    let val=0;
    typeField=='profit'?setTakeProfitPlus({type:type,val:val}):setStopLossPlus({type:type,val:val});
  }
  const incrementTradeVol=()=>{
    // console.log(tradeVol,moverData,'hhhhhhhhyyyyyyyyyyyyooooooooooooooooo')
    let currentVal=parseFloat(tradeVol?.val)||0;
    let newVal=tradeVol?.type=='lots'?parseFloat(currentVal+0.01).toFixed(2):parseFloat(currentVal+1);
    let lotsize=currentData?.lotsize||1;
    let qty=moverData?.qty; 
    if(qty&&lotsize){
      if(tradeVol?.type=='lots'){
        let lotLimit=Number(qty)/Number(lotsize);
        if(newVal<=lotLimit){
          setTradeVol({...tradeVol,val:newVal});
          setIsError(false)
          setErrorMsg('')
        }else{
          setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
        }
      }else if(tradeVol?.type=='usd'){
        if(newVal<=Number(qty)){
          setErrorMsg('')
          setIsError(false);
          setTradeVol({...tradeVol,val:newVal});
        }else{
          setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
        }
      }
    }else{
      setIsError(false)
      setErrorMsg('')
      return;
    }
  }
  const decrementTradeVol=()=>{
    let currentVal=parseFloat(tradeVol.val)||0;
    let lotsize=currentData?.lotsize||1;
    if(!tradeVol.val||tradeVol.val<=0){
      setTradeVol({...tradeVol,val:0})
      return;
    }
    let newVal=tradeVol.type=='lots'?parseFloat(currentVal-0.01).toFixed(2):parseFloat(currentVal-1);
    setTradeVol({...tradeVol,val:newVal});
    if(tradeVol?.type=='lots'){
      let lotlimit=Number(moverData?.qty)/Number(lotsize);
      if(newVal>lotlimit){
        setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
      }else{
        setIsError(false)
        setErrorMsg('');
      }
    }else if(tradeVol?.type=='usd'){
      if(newVal>Number(moverData?.qty)){
        setIsError(true);
        setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
      }else{
        setIsError(false)
        setErrorMsg('')
      }
    }
  }
  const handleSelectChangeVolume=(e)=>{
    const typeFieldVal=e.target.value;
    setTradeVol({type:typeFieldVal,val:0});
  }
  const handleChangeTradeVol=(e)=>{
      const value=e.target.value;
      
  const regex= /^[0-9]*\.?[0-9]*$/
  // const regex= /^\d+(\.\d+)?$/
  if(regex.test(value)){
    if(moverData?.exchange=="CRYPTO"){
      if(value<Number(moverData?.qty)){
        setIsError(false)
        setErrorMsg('')
        setTradeVol({...tradeVol,val:value})
        return
      }else{
        setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
          setTradeVol({...tradeVol,val:value})
          return
      }
    }
    if(moverData?.qty&&currentData?.lotsize){
      if(tradeVol?.type=='lots'){
        let lotlimit=Number(moverData?.qty)/Number(currentData?.lotsize);
        if(value<=lotlimit){
          setIsError(false);
          setErrorMsg('');
          setTradeVol({...tradeVol,val:value})
        }else{
          setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
          setTradeVol({...tradeVol,val:value})
        }
      }else if(tradeVol?.type=='usd'){
        if(value<Number(moverData?.qty)){
          setIsError(false)
          setErrorMsg('')
          setTradeVol({...tradeVol,val:value})
        }else{
          setIsError(true);
          setErrorMsg('Error: Quantity exceeds shares in your portfolio.')
          setTradeVol({...tradeVol,val:value})
        }
      }else{
        setIsError(false)
        setErrorMsg('')
        // console.log('hi this is text message','ooooppppiiiiiiiiiii')
        e.preventDefault();
        return;
      }
    }else{
      setIsError(false)
      setErrorMsg('')
      // console.log('hi this is text message','ooooppppiiiiiiiiiii')
      e.preventDefault();
      return;
    }
    
  }else{
    setIsError(false)
      setErrorMsg('')
    e.preventDefault();
    // console.log('hi this is an error','ooooppppiiiiiiiiiii')
    return;
  }
  }
  useEffect(()=>{
    takeProfitPlus?.val || stopLossPlus?.val || takeProfitPlus?.type > 0 ? setIsUpdating(true) : setIsUpdating(false);
    return ()=>{
      setIsUpdating(false);
    }
  },[ takeProfitPlus?.val, stopLossPlus?.val]);
  useEffect(() => {
          if (isDrawerOpen) {
              document.addEventListener('click', handleDrawerClick);
          } else {
              document.removeEventListener('click', handleDrawerClick);
          }
  
          // Cleanup the event listener when the component is unmounted or drawer state changes
          return () => {
              document.removeEventListener('click', handleDrawerClick);
          };
      }, [isDrawerOpen]);  

  

  const handleGotoStockdetails =async (symbol,exchange)=>{ 
    try{
      let data={symbol:symbol}  
    let config={
        url:`${process.env.REACT_APP_API_FRONTEND}client/market/fetchDataBySymbol`,
        method:"POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data:data
      }
      let response=await axios.request(config)
      // console.log(response,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
      let allData;
      if(response?.data?.status=="SUCCESS")
      allData=response?.data?.payload;
      else
      allData={};
      navigate('/stockdetails',{
        state: {
          idtoken: symbol,
          exchange: exchange,
          moverData:{...moverData,...allData}
        },
      })
    }catch(err){
      console.log(err,'err in fetch specific stock')
      navigate('/stockdetails',{
        state: {
          idtoken: symbol,
          exchange: exchange,
          moverData:moverData
        },
      })
    }
  }


  const calculateTotalQuantities = (data) => {
    if (!data || typeof data !== 'object') {
        return { totalBidQty: 0, totalAskQty: 0 };
    }  
    const bidQtyKeys = ['bid_qty', 'bid_qty1', 'bid_qty2', 'bid_qty3', 'bid_qty4'];
    const askQtyKeys = ['ask_qty', 'ask_qty1', 'ask_qty2', 'ask_qty3', 'ask_qty4'];  
    const totalBidQty = bidQtyKeys.reduce((sum, key) => sum + (parseInt(data[key]) || 0), 0);
    const totalAskQty = askQtyKeys.reduce((sum, key) => sum + (parseInt(data[key]) || 0), 0);  
    return { totalBidQty, totalAskQty };
  };
  
  const { totalBidQty, totalAskQty } = calculateTotalQuantities(currentData);

 const handleCloseDrawer=()=>{
  
  setIsDrawerOpen(false)
 }

 const handleChangeTakeProfit=(e)=>{
  const value=e.target.value;
  const regex= /^[0-9]*\.?[0-9]*$/
  if(regex.test(value)){
      
setTakeProfitPlus({...takeProfitPlus,val:value});

}else{
  e.preventDefault();
}
  
}
const handleChangeStopLoss=(e)=>{
  const value=e.target.value;
  const regex= /^[0-9]*\.?[0-9]*$/
  if(regex.test(value)){
      
setStopLossPlus({...stopLossPlus,val:value});

}else{
  e.preventDefault();
}
  
}

const profitloss=calculateProfitLoss(moverData)
// console.log(profitloss,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
  
  return (
    // <div className={`drawer ${isDrawerOpen ? 'open' : ''}`} >
    // <div className={`drawer ${isDrawerOpen ? 'open' : ''}`} onClick={()=>setIsDrawerOpen(true)}>
  <>  <div 
  className={`drawer ${isDrawerOpen ? 'open' : ''}`} 
  onClick={() => {
    if (moverData?.exchange === "FOREX" || moverData?.exchange === "US" || moverData?.exchange === "CRYPTO") {
      onClose();
    } else {
      handleCloseDrawer();
    }
  }}
>
      <div className="drawer-content">
        {currentData ? (
          
          <div className="drawerconteninner">
            <div className="namediv">
              <div className="nameflex upperdiv">
                <h4>{currentData.symbol} {currentData.exchange}</h4>
                <h4>{getCurrencySymbol2(currentData.exchange)} {getLivePrice(currentData) || 0.00}</h4>
              </div>
              <div className="nameflex lowerdiv">
                <p>Lot Size: {currentData?.lotsize?currentData.lotsize:1}</p>
                <p>{getCurrencySymbol2(currentData.exchange)} {currentData.price_change||0} ({currentData.percentage_change||0}%)</p>
              </div>
            </div>
            {/* <div className="nameflex lowheighdiv">
              <div> <p>Open</p> <h4>{formatNumber(currentData.open_price)}</h4> </div>
              <div> <p>High</p> <h4>{formatNumber(currentData.high_price)}</h4> </div>
              <div> <p>Low</p> <h4>{formatNumber(currentData.low_price)}</h4> </div>
              <div> <p>Close</p> <h4>{formatNumber(currentData.close_price)}</h4> </div>
            </div> */}
           
            
            {
              currentData.exchange == "NFO" || currentData.exchange == "MCX" ? (
                <div className="dtydiv">
              <table className="table table-responsive buysell">
                <thead>
                  <tr>
                    <th>QTY.</th>
                    <th>BUY PRICE</th>
                    <th>SELL PRICE</th>
                    <th>QTY.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{currentData.bid_qty}</td>
                    <td><div className="graph">{currentData.bid_price}</div></td>
                    <td><div className="graph red">{currentData.ask_price}</div></td>
                    <td>{currentData.ask_qty}</td>
                  </tr>                  
                  <tr>
                    <td>{currentData.bid_qty2}</td>
                    <td><div className="graph">{currentData.bid_price2}</div></td>
                    <td><div className="graph red">{currentData.ask_price2}</div></td>
                    <td>{currentData.ask_qty2}</td>
                  </tr>
                  <tr>
                    <td>{currentData.bid_qty3}</td>
                    <td><div className="graph">{currentData.bid_price3}</div></td>
                    <td><div className="graph red">{currentData.ask_price3}</div></td>
                    <td>{currentData.ask_qty3}</td>
                  </tr>
                  <tr>
                    <td>{currentData.bid_qty4}</td>
                    <td><div className="graph">{currentData.bid_price4}</div></td>
                    <td><div className="graph red">{currentData.ask_price4}</div></td>
                    <td>{currentData.ask_qty4}</td>
                  </tr>
                  <tr>
                    <td><b>{totalBidQty}</b></td>
                    <td className="text-center" colSpan={2}><b>TOTAL QUANTITY</b></td>
                    <td><b>{totalAskQty}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
              ): <>
                <div onClick={(e)=>e.stopPropagation()} className='forexbottomdrawer'>
              <ul className="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button  className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#marketorder" type="button" role="tab" aria-controls="home" aria-selected="true">Modify</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button  className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#buylimit" type="button" role="tab" aria-controls="profile" aria-selected="false" >Partial close</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button  className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#buystop" type="button" role="tab" aria-controls="contact" aria-selected="false" >Cancel by</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="marketorder" role="tabpanel" aria-labelledby="home-tab">
                  <div className="foresmain">
                        <div className="forexclear mt-1">
                            <label>Take Profit</label>
                            <button onClick={()=>clearData(takeProfitPlus,'profit')}>Clear</button>
                        </div>
                        <div className="d-flex align-items-center w-100">
                            <div className="volume-input">
                                {/* <button onClick={() => setProfitplus((prev) => parseFloat((prev - 0.10).toFixed(2)))}>-</button> */}
                                <button onClick={()=>decrementTakeProfit(takeProfitPlus,'profit')}>-</button>
                                <input onChange={handleChangeTakeProfit} type="text" value={takeProfitPlus?.val?takeProfitPlus?.val:''} placeholder='Not set'/> 
                                <button onClick={()=>incrementTakeProfit(takeProfitPlus,'profit')}>+</button>
                            </div>
                            <select value={takeProfitPlus?.type} onChange={(e)=>setTakeProfitPlus({type:e.target.value,val:takeProfitPlus.val})} className="cusselect">
                                <option value="in_price">Price</option>
                                <option value="in_asset_price">By asset price</option>
                                <option value="in_money">In money</option>
                                <option value="in_percentage">In % of equity</option>
                                <option value="in_pip">In pips</option>
                            </select>
                        </div>
                  </div>
                  <div className="foresmain">
                      <div className="forexclear mt-1">
                          <label>Stop Loss</label>
                          <button onClick={()=>clearData(stopLossPlus,'stoploss')} >Clear</button>
                      </div>
                      <div className="d-flex align-items-center w-100">
                          <div className="volume-input">
                              {/* <button onClick={() => setStoplossplus((prev) => parseFloat((prev - 0.10).toFixed(2)))}>-</button> */}
                              <button onClick={()=>decrementTakeProfit(stopLossPlus,'stoploss')} >-</button>
                              <input onChange={handleChangeStopLoss} value={stopLossPlus?.val?stopLossPlus?.val:''} type="text" placeholder='Not set' />
                              <button onClick={()=>incrementTakeProfit(stopLossPlus,'stoploss')}>+</button>
                          </div>
                          <select value={stopLossPlus?.type} onChange={(e)=>setStopLossPlus({type:e.target.value,val:stopLossPlus.val})} className="cusselect" >
                              <option value="in_price">Price</option>
                              <option value="in_asset_price">By asset price</option>
                              <option value="in_money">In money</option>
                              <option value="in_percentage">In % of equity</option>
                              <option value="in_pip">In pips</option>
                          </select>
                      </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="buylimit" role="tabpanel" aria-labelledby="profile-tab">
                  <div className="foresmain">
                      <div className="forexclear mt-1">
                          <label>Closing Volumes</label>
                      </div>
                      <div className="d-flex align-items-center w-100">
                          <div className="volume-input w-100" style={{borderRadius:'5px'}}>
                              {/* <button onClick={() => setStoplossplus((prev) => parseFloat((prev - 0.10).toFixed(2)))}>-</button> */}
                              <button onClick={decrementTradeVol} >-</button>
                              <input value={tradeVol?.val?tradeVol?.val:""} onChange={handleChangeTradeVol} type="text" placeholder='Not set' style={{width:"100%"}}/>
                              <button onClick={incrementTradeVol}>+</button>
                          </div>   
                          {moverData?.exchange=="CRYPTO"?<select value={tradeVol.type} className="cusselect" onChange={handleSelectChangeVolume}>
                                <option value="usd">USD</option>
                            </select>:<select value={tradeVol.type} className="cusselect" onChange={handleSelectChangeVolume}>
                                <option value="lots">Lots</option>
                                <option value="usd">Units</option>
                            </select>}                       
                      </div>

                  </div>
                  {isError?<div style={{marginTop:"5px",color:"red"}}>{errorMsg}</div>:<></>}
                  <div className="nameflex mt-2"> <p>Estimated P&L</p> <p>{calculateProfitLoss(moverData)}</p> </div>
                </div>
                <div className="tab-pane fade" id="buystop" role="tabpanel" aria-labelledby="contact-tab">
                  <p>Content for Cancel by tab.</p>
                </div>
              </div>
            </div>
              </>
            }
            <div className="detailsdiv">              
              {
                currentData.exchange == "NFO" || currentData.exchange == "MCX" ? (
                  <>
                    <div className="nameflex"> <p>Last traded at</p> <p>{getCurrencySymbol2(currentData.exchange)} {currentData.ltp}</p> </div>
                    <div className="nameflex"> <p>Lower circuit</p> <p>{currentData.lower_circuit}</p> </div>
                    <div className="nameflex"> <p>Upper circuit</p> <p>{currentData.upper_circuit}</p> </div>
                    <div style={profitloss<0?{color:"red"}:{color:"green"}} className="nameflex"> <p>P & L</p> <p>{`₹ ${profitloss}`}</p> </div>
                  </>
                ) : ""
              }
            </div>
            {/* <div className="nameflex btndiv2">              
              <div className="buy"> <button onClick={handleBuy}> <p>Add</p> <p><b>{getLivePrice(livePrices[moverData?.token]) || 'N/A'}</b></p> </button> </div>             
              <div className="sell"> <button onClick={exitPosition}> <p>Exit</p> <p><b>{getLivePrice(livePrices[moverData?.token])|| 'N/A'}</b></p> </button> </div>
            </div> */}
           {
            currentData.exchange == "MCX" || currentData.exchange =="NFO"?(
              <div className="nameflex btndiv2">              
                <div className="buy"> <button onClick={handleAddExitAction}><p>Add</p> <p><b>{getLivePrice(currentData)? getLivePrice(currentData) : 0.00}</b></p> </button> </div>
                <div className="sell"> <button onClick={handleExitAction}> <p>Exit</p> <p><b>{getLivePrice(currentData)? getLivePrice(currentData) : 0.00}</b></p> </button> </div>
              </div>
            ):(
              <>
              <div style={{display:"flex",gap:"5px",margin:"5px 0px"}}><input checked={isUpdating} onClick={(e)=>{e.stopPropagation();setIsUpdating(!isUpdating)}} type='checkbox' /> <p>Modify</p></div>
              <div style={profitloss<0?{color:"red"}:{color:"green"}} className="nameflex"> <p>P & L</p> <p>{`₹ ${profitloss}`}</p> </div>
              <div className="nameflex btndiv2">  
                 <div className="sell d-flex justify-content-between gap-10" style={{width:"100%",gap:"10px"}}> 
                      {/* <button onClick={handleDetailsorder} style={{background:"#d3d3d38f",color:"#000"}}> <p style={{color:"#000",fontWeight:"600"}}>Details</p></button> */}
                      <button onClick={()=>handleGotoStockdetails(currentData.symbol,currentData.exchange)} style={{background:"#d3d3d38f",color:"#000"}}> <p style={{color:"#000",fontWeight:"600",padding:"5px"}}>View on chart</p></button>
                      <button disabled={isError} onClick={handleCancelOrder} > <p>{isUpdating?"Modify Order":"Close Order"}</p></button>
                  </div>            
                {/* {
                  moverData.type=="sell" ?(
                    <div className="buy d-flex justify-content-between" style={{width:"100%",gap:"10px"}}> 
                        <button onClick={onClose} style={{width:"35%",background:"#d3d3d38f",color:"#000"}}><p style={{color:"#000",fontWeight:"600"}}>Close</p></button>
                        <button onClick={handleAddExitAction} style={{width:"65%",background:"red"}}><p>Exit</p> <p><b>{getLivePrice(currentData)? getLivePrice(currentData) : 0.00}</b></p> </button>
                    </div>
                  ):(
                    <div className="sell d-flex justify-content-between gap-10" style={{width:"100%",gap:"10px"}}> 
                        <button onClick={()=>handleGotoStockdetails(currentData.symbol,currentData.exchange)} style={{background:"#d3d3d38f",color:"#000"}}> <p style={{color:"#000",fontWeight:"600"}}>View on chart</p></button>
                        <button onClick={handleExitAction} > <p>Exit</p> <p><b>{getLivePrice(currentData)? getLivePrice(currentData) : 0.00}</b></p> </button>
                    </div>
                    
                  )
                } */}
                {/* <div className="sell"> <button onClick={handleExitAction}> <p>Exit</p> <p><b>{getLivePrice(currentData)? getLivePrice(currentData) : 0.00}</b></p> </button> </div> */}
              </div></>
            )
           }

          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>  
    </div>
      <CancelOrderdrawer
      isUpdating={isUpdating}
      setIsUpdating={setIsUpdating}
      opencancelorder={opencancelorder}            
      onClose={() => setOpenCancelOrder(false)}
     calculateProfitLoss={calculateProfitLoss(moverData)}
     moverData={moverData}
     tradeVol={tradeVol}
    takeProfitPlus={takeProfitPlus}
    stopLossPlus={stopLossPlus}
    currentData={currentData}
    setPopupMessage={setPopupMessage}
    setShowPopup={setShowPopup}
    />
    <PartialOrderclosedrawe 
      opendetailsorder={opendetailsorder}            
      onClose={() => setOpenDetailsOrder(false)}          
    />
    </>
  );
};

export default Exittradedrawer;
