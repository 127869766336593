// reducers/authReducer.js
const initialState = {
  loading: false,
  user: JSON.parse(localStorage.getItem("userData")) || null,
  error: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFYEMAIL_REQUEST':
    case 'SIGNIN_REQUEST':
    case 'SIGNUP_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'VERIFYEMAIL_SUCCESS':
    case 'SIGNIN_SUCCESS':
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case 'VERIFYEMAIL_FAILURE':
    case 'SIGNIN_FAILURE':
    case 'SIGNUP_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'SIGNOUT':
      return initialState;
    default:
      return state;
  }
};
