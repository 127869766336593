import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

const Alltabbtn = () => {
  const dispatch = useDispatch(); // ✅ Initialize dispatch
  const activeTab = useSelector((state) => state.trade.activeTab);
  const tabRefs = useRef({});

  useEffect(()=>{
    const tabId=localStorage.getItem('tabid');
    if(tabId){
      handleTabClick(tabId)
    }
  },[])

  const handleTabClick = (tabId) => {
    localStorage.setItem('tabid',tabId);
    dispatch({ type: "SET_ACTIVE_TAB", payload: tabId }); // ✅ Dispatch Redux action correctly
    dispatch({ type: "CLEAR_TRADE_DATA" });
  };

  useEffect(() => {
    if (tabRefs.current[activeTab]) {
      tabRefs.current[activeTab].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center", // Ensures horizontal centering
      });
    }
  }, [activeTab]);

  const tabs = [
    { id: "nav-fav", label: "Favourite" },
    { id: "nav-mov", label: "Top Movers" },
    { id: "nav-looser", label: "Top Losers" },
    { id: "nav-pop", label: "Popular" },
    { id: "nav-stocks", label: "Stocks" },
    { id: "nav-metal", label: "Metal" },
    { id: "nav-crypto", label: "Crypto" },
    { id: "nav-indices", label: "Indices" },
    { id: "nav-energies", label: "Energies" },
    { id: "nav-majors", label: "Majors" },
    { id: "nav-exotic", label: "Exotic" },
    { id: "nav-minors", label: "Minors" },
    { id: "nav-all", label: "All" },
  ];

  return (
    <>
      {tabs.map((tab) => (
        <button
          // key={tab.id}
          // className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
          // onClick={() => handleTabClick(tab.id)}
          // id={`${tab.id}-tab`}
          // data-bs-toggle="tab"
          // data-bs-target={`#${tab.id}`}
          // type="button"
          // role="tab"
          // aria-controls={tab.id}
          key={tab.id}
          ref={(el) => (tabRefs.current[tab.id] = el)} // Store ref for each tab
          className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
          onClick={() => handleTabClick(tab.id)}
          id={`${tab.id}-tab`}
          data-bs-toggle="tab"
          data-bs-target={`#${tab.id}`}
          type="button"
          role="tab"
          aria-controls={tab.id}
        >
          {tab.label}
        </button>
      ))}
    </>
  );
};

export default Alltabbtn;
