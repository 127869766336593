import React, { useState } from 'react'
import NewHeader from '../Header/NewHeader'
import checked from '../../Images/checked.png'

const Notificationsetting = () => {

  const [checkednotification, setCheckedNotification] = useState([false, false]);
  const toggleChecknotification = (index) => {
    setCheckedNotification((prevCheckedNotification) =>
      prevCheckedNotification.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <div className='accountsmain'>
        <NewHeader title="Notifications" addicon="none" />
        <div className='notisatting'>
          <h4 className='profileheading'>Operations</h4>
          <div className="card">
            <div className="card-body">
                {checkednotification.map((isChecked, index) => (
                    <div className='indiceslist' key={index} onClick={() => toggleChecknotification(index)} >                
                      <div className='leftlist'>                        
                          <p className='mb-0'>Trading</p>
                      </div>
                      <div className='rightlist'>
                          <span style={{ marginRight: '10px' }}>
                              {/* {isChecked ? '✅' : '⬜'} */}
                              {isChecked ? <div><img src={checked}/></div> : ''}
                          </span>
                      </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
    </div>
  )
}

export default Notificationsetting