import React, { useEffect, useState } from 'react';
import './Bottomdrawer.css';
import { useNavigate } from 'react-router-dom';
import { getUserToken } from '../../helper/getToken';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios';
import { fetchUserAlerts } from '../../Redux/actions/userAction';

const AlertsetDrawer = ({ issetalert, onClose, moverData }) => {
  const navigate = useNavigate();
  const { priceSocket } = useSelector((state) => state.sockets);
  const token = getUserToken();
  const dispatch=useDispatch();

  
  

  // State for API price, user input price, loading, and selected price type
  const [price, setPrice] = useState(0); // API price
  const [manualPrice, setManualPrice] = useState(""); // User-entered price (empty string for full keyboard control)
  const [loading, setLoading] = useState(true);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPriceType, setSelectedPriceType] = useState("BID");
  const [priceFlow,setPriceFlow]=useState("GREATER");


  const getPrice=(moverData)=>{   
    if(moverData?.ltp&&moverData?.ltp!==0){
      return moverData?.ltp
    }else if(moverData?.bid_price&&moverData?.bid_price!==0){
      return moverData?.bid_price
    }else if(moverData?.price&&moverData?.price!==0){
      return moverData?.price
    }else if(moverData?.open_price&&moverData?.open_price!==0){
      return moverData?.open_price
    }else if(moverData?.ask_price&&moverData?.ask_price!==0){
      return moverData?.ask_price
    }else{
      return 0;
    }
  }

  useEffect(() => {
    setLoading(true);
    if (moverData) {
      const newPrice = getPrice(moverData);
      setPrice(newPrice);
      setManualPrice(newPrice?.toString()); // Sync manual input with fetched price
      setTimeout(() => setLoading(false), 500); // Simulate loading delay
    }
  }, [moverData]);

  // useEffect(() => {
  //   setLoading(true);
  //   if (moverData?.exchange) {
  //     // const newPrice = moverData?.exchange === "FOREX" ? moverData?.ask_price : moverData?.ltp;
  //     const newPrice = getPrice(moverData);
  //     setPrice(newPrice);
  //     setManualPrice(newPrice?.toString()); // Initialize user input with API price
  //     setTimeout(() => setLoading(false), 500); // Simulate loading delay
  //   }
  // }, [moverData?.exchange, moverData?.ask_price, moverData?.ltp]);

  // Handle manual input (allows full keyboard entry)
  const handleManualPriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      // Only allow numbers & decimal point
      setManualPrice(value);
    }
  };

  // Handle Increment & Decrement (prevents negative values)
  const handleIncrement = () => {
    setManualPrice((prev) => (prev ? (parseFloat(prev) + 1).toString() : "1"));
  };

  const handleDecrement = () => {
    setManualPrice((prev) => {
      const newValue = parseFloat(prev) - 1;
      return newValue > 0 ? newValue.toString() : "0";
    });
  };

  // Handle dropdown change
  const handlePriceTypeChange = (e) => {
    setSelectedPriceType(e.target.value);
  };

  // Handle form submission
  // const handleSubmit = () => {
  //   if (loading || !manualPrice) return;

  //   let alertData = {};
  //   if(moverData.exchange ==="NFO" || moverData.exchange==="MCX"){
  //     alertData = {
  //       token: moverData?.token,
  //       // token: moverData?.symbol,
  //       exchange:moverData?.exchange,
  //       price: parseFloat(manualPrice), // Convert string to number before submitting
  //       price_flow:priceFlow,
  //       price_type: selectedPriceType,
  //       currency:"INR",
  //       duration:7,
  //     };
  //   }else{
  //     alertData = {
  //       token: moverData?.symbol,
  //       exchange:moverData?.exchange,
  //       price: parseFloat(manualPrice), // Convert string to number before submitting
  //       price_flow:priceFlow,
  //       price_type: selectedPriceType,
  //       currency:"USD",
  //       duration:7
  //     };
  //   }
  //   let config={
  //     url:`${process.env.REACT_APP_API_FRONTEND}client/notification/alertPrice`,
  //     method:"POST",
  //     headers:{
  //       Authorization:`Bearer ${token}`
  //     },
  //     data:alertData
  //   }
  //   axios.request(config).then(response=>{
  //     setPopupMessage(response?.data?.message)
  //     setShowPopup(true)
  //     dispatch(fetchUserAlerts(token))
  //   }).catch(err=>{
  //     setPopupMessage(err?.response?.data?.message||err?.message||"something went wrong")
  //     setShowPopup(true)
  //     console.log(err)

  //   })
    
  //   // TODO: Send `alertData` to API or Redux store

  //   onClose(); // Close drawer after submission
  // };

  const handleSubmit = () => {
    if (loading || !manualPrice) return;
    let alertData = {
      token: moverData?.exchange === "NFO" || moverData?.exchange === "MCX" ? moverData?.token : moverData?.symbol,
      exchange: moverData?.exchange,
      price: parseFloat(manualPrice), // Convert string to number before submitting
      price_flow: priceFlow,
      price_type: selectedPriceType,
      currency: moverData?.exchange === "NFO" || moverData?.exchange === "MCX" ? "INR" : "USD",
      duration: 7,
    };
    let config = {
      url: `${process.env.REACT_APP_API_FRONTEND}client/notification/alertPrice`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: alertData,
    };
  
    axios
      .request(config)
      .then((response) => {
        setPopupMessage(response?.data?.message);
        setShowPopup(true);
        dispatch(fetchUserAlerts(token));
      })
      .catch((err) => {
        setPopupMessage(err?.response?.data?.message || err?.message || "Something went wrong");
        setShowPopup(true);
        console.log("Error submitting alert:", err);
      });
  
    onClose();
  };
  


  
  return (
    <div className={`drawer ${issetalert ? 'open' : ''}`} onClick={onClose}>
      <div className="drawer-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn alertclosebtn" onClick={onClose}>✖</button>
        <div className="alertsetdrawer-header">
          <span className="crypto-title">{moverData?.symbol}</span>
          {loading ? (
            <span className="price-low"><ThreeDots height="20" width="40" color="green" /></span>
          ) : (
            <span className="price-low">{Number(price).toFixed(4)}</span>
          )}
        </div>

        {/* Live Prices */}
        <div className="live-prices">
          {loading ? (
            <span className="price-low"><ThreeDots height="20" width="40" color="green" /></span>
          ) : (
            <span className="price-high" style={{ color: moverData?.price_change > 0 ? "green" : "red" }}>
              {Number(moverData?.price_change).toFixed(4)}
            </span>
          )}
          {loading ? (
            <span className="price-low"><ThreeDots height="20" width="40" color="green" /></span>
          ) : (
            <span className="price-low" style={{ color: moverData?.percentage_change > 0 ? "green" : "red" }}>
              {Number(moverData?.percentage_change).toFixed(4)} %
            </span>
          )}
        </div>

        {/* Alert Price Input (Manual + Increment/Decrement) */}
        <div className="alert-input">
          <button className="btn-minus" disabled={loading} onClick={handleDecrement}>-</button>
          <input
            type="text"
            value={manualPrice}
            className="form-control"
            onChange={handleManualPriceChange}
            disabled={loading}
          />
          <button className="btn-plus" disabled={loading} onClick={handleIncrement}>+</button>
          <select
            className="price-type"
            disabled={loading}
            value={priceFlow}
            onChange={(e)=>setPriceFlow(e.target.value)}
          >
            <option value="GREATER">greater than</option>
            <option value="LESS">less than</option>
          </select>
          <select
            className="price-type"
            disabled={loading}
            value={selectedPriceType}
            onChange={handlePriceTypeChange}
          >
            <option value="BID">Bid price</option>
            <option value="ASK">Ask price</option>
          </select>
        </div>

        {/* Buttons */}
        <div className="alertsetdrawer-footer">
          <button className="cancel" onClick={onClose} disabled={loading}>Cancel</button>
          <button className="confirm" onClick={handleSubmit} disabled={loading || !manualPrice}>Confirm</button>
        </div>
      </div>
      {showPopup && (
                <div className='popup-overlay'>
                    <div style={{width:"300px"}} className='popup-content'>
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                </div>
            )}
    </div>
  );
};

export default AlertsetDrawer;
