import React, { useState } from 'react'
import './Customname.scss'
import NewHeader from '../Header/NewHeader'
import { useSelector } from 'react-redux'
function Customname() {
    const {user,loading,error} = useSelector((state)=>state.user);
    const [firstname, setUsername] = useState(user?.payload?.firstname || "");
    const handleChange = (e) => {
        setUsername(e.target.value);
    };
    return (
        <div className='accountsmain'>
            <NewHeader title={user?.payload?.username} addicon="none" />
            <div className='custom_name_container mt-3  '>
                <div className='custom-name'>                  
                    <input type="text" value={firstname} name="username" className="form-control" onChange={handleChange} maxLength={36} />
                    <p className="custom_detail-p">{firstname.length}/36</p>
                </div>
                <div className='custom_name-div-1'>
                    <span>save changes</span>
                </div>
            </div>

        </div>
    )
}

export default Customname
