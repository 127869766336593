import React from 'react'
import NewHeader from '../Header/NewHeader'
import Preferences from '../Settings/Preferences'
import Securities from '../Settings/Securities'
import Deletecomp from '../Settings/Deletecomp'

const Settings = () => {
  return (
    <div className='accountsmain'>
        <NewHeader title="Settings" addicon="none"/>
        <Preferences/>
        <Securities/>
        <Deletecomp/>
    </div>
  )
}

export default Settings