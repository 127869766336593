import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CandlestickChart = () => {
  const series = [
    {
      data: [
        {
          x: new Date(2024, 10, 1), // Date
          y: [6600, 6650, 6580, 6620], // [Open, High, Low, Close]
        },
        {
          x: new Date(2024, 10, 2),
          y: [6620, 6700, 6600, 6680],
        },
        {
          x: new Date(2024, 10, 3),
          y: [6680, 6800, 6650, 6700],
        },
        {
          x: new Date(2024, 10, 4),
          y: [6700, 6750, 6680, 6730],
        },
        {
          x: new Date(2024, 10, 5),
          y: [6730, 6800, 6700, 6770],
        },
        {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
          {
            x: new Date(2024, 10, 5),
            y: [6730, 6800, 6700, 6770],
          },
      ],
    },
  ];

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
    },
    title: {
      text:"" ,
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="candlestick" height={350} />
    </div>
  );
};

export default CandlestickChart;
