import React, { useState } from 'react';
import helpicon from '../../Images/Helpicons.svg';
import openticket from '../../Images/openticketicons.svg';
import livechat from '../../Images/Livechaticons.svg';
import suggestionicon from '../../Images/suggesticons.svg';
import legaldocument from '../../Images/LegalDocumenticons.svg';
import rateus from '../../Images/rateicons.svg';
import Modal from 'react-bootstrap/Modal';
import Popupticket from '../Popupmodal/Popupticket';
import { useNavigate } from 'react-router-dom';

const Support = () => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleShow = () => {
        setShow(true);
    };

    const handleOtherAction = () => {
        console.log("Another action triggered!");
    };
    const handleLegalAction = () => {
        navigate('/legaldocument')
    };
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

    const handleWhatsAppOpen = () => {
        // const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
        // const message = "Hello, I need support!";
        // const whatsappURL = `https://api.whatsapp.com/send/?phone=91${phoneNumber}&text=${message}&type=phone_number&app_absent=0`;
        // window.open(whatsappURL, "_blank");
        const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
        const message = "I want help";
        const whatsappWebURL = `https://wa.me/91${phoneNumber}?text=${encodeURIComponent(message)}`;
        const whatsappAppURL = `intent://send?phone=91${phoneNumber}&text=${encodeURIComponent(message)}#Intent;scheme=whatsapp;package=com.whatsapp;end;`;
        const isAndroidWebView = /wv/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);
        if (isAndroidWebView) {
            window.location.href = whatsappAppURL; // Works in WebView
        } else {
            window.open(whatsappWebURL, "_blank"); // Works in web browsers
        }
    };
    const handleLedgerOpen = () => {
        navigate('/userledger')
    };

    const supportData = [
        {
            id: 1,
            image: helpicon,
            title: "Help Center",
            subtitle: "Boost your income by sharing your trading strategies",
            // onClick: handleOtherAction,
            onClick: handleWhatsAppOpen,
        },
        // {
        //     id: 2,
        //     image: openticket,
        //     title: "Open Ticket",
        //     subtitle: "Copy successful strategies of other traders",
        //     // onClick: handleShow, 
        //     onClick: handleOtherAction, 
        // },
        {
            id: 3,
            image: livechat,
            title: "Live Chat",
            subtitle: "Boost your income by sharing your trading strategies",
            onClick: handleWhatsAppOpen,
        },

        {
            id: 4,
            image: livechat,
            title: "Ledger",
            subtitle: "All user ledger",
            onClick: handleLedgerOpen,
        },
        // {
        //     id: 4,
        //     image: suggestionicon,
        //     title: "Suggest a feature",
        //     subtitle: "Copy successful strategies of other traders",
        //     onClick: handleOtherAction,
        // },
        // {
        //     id: 5,
        //     image: legaldocument,
        //     title: "Legal documents",
        //     subtitle: "Boost your income by sharing your trading strategies",
        //     onClick: handleLegalAction,
        // },
        // {
        //     id: 6,
        //     image: rateus,
        //     title: "Rate the app",
        //     subtitle: "Copy successful strategies of other traders",
        //     onClick: handleOtherAction,
        // },
    ];

    return (
        <div className='supportdiv'>
            <h4 className='profileheading'>Support</h4>
            <div className="card">
                <div className="card-body">
                    {
                        supportData.map((support) => {
                            return (
                                <div
                                    className='innerdiv'
                                    key={support.id}
                                    onClick={support.onClick} // Use onClick from the object
                                >
                                    <div>
                                        <img src={support.image} alt="" />
                                    </div>
                                    <div style={{cursor:'pointer'}}>
                                        <p>{support.title}</p>
                                        <small>{support.subtitle}</small>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Modal className='cusmodal' show={show} onHide={() => setShow(false)}>
                <Popupticket name="" show={show} setShow={setShow} />
            </Modal>
        </div>
    );
};

export default Support;
