import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import gbpusd from '../../Images/gbpusd.svg';
import usdjpy from '../../Images/usdjpy.svg';
import ustech from '../../Images/ustech.svg';
import eurusd from '../../Images/eurusd.svg';
import equalsign from '../../Images/equal.png';
import close from '../../Images/close.png';
import Editmodal from './Editmodal';
import './Allmodal.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getUserToken } from '../../helper/getToken';

const baseURL = process.env.REACT_APP_API_FRONTEND;

const Notificationmodal = ({ name, setShow, show }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const [loading,setLoadingData] = useState(false);

  const {user}=useSelector((state) => state.user)
  const [notification, setAllNotification] = useState()

//   console.log("useruseruseruseruseruseruseruser",user?.payload?.client_account?.client_id);

  const handleEditModalShow = () => {
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const token = getUserToken();

  const getAllNotification = async () => {
    setLoadingData(true); // Show loading spinner
    try {
        let config={
            url:`${baseURL}client/client/fetchRejectionLogsByClientId`,
            method:"POST",
            headers: {
                Authorization: `Bearer ${token}`,
              },
        }
        const response = await axios.request(config)        
        setAllNotification(response.data.payload);
    } catch (error) {
        console.log("Error fetching news:", error);
    } finally {
        setLoadingData(false); // Hide spinner regardless of success or failure
    }
};

// function timeAgo(timestamp) {
//     const inputDate = new Date(timestamp.replace(" ", "T")); // Convert to Date object
//     const now = new Date();
//     const diffInSeconds = Math.floor((now - inputDate) / 1000);

//     if (diffInSeconds < 60) {
//         return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
//     } 
    
//     const diffInMinutes = Math.floor(diffInSeconds / 60);
//     if (diffInMinutes < 60) {
//         return `${diffInMinutes} min${diffInMinutes !== 1 ? "s" : ""} ago`;
//     }
    
//     const diffInHours = Math.floor(diffInMinutes / 60);
//     if (diffInHours < 24) {
//         return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
//     }

//     // If more than 24 hours, return the formatted date
//     const day = inputDate.getDate().toString().padStart(2, "0");
//     const month = inputDate.toLocaleString("en-US", { month: "short" }).toLowerCase();
//     const year = inputDate.getFullYear();
//     return `${day} ${month} ${year}`;
// }

const convertToIST = (timestamp) => {
  if (!timestamp) return null;

  let date = new Date(timestamp);
  if (isNaN(date.getTime())) {
      console.error("Invalid date format:", timestamp);
      return null;
  }

  // Convert to IST (UTC + 5:30)
  const istOffset = 5.5 * 60 * 60 * 1000;
  return new Date(date.getTime() + istOffset);
};


function timeAgo(timestamp) {
  if (!timestamp) return "Invalid date";

  let inputDate = new Date(timestamp);
  if (isNaN(inputDate.getTime())) {
      console.error("Invalid date format:", timestamp);
      return "Invalid date";
  }

  // Convert inputDate to IST (Indian Standard Time UTC+5:30)
  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(inputDate.getTime() + istOffset);

  // Get current IST time
  const nowUTC = new Date();
  const nowIST = new Date(nowUTC.getTime() + istOffset);

  const diffInSeconds = Math.floor((nowIST - istDate) / 1000);

  if (diffInSeconds < 60) return `${diffInSeconds} sec ago`;
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} hours ago`;

  // If more than 24 hours, return formatted IST date
  return istDate.toLocaleString("en-IN", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
  });
}





const renderNotificationData=(notification)=>{
    if(notification==null||notification==undefined){
        return <></>
    }else if(Array.isArray(notification)&&notification.length==0){
        return <></>
    }else if(Array.isArray(notification)&&notification.length>0){
        return (
          notification.map(val=>(
            <div className='notificationdiv'>
              <div className='descr'>
                  <small className='notifitext'>{val?.message}</small>
                  {/* <small className='notifitime'>{timeAgo(new Date(val.time).toISOString())}</small> */}                
              </div>
              <div className='datediv'>
                <small className='notifitime'>{timeAgo(convertToIST(val?.time))}</small>
              </div>
        </div>
          ))
        )
    }else{
        return <></>
    }
}

  useEffect(()=>{
    getAllNotification();
  },[])

//   console.log("notificationnotificationnotificationnotification",notification);

  return (
    <div>
      <Modal.Header className='cusheader'>
        <div className='left'>
          <button onClick={() => setShow(false)}> <img src={close} alt="close" style={{width:"15px"}}/> </button>
          <Modal.Title style={{fontSize:"16px"}}>{`Rejection Logs`}</Modal.Title>
        </div>
        <div className='right'>
          <button style={{fontSize:"14px",fontWeight:"400"}}><small>Read all</small></button>
        </div>
      </Modal.Header>
      <Modal.Body>
            <div className='topmovermain2'>
                <div className='favedit'>                  
                   {renderNotificationData(notification)}
                </div>
            </div>
      </Modal.Body>

      {/* Edit Modal */}
      <Modal className='cusmodal' show={showEditModal} onHide={handleEditModalClose}>
        <Editmodal name="Favourite" show={showEditModal} setShow={setShowEditModal} />
      </Modal>
    </div>
  );
}

export default Notificationmodal;
