// import axios from 'axios';
import { 
    FETCH_TRADE_REQUEST, FETCH_TRADE_SUCCESS, FETCH_TRADE_FAILURE,
    FETCH_WATCHLIST_REQUEST, FETCH_WATCHLIST_SUCCESS, FETCH_WATCHLIST_FAILURE,
    FETCH_TOPMOVERS_REQUEST, FETCH_TOPMOVERS_SUCCESS, FETCH_TOPMOVERS_FAILURE,
    FETCH_TOPLOOSER_REQUEST, FETCH_TOPLOOSER_SUCCESS, FETCH_TOPLOOSER_FAILURE,
    FETCH_ALLSTOCKS_REQUEST, FETCH_ALLSTOCKS_SUCCESS, FETCH_ALLSTOCKS_FAILURE,

    FETCH_ALLTRADEDATA_REQUEST, FETCH_ALLTRADEDATA_SUCCESS, FETCH_ALLTRADEDATA_FAILURE,
    FETCH_LEVERAGE_REQUEST ,FETCH_LEVERAGE_SUCCESS ,FETCH_LEVERAGE_FAILURE ,
    FETCH_MARGIN_REQUEST ,FETCH_MARGIN_SUCCESS ,FETCH_MARGIN_FAILURE ,
    
    
    SEARCH_WATCHLIST_REQUEST, SEARCH_WATCHLIST_SUCCESS, SEARCH_WATCHLIST_FAILURE,
    SEARCH_MARKETLIST_REQUEST, SEARCH_MARKETLIST_SUCCESS, SEARCH_MARKETLIST_FAILURE,    
    ADD_WATCHLIST_REQUEST, ADD_WATCHLIST_SUCCESS, ADD_WATCHLIST_FAILURE,
    INDEXFUND_REQUEST, INDEXFUND_SUCCESS, INDEXFUND_FAILURE,    
    DELETE_WATCHLIST_REQUEST,
    DELETE_WATCHLIST_SUCCESS,
    DELETE_WATCHLIST_FAILURE,
    INDICES_FUND_REQUEST,
    INDICES_FUND_SUCCESS,
    INDICES_FUND_FAILURE,
    METALS_REQUEST,
    METALS_SUCCESS,
    METALS_FAILURE,
    FETCH_CURRENCIES_REQUEST,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS
} from '../authCostants';
import {fetchTradedata,fetchWatchListdata,deleteWatchListdata,fetcTopMoverdata,fetcTopLooserdata,fetcAllstocksdata,searchWatchListdata,searchMarketListdata,addWatchListdata, fetchIndexfund, fetchIndicesFund, fetchMetals, fetchCurrencies, fetchAlltradeData, fetchLeverage, fetchMargin} from "../../helper/api_helper"
import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';



// Action to fetch order data with token
export const fetchTrade = (token) => async (dispatch) => {    
    dispatch({ type: FETCH_TRADE_REQUEST });
    try {
      const response = await fetchTradedata(token);  
      dispatch({ type: FETCH_TRADE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: FETCH_TRADE_FAILURE,
        payload: error.response?.data.message || error.message,
      });
    }
};

// Action to fetch order data with token
export const fetchWatchList = (token) => async (dispatch) => {    
  dispatch({ type: FETCH_WATCHLIST_REQUEST });
  try {
    const response = await fetchWatchListdata(token); 
    dispatch({ type: FETCH_WATCHLIST_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_WATCHLIST_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};


// export const deleteWatchList = (watchlisttoken, usertoken) => async (dispatch, getState) => {
//   dispatch({ type: DELETE_WATCHLIST_REQUEST });
//   try {
//     // Call the API to delete the watchlist item
//     const response = await deleteWatchListdata(watchlisttoken, usertoken);  // Ensure deleteWatchListdata is defined correctly

//     if (response.status === 200 || response.data?.status === 'SUCCESS') {
//       // Retrieve the current state
//       const state = getState();

//       // Find the currency where the token exists
//       const currency = Object.keys(state.trade.trade.payload || {}).find((cur) =>
//         state.trade.trade.payload[cur]?.some((item) => item.token === watchlisttoken)
//       );

//       // Dispatch success action with token and the identified currency
//       dispatch({ 
//         type: DELETE_WATCHLIST_SUCCESS, 
//         payload: { token: watchlisttoken, currency } 
//       });
//     } else {
//       throw new Error('Failed to delete the watchlist item');
//     }
//   } catch (error) {
//     // Dispatch failure action with an error message
//     dispatch({
//       type: DELETE_WATCHLIST_FAILURE,
//       payload: error.response?.data?.message || error.message || 'An error occurred',
//     });
//   }
// };

export const deleteWatchList = (watchlistToken, userToken) => async (dispatch, getState) => {
  dispatch({ type: DELETE_WATCHLIST_REQUEST });

  try {
    const response = await deleteWatchListdata(watchlistToken, userToken);
   

    if (response.status === 200 || response.data?.status === 'SUCCESS') {
      const state = getState();      
      dispatch({
        type: 'DELETE_WATCHLIST_SUCCESS',
        payload: { token: watchlistToken },
      });
      return { status: 'SUCCESS' };
    } else {
      throw new Error('Failed to delete the watchlist item');
    }
  } catch (error) {
    console.error("Error in deleteWatchList:", error);
    dispatch({
      type: DELETE_WATCHLIST_FAILURE,
      payload: error.response?.data?.message || error.message || 'An error occurred',
    });
    return { status: 'ERROR', error };
  }
};



export const addWatchList = (watchlistToken, userToken) => async (dispatch, getState) => {
  dispatch({ type: ADD_WATCHLIST_REQUEST });
  try {
    const response = await addWatchListdata(watchlistToken, userToken);

    if (response.status === 200 || response.data?.status === 'SUCCESS') {
      const state = getState();
      const currency = Object.keys(state.trade.trade.payload || {}).find((cur) =>
        state.trade.trade.payload[cur]?.some((item) => item.token === watchlistToken)
      );
      
      // Return success response from action
      dispatch({
        type: ADD_WATCHLIST_SUCCESS,
        payload: { token: watchlistToken, currency, newItem: response.data.item },
      });
      return { status: 'SUCCESS', data: response.data };
    } else {
      throw new Error('Failed to add the watchlist item');
    }
  } catch (error) {
    dispatch({
      type: ADD_WATCHLIST_FAILURE,
      payload: error.response?.data?.message || error.message || 'An error occurred',
    });
    return { status: 'ERROR', error };
  }
};





  export const searchWatchList = (token, formData) => async (dispatch) => {
    dispatch({ type: SEARCH_WATCHLIST_REQUEST });
    try {
      const response = await searchWatchListdata(token, formData);
      dispatch({ type: SEARCH_WATCHLIST_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({
        type: SEARCH_WATCHLIST_FAILURE,
        payload: error.response?.data.message || error.message,
      });
      throw error;
    }
  };

  export const searchMarketList = (token, formData) => async (dispatch) => {
    dispatch({ type: SEARCH_MARKETLIST_REQUEST });
    try {
      const response = await searchMarketListdata(token, formData);      
      dispatch({ type: SEARCH_MARKETLIST_SUCCESS, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({
        type: SEARCH_MARKETLIST_FAILURE,
        payload: error.response?.data.message || error.message,
      });
      throw error;
    }
  };

  

// Action to fetch order data with token
export const fetchTopmoversList = (token) => async (dispatch) => {    
  dispatch({ type: FETCH_TOPMOVERS_REQUEST });
  try {
    const response = await fetcTopMoverdata(token);
    dispatch({ type: FETCH_TOPMOVERS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_TOPMOVERS_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};



export const updateTopmoversList = (data) => ({
  type: 'UPDATE_TOP_MOVERS',
  payload: data,
});

// Action to fetch top looser data with token
export const fetchTopLoosersList = (token) => async (dispatch) => {    
  dispatch({ type: FETCH_TOPLOOSER_REQUEST });
  try {
    const response = await fetcTopLooserdata(token);  // Pass token here        
    dispatch({ type: FETCH_TOPLOOSER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_TOPLOOSER_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};

export const fetchAllStocksList = (token) => async (dispatch) => {    
  dispatch({ type: FETCH_ALLSTOCKS_REQUEST });
  try {
    const response = await fetchAlltradeData(token); 
      
    dispatch({ type: FETCH_ALLSTOCKS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: FETCH_ALLSTOCKS_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};

export const fetchAlltrade = (token,currentPage) => async (dispatch) => {    
  dispatch({ type: FETCH_ALLTRADEDATA_REQUEST });
  try {
    const response = await fetchAlltradeData(token,currentPage);      
   
    dispatch({ type: FETCH_ALLTRADEDATA_SUCCESS, payload:response.data.payload });
  } catch (error) {
    dispatch({
      type: FETCH_ALLTRADEDATA_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};




// Action to fetch Indexces fund data with token
export const fetchIndexfundList = (token) => async (dispatch) => { 
  dispatch({ type: INDEXFUND_REQUEST });
  try {
    const response = await fetchIndexfund(token); 
        
    dispatch({ type: INDEXFUND_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: INDEXFUND_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};


export const fetchIndicesFundList=(token)=>async (dispatch)=>{
  dispatch({ type: INDICES_FUND_REQUEST });
  try {
    const response = await fetchIndicesFund(token); 
        
    dispatch({ type: INDICES_FUND_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: INDICES_FUND_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
}

export const fetchMetalsList=(token)=> async (dispatch)=>{
  dispatch({type:METALS_REQUEST});
  try {
    const response = await fetchMetals(token); 
    console.log("Metal trader ---in Action",response);
        
    dispatch({ type: METALS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: METALS_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
}

export const fetchCurrenciesList=(token)=>async (dispatch)=>{
  dispatch({type:FETCH_CURRENCIES_REQUEST});
  try{
    const response=await fetchCurrencies(token);
    dispatch({type:FETCH_CURRENCIES_SUCCESS,payload:response.data});
  }catch(error){
    dispatch({
      type:FETCH_CURRENCIES_FAILURE,
      payload:error.response?.data.message || error.message,
    })
  }
}


export const fetchAllLeverage=(token,bodyData)=>async (dispatch)=>{  
  dispatch({type:FETCH_LEVERAGE_REQUEST});
  try{
    const response=await fetchLeverage(token,bodyData);     
    dispatch({type:FETCH_LEVERAGE_SUCCESS,payload:response.data});
  }catch(error){
    dispatch({
      type:FETCH_LEVERAGE_FAILURE,
      payload:error.response?.data.message || error.message,
    })
  }
}

export const fetchAllIndianMargin=(token,bodyData)=>async (dispatch)=>{  
  dispatch({type:FETCH_MARGIN_REQUEST});
  try{
    const response=await fetchMargin(token,bodyData);     
    dispatch({type:FETCH_MARGIN_SUCCESS,payload:response.data});
  }catch(error){
    dispatch({
      type:FETCH_MARGIN_FAILURE,
      payload:error.response?.data.message || error.message,
    })
  }
}





