import React, { useEffect, useState } from 'react'
import graphimage from '../../Images/graphimage.svg'
import '../Topmovers/Markets.css'
import applle from "../../Images/icons.svg"
import applle1 from "../../Images/icons1.svg"
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { gethasFlag, getUserToken } from '../../helper/getToken'
import axios from 'axios'



const baseUrl  = process.env.REACT_APP_API_FRONTEND;
const apikey = process.env.REACT_APP_API_FRONTEND_APIKEY;
// const tradewebapi = "https://financialmodelingprep.com/api/v3/";
// const flagBaseUrl = "https://flagcdn.com/w320";
const flagBaseUrl = process.env.REACT_APP_FLAG_WEBSITE_API;

const tradewebapi = process.env.REACT_APP_TRADE_WEBSITE_API;

const Upcommingevents = () => {
    const navigate = useNavigate();
    const shomoreHandler =()=>{
        navigate('/showmoreevents')
    }

    const token = getUserToken();

    const [allevents,setAllEvents] = useState([])
    const todaydate = new Date().toISOString().split("T")[0];
    const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];

    
    
    const getAllEvents = async()=>{
          let response = await axios.get(`${baseUrl}client/news/getUpcomingEvent?from=${todaydate}&to=${yesterdayDate}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })        
          setAllEvents(response.data);    }

    useEffect(()=>{
        getAllEvents();
    },[])

    let hasFlag = gethasFlag()

  return (
    <div className='upcommingdiv'>
        <div className='topheader'>
            <h4>Upcoming Events</h4>
            <button onClick={shomoreHandler}> Show more</button>
        </div>
        {/* <div className='card'>
            <div className='card-body'>
                
                
            </div>
        </div> */}
        {
            allevents && allevents?.payload?.map((events,index)=>{
                return(
                    <Link to={{
                        pathname: "/upcomingeventsdetails",
                        state: { event: events },
                    }}>
                        <div className='eventlist'>
                            <div className='topdiv'>
                                <span>
                                    {/* <img src={applle1}/>{events.title} */}
                                    <img src={`${flagBaseUrl}/${events?.country.toLowerCase()}.png`} alt={`${events?.country} flag`} width="20px" />
                                    <p>{events.event}</p>
                                </span>
                            </div>
                            <div className='bottomdiv'>
                                <p>{events?.country}</p>
                                <div className='linediv'>
                                    <div class="line red-line"></div>
                                    <div class="line blue-line"></div>
                                    <div class="line green-line"></div>
                                </div>
                                <p>{events?.date.slice(0,16)}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
        
        {/* <div className='eventlist'>
            <div className='topdiv'>
                <span><img src={applle}/>{eventsData[2].title}</span>
            </div>
            <div className='bottomdiv'>
                <h4>{eventsData[2].heading}</h4>
                <div className='linediv'>
                    <div class="line red-line"></div>
                    <div class="line blue-line"></div>
                    <div class="line green-line"></div>
                </div>
                <p>{eventsData[2].time}</p>
            </div>
        </div>
        <div className='eventlist'>
            <div className='topdiv'>
                <span><img src={applle1}/>{eventsData[3].title}</span>
            </div>
            <div className='bottomdiv'>
                <h4>{eventsData[3].heading}</h4>
                <div className='linediv'>
                    <div class="line red-line"></div>
                    <div class="line blue-line"></div>
                    <div class="line green-line"></div>
                </div>
                <p>{eventsData[3].time}</p>
            </div>
        </div> */}
    </div>
  )
}

export default Upcommingevents