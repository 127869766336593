import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BackArrow from '../Images/left-arrow.png';
import { useFormik } from "formik";
import * as Yup from "yup";
import { signIn } from '../Redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import Forgotmodal from '../Components/Popupmodal/Forgotmodal';
import Otpmodal from "../Components/Popupmodal/Otpmodal";
import Resetpasswordmodal from "../Components/Popupmodal/Resetpasswordmodal"; // Import the Reset password modal
import Swal from 'sweetalert2';
import { fetchUser } from '../Redux/actions/userAction';
import { getUserToken } from '../helper/getToken';
import { FiEye, FiEyeOff } from 'react-icons/fi'
import Otpmodal2 from '../Components/Popupmodal/Otpmodal2';

const SignIn = () => {

  const [isModalVisible, setModalVisible] = useState(false);  // Forgot password modal
  const [isModalVisible1, setModalVisible1] = useState(false); // OTP modal
  const [isModalVisible2, setModalVisible2] = useState(false); // Reset password modal
  const [isModalVisible3, setModalVisible3] = useState(false);
  const [forgotkey,setForgotkey] = useState('');
  const [loadingstate,setLoadingState] = useState(false);
  const {user, loading,error} = useSelector((state)=> state.user);
  const [showPassword, setShowPassword] = useState(false);

  

  const [email, setEmail] = useState(''); // Email state to pass between modals
  const [mobile, setMobile] = useState('');
  

  const openModal = () => {
    setModalVisible(true);  // Open Forgot Password modal
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, "At least 3 characters long")
      .max(55, "Cannot be longer than 55 characters")
      .matches(/^[A-Za-z0-9\s]+$/, "Only contain letters and spaces")
      .required("User id required"),

    // password: Yup.string()
    //   .min(6, 'Password must be at least 6 characters long')
    //   .matches(/[0-9]/, 'Password must contain a number')
    //   .matches(/[a-z]/, 'Password must contain a lowercase letter')
    //   .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    //   .matches(/[^\w]/, 'Password must contain a special character')
    //   .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    // onSubmit: async (values, { resetForm }) => {
    //   try {
    //     let response = await dispatch(signIn(values, navigate));        
    //     if(response?.data?.status === "SUCCESS"){  
    //       Swal.fire({
    //         width: "20rem",
    //         title: `${response.data.message}`,
    //         timer: 6000,
    //         timerProgressBar: true,  
    //         showConfirmButton: false, 
    //         customClass: {
    //           title: 'swaltext'
    //         } 
    //       });
    //       setTimeout(() => {            
    //         navigate('/signin');
    //       }, 2000);
          
    //     } else {
    //       Swal.fire({
    //         width: "20rem",
    //         title: `${response?.data?.message}`,
    //         timer: 6000,  
    //         timerProgressBar: true,  
    //         showConfirmButton: false,  
    //         customClass: {
    //           title: 'swaltext'
    //         } 
    //       }); 
    //       // alert("kdsflskjfsljs") 
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoadingState(true);
        const response = await dispatch(signIn(values, navigate));        
        if (response?.status === "SUCCESS") { 
          setLoadingState(false); 
          Swal.fire({
            width: "20rem",
            title: `${response.message}`,
            timer: 2000,
            timerProgressBar: true,  
            showConfirmButton: false, 
            customClass: {
              title: 'swaltext'
            } 
          });
    
          setTimeout(() => {            
            navigate('/accounts');
            
          }, 1000);
          
        } else {
          Swal.fire({
            width: "20rem",
            title: `${response?.message || 'Invalid credentials!'}`,
            timer: 6000,  
            timerProgressBar: true,  
            showConfirmButton: false,  
            customClass: {
              title: 'swaltext'
            } 
          }); 
        }
      } catch (error) {
        console.error("Error during sign-in:", error.message);
    
        Swal.fire({
          width: "20rem",
          title: error.message || "Something went wrong!",
          timer: 6000,
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            title: 'swaltext'
          }
        });
      }
    }
  });

  return (
    <div className='signinmain'>
      <div className='header'>
        <Link to="/"><img src={BackArrow} alt='' /></Link>
        <h2>Sign In</h2>
      </div>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className='signinform'>
            <p>Please enter your user id and password</p>
            <div className='emailinput'>
              <label>User Id</label>
              <input type='text'
                placeholder='User Id'
                name='username'
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="Formikerrormsg">{formik.errors.username}</div>
              ) : null}
            </div>
            <div className='emailinput'>
              <label>Password</label>
              {/* <input
                type='password'
                name='password'
                placeholder='Password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
              <div className="password-wrapper">
                  <input
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      placeholder='Password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  <span className="toggle-password" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="Formikerrormsg">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className='signregisterbtns'>
            <button type='submit' className='button1 mb-0' disabled={loadingstate}>{loadingstate ? ( <> Signing In <span className="spinner"></span> </> ) : ( "Sign In" )} </button>
            <p>New User? <Link style={{fontSize:"12px"}} to="/residence"><strong>Register Now</strong></Link></p>    
            <p onClick={openModal} className='mt-0'>I forgot my password</p>       
          </div>
        </form>
      </div>

      {/* Forgot Password Modal */}
      
      <Forgotmodal 
        isModalVisible={isModalVisible} 
        setModalVisible={setModalVisible} 
        setModalVisible3={setModalVisible3} 
        setEmail={setEmail} 
        setMobile={setMobile} 
      />

      <Otpmodal2 
        isModalVisible3={isModalVisible3} 
        setModalVisible1={setModalVisible1} 
        setModalVisible2={setModalVisible2} 
        setModalVisible3={setModalVisible3}
        email={email} 
        mobile={mobile}
        setForgotkey={setForgotkey}       
      />

      {/* Reset Password Modal */}
      <Resetpasswordmodal 
        isModalVisible2={isModalVisible2} 
        setModalVisible2={setModalVisible2} 
        setModalVisible1 = {setModalVisible1}
        isModalVisible1 = {isModalVisible1}
        setModalVisible3 = {setModalVisible3}
        isModalVisible3 = {isModalVisible1}
        email={email} 
        mobile={mobile}
        setForgotkey ={setForgotkey}
        forgot_key={forgotkey}  // Pass the forgot_key state
      />
    </div>
  );
}

export default SignIn;
