import { DELETE_ALERTS_SUCCESS, FETCH_ALERTS_SUCCESS } from "../authCostants";

// reducers/authReducer.js
const initialState = {
  loading: false,    
  error: null,
  user: null,
  position: null,
  fund:null,
  withdrawfund: null,
  buytrade: null,
  isDrawerOpen: false,
  // alerts:null,
  alerts: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USER_REQUEST':  
    case 'ADD_FUND_REQUEST': 
    case 'FETCH_USER_POSITION_REQUEST': 
    case 'WITHDRAW_FUND_REQUEST':
    case 'FETCH_ALERTS_REQUEST': 
    case 'BUY_TRADE_REQUEST':   
      return {
        ...state,
        loading: true,
        error: null,
      };      
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
    };
    case 'FETCH_USER_POSITION_SUCCESS':
      return {
        ...state,
        loading: false,
        position: action.payload,
        error: null,
    };
    case 'ADD_FUND_SUCCESS':
      return {
        ...state,
        loading: false,
        fund: action.payload,
        error: null,
      };
    case 'WITHDRAW_FUND_SUCCESS':
      return {
        ...state,
        loading: false,
        withdrawfund: action.payload,
        error: null,
      };
    case 'BUY_TRADE_SUCCESS':
      return {
        ...state,
        loading: false,
        buytrade: action.payload,
        error: null,
      };
      case FETCH_ALERTS_SUCCESS:
        return {
            ...state,
            loading: false,
            alerts: action.payload, // Replace alerts with fresh data from API
            error: null,
        };
    
    case DELETE_ALERTS_SUCCESS:
        return {
            ...state,
            alerts: state.alerts?.filter(alert => alert.id !== action.payload) || [], // Ensure array exists
        };
    case 'FETCH_USER_FAILURE':
    case 'FETCH_USER_POSITION_FAILURE':
    case 'ADD_FUND_FAILURE':
    case 'WITHDRAW_FUND_FAILURE': 
    case 'FETCH_ALERTS_FAILURE':
      return {
        ...state,
        loading:false,
        error:action.payload,
      }
    case 'BUY_TRADE_FAILURE':       
      return {
        ...state,
        loading: false,
        error: action.payload,
      }; 
    case 'OPEN_DRAWER':
      return { ...state, isDrawerOpen: true };
    case 'CLOSE_DRAWER':
      return { ...state, isDrawerOpen: false };
    case 'TOGGLE_DRAWER':
      return { ...state, isDrawerOpen: !state.isDrawerOpen };     
    default:
      return state;
  }
};