import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { searchMarketList } from '../../Redux/actions/tradeAction';
import { Card } from 'react-bootstrap';
import './Allmodal.css';
import { getUserToken, debounce } from '../../helper/getToken';
import Searchheader from './Searchheader';
import { useNavigate } from 'react-router-dom';
import Stockdetails from '../Bottomdrawer/Stockdetails';

const Searchpopup = ({ setShow, show }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('ALL');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [stockDetailsOpen, setStockDetailsOpen] = useState(false);
  const [idtoken,setIdToken] = useState()
  const { loading, error } = useSelector((state) => state.trade);
  const dispatch = useDispatch();

  console.log("idtokenidtokenidtokenidtokenidtokenidtoken",idtoken);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchSearchResults = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    const token = getUserToken();
    const formData = {
      query,
      exchange: selectedCurrency,
    };

    try {
      const results = await dispatch(searchMarketList(token, formData));
      if (isMounted.current && results.status === 'SUCCESS') {
        setSearchResults(results.payload);
      } else if (isMounted.current) {
        setSearchResults([]);
      }
    } catch (error) {
      if (isMounted.current) {
        console.error('Error fetching search results:', error);
        setSearchResults([]);
      }
    }
  };

  const debouncedFetch = debounce((query) => {
    if (isMounted.current && query.trim()) {
      fetchSearchResults(query);
    }
  }, 300);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }
    debouncedFetch(searchTerm);
  }, [searchTerm, selectedCurrency]);

  const labelClass = {
    NFO: 'label-red',
    MCX: 'label-blue',
    FOREX: 'label-green',
    CRYPTO: 'label-blue',
    US: 'label-red',
    CDS: 'label-blue',
  };

  const navigate = useNavigate();

  const handleOpen = (idtoken, exchange, moverData) => {
    console.log("setIdToken(idtoken)",idtoken);
    if (['forex', 'crypto', 'us'].includes(exchange.toLowerCase())) {
      navigate('/stockdetails', { state: { idtoken, exchange, moverData } });
    } else {
      setIdToken(idtoken)
      setStockDetailsOpen(true);
      
    }
  };

  const getPrice = (moverData) => {
    return (
      moverData?.ltp ||
      moverData?.bid_price ||
      moverData?.price ||
      moverData?.open_price ||
      moverData?.ask_price ||
      0
    );
  };

  const filteredResults = searchResults.filter(
    (instrument) => selectedCurrency === 'ALL' || instrument.exchange === selectedCurrency
  );

  return (
    <div className="editmodaldiv">
      {/* Exchange Filter Buttons */}     

      <Searchheader show={show} setShow={setShow} />

      <div className="acctabbtndiv2 p-2 mb-2">
        {['ALL', 'MCX', 'NFO', 'FOREX', 'US', 'CRYPTO', 'CDS'].map((exchange) => (
          <button key={exchange} className={`btn ${selectedCurrency === exchange ? 'currencybtn active' : ''}`} onClick={() => setSelectedCurrency(exchange)} style={{fontSize:"11px",padding:"0"}} > {exchange} </button>
        ))}
      </div>

      {/* Search Bar */}
      <div className="p-2 mb-2">
        <input
          type="text"
          placeholder="Search by trading symbol..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>

      <Modal.Body>
        {loading && <span className="loader"></span>}
        {error && <p>Error loading trades: {error.message}</p>}
        {filteredResults.length > 0 ? (
          filteredResults.map((instrument) => {
            const percentage_change = instrument?.percentage_change;
            const price_change =
              instrument?.price_change !== 0
                ? instrument?.price_change
                : Number(instrument?.high_price) - Number(instrument?.low_price);

            return (
              <Card
                className="border-0 p-0 mb-2 w-100"
                key={instrument.token}
                // onClick={() => handleOpen(instrument.symbol || instrument.token, instrument.exchange, instrument)}
                onClick={() => handleOpen(
                  ['FOREX', 'US', 'CRYPTO', 'CDS'].includes(instrument.exchange) ? instrument.symbol : instrument.token,
                  instrument.exchange,
                  instrument
                )}
              >
                <Card.Body className="p-3">
                  <div className="topdiv d-flex justify-content-between">
                    <div className="text-start">
                      <b>
                        {instrument.symbol}{' '}
                        <span className={`${labelClass[instrument?.exchange]} bage`}>
                          {instrument.exchange}
                        </span>
                      </b>
                    </div>
                    <div className="text-end">
                      <p style={{ color: percentage_change >= 0 ? 'green' : 'red' }}>
                        <b>{getPrice(instrument)}</b>
                      </p>
                    </div>
                  </div>
                  <div className="bottomdiv d-flex justify-content-between mt-2">
                    <div className="text-start">
                      {instrument.expiry ? <p>Expiry: {instrument.expiry}</p> : ''}
                    </div>
                    <div className="text-end" style={{ color: percentage_change >= 0 ? 'green' : 'red' }}>
                      <i
                        className={`fa ${percentage_change >= 0 ? 'fa-arrow-up' : 'fa-arrow-down'}`}
                        aria-hidden="true"
                      ></i>
                      {price_change} ({percentage_change})%
                    </div>
                  </div>
                </Card.Body>
                {stockDetailsOpen && (
                  <Stockdetails isDrawerOpen={stockDetailsOpen} searchedtoken = {idtoken} moverData={instrument} onClose={() => setStockDetailsOpen(false)} />
                )}
              </Card>
            );
          })
        ) : (
          <p>No results found.</p>
        )}
      </Modal.Body>
    </div>
  );
};

export default Searchpopup;
