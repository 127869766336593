import React, { useEffect } from 'react'
const Currencybtn = ({currencybtn,setSelectedCurrency,displaypropallstock}) => {
  const handleChangeCurrency=(currency)=>{
    localStorage.setItem("currency",currency);
    setSelectedCurrency(currency)
  }
  useEffect(()=>{
    const currency=localStorage.getItem('currency');
    if(currency){
      setSelectedCurrency(currency)
    }
  },[])
  return (
    <div>
        <div className='acctabbtndiv2'>
            {/* <button className={selectedCurrency === 'INR' ? 'active' : ''} onClick={() => setSelectedCurrency('INR')}>INR</button> */}
            <button className={currencybtn === 'MCX' ? 'active' : ''} onClick={() => handleChangeCurrency('MCX')} style={{display:`${displaypropallstock}`}}>MCX</button>
            <button className={currencybtn === 'NFO' ? 'active' : ''} onClick={() => handleChangeCurrency('NFO')}>NFO</button>            
            <button className={currencybtn === 'FX' ? 'active' : ''} onClick={() => handleChangeCurrency('FX')} style={{display:`${displaypropallstock}`}}>FX</button>
            <button className={currencybtn === 'US' ? 'active' : ''} onClick={() => handleChangeCurrency('US')}>US</button>
            <button className={currencybtn === 'CRYPTO' ? 'active' : ''} onClick={() => handleChangeCurrency('CRYPTO')} style={{display:`${displaypropallstock}`}}>CRYPTO</button>
            <button className={currencybtn === 'CDS' ? 'active' : ''} onClick={() => handleChangeCurrency('CDS')} style={{display:`${displaypropallstock}`}}>CDS</button>
        </div>
    </div>
  )
}

export default Currencybtn