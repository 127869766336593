// constants/authConstants.js
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const VERIFYEMAIL_REQUEST = 'VERIFYEMAIL_REQUEST';
export const VERIFYEMAIL_SUCCESS = 'VERIFYEMAIL_SUCCESS';
export const VERIFYEMAIL_FAILURE = 'VERIFYEMAIL_FAILURE';

export const SIGNOUT = 'SIGNOUT';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_USER_POSITION_REQUEST = 'FETCH_USER_POSITION_REQUEST';
export const FETCH_USER_POSITION_SUCCESS = 'FETCH_USER_POSITION_SUCCESS';
export const FETCH_USER_POSITION_FAILURE = 'FETCH_USER_POSITION_FAILURE';

export const ADD_FUND_REQUEST = 'ADD_FUND_REQUEST';
export const ADD_FUND_SUCCESS = 'ADD_FUND_SUCCESS';
export const ADD_FUND_FAILURE = 'ADD_FUND_FAILURE';

export const WITHDRAW_FUND_REQUEST = 'WITHDRAW_FUND_REQUEST';
export const WITHDRAW_FUND_SUCCESS = 'WITHDRAW_FUND_SUCCESS';
export const WITHDRAW_FUND_FAILURE = 'WITHDRAW_FUND_FAILURE';

export const BUY_TRADE_REQUEST = 'BUY_TRADE_REQUEST';
export const BUY_TRADE_SUCCESS = 'BUY_TRADE_SUCCESS';
export const BUY_TRADE_FAILURE = 'BUY_TRADE_FAILURE';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const FETCH_TRADE_REQUEST = 'FETCH_TRADE_REQUEST';
export const FETCH_TRADE_SUCCESS = 'FETCH_TRADE_SUCCESS';
export const FETCH_TRADE_FAILURE = 'FETCH_TRADE_FAILURE';

export const FETCH_WATCHLIST_REQUEST = 'FETCH_WATCHLIST_REQUEST';
export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS';
export const FETCH_WATCHLIST_FAILURE = 'FETCH_WATCHLIST_FAILURE';

export const DELETE_WATCHLIST_REQUEST = 'DELETE_WATCHLIST_REQUEST';
export const DELETE_WATCHLIST_SUCCESS = 'DELETE_WATCHLIST_SUCCESS';
export const DELETE_WATCHLIST_FAILURE = 'DELETE_WATCHLIST_FAILURE';

export const ADD_WATCHLIST_REQUEST = 'ADD_WATCHLIST_REQUEST';
export const ADD_WATCHLIST_SUCCESS = 'ADD_WATCHLIST_SUCCESS';
export const ADD_WATCHLIST_FAILURE = 'ADD_WATCHLIST_FAILURE';

export const SEARCH_WATCHLIST_REQUEST = 'SEARCH_WATCHLIST_REQUEST';
export const SEARCH_WATCHLIST_SUCCESS = 'SEARCH_WATCHLIST_SUCCESS';
export const SEARCH_WATCHLIST_FAILURE = 'SEARCH_WATCHLIST_FAILURE';

export const SEARCH_MARKETLIST_REQUEST = 'SEARCH_WATCHLIST_REQUEST';
export const SEARCH_MARKETLIST_SUCCESS = 'SEARCH_WATCHLIST_SUCCESS';
export const SEARCH_MARKETLIST_FAILURE = 'SEARCH_WATCHLIST_FAILURE';

export const FETCH_TOPMOVERS_REQUEST = 'FETCH_TOPMOVERS_REQUEST';
export const FETCH_TOPMOVERS_SUCCESS = 'FETCH_TOPMOVERS_SUCCESS';
export const FETCH_TOPMOVERS_FAILURE = 'FETCH_TOPMOVERS_FAILURE';

export const FETCH_TOPLOOSER_REQUEST = 'FETCH_TOPLOOSER_REQUEST';
export const FETCH_TOPLOOSER_SUCCESS = 'FETCH_TOPLOOSER_SUCCESS';
export const FETCH_TOPLOOSER_FAILURE = 'FETCH_TOPLOOSER_FAILURE';

export const FETCH_ALLTRADEDATA_REQUEST = 'FETCH_ALLTRADEDATA_REQUEST';
export const FETCH_ALLTRADEDATA_SUCCESS = 'FETCH_ALLTRADEDATA_SUCCESS';
export const FETCH_ALLTRADEDATA_FAILURE = 'FETCH_ALLTRADEDATA_FAILURE';

export const FETCH_LEVERAGE_REQUEST = 'FETCH_LEVERAGE_REQUEST';
export const FETCH_LEVERAGE_SUCCESS = 'FETCH_LEVERAGE_SUCCESS';
export const FETCH_LEVERAGE_FAILURE = 'FETCH_LEVERAGE_FAILURE';

export const FETCH_MARGIN_REQUEST = 'FETCH_MARGIN_REQUEST';
export const FETCH_MARGIN_SUCCESS = 'FETCH_MARGIN_SUCCESS';
export const FETCH_MARGIN_FAILURE = 'FETCH_MARGIN_FAILURE';


export const FETCH_ALLSTOCKS_REQUEST = 'FETCH_ALLSTOCKS_REQUEST';
export const FETCH_ALLSTOCKS_SUCCESS = 'FETCH_ALLSTOCKS_SUCCESS';
export const FETCH_ALLSTOCKS_FAILURE = 'FETCH_ALLSTOCKS_FAILURE';

export const FETCH_PRICESOCKET_REQUEST = 'FETCH_PRICESOCKET_REQUEST';
export const FETCH_PRICESOCKET_SUCCESS = 'FETCH_PRICESOCKET_SUCCESS';
export const FETCH_PRICESOCKET_FAILURE = 'FETCH_PRICESOCKET_FAILURE';

export const FETCH_NOTIFYSOCKET_REQUEST = 'FETCH_NOTIFYSOCKET_REQUEST';
export const FETCH_NOTIFYSOCKET_SUCCESS = 'FETCH_NOTIFYSOCKET_SUCCESS';
export const FETCH_NOTIFYSOCKET_FAILURE = 'FETCH_NOTIFYSOCKET_FAILURE';

export const INDEXFUND_REQUEST = 'INDEXFUND_REQUEST';
export const INDEXFUND_SUCCESS = 'INDEXFUND_SUCCESS';
export const INDEXFUND_FAILURE = 'INDEXFUND_FAILURE';

export const INDICES_FUND_REQUEST='INDICES_FUND_REQUEST';
export const INDICES_FUND_SUCCESS='INDICES_FUND_SUCCESS';
export const INDICES_FUND_FAILURE='INDICES_FUND_FAILURE'

export const METALS_REQUEST='METALS_REQUEST'
export const METALS_SUCCESS='METALS_SUCCESS'
export const METALS_FAILURE='METALS_FAILURE'

export const FETCH_CURRENCIES_REQUEST='FETCH_CURRENCIES_REQUEST'
export const FETCH_CURRENCIES_SUCCESS='FETCH_CURRENCIES_SUCCESS'
export const FETCH_CURRENCIES_FAILURE='FETCH_CURRENCIES_FAILURE'

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export const FETCH_ALERTS_REQUEST='FETCH_ALERTS_REQUEST'
export const FETCH_ALERTS_SUCCESS='FETCH_ALERTS_SUCCESS'
export const FETCH_ALERTS_FAILURE='FETCH_ALERTS_FAILURE'

export const DELETE_ALERTS_REQUEST = 'DELETE_ALERTS_REQUEST' 
export const DELETE_ALERTS_SUCCESS = 'DELETE_ALERTS_SUCCESS' 
export const DELETE_ALERTS_FAILURE = 'DELETE_ALERTS_FAILURE' 




