import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Otpmodal from '../Popupmodal/Otpmodal';
import rightarrow from '../../Images/chevronright.svg';
import copyicon from '../../Images/contentcopy.svg';

const baseUrl = process.env.REACT_APP_API_FRONTEND;

const AccountSettings = ({ setForgotkey,selectedCurrency,leverage }) => {
    const [loadingState, setLoadingState] = useState(false);
    const [isModalVisible2, setModalVisible2] = useState(false); // State to control Otpmodal visibility
    const { user } = useSelector((state) => state.user);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedLeverage, setSelectedLeverage] = useState("1:2000");
  
    const toggleAccordion = () => {
      setIsOpen((prev) => !prev);
    };
  
    const handleLeverageSelect = (leverage) => {
      setSelectedLeverage(leverage);
      setIsOpen(false); // Close accordion after selection
    };

    const navigate = useNavigate();

    const copyText = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert('Text copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy text', err);
            });
    };

    const handlePasswordChange = async () => {
        // const email = user?.payload.email;
        // const formData = { email };
        // setLoadingState(true);
        // try {
            
        //     const response = await axios.post(`${baseUrl}forgotPassword`, formData);
        //     if (response?.data?.status === 'SUCCESS') {
        //         setModalVisible2(true); // Open the OTP modal
        //     } else {
        //         console.log('Something went wrong.');
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        // } finally {
        //     setLoadingState(false); // Stop the loading spinner
        // }
        setModalVisible2(true); // Open the OTP modal
    };

    const customHandler = () => {
        navigate('/customname');
    };

    return (
        <div>
           

            {/* Account details - 2 */}
            <div className="detailsheading">
                <h4>STANDARD ACCOUNT</h4>
            </div>
            <div className="card">
                <div className="card-body accounttypediv">
                    <div className="accounttypedivinner">
                        <p>No commission</p>
                        <p></p>
                    </div>
                    <div className="accounttypedivinner">
                        <p>Minimum spread</p>
                        <p></p>
                    </div>
                   
                    <div className="accounttypedivinner">
                        {/* <p>Maximum leverage</p> */}
                        <div className="accordion w-100">
                            <button onClick={toggleAccordion} className="accordion-header w-100" >
                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                    <p>Maximum leverage</p>
                                    <span> <img src={rightarrow} alt="right-arrow" className={isOpen ? "rotate-arrow" : ""} /></span>
                                </div>
                            </button>

                            {isOpen && (
                            <div className="accordion-content">
                                <ul className="accordion-options mt-3" style={{listStyle:"none", padding:"0", width:"95%", margin:"0 auto"}}>
                              
                                   {
                                    selectedCurrency ==="INR"?(
                                        <>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage MCX</div>
                                                <div>{leverage?.mcx?.intraday}</div>
                                            </li>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage NFO</div>
                                                <div>{leverage?.mcx?.intraday}</div>
                                            </li>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage CDS</div>
                                                <div>{leverage?.mcx?.intraday}</div>
                                            </li>
                                        </>
                                    ):(
                                        <>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage FOREX</div>
                                                <div>{leverage?.forex?.intraday}</div>
                                            </li>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage CRYPTO</div>
                                                <div>{leverage?.crypto?.intraday}</div>
                                            </li>
                                            <li className='d-flex justify-content-between align-items-center'>
                                                <div>Leverage US</div>
                                                <div>{leverage?.us?.intraday}</div>
                                            </li>
                                        </>
                                    )
                                   }
                                   
                                </ul>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Account details - 3 */}
            <div className="detailsheading">
                <h4>Trading platform - MetaTrader 5</h4>
            </div>
            <div className="card">
                <div className="card-body accounttypediv">
                    <div className="accounttypedivinner">
                        <p>Login</p>
                        <div className="innerdiv">
                            <button onClick={() => copyText(`${user?.payload?.username}`)}>
                                {/* 148685408 */}
                                {
                                    user?.payload?.username
                                }
                                <img src={copyicon} alt="copy-icon" />
                            </button>
                        </div>
                    </div>
                    <div className="accounttypedivinner">
                        <p>Server</p>
                        <div className="innerdiv">
                            <button onClick={() => copyText(`${process.env.REACT_APP_COMPANY_NAME}-MT5Real18`)}>
                            {process.env.REACT_APP_COMPANY_NAME}-MT5Real18
                                <img src={copyicon} alt="copy-icon" />
                            </button>
                        </div>
                    </div>
                    <div className="accounttypedivinner">
                        <p>Change trading password</p>
                        <div>
                            <button onClick={handlePasswordChange}>
                                <img src={rightarrow} alt="right-arrow" />
                            </button>
                        </div>
                    </div>
                    
                </div>
            </div>

            {/* OTP Modal */}
            {isModalVisible2 && (
                <Otpmodal
                    isModalVisible1={isModalVisible2} // Pass the state to the modal
                    setModalVisible1={setModalVisible2} // Pass the function to close the modal
                    email={user?.payload.email}
                    setForgotkey={setForgotkey}
                />
            )}
        </div>
    );
};

export default AccountSettings;
