import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NewHeader from '../Header/NewHeader';
import './Markets.css';
import { useDispatch, useSelector } from 'react-redux';
import { getPrice, getUserToken } from '../../helper/getToken';
import { userTradeBuy } from '../../Redux/actions/userAction';
import { fetchAllIndianMargin } from '../../Redux/actions/tradeAction';
import { formatNumber } from '../../helper/commonFunctions';

const Sellpage = () => {
    //const [selectedOption, setSelectedOption] = useState('LIMIT');    
    const [selectedOption, setSelectedOption] = useState('MARKET');    
    // const [lots, setLots] = useState(1);
    const [price, setPrice] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const { user,loading,error } = useSelector((state) => state.user);
     const {indianmargin} = useSelector((state)=>state.trade)
    const [livePrices,setLiveprices]=useState({})
    const {priceSocket}=useSelector(state=>state.sockets)

    const dispatch = useDispatch();
    const token = getUserToken();
    const navigate = useNavigate(); 

    useEffect(()=>{
        if(moverData&&priceSocket){
            const event=moverData?.exchange==="FOREX"||moverData?.exchange==="CRYPTO"||moverData?.exchange==="US"?`forexRoom${moverData?.symbol.toLowerCase()}` : `room${moverData?.token}`
            const emitData=moverData?.exchange==="FOREX"||moverData?.exchange==="CRYPTO"||moverData?.exchange==="US"?moverData?.symbol.toLowerCase():moverData?.token
            priceSocket.emit('tokenData',emitData);
            priceSocket.on(event,(updatedInstrument)=>{
                let typeNmae=updatedInstrument.exchange === 'FOREX' || updatedInstrument.exchange=== "CRYPTO" || updatedInstrument.exchange=== "US" ? updatedInstrument.symbol.toLowerCase() : updatedInstrument.token;
                setLiveprices((prevState)=>({
                    ...prevState,
                    [typeNmae]:updatedInstrument
                }))
            })
        }
        return ()=>{
            setLiveprices({})
        }
    },[priceSocket])

    const handleClick = (option) => {
        setSelectedOption(option);
        // if (option === 'MARKET') {
            setPrice(moverData.ltp);
        // } else if (option === 'LIMIT') {
            // setPrice(''); 
        // }
    };
    

    // const handlePriceChange = (e) => {
    //     setPrice(e.target.value);
    // };
    const handlePriceChange = (event) => {
        const { value } = event.target;
        if (selectedOption === 'LIMIT') {
            const regex=/^[0-9]*\.?[0-9]*$/
            if(regex.test(value)){                
                setPrice(value);
            }else{
                event.preventDefault();
            }            
        }
    };

       
    const handleAddQty=(e)=>{
        const value=e.target.value;
        const regex=/^[0-9]*\.?[0-9]*$/
        if(regex.test(value)){
            setQty(value);
            // const newLots=parseFloat(Number(value)/Number(moverData.lotsize)).toFixed(2)
            const newLots=parseFloat(Number(value)/Number(currentData.lotsize)).toFixed(2)

            setLots(newLots)
        }else{
            e.preventDefault();
        }
    }
    const handleAddLots=(e)=>{
        const value=e.target.value;
        const regex=/^[0-9]*\.?[0-9]*$/
        if(regex.test(value)){
            setLots(value)
            // const newQty=Number(value)*Number(moverData.lotsize);
            const newQty=Number(value)*Number(currentData.lotsize);
            setQty(newQty)
        }else{
            e.preventDefault();
        }
    }
    const handleQtyChange = (amount) => {
        const newQty=amount>0?Number(qty)+1:Number(qty)-1;
        setQty((prevQty) => amount>0?Math.max(1, Number(prevQty) + 1):Math.max(1, Number(prevQty) - 1)); // Minimum quantity is 1
        // const newLots=parseFloat(Number(newQty)/Number(moverData.lotsize)).toFixed(2);
        const newLots=parseFloat(Number(newQty)/Number(currentData.lotsize)).toFixed(2);
        setLots(newLots);
    };

    const handleLotsChange = (amount) => {        
        const newLots = Math.max(0, Math.round(lots + amount));
        setLots(newLots);    
        // const newQty = newLots * moverData.lotsize;
        const newQty = newLots * currentData.lotsize;
        setQty(newQty);
    };

    const location = useLocation();
   
    // const { currentData: moverData = {}, positionMoverdata = {} } = location.state || {};
    const { currentData = {}, moverData = {} } = location.state || {};
    // console.log(moverData,currentData,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
    // const [qty, setQty] = useState(Number(positionMoverdata?.qty ? positionMoverdata?.qty : moverData?.lotsize));
    const calculateProfitLoss = (item) => {
        
        const livePrice = item?.ltp || item?.bid_price || item?.ask_price || item?.avg_price ;
        // console.log(currentData,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        let factor=1;
        if(item?.type=='sell'){
            factor=-1;
        }
        const quantity = parseFloat(item?.qty);
        const avgPrice = parseFloat(item?.avg_price);
        const currentPrice = parseFloat(livePrice);
        
        const profitLoss = ((currentPrice - avgPrice) * quantity*factor);
        // console.log(item,'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        return formatNumber(profitLoss);
    };
    const [qty, setQty] = useState(Math.floor(Number(moverData.qty ? moverData.qty : currentData.lotsize))); 
    const buyorselllot = Number(qty / currentData.lotsize);
    
    
    const [lots, setLots] = useState(buyorselllot ? buyorselllot : 1); // Initial lot value  

    const handleSubmit = async(e) => {
        e.preventDefault();        
        const formData = {
            token: currentData.token,
            // price: selectedOption === 'MARKET' ? (currentData.ltp * qty) : (price * qty),
            price: selectedOption === 'MARKET' ? Math.round(currentData.ltp * qty) : Math.round(price * qty),
            order_type: selectedOption.toLowerCase(),
            type: 'sell',
            qty: Math.floor(qty),
            from_custom_device_id: 'web',
        };

        try {
            // Dispatch action to add fund
            const response = await dispatch(userTradeBuy(formData, token,navigate));  
            console.log("responseresponseresponseresponse",response);          
            if (response && response.status === 'SUCCESS') {   
              localStorage.setItem('selectedcurrency','INR'); 
              localStorage.setItem('ordertabname','open');          
              setPopupMessage(response.message);
              setShowPopup(true);              
              setTimeout(() => {
                navigate('/accounts');
              }, 2000);
            }else{
                setPopupMessage(response.message);
                setShowPopup(true); 
                // navigate('/sell');
            }
          } catch (error) {
            console.error("Error adding funds:", error);
            setPopupMessage(error);
            setShowPopup(true);
            
          }        
    };

    const bodyData={
        token:moverData.token
    }
   
     useEffect(() => {
        dispatch(fetchAllIndianMargin(token,bodyData));
    }, [dispatch,moverData]);

    
    let intradaymarginsell = indianmargin?.payload?.MIS?.sell?.margin || 0;
    let overnightmarginsell = indianmargin?.payload?.CNC?.sell?.margin || 0;
    let typeName=moverData?.exchange ==='FOREX' || moverData?.exchange === "US" || moverData?.exchange === "CRYPTO" ? moverData?.symbol.toLowerCase() : moverData?.token;
    const liveData=livePrices[typeName]
    let profitLoss=calculateProfitLoss({...moverData,...liveData})
    // console.log(currentData,'ooooooooooooooooooooooooooooooooooooooooooooooo')
    
    return (
        <div className='buyerdiv'>
            <NewHeader title={moverData?.trading_symbol} addicon="none" />
            <div className='namediv'>
                <div className='nameflex upperdiv'>
                    <h4>{currentData?.trading_symbol} ({currentData.exchange})</h4>
                    <h4>{liveData?.ltp||currentData?.ltp||moverData?.ltp}</h4>
                </div>
                <div className='nameflex lowerdiv'>
                    <p><b>Lot Size: {currentData.lotsize?currentData.lotsize :moverData.lotsize }</b></p>
                    <p className='fontstylesmall' style={liveData?.price_change||currentData?.price_change>=0?{color:"green"}:{color:"red"}}><b> {`${liveData?.price_change||currentData?.price_change||0} (${liveData?.percentage_change||currentData?.percentage_change||0}%)`}</b></p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='pricedivmain'>
                    <p>Price</p>                    
                    <div className='pricediv'>
                        <div className='w-50'>
                            {/* <input type='number' className='form-control' name='price' value={selectedOption === 'MARKET' ? moverData.ltp : price} onChange={handlePriceChange} disabled={selectedOption === 'MARKET'} placeholder={selectedOption === 'MARKET' ? 'Market Price' : 'Limit Price'} /> */}
                            <input 
                                type='text' 
                                className='form-control' 
                                name='price' 
                                value={selectedOption === 'MARKET' ? liveData?.ltp||currentData.ltp : price} 
                                onChange={handlePriceChange} 
                                disabled={selectedOption === 'MARKET'} 
                                placeholder={selectedOption === 'MARKET' ? 'Market Price' : 'Limit Price'} 
                            />
                        </div>
                        <div className='switcher-container'>
                            <button type='button' className={`switcher-button1 ${selectedOption === 'LIMIT' ? 'active' : ''}`} onClick={() => handleClick('LIMIT')} > LIMIT </button>
                            <button type='button' className={`switcher-button1 ${selectedOption === 'MARKET' ? 'active' : ''}`} onClick={() => handleClick('MARKET')} > MARKET </button>
                        </div>
                    </div>
                </div>
                {/* <div className="qty-lots-containersell">
                    <div className="qty-control">
                        <p>Qty</p>
                        <div className="control-buttons">
                            <button type="button" onClick={() => handleQtyChange(-1)}>-</button>
                            <input type="text" value={moverData.qty ? moverData.qty :qty} readOnly />
                            <button type="button" onClick={() => handleQtyChange(1)}>+</button>
                        </div>
                    </div>
                    <div className="lots-control">
                        <p>Lots</p>
                        <div className="control-buttons">
                            <button type="button" onClick={() => handleLotsChange(-0.1)}>-</button>
                            <input type="text" value={lots} readOnly />
                            <button type="button" onClick={() => handleLotsChange(0.1)}>+</button>
                        </div>
                    </div>
                </div> */}
                <div className='qty-lots-container'>
                    <div className='qty-control1'>
                        <p>Qty</p>
                        <div className='control-buttons'>
                            <button type='button' onClick={() => handleQtyChange(-1)}>-</button>
                            <input type='text' value={qty} onChange={handleAddQty} />
                            <button type='button' onClick={() => handleQtyChange(1)}>+</button>
                        </div>
                    </div>

                    <div className='lots-control1'>
                        <p>Lots</p>
                        <div className='control-buttons'>
                            <button type='button' onClick={() => handleLotsChange(-1)}>-</button>
                            <input type='text' value={lots} onChange={handleAddLots} />
                            <button type='button' onClick={() => handleLotsChange(1)}>+</button>
                        </div>
                    </div>
                </div>
                <div className='balancediv'>
                    <h4>Available Balance: {user?.payload?.client_account.balance}</h4>
                </div>
                
                <div className='graphdiv'></div>
                <div className='intradaydivsell'>
                    {/* <button type="button">Intraday : {(qty * price) / intradaymarginsell}</button>
                    <button type="button">Overnight : {(qty * price) / overnightmarginsell}</button> */}
                    {/* <button type='button'>Intraday: {Number((qty * (getPrice(currentData).toFixed(2)))) / intradaymarginsell}</button>
                    <button type='button'>Overnight: {Number((qty * (getPrice(currentData).toFixed(2)))) / overnightmarginsell}</button> */}
                    <button type='button'>Intraday: {Number((qty * getPrice(liveData||currentData)) / intradaymarginsell).toFixed(2)}</button>
                    <button type='button'>Overnight: {Number((qty * getPrice(liveData||currentData)) / overnightmarginsell).toFixed(2)}</button>
                </div>
                {moverData?.type!=null&&moverData?.type!=undefined&&<div style={{display:'flex',padding:"0 15px"}}><p>P & L : </p><strong><p style={profitLoss<0?{color:"red",marginLeft:"10px"}:{color:"green",marginLeft:"10px"}}>{profitLoss}</p></strong></div>}
                <div className='buybtnsell'>
                    <button type="submit">SELL</button>
                </div>
            </form>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                    <p>{popupMessage}</p>
                    <button onClick={() => setShowPopup(false)} >Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sellpage;
