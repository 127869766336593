// Accountdeposite component
import React, { useState } from 'react';
import NewHeader from '../Header/NewHeader';
import astropay from '../../Images/astropay-logo.png';
import bianance from '../../Images/Binance_LOGO.png';
import neteller from '../../Images/neteller.png';
import perfectpay from '../../Images/perfect-money.png';
import skrillmoney from '../../Images/skrill_logo.png';
import stickeypay from '../../Images/STATICPAY.png';
import Customselect from './Customselect';
import './Customname.css';
import { useDispatch } from 'react-redux';
import { addFundInaccount } from '../../Redux/actions/userAction';
import { getUserToken } from '../../helper/getToken';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Accountdeposite = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [amount, setAmount] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const apptype = queryParams.get("apptype");

  console.log("apptypeapptypeapptypeapptypeapptypeapptype",apptype);

  const [popupMessage, setPopupMessage] = useState(''); // State for popup message
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [loadingstate,setLoadingState] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const token = getUserToken(); // Correctly retrieve token

  // Currency options for the CustomSelect
  const currencyOptions = [
    { label: 'INR', value: 'INR' },
    { label: 'USD', value: 'USD' },
  ];

  const handleCurrencySelect = (selectedOption) => {
    setSelectedCurrency(selectedOption.value);
  }; 

 
  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload
    setLoadingState(true); // ✅ Start loading
  
    if (!amount || isNaN(amount) || amount <= 0) {
      setPopupMessage('Please enter a valid amount');
      setShowPopup(true);
      setLoadingState(false); // ✅ Stop loading on validation failure
      return;
    }
  
    const formData = {
      amount: amount,
      currency: selectedCurrency,
    };
  
    try {
      // Dispatch action to add fund
      const response = await dispatch(addFundInaccount(formData, token, navigate));
  
      if (response.status === "SUCCESS") {
        if(apptype==="android" || apptype ==="ios"){
          navigate(response.payload.url)
        }else{
          window.location.href = response.payload.url;
        }
        
      } else {
        setPopupMessage(response.message);
        setShowPopup(true);
        setAmount(0);
        setSelectedCurrency('INR');
      }
    } catch (error) {
      console.error("Error adding funds:", error);
      setPopupMessage('Failed to add amount.');
      setShowPopup(true);
      setAmount(0);
      setSelectedCurrency('INR');
    } finally {
      setLoadingState(false); // ✅ Stop loading in all cases
    }
  };
  

  return (
    <div className="accountsmain">
      <NewHeader title="Deposit" addicon="none" />
      <div className="topmovermain2">
        <div className="depositemain">
          <div className="depositeinner">
            <form onSubmit={handleSubmit}>
              <div className="depositeform">
                <input type="number" name="amount" className="form-control" placeholder="0" autoComplete="off" value={amount} onChange={(e) => setAmount(e.target.value)} />
                <Customselect options={currencyOptions} placeholder="Currency" onSelect={handleCurrencySelect} />
              </div>
              <button type="submit" className="form-control mt-2"> {loadingstate?(<>Submit<span className='spinner'></span></>):"Submit"} </button>
            </form>
          </div>
        </div>
      </div>
      {/* Popup for success or error messages */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={() => setShowPopup(false)} >Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accountdeposite;
